.text-image.grid {
  padding-left: 0;
  padding-right: 0;
  align-items: center;

  @media (min-width: 769px) {
    grid-auto-flow: dense;
  }

  &.reversed {
    .text-image__text {
      @media (min-width: 769px) {
        grid-column: 7 / 12;
      }
    }

    .text-image__img {
      @media (min-width: 769px) {
        grid-column: 2 / 6;
      }
    }
  }
}

.text-image__text {
  grid-column: 2 / -2;

  @media (min-width: 769px) {
    grid-column: 2 / 7;
    margin-bottom: 0;
  }

  /* WYSIWYG items */
  p {
    @media (min-width: 769px) {
      font-size: calc(16rem / 14);
    }

    line-height: calc(28 / 16);
  }

  a {
    font-size: inherit;
  }

  // h1,h2,h3,h4,h5,h4,ul,li --> TODO: Bespreek met Jantine

  .link-button:last-child {
    margin-bottom: 0;
  }
}

.text-image__img {
  margin: 0;
  grid-column: 2 / -2;

  @media (min-width: 769px) {
    grid-column: 8 / 12;
  }

  img {
    max-width: 100%;
    display: block;
    width: 100%;
  }
}

/* Inline version*/
.text-image.grid.text-image--inline {
  .text-image__text {
    grid-column: 1 / -1;

    @media (min-width: 769px) {
      grid-column: 7 / 13;
    }
  }

  .text-image__img {
    grid-column: 1 / -1;

    @media (min-width: 769px) {
      grid-column: 1 / 6;
    }
  }

  &.base-grid {
    margin-top: 3em;
  }
}

.text-image--inline__main-title {
  font-size: calc(18rem / 14);
  line-height: calc(28 / 18);
  text-transform: none;
  letter-spacing: 0;
  font-family: $font-family-name__header;
  margin: 20px 0;
  font-weight: 300;

  @media (min-width: 769px) {
    margin: 0 0 calc(35 / 1440 * 100vw) 0;
  }

  @media (min-width: 1440px) {
    margin-bottom: 35px;
  }
}

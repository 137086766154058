.article-next {
  padding: calc(80 / 1440 * 100vw) 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  margin-bottom: 0;

  &:after {
    display: none;
  }

  &:before {
    background-color: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.article-next__inner {
  padding-left: 0;
  padding-right: 0;
  position: relative;
  z-index: 2;
}

.article-next__heading {
  color: white;
}

.article-next__subtitle {
  font-family: $font-family-name__cta;
}
.article-next__title {
  color: white;
  font-size: calc(24rem / 14);

  @media (min-width: 769px) {
    font-size: calc(50rem / 14);
  }
}

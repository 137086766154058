#main-header .panel {
  display: none;
} // TODO: should be removed in the backend

.page-wrapper {
  position: relative;
  z-index: 1;
  padding-top: calc(58rem / 14);

  @media (min-width: 1380px) {
    padding-top: 8.6rem; // Account for fixed header
  }
}

.ReactModalPortal {
  position: relative;
  z-index: 10;
}

.page-wrapper .page-header {
  z-index: 11;
}

.page-header .header.content {
  z-index: 2;
  display: grid;
  @extend .base-grid;

  @media (max-width: 1379px) {
    padding: 0;
  }

  @media (min-width: 1380px) {
    align-items: center;
    grid-template-columns: calc(((100 / 12) * 3) * 1%) 1fr 100px 100px calc(
        50rem / 14 + 60px
      );

    &:after {
      display: none;
    }
  }

  @media (min-width: 1380px) {
    grid-template-columns: calc(((100 / 12) * 2) * 1%) 1fr 100px 100px calc(
        50rem / 14 + 60px
      );
  }
}

body.nav-open .page-header .header.content {
  @media (max-width: 1380px) {
    display: grid;
    grid-template-rows: repeat(4, min-content);
    height: 100vh;
    padding-bottom: 0;
    overflow-y: scroll;
    overflow-x: hidden; // The transformed rotated arrow causes a scrollbar
  }
}

// Change the order of the header items for mobile navigation
.header.content {
  @media (max-width: 1379px) {
    .block-search {
      order: 1;
    }

    .navigation {
      order: 2;
    }

    .header.links {
      order: 3;
    }

    .minicart-wrapper {
      order: 4;
    }

    .help {
      order: 5;
    }
  }
}

.header {
  .contact {
    display: none !important;
  }
}

.header.content {
  height: 100%;
}

.header__mobile-container,
.header.content {
  position: relative;
}

.header__mobile-container {
  @media (min-width: 1380px) {
    grid-column: span 2;
  }
}

/* Style help based on context (mobile header) */
.header.content .help {
  align-self: end;
  padding: 30px 1rem;
  display: none;
}

body.nav-open .header.content .help {
  display: block;

  @media (min-width: 1380px) {
    display: none;
  }
}

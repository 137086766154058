.paragraph-heading {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: calc(
    50 / 1440 * 100vw
  ); // TODO: extract implementation detail in context
}

.paragraph-heading__title {
  margin: 0;
  font-size: calc(24rem / 14);

  @media (min-width: 769px) {
    font-size: calc(30rem / 14);
  }
}

.paragraph-heading__subtitle {
  display: block;
  margin-bottom: 0.5rem;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: calc(10rem / 14);

  @media (min-width: 769px) {
    margin-bottom: 1rem;
    font-size: calc(12rem / 14);
  }
}

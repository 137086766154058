.custom-radio-container {
  input {
    visibility: hidden;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
  }

  input[type='radio']:checked + .custom-radio:after {
    transform: scale(0.6);
  }
}

/* Custom radio */
.custom-radio {
  position: relative;
  cursor: pointer;
  display: flex;

  /*circle*/
  &:before {
    content: '';
    height: 1.2rem;
    width: 1.2rem;
    background: white;
    display: block;
    position: relative;
    top: 1px;
    border-radius: 50%;
  }

  /*dot*/
  &:after {
    content: '';
    height: 1.2rem;
    width: 1.2rem;
    background-color: black;
    left: 0;
    top: 1px;
    position: absolute;
    display: block;
    transform: scale(0);
    transition: transform 250ms cubic-bezier(0.6, 0, 0.01, 0.99);
    border-radius: 50%;
  }
}

.custom-radio__text {
  margin-left: 0.8571428571rem;
  line-height: 1;
  flex: 1;
  font-size: 0.8571428571rem;
  font-style: italic;
  font-family: $font-family-name__base;

  a {
    font-family: inherit;
    font-size: inherit;
    display: inline-block;
    margin-bottom: 0;
  }
}

.opc-summary-wrapper:focus {
  outline: none;
}

.opc-block-summary {
  & > .title {
    display: block;
    font-family: $font-family-name__h1;
    font-size: 1.71rem;
    font-weight: $font-weight__semibold;
    line-height: $h1__line-height;
    color: $verno-header-primary;
  }

  .table-caption {
    display: none;
  }

  tr {
    text-align: left;
  }

  .table-totals {
    width: 100%;
  }
}

.opc-block-summary,
.cart-totals,
.table-totals {
  margin-bottom: 3.92rem;
  font-family: $font-family__base;
  font-size: $root__font-size;
  font-weight: $font-weight__light;
  line-height: $line-height__base;
  margin-bottom: $indent__base;

  th,
  .excl th strong,
  .shipping .mark .label {
    font-weight: normal;
  }

  .table {
    width: 100%;
  }

  .mark {
    text-align: left;

    .label {
      font-weight: bold;
    }
  }

  tbody {
    display: flex;
    flex-direction: column;
  }

  .totals-tax,
  .totals {
    .mark {
      width: 100%;
    }

    &.grand.incl,
    .grand.incl {
      border-top: 2px solid $verno-light-green;
      font-size: 1.25rem;
      font-family: $font-family-name__h1;
      order: 1;
      padding-top: 1rem;
    }

    .amount {
      text-align: right;
    }

    .price {
      font-size: 18px;
    }

    &.incl:not(.shipping) .price,
    .incl:not(.shipping) .price {
      font-size: 25px;
    }
  }

  .shipping span {
    display: inline;
  }
}

.summary.title {
  display: none;
}

.custom-checkbox-container {
  input {
    visibility: hidden;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
  }

  input[type='checkbox']:checked + .custom-checkbox:after {
    transform: scale(1);
  }
}

/* Custom checkbox */
.custom-checkbox {
  position: relative;
  cursor: pointer;
  display: flex;
  font-size: 0.8571428571rem;

  /*square*/
  &:before {
    content: '';
    height: 16px;
    width: 16px;
    background: white;
    display: block;
    position: relative;

    // The top depends on the font size of custom-checkbox__text, because it needs to be in the middle of the line
    top: 0px;
    border: 2px solid black;
  }

  /*check*/
  &:after {
    content: '';
    height: 10px;
    width: 10px;
    background-color: black;
    left: 3px;

    // The top depends on the font size of custom-checkbox__text
    top: 3px;
    position: absolute;
    display: block;
    transform: scale(0);
    transition: transform 250ms cubic-bezier(0.6, 0, 0.01, 0.99);
  }
}

.custom-checkbox__text {
  margin-left: 0.8571428571rem;
  line-height: 1.5;
  flex: 1;
  font-size: inherit;
  font-style: italic;
  font-family: $font-family-name__base;
  position: relative;
  top: -2px;

  a {
    font-family: inherit;
    font-size: inherit;
    display: inline-block;
    margin-bottom: 0;
    text-decoration: underline;
  }
}

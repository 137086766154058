@mixin link() {
  color: $color-black;
  text-decoration: none;
  position: relative;
  display: inline-block;

  &:after,
  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 100%;
    background: $verno-link-color;
  }

  &:before {
    width: 0;
    transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99);
  }

  &:after {
    right: 0;
    transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99) 100ms;
  }

  @media (hover: hover) {
    &:hover:after {
      width: 0;
      transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99);
    }

    &:hover:before {
      width: 100%;
      transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99) 100ms;
    }
  }

  &--clean {
    &:before,
    &:after {
      display: none;
    }
  }

  // White versions
  &.white {
    color: white;
  }
}

.header.content .block-search {
  display: none;

  .nav-open & {
    display: block;
  }

  @media (min-width: 1380px) {
    display: block;
  }

  .block-content {
    display: block;

    @media (min-width: 1380px) {
      display: none;
      height: 8.6rem;
    }

    &.active {
      display: block;

      @media (min-width: 1380px) {
        display: grid;
      }
    }

    @media (min-width: 1380px) {
      grid-template-columns: repeat(12, [col-start] 1fr);
      row-gap: 0;
      column-gap: 2px;
      max-width: 110.769rem;
      width: 100vw;
      position: absolute;
      left: 50%;
      bottom: 1px; //fix strange alignment
      transform: translateX(-50%) translateY(100%);
      top: auto !important;
      padding: 2rem 4.2rem;

      &:before {
        content: '';
        position: absolute;
        left: calc((-100vw + 110.7692307692rem) / 2);
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        transition: background 250ms cubic-bezier(0.6, 0, 0.01, 0.9);
        background-color: $verno-light-green;
        z-index: -1;

        @media (min-width: 1550px) {
          left: calc((-100vw + 110.7692307692rem) / 2);
        }
      }
    }
  }
}

.block-search {
  label,
  .block-title {
    display: none;
    cursor: pointer;
  }

  .block-title {
    @media (min-width: 1380px) {
      display: block;
      padding: 1rem 0;

      svg {
        display: block;
        margin: 0 auto;
        height: 1.375rem;

        g {
          stroke: $verno-white;
        }
      }
    }
  }

  .control {
    background: $search-input-bg;
  }

  input {
    border: none;
    margin: 0;
    padding: 1.2rem 1rem;
  }
}

.customer-account-createpassword .block-title svg g,
.contentmanager-content-view .block-title svg g,
.checkout-onepage-success .block-title svg g,
.cms-page-view .page-header .block-title svg g,
.checkout-cart-index .page-header .block-title svg g,
.account .page-header .block-title svg g,
.cms-no-route .page-header .block-title svg g,
.customer-account-create .page-header .block-title svg g,
.customer-account-login .page-header .block-title svg g,
.catalog-product-view .page-header .block-title svg g,
.page-header.is-scrolled .block-title svg g,
.page-header.is-active .block-title svg g {
  stroke: $verno-black;
}

.header input#search {
  padding: 0 1rem;
  height: 50px;
  font-size: 0.8571428571rem;
  font-family: $font-family-name__regular;
  background: $search-btn-bg;

  @media (min-width: 1380px) {
    font-size: $h2__font-size;
    padding: 0;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-style: italic;

    @media (min-width: 1380px) {
      font-size: $h2__font-size;
    }
  }
}

.header .minisearch {
  display: flex;
  align-items: center;

  @media (min-width: 1380px) {
    grid-column: 1 / span 13;
  }

  .search {
    flex: 100%;

    @media (min-width: 1380px) {
      flex: 91.666;
    }
  }

  .actions {
    @media (min-width: 1380px) {
      width: 8.333%;

      .action {
        width: 100%;
      }
    }
  }
}

.header .block-search .actions button {
  height: 50px;
  width: 50px;
  background: $search-btn-bg;
  padding-right: 1rem;
  outline: none;
  text-align: center;
  color: $verno-black;

  @media (min-width: 1380px) {
    padding-right: 0;
  }

  &:before {
    content: '';
    background-image: url('../images/icons/search.svg');
    background-size: 21px;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    padding-right: 0;
  }

  span {
    display: none;
  }
}

.header .block-search {
  margin-bottom: 2rem;

  @media (min-width: 1380px) {
    margin-bottom: 0;
  }
}

.search-autocomplete {
  display: none;
  background: #fff;
  width: 100vw !important;
  left: 50%;
  bottom: 1px;
  transform: translate(-50%, 100%);

  @media (max-width: 1380px) {
    display: none !important;
  }

  ul {
    max-width: 110.769rem;
    list-style: none;
    margin: 0 auto;
    padding: 0 4.2rem;
  }

  li {
    position: relative;
    padding: 1rem 0;

    &.selected .qs-option-name:before {
      width: 100%;
      transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99) 100ms;
    }

    &:before {
      content: '';
      position: absolute;
      left: calc((-100vw + 110.7692307692rem) / 2);
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      transition: background 250ms cubic-bezier(0.6, 0, 0.01, 0.9);

      @media (min-width: 1550px) {
        left: calc((-100vw + 110.7692307692rem) / 2);
      }
    }

    .qs-option-name {
      @extend .link;
      font-size: $h2__font-size;
      cursor: pointer;

      &:after {
        display: none;
      }
    }

    .amount {
      display: none;
    }
  }
}

#search-overlay {
  display: none;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  top: 8.59rem;
  left: 50%;
  transform: translateX(-50%);

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -8.59rem;
    width: 100%;
    height: 8.59rem;
    background-color: rgba(255, 255, 255, 0.5);
  }

  &.active {
    display: block;
  }
}

.prevent-scroll {
  overflow: hidden;
}

.header.content .block-search {
  label,
  .control {
    background: $search-input-bg;
  }

  input {
    border: none;
    margin: 0;
    padding: 1.2rem 1rem;
  }
}

.search {
  .not-found-message {
    color: #ffffff;
  }
}

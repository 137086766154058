.account-nav {
  position: relative;
  margin-bottom: 1.785rem;

  @media (min-width: 768px) {
    position: initial;
    padding-right: 2rem;
  }
}

.account-nav-title {
  padding: 10px 0;

  @media (min-width: 768px) {
    display: none;
  }

  strong {
    font-family: $font-family__rubik-semi-bold;
    font-weight: 300;
    display: block;
    position: relative;
    line-height: 2.2857142857rem;
  }

  strong:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 7px;
    height: 13px;
    background-image: url('../images/icons/chevron-bold.svg');
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(90deg);
    transition: transform 250ms cubic-bezier(0.6, 0, 0.01, 0.9);
  }

  &.active strong:after {
    transform: rotate(-90deg);
  }
}

.account-nav-content {
  display: none;
  position: absolute;
  background-color: $verno-light-green;
  width: calc(100% + 2rem);
  margin-left: -1rem;
  padding: 1rem;
  z-index: 2;

  &.active {
    display: block;
  }

  @media (min-width: 768px) {
    display: block;
    position: inherit;
    width: auto;
    background: none;
  }

  .nav {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .item {
    padding: 0.25rem 0;
  }

  a,
  strong {
    line-height: 2.2857142857rem;
    margin: 0;
  }

  a:after {
    display: none;
  }

  .current strong {
    @extend .link;
    font-weight: 300;
  }

  .delimiter {
    display: block;
    height: 2px;
    background: #000;

    @media (min-width: 768px) {
      background: $verno-light-green;
    }
  }
}

.modal-popup {
  &._show {
    .modal-inner-wrap {
      transform: translateY(0);
    }
  }

  .modal-inner-wrap {
    transition: transform 0.2s ease;
  }
}

.modal-popup {
  position: absolute;
  top: -9999px;
  left: 50%;
  width: 100%;
  max-width: 70rem;
  padding: 0;
  margin-top: 0;
  background: $verno-white;
  transform: translateX(-50%);

  &._show {
    position: absolute;
    top: 0;
    padding: 2.1rem;
    padding-top: 4.2rem;
    margin-top: 4.2rem;

    @media (min-width: 768px) {
      padding: 4.2rem;
    }
  }

  .field {
    margin-bottom: 4rem;

    .field {
      margin-bottom: 0;
    }
  }

  .select {
    width: 100%;
  }

  .custom-checkbox__text {
    line-height: 1.5;
    font-style: normal;
  }

  .modal-content,
  .field-tooltip-content,
  .label {
    font-family: $font-family__base;
    font-size: $root__font-size;
    font-weight: $font-weight__light;
    line-height: $line-height__base;
  }

  .action-close {
    position: absolute;
    top: -3.15rem;
    right: -1.75rem;
    border: none;
    background: none;

    @media (min-width: 758px) {
      top: -1.75rem;
    }

    span {
      display: none; // hide close text
    }

    &:before {
      content: '';
      background-image: url('../images/icons/close.svg');
      height: 10px;
      width: 10px;
      display: block;
    }
  }
}

.modals-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.category-seo-top-container,
.category-seo-bottom-container {
    background-color: #000;
    padding: 30px 14px;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #fff;
        margin-bottom: 14px;
        font-size: 40px;
        font-family: "Noe semibold";
    }
    p {
        color: #fff;
        margin: 0;
        font-size: 14px;
        line-height: 26px;
        a {
            color: #fff;
            text-decoration: underline;
        }
    }
    @media (min-width: 769px) {
        padding: 47px calc(100 / 24 * 1%) 44px;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 30px;
        }
        p {
            font-size: 16px;
            line-height: 28px;
        }
    }
}
.catalogsearch-result-index {
  background-color: $verno-green;

  .page-main {
    @extend .base-grid;
  }

  .page-title-wrapper {
    display: block;

    .base {
      color: $verno-white;
    }
  }

  .page-header__svg {
    fill: white;
  }

  .layered-filter-block-container,
  .sub-search-for-content,
  .sidebar-main {
    display: none;
  }

  .products-grid {
    display: block;
  }

  .product-item > a {
    display: none; // hide second anchor tag that couldn't be fonud on template
  }
}

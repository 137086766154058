.block-reorder {
  .product-items {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 1.785rem;
  }

  .custom-checkbox:before {
    border: 2px solid #000;
  }

  .custom-checkbox__text {
    line-height: 1.5;
    font-style: normal;
  }

  .account & {
    @media (min-width: 768px) {
      padding-left: 25%;
    }
  }
}

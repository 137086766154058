.page-header:before {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  content: '';
  z-index: 1;
  opacity: 0;
  top: 0;
  transition: opacity 280ms ease;
}

.customer-account-createpassword .page-header,
.contentmanager-content-view:not(.contentmanager-contenttype-landingpage)
  .page-header,
.checkout-onepage-success .page-header,
.page-header.is-active,
.nav-open .page-header,
.page-header.is-scrolled,
.catalog-product-view .page-header,
.checkout-index-index .page-header,
.checkout-cart-index .page-header,
.customer-account-index .page-header,
.sales-order-history .page-header,
.customer-address-index .page-header,
.customer-account-edit .page-header,
.newsletter-manage-index .page-header,
.customer-address-form .page-header,
.customer-account-login .page-header,
.customer-account-create .page-header,
.customer-account-forgotpassword .page-header,
.account .page-header,
.cms-no-route .page-header,
.cms-page-view .page-header {
  &:before {
    opacity: 1;
    top: 0;
    transition: opacity 280ms ease;
  }

  .page-header__svg,
  .nav-toggle__nav {
    fill: black;
  }

  .nav-toggle__close,
  .nav-toggle__nav {
    stroke: black;
  }

  .showcart .text,
  .header.links a {
    color: $verno-black;
  }
}

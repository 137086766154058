#password-strength-meter-container,
.mage-error {
  margin: 1rem 0;
}

.message.info,
.mage-error:not(input),
.password-strength-meter,
.message-default,
.password-none {
  font-size: $font-size__xs;
  color: $verno-light-gray;
}

#email-error,
.message-error.error,
#password-confirmation-error.mage-error,
.password-weak .password-strength-meter,
.message-critical {
  background-color: $venro-nav-pale-red-bg;
  color: $verno-black;
  padding: 1.125rem;
}

.message-warning,
.password-medium .password-strength-meter {
  background-color: #f8e5d5;
  color: $verno-black;
  padding: 1.125rem;
}

.message-success,
.password-very-strong .password-strength-meter,
.password-strong .password-strength-meter {
  background-color: #dee6cf;
  color: $verno-black;
  padding: 1.125rem;
}

.page.messages {
  z-index: 13;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(4.142rem);
  transition: transform 600ms cubic-bezier(0.6, 0, 0.01, 0.99);

  @media (min-width: 1024px) {
    transform: translateY(8.6rem);
  }

  .scroll-hidden + .page-main & {
    transform: translateY(0);
  }
}

.messages .message {
  animation: 0.2s ease-in 10s fadeout forwards;
}

@keyframes fadeout {
  from {
    display: initial;
    opacity: 1;
  }
  to {
    display: none;
    opacity: 0;
  }
}

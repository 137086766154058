// /**
// * Copyright © Magento, Inc. All rights reserved.
// * See COPYING.txt for license details.
// */

.fotorama--fullscreen {
  background: $color-white;
  bottom: 0 !important;
  float: none !important;
  left: 0 !important;
  margin: 0 !important;
  position: fixed !important;
  right: 0 !important;
  top: 0 !important;
  width: 100% !important;
  z-index: $z-index-10 !important;
  @extend .fotorama-gpu;

  .fotorama__wrap {
    max-width: 100% !important;
  }
}

.fotorama__fullscreen {
  overflow: hidden;
  position: relative;
  .fotorama__zoom-in,
  .fotorama__zoom-out {
    cursor: pointer;
    display: block;
    height: $size-fotorama-block;
    overflow: hidden;
    position: absolute;
    width: $size-fotorama-block;
  }
  .fotorama__zoom-out {
    @extend .fotorama-sprite;
    background-position: 0 (-$size-fotorama-block) !important;
    top: 80px;
    &.fotorama__zoom-out--disabled {
      display: none;
    }
  }
  .fotorama__zoom-in {
    @extend .fotorama-sprite;
    background-position: 0 0 !important;
    top: 0;
    &.fotorama__zoom-in--disabled {
      display: none;
    }
  }
  .fotorama__video-close {
    display: none;
  }
}

.fotorama--fullscreen .fotorama__loaded--full {
  .fotorama__img {
    display: none;
  }
  .fotorama__img--full {
    display: block;
  }
}

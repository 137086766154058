@import './product-detail-slider/vendor/gallery';

.catalog-product-view {
  .action-skip-wrapper {
    display: none;
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .fotorama__arr__arr {
    background: none;
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    display: none;
  }

  .gallery-placeholder,
  .fotorama__stage,
  .product.media:before {
    @media (min-width: 1024px) {
      max-height: 625px !important;
      height: 44.4444444444vw !important;
    }
  }

  .fotorama__arr--prev,
  .fotorama__arr--next {
    opacity: 1 !important;
    top: auto;
    background-color: transparent;
    width: 50px;
    padding-top: 30px;
    padding-bottom: 30px;
    height: 16px;
    box-sizing: content-box;

    &:active {
      background-color: rgba(255, 255, 255, 0.6);
    }

    &:before {
      background-image: url('../images/icons/chevron-white.svg');
      background-size: cover;
      background-repeat: no-repeat;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 7px;
      height: 13px;
    }

    @media (min-width: 1024px) {
      display: none;
    }
  }

  .fotorama,
  .fotorama__stage,
  .fotorama__nav {
    overflow: visible !important;
  }

  .fotorama__arr--prev {
    &:before {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }

  .fotorama__thumb__arr {
    display: none !important;
  }

  .fotorama__nav-wrap {
    display: none !important;

    @media (min-width: 1024px) {
      display: block !important;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 30px;
    }
  }

  .fotorama__nav__shaft {
    @media (min-width: 769px) {
      display: flex;
      flex-direction: column;
    }
  }

  .fotorama__nav.fotorama__nav--thumbs {
    @media (min-width: 769px) {
      width: auto !important;
      height: auto !important;
    }
  }

  // Hide default animated border
  .fotorama__thumb-border {
    display: none !important;
  }

  .fotorama__nav__frame.fotorama__nav__frame--thumb {
    @media (min-width: 769px) {
      height: 80px !important;
      width: 60px !important;
      margin-bottom: 8px;
      padding: 0 !important;
      border: 2px solid transparent;
      cursor: pointer;
    }

    &.fotorama__active {
      @media (min-width: 769px) {
        border: 2px solid white;
      }
    }
  }

  /* Main images */
  .fotorama__img {
    object-fit: cover;
    max-width: 100%;
    height: 100%;

    @media (min-width: 769px) {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      left: auto;
    }
  }

  // set horizontal ratio to full-width
  .fotorama__stage__shaft
    > .fotorama__loaded--img.fotorama_horizontal_ratio
    .fotorama__img {
    width: 100%;
  }

  // Let the first image just contain it (it's transparent)
  .fotorama__stage__shaft > .fotorama__loaded--img .fotorama__img {
    object-fit: contain;
  }

  .fotorama__slide-data {
    display: flex;
    position: absolute;
    color: white;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
    align-items: center;

    @media (min-width: 1024px) {
      display: none;
    }

    &:after {
      display: block;
      content: '';
      width: 30px;
      height: 2px;
      background-color: white;
      margin: 0 10px;
      order: 2;
    }
    .current {
      order: 1;
    }
    .total {
      order: 3;
    }

    .current,
    .total {
      font-size: 12px;
      font-weight: 600;
    }
  }

  .fotorama__nav-wrap .fotorama_horizontal_ratio .fotorama__img {
    top: 0;
  }
}

.customer-account-edit {
  .page-main {
    @extend .base-grid;
    padding-top: 2.1rem;
  }

  .page-title-wrapper {
    display: block;

    @media (min-width: 768px) {
      padding-left: 25%;
    }
  }

  .main {
    display: grid;
    grid-template-columns: 25% auto;
    grid-template-areas:
      'side-nav side-nav side-nav'
      'edit-account edit-account edit-account';

    @media (min-width: 768px) {
      grid-template-areas: 'side-nav edit-account edit-account';
    }
  }

  .account-nav {
    grid-area: side-nav;
  }

  .form-edit-account {
    grid-area: edit-account;
    margin-bottom: 3.57rem;
  }

  .block-reorder,
  .action.back {
    display: none;
  }
}

.customer-account-edit {
  .password-info {
    display: none;
  }

  .custom-checkbox:before {
    border: 2px solid #000;
  }

  .custom-checkbox__text {
    line-height: 1.5;
    font-style: normal;
  }

  .form-edit-account {
    @media (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .fieldset {
    margin: 0;
    padding: 0;
    border: none;
    margin-bottom: 1.785rem;

    @media (min-width: 768px) {
      flex-basis: 50%;
      padding-right: 8.333%;
    }
  }

  .actions-toolbar {
    @media (min-width: 768px) {
      flex-basis: 100%;
    }
  }

  .label span {
    font-family: $font-family__base;
    font-size: $root__font-size;
    font-weight: $font-weight__light;
    line-height: $line-height__base;
    margin-bottom: $indent__base;
  }

  .field:not(.search) {
    margin-bottom: 4rem;
  }

  .field-name-lastname,
  .no-margin-bottom:not(.search) {
    margin-bottom: 0;
  }
}

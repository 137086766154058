// /**
// * Copyright © Magento, Inc. All rights reserved.
// * See COPYING.txt for license details.
// */

.fotorama__fullscreen-icon:focus,
.fotorama__zoom-out:focus,
.fotorama__zoom-in:focus,
.fotorama__arr:focus,
.fotorama__stage__shaft:focus,
.fotorama__nav__frame--thumb:focus .fotorama__thumb,
.fotorama__nav__frame--dot:focus .fotorama__dot {
  box-shadow: none;
  &:after {
    border-radius: inherit;
    bottom: $fotorama-inner-box-shadow;
    box-shadow: $focus__box-shadow;
    content: '';
    left: $fotorama-inner-box-shadow;
    position: absolute;
    right: $fotorama-inner-box-shadow;
    top: $fotorama-inner-box-shadow;
    z-index: $z-index-10;
  }
}

.fotorama__nav__frame--thumb:focus,
.fotorama__nav__frame--dot:focus {
  .fotorama__thumb:after,
  .fotorama__dot:after {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
  .fotorama__thumb.fotorama_vertical_ratio:after {
    left: 2px;
    right: 2px;
  }
  .fotorama__thumb {
    overflow: inherit;
  }
}

.fotorama__nav__frame:nth-child(2):focus {
  .fotorama__thumb:after {
    left: 1px;
  }
  .fotorama__thumb.fotorama_vertical_ratio:after {
    top: 1px;
  }
}

.fotorama__nav__frame:last-child:focus {
  .fotorama__thumb:after {
    right: 1px;
  }
  .fotorama__thumb.fotorama_vertical_ratio:after {
    bottom: 1px;
  }
}

.fotorama__thumb__arr {
  box-shadow: none;
}

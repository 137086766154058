.cms-page-view {
  .page-main {
    @extend .base-grid;
  }

  .column.main {
    padding-bottom: 3.92rem;

    @media (min-width: 1024px) {
      padding-bottom: 4.57rem;
    }
  }

  .page-title-wrapper {
    display: block;
  }

  .block-reorder {
    display: none;
  }

  dd {
    font-family: $font-family__base;
    font-size: $root__font-size;
    font-weight: $font-weight__light;
    line-height: $line-height__base;
  }

  dt {
    font-family: $font-family-name__h2;
    font-size: $h2__font-size;
    font-weight: $font-weight__light;
    line-height: $h2__line-height;
  }

  .main a {
    @include link();
  }

  .main ul {
    @extend .disc;
  }

  .disc {
    list-style: none;
    padding: 0;
    margin: 0 0 2.58rem 0;

    li {
      position: relative;
      padding: 0 0 0 1rem;
      width: 100%;
      font-size: $root__font-size;
      font-weight: $font-weight__light;
      line-height: $line-height__base;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0.65rem;
        width: 6px;
        height: 6px;
        background: black;
      }
    }
  }

  .main ol li {
    line-height: 1.5;
  }

  .main strong {
    font-weight: bold;
  }

  .main h1,
  .main h2,
  .main h3,
  .main h4,
  .main h5,
  .main h6 {
    strong {
      font-weight: inherit;
    }
  }
}

.visual-blocks {
  background-size: 100%;
  padding-top: calc(120 / 1440 * 100vw);
  padding-bottom: calc(120 / 1440 * 100vw);
  position: relative;

  &:before {
    background-color: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    content: '';
  }
}

.visual-blocks__inner {
  position: relative;
  z-index: 2;
}

.visual-blocks__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  @media (min-width: 769px) {
    margin-bottom: calc(50 / 1440 * 100vw);
  }

  @media (min-width: 1440px) {
    margin-bottom: 50px;
  }
}

.visual-blocks__title {
  color: white;
  margin: 0 20px 0 0;
  font-size: calc(24rem / 14);
  line-height: calc(40 / 30);
  font-family: $font-family-name__h1;
  flex: 1;

  @media (min-width: 769px) {
    font-size: calc(30rem / 14);
  }
}

.visual-blocks__more-link {
  margin: 0;
  color: white;
}

@charset "UTF-8";
html {
  font-family: sans-serif;
  text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

progress {
  vertical-align: baseline; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline-width: 0; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b,
strong {
  font-weight: inherit; }

b,
strong {
  font-weight: bolder; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background-color: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

img {
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

code,
kbd,
pre,
samp {
  /* stylelint-disable */
  font-family: monospace, monospace;
  /* stylelint-enable */
  font-size: 1em; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

button,
input,
select,
textarea {
  font: inherit; }

optgroup {
  font-weight: bold; }

button,
input,
select {
  overflow: visible; }

button,
input,
select,
textarea {
  margin: 0; }

button,
select {
  text-transform: none; }

[type='button'],
[type='reset'],
[type='submit'],
button {
  cursor: pointer; }

[disabled] {
  cursor: default; }

[type='reset'],
[type='submit'],
button,
html [type='button'] {
  appearance: button; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

button:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

textarea {
  overflow: auto; }

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0; }

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search'] {
  appearance: field; }

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  appearance: none; }

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important; }

.no-scroll {
  overflow: hidden; }

.white {
  color: white; }

.ac {
  overflow: hidden; }

.ac--up .inner {
  transform: translateY(-100%);
  animation: up 1000ms cubic-bezier(0.6, 0, 0.01, 0.9) forwards; }

.ac--down .inner {
  transform: translateY(100%);
  animation: down 1000ms cubic-bezier(0.6, 0, 0.01, 0.9) forwards; }

/* Refactor to own folder when it gets too big */
@keyframes SubMenuIn {
  from {
    transform: translateY(-100%); }
  to {
    transform: translateY(0); } }

@keyframes FadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(35px); }
  to {
    opacity: 1;
    transform: translateX(0); } }

@keyframes up {
  0% {
    transform: translateY(100%); }
  100% {
    transform: translateY(0); } }

@keyframes down {
  0% {
    transform: translateY(-100%); }
  100% {
    transform: translateY(0); } }

@keyframes right {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(100%); } }

@font-face {
  font-family: "Rubik light";
  src: url("../fonts/rubik/rubik-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-face: swap; }

@font-face {
  font-family: "Rubik medium";
  src: url("../fonts/rubik/rubik-medium-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-face: swap; }

@font-face {
  font-family: "Rubik regular";
  src: url("../fonts/rubik/rubik-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-face: swap; }

@font-face {
  font-family: "Noe book";
  src: url("../fonts/noe/noe-text-book.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-face: swap; }

@font-face {
  font-family: "Noe semibold";
  src: url("../fonts/noe/noe-text-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-face: swap; }

@font-face {
  font-family: "Noe Display";
  src: url("../fonts/noe/NoeDisplay-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-face: swap; }

@font-face {
  font-family: "Noe semibold";
  src: url("../fonts/noe/NoeText-BoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-face: swap; }

html {
  font-size: 14px;
  color: #000; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0.6em 0; }

input,
.messages,
.agreement-notice,
a,
p {
  font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.8;
  margin-bottom: 25px; }


.messages,
.agreement-notice {
  margin-bottom: 0; }

a {
  margin-bottom: 0;
  text-decoration: none;
  color: #000; }

h1 {
  font-family: "Noe semibold";
  font-size: 40px;
  font-weight: 600;
  line-height: 1.2;
  color: #262626; }

h2 {
  font-family: "Rubik light";
  font-size: 24px;
  font-weight: 300;
  line-height: 1.5; }

h3,
.product.detailed .label,
.products-grid .product-item-name,
.products-grid .product-item-name a,
.product-label,
.product-item-details strong a,
.product-item-name {
  font-family: "Rubik medium";
  font-size: 12px;
  font-weight: 500;
  line-height: 1.8;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #262626; }

h4,
.price {
  font-family: "Noe book";
  font-size: 19px;
  font-weight: 300;
  line-height: 1.2; }

.navigation span.small a,
.navigation span.small span,
.navigation a.small a,
.navigation a.small span {
  font-family: "Rubik light"; }

.navigation .level2 span {
  font-family: "Rubik regular";
  color: black; }

ul,
ol {
  font-family: "Rubik light"; }

.link-button,
button {
  font-family: "Rubik regular";
  font-weight: 500;
  letter-spacing: 1px;
  text-align: left; }
  .link-button.cta, .cookie-opt-out .link-button.action,
  button.cta,
  .cookie-opt-out button.action {
    font-family: "Rubik medium"; }

.header.content .contact h3 {
  font-size: 14px;
  margin: 0 0 1rem 0; }

.header.content .contact ul {
  margin: 0;
  padding: 0;
  list-style: none; }

.price {
  font-size: 25px; }
  .old-price .price {
    font-size: 15px;
    color: #9b9b9b;
    text-decoration: line-through;
    margin-left: 1.5rem; }

.action.back {
  display: inline-block;
  vertical-align: middle;
  font-family: "Rubik medium";
  font-weight: 500;
  padding-left: 1.8rem;
  margin: 1.166rem 0;
  line-height: 1; }
  .action.back:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    background-image: url("../images/icons/arrow-bold.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    height: 1rem;
    width: 1rem;
    transform: rotate(180deg) translateX(1.8rem); }
  .action.back:after {
    display: none; }

.shipping-information-content,
.billing-address-details,
address {
  font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.8;
  margin-bottom: 25px;
  font-style: normal; }
  .shipping-information-content a:before,
  .shipping-information-content a:after,
  .billing-address-details a:before,
  .billing-address-details a:after,
  address a:before,
  address a:after {
    display: none; }

strong {
  font-weight: bold; }

*,
*:before,
*:after {
  box-sizing: border-box; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; }

body.nav-open {
  overflow: hidden; }

@media (min-width: 1024px) {
  .page-main {
    min-height: calc( 100vh - 550px - 120px); } }

.page-title-wrapper:not(.product) {
  display: none; }

.base-grid, .page-header .header.content, .footer, body .yotpo-container, .home-grid, .catalog-product-view .product-top-wrapper, .catalog-product-view .product-details-wrapper, .catalog-category-view .columns, .hyvareactcheckout-reactcheckout-index .page-main, .checkout-onepage-success .page-main,
.checkout-onepage-failure .page-main, .checkout-cart-index .page-main, .customer-account-login .page-main, .customer-account-create .columns, .catalogsearch-result-index .page-main, .customer-account-index .page-main, .sales-order-history .page-main, .sales-order-print .page-main,
.sales-order-invoice .page-main,
.sales-order-view .page-main, .customer-address-index .page-main, .customer-account-edit .page-main, .newsletter-manage-index .page-main, .customer-address-form .page-main, .customer-account-forgotpassword .page-main, .customer-account-createpassword .page-main, .customer-account-logoutsuccess .page-main, .cms-noroute-index .page-main, .cms-page-view .page-main {
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 110.76923rem;
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 1380px) {
    .base-grid, .page-header .header.content, .footer, body .yotpo-container, .home-grid, .catalog-product-view .product-top-wrapper, .catalog-product-view .product-details-wrapper, .catalog-category-view .columns, .hyvareactcheckout-reactcheckout-index .page-main, .checkout-onepage-success .page-main,
    .checkout-onepage-failure .page-main, .checkout-cart-index .page-main, .customer-account-login .page-main, .customer-account-create .columns, .catalogsearch-result-index .page-main, .customer-account-index .page-main, .sales-order-history .page-main, .sales-order-print .page-main,
    .sales-order-invoice .page-main,
    .sales-order-view .page-main, .customer-address-index .page-main, .customer-account-edit .page-main, .newsletter-manage-index .page-main, .customer-address-form .page-main, .customer-account-forgotpassword .page-main, .customer-account-createpassword .page-main, .customer-account-logoutsuccess .page-main, .cms-noroute-index .page-main, .cms-page-view .page-main {
      padding-left: 4.2rem;
      padding-right: 4.2rem; } }

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

@media (max-width: 768px) {
  .grid--desktop {
    display: block;
    grid-template-columns: none; } }

/* All the fullwidth repeater fields need to be on the grid-column */
.grid__blog-item {
  grid-column: 2 / -2; }

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important; }

.no-scroll {
  overflow: hidden; }

.white {
  color: white; }

.ac {
  overflow: hidden; }

.ac--up .inner {
  transform: translateY(-100%);
  animation: up 1000ms cubic-bezier(0.6, 0, 0.01, 0.9) forwards; }

.ac--down .inner {
  transform: translateY(100%);
  animation: down 1000ms cubic-bezier(0.6, 0, 0.01, 0.9) forwards; }

/* Refactor to own folder when it gets too big */
@keyframes SubMenuIn {
  from {
    transform: translateY(-100%); }
  to {
    transform: translateY(0); } }

@keyframes FadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(35px); }
  to {
    opacity: 1;
    transform: translateX(0); } }

@keyframes up {
  0% {
    transform: translateY(100%); }
  100% {
    transform: translateY(0); } }

@keyframes down {
  0% {
    transform: translateY(-100%); }
  100% {
    transform: translateY(0); } }

@keyframes right {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(100%); } }

/* Form components/typography/utilities etc.  */
.input-container {
  margin-bottom: 12px; }

option,
select,
input {
  font-size: 16px; }
  @media (min-width: 768px) {
    option,
    select,
    input {
      font-size: 14px; } }
  option::placeholder,
  select::placeholder,
  input::placeholder {
    font-size: 14px; }

.custom-select-container {
  display: block;
  position: relative;
  cursor: pointer;
  border-bottom: 2px solid black; }
  .custom-select-container select {
    margin-bottom: 0;
    cursor: pointer; }
  .custom-select-container:before {
    content: '';
    width: 10px;
    height: 10px;
    display: block;
    background-image: url("../images/icons/chevron-bold.svg");
    background-size: 6px 10px;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    pointer-events: none; }
  .custom-select-container.white:before {
    background-image: url("../images/icons/chevron-white.svg"); }
  .custom-select-container.large {
    display: flex; }
    .custom-select-container.large .mock-select {
      padding-right: 2.8571428571rem;
      border-bottom: 2px solid white; }

/* Custom select */
.mock-select {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer; }
  .mock-select select {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    appearance: menulist-button;
    cursor: pointer; }

.mock-select__text {
  font-size: 1rem;
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding: 8px 0;
  font-style: italic;
  font-weight: 300; }

.custom-radio-container input, .product-info-price .product-options-wrapper .field.choice input {
  visibility: hidden;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0; }

.custom-radio-container input[type='radio']:checked + .custom-radio:after, .product-info-price .product-options-wrapper .field.choice input[type='radio']:checked + .custom-radio:after, .product-info-price .product-options-wrapper .field.choice .custom-radio-container input[type='radio']:checked + .label:after, .product-info-price .product-options-wrapper .field.choice input[type='radio']:checked + .label:after {
  transform: scale(0.6); }

/* Custom radio */
.custom-radio, .product-info-price .product-options-wrapper .field.choice input[type='radio'] + .label {
  position: relative;
  cursor: pointer;
  display: flex;
  /*circle*/
  /*dot*/ }
  .custom-radio:before, .product-info-price .product-options-wrapper .field.choice input[type='radio'] + .label:before {
    content: '';
    height: 1.2rem;
    width: 1.2rem;
    background: white;
    display: block;
    position: relative;
    top: 1px;
    border-radius: 50%; }
  .custom-radio:after, .product-info-price .product-options-wrapper .field.choice input[type='radio'] + .label:after {
    content: '';
    height: 1.2rem;
    width: 1.2rem;
    background-color: black;
    left: 0;
    top: 1px;
    position: absolute;
    display: block;
    transform: scale(0);
    transition: transform 250ms cubic-bezier(0.6, 0, 0.01, 0.99);
    border-radius: 50%; }

.custom-radio__text {
  margin-left: 0.8571428571rem;
  line-height: 1;
  flex: 1;
  font-size: 0.8571428571rem;
  font-style: italic;
  font-family: "Rubik light"; }
  .custom-radio__text a {
    font-family: inherit;
    font-size: inherit;
    display: inline-block;
    margin-bottom: 0; }

.custom-checkbox-container input, .product-info-price .product-options-wrapper .field.choice input {
  visibility: hidden;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0; }

.custom-checkbox-container input[type='checkbox']:checked + .custom-checkbox:after, .product-info-price .product-options-wrapper .field.choice input[type='checkbox']:checked + .custom-checkbox:after, .product-info-price .product-options-wrapper .field.choice .custom-checkbox-container input[type='checkbox']:checked + .label:after, .product-info-price .product-options-wrapper .field.choice input[type='checkbox']:checked + .label:after {
  transform: scale(1); }

/* Custom checkbox */
.custom-checkbox, .product-info-price .product-options-wrapper .field.choice input[type='checkbox'] + .label {
  position: relative;
  cursor: pointer;
  display: flex;
  font-size: 0.8571428571rem;
  /*square*/
  /*check*/ }
  .custom-checkbox:before, .product-info-price .product-options-wrapper .field.choice input[type='checkbox'] + .label:before {
    content: '';
    height: 16px;
    width: 16px;
    background: white;
    display: block;
    position: relative;
    top: 0px;
    border: 2px solid black; }
  .custom-checkbox:after, .product-info-price .product-options-wrapper .field.choice input[type='checkbox'] + .label:after {
    content: '';
    height: 10px;
    width: 10px;
    background-color: black;
    left: 3px;
    top: 3px;
    position: absolute;
    display: block;
    transform: scale(0);
    transition: transform 250ms cubic-bezier(0.6, 0, 0.01, 0.99); }

.custom-checkbox__text {
  margin-left: 0.8571428571rem;
  line-height: 1.5;
  flex: 1;
  font-size: inherit;
  font-style: italic;
  font-family: "Rubik light";
  position: relative;
  top: -2px; }
  .custom-checkbox__text a {
    font-family: inherit;
    font-size: inherit;
    display: inline-block;
    margin-bottom: 0;
    text-decoration: underline; }

.input-icon {
  display: block;
  position: relative; }
  .input-icon input[type='email'],
  .input-icon input[type='text'],
  .input-icon input[type='number'] {
    width: 100%;
    padding-right: 20px;
    margin-bottom: 0;
    outline: none;
    font-weight: 300; }

.input-icon__icon,
.input-icon__submit {
  position: absolute;
  width: 16px;
  right: 0;
  top: 1em; }

.input-icon__submit {
  border: 0;
  background: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }

.input-icon__icon--submit {
  position: relative;
  top: auto;
  transform: none;
  right: auto;
  height: 12px;
  width: 16px;
  display: block; }

.input-line {
  display: flex; }

.input-line__text {
  display: block;
  margin-left: 5px; }

.link, .link-button--link,
button.action--link, .page-footer a, .page-footer .footer > .column:first-child ul:not(.horizontal-list) a, .page-header .navigation a.level0.first.level-top.menu-item-active, .page-header a.level0.level-top, body .yotpo-reviews-carousel .carousel-display-wrapper .carousel-read-more, #layered-filter-block-container .filter-options-item.has-items .filter-options-title > span, .product-info-main .add-sample-to-cart button[type='submit'], .product-info-main #uploadBtn.active, .search-autocomplete li .qs-option-name, .account-nav-content .current strong, .related-header > a, .hyvareactcheckout-reactcheckout-index .shipping-information .action, .sales-order-print .order-links a,
.sales-order-invoice .order-links a,
.sales-order-view .order-links a {
  color: #000;
  text-decoration: none;
  position: relative;
  display: inline-block; }
  .link:after, .link-button--link:after,
  button.action--link:after, .page-footer a:after, .page-footer .footer > .column:first-child ul:not(.horizontal-list) a:after, .page-header .navigation a.level0.first.level-top.menu-item-active:after, .page-header a.level0.level-top:after, body .yotpo-reviews-carousel .carousel-display-wrapper .carousel-read-more:after, #layered-filter-block-container .filter-options-item.has-items .filter-options-title > span:after, .product-info-main .add-sample-to-cart button[type='submit']:after, .product-info-main #uploadBtn.active:after, .search-autocomplete li .qs-option-name:after, .account-nav-content .current strong:after, .related-header > a:after, .hyvareactcheckout-reactcheckout-index .shipping-information .action:after, .sales-order-print .order-links a:after,
  .sales-order-invoice .order-links a:after,
  .sales-order-view .order-links a:after, .link:before, .link-button--link:before,
  button.action--link:before, .page-footer a:before, .page-footer .footer > .column:first-child ul:not(.horizontal-list) a:before, .page-header .navigation a.level0.first.level-top.menu-item-active:before, .page-header a.level0.level-top:before, body .yotpo-reviews-carousel .carousel-display-wrapper .carousel-read-more:before, #layered-filter-block-container .filter-options-item.has-items .filter-options-title > span:before, .product-info-main .add-sample-to-cart button[type='submit']:before, .product-info-main #uploadBtn.active:before, .search-autocomplete li .qs-option-name:before, .account-nav-content .current strong:before, .related-header > a:before, .hyvareactcheckout-reactcheckout-index .shipping-information .action:before, .sales-order-print .order-links a:before,
  .sales-order-invoice .order-links a:before,
  .sales-order-view .order-links a:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 100%;
    background: #feac00; }
  .link:before, .link-button--link:before,
  button.action--link:before, .page-footer a:before, .page-footer .footer > .column:first-child ul:not(.horizontal-list) a:before, .page-header .navigation a.level0.first.level-top.menu-item-active:before, .page-header a.level0.level-top:before, body .yotpo-reviews-carousel .carousel-display-wrapper .carousel-read-more:before, #layered-filter-block-container .filter-options-item.has-items .filter-options-title > span:before, .product-info-main .add-sample-to-cart button[type='submit']:before, .product-info-main #uploadBtn.active:before, .search-autocomplete li .qs-option-name:before, .account-nav-content .current strong:before, .related-header > a:before, .hyvareactcheckout-reactcheckout-index .shipping-information .action:before, .sales-order-print .order-links a:before,
  .sales-order-invoice .order-links a:before,
  .sales-order-view .order-links a:before {
    width: 0;
    transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99); }
  .link:after, .link-button--link:after,
  button.action--link:after, .page-footer a:after, .page-footer .footer > .column:first-child ul:not(.horizontal-list) a:after, .page-header .navigation a.level0.first.level-top.menu-item-active:after, .page-header a.level0.level-top:after, body .yotpo-reviews-carousel .carousel-display-wrapper .carousel-read-more:after, #layered-filter-block-container .filter-options-item.has-items .filter-options-title > span:after, .product-info-main .add-sample-to-cart button[type='submit']:after, .product-info-main #uploadBtn.active:after, .search-autocomplete li .qs-option-name:after, .account-nav-content .current strong:after, .related-header > a:after, .hyvareactcheckout-reactcheckout-index .shipping-information .action:after, .sales-order-print .order-links a:after,
  .sales-order-invoice .order-links a:after,
  .sales-order-view .order-links a:after {
    right: 0;
    transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99) 100ms; }
  @media (hover: hover) {
    .link:hover:after, .link-button--link:hover:after,
    button.action--link:hover:after, .page-footer a:hover:after, .page-footer .footer > .column:first-child ul:not(.horizontal-list) a:hover:after, .page-header .navigation a.level0.first.level-top.menu-item-active:hover:after, .page-header a.level0.level-top:hover:after, body .yotpo-reviews-carousel .carousel-display-wrapper .carousel-read-more:hover:after, #layered-filter-block-container .filter-options-item.has-items .filter-options-title > span:hover:after, .product-info-main .add-sample-to-cart button[type='submit']:hover:after, .product-info-main #uploadBtn.active:hover:after, .search-autocomplete li .qs-option-name:hover:after, .account-nav-content .current strong:hover:after, .related-header > a:hover:after, .hyvareactcheckout-reactcheckout-index .shipping-information .action:hover:after, .sales-order-print .order-links a:hover:after,
    .sales-order-invoice .order-links a:hover:after,
    .sales-order-view .order-links a:hover:after {
      width: 0;
      transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99); }
    .link:hover:before, .link-button--link:hover:before,
    button.action--link:hover:before, .page-footer a:hover:before, .page-footer .footer > .column:first-child ul:not(.horizontal-list) a:hover:before, .page-header .navigation a.level0.first.level-top.menu-item-active:hover:before, .page-header a.level0.level-top:hover:before, body .yotpo-reviews-carousel .carousel-display-wrapper .carousel-read-more:hover:before, #layered-filter-block-container .filter-options-item.has-items .filter-options-title > span:hover:before, .product-info-main .add-sample-to-cart button[type='submit']:hover:before, .product-info-main #uploadBtn.active:hover:before, .search-autocomplete li .qs-option-name:hover:before, .account-nav-content .current strong:hover:before, .related-header > a:hover:before, .hyvareactcheckout-reactcheckout-index .shipping-information .action:hover:before, .sales-order-print .order-links a:hover:before,
    .sales-order-invoice .order-links a:hover:before,
    .sales-order-view .order-links a:hover:before {
      width: 100%;
      transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99) 100ms; } }
  .link--clean:before, .link--clean:after {
    display: none; }
  .link.white, .white.link-button--link,
  button.white.action--link, .page-footer a.white, .page-footer .footer > .column:first-child ul:not(.horizontal-list) a.white, .page-header .navigation a.white.level0.first.level-top.menu-item-active, .page-header a.white.level0.level-top, body .yotpo-reviews-carousel .carousel-display-wrapper .white.carousel-read-more, #layered-filter-block-container .filter-options-item.has-items .filter-options-title > span.white, .product-info-main .add-sample-to-cart button.white[type='submit'], .product-info-main .white#uploadBtn.active, .search-autocomplete li .white.qs-option-name, .account-nav-content .current strong.white, .related-header > a.white, .hyvareactcheckout-reactcheckout-index .shipping-information .white.action, .sales-order-print .order-links a.white,
  .sales-order-invoice .order-links a.white,
  .sales-order-view .order-links a.white {
    color: white; }

/* Mobile link without orange lines utility */
.link--mobile:after, .page-header a.level0.level-top:after, .link--mobile:before, .page-header a.level0.level-top:before {
  display: none; }

button.link, button.link-button--link,
button.action--link, body .yotpo-reviews-carousel .carousel-display-wrapper button.carousel-read-more, .product-info-main .add-sample-to-cart button[type='submit'], .product-info-main button#uploadBtn.active, .search-autocomplete li button.qs-option-name, .hyvareactcheckout-reactcheckout-index .shipping-information button.action {
  line-height: 1.8 !important;
  font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  outline: none; }

.link-button,
button.action {
  box-sizing: border-box;
  padding: 1.21rem 1.43rem;
  border: none;
  z-index: 1;
  position: relative;
  cursor: pointer; }
  .link-button:before,
  button.action:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99) 200ms;
    z-index: -1; }
  @media (hover: hover) {
    .link-button:hover:before,
    button.action:hover:before {
      width: 100%;
      left: 0; } }
  .link-button.primary, .cookie-opt-out .link-button.action,
  button.action.primary,
  .cookie-opt-out button.action {
    background: #fff;
    color: #000; }
    .link-button.primary:before, .cookie-opt-out .link-button.action:before,
    button.action.primary:before,
    .cookie-opt-out button.action:before {
      background: #f5f5ef; }
  .link-button.primary--inverted,
  button.action.primary--inverted {
    background: #000;
    color: #fff; }
    .link-button.primary--inverted:before,
    button.action.primary--inverted:before {
      background: #48474b; }
  .link-button--link,
  button.action--link {
    padding: 0; }
  .link-button.cta, .cookie-opt-out .link-button.action,
  button.action.cta,
  .cookie-opt-out button.action {
    background: #feac00;
    color: #000; }
  .link-button.full-width,
  button.action.full-width {
    width: 100%;
    appearance: button;
    font-size: 16px; }
  .link-button.split--left,
  button.action.split--left {
    background: #fff;
    color: #000; }
  .link-button.light, .link-button.split--right,
  button.action.light,
  button.action.split--right {
    background: rgba(255, 255, 255, 0.1);
    color: #fff; }
    .link-button.light:before, .link-button.split--right:before,
    button.action.light:before,
    button.action.split--right:before {
      background: rgba(255, 255, 255, 0.1); }

.contentmanager-content-view .link-button,
.contentmanager-content-view button.action {
  font-family: "Rubik medium"; }

.link-button {
  display: inline-block; }
  .link-button:after {
    background: none; }

select,
input[type='text'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='tel'],
textarea {
  box-sizing: border-box;
  width: 100%;
  padding: 0.5rem 0.5rem 0.5rem 0;
  background: none;
  border: none;
  border-bottom: 2px solid black;
  border-radius: 0;
  margin: 0 0 1rem 0; }
  select::placeholder,
  input[type='text']::placeholder,
  input[type='email']::placeholder,
  input[type='number']::placeholder,
  input[type='password']::placeholder,
  input[type='tel']::placeholder,
  textarea::placeholder {
    color: black;
    opacity: 1; }

textarea {
  border: 2px solid black;
  margin-top: 1rem;
  min-height: 100px;
  padding-left: 0.5rem; }

select {
  appearance: none;
  outline: none;
  cursor: pointer; }

label {
  cursor: pointer; }

[role='button'] {
  cursor: pointer; }

[role='tab'] {
  cursor: pointer; }

.homepage-header {
  position: relative;
  padding-top: 14.7rem;
  padding-bottom: calc(30 / 14 * 1rem); }
  @media (min-width: 1025px) and (max-width: 1441px) {
    .homepage-header {
      padding-top: 9.4583333vw;
      padding-bottom: 12.2361111111vw; } }
  @media (min-width: 1441px) {
    .homepage-header {
      padding-top: 11.4583333vw;
      padding-bottom: 14.2361111111vw; } }

@media (min-width: 768px) {
  .homepage-header__buttons {
    width: 24rem; } }

.homepage-header__button {
  margin: 0 0 0.7rem 0;
  width: 100%; }
  .homepage-header__button:last-child {
    margin: 0.7rem 0 0;
    display: inline-block; }

.homepage-header #react-configure-frame-flow button {
  width: 100%; }

.homepage-header__title-cnt {
  margin-bottom: calc(30 / 14 * 1rem);
  grid-column: auto / span 12; }
  @media (min-width: 1024px) {
    .homepage-header__title-cnt {
      margin-bottom: 40px; } }

.homepage-header__title {
  font-size: 3.8rem;
  line-height: 1;
  color: white;
  font-family: "Noe Display";
  margin: 0; }
  @media (min-width: 1024px) {
    .homepage-header__title {
      font-size: 4.2857142857rem; } }

.ec-gtm-cookie-directive {
  position: initial;
  bottom: initial;
  left: initial;
  right: initial;
  text-align: initial;
  z-index: 1;
  opacity: 1; }

.ec-gtm-cookie-directive-segments {
  background: initial;
  margin: initial;
  padding: initial; }

.cookie-consent {
  background-color: #000;
  color: white;
  padding: 2rem 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1; }
  @media (min-width: 768px) {
    .cookie-consent {
      padding: 2rem 0; } }
  .cookie-consent .custom-checkbox__text {
    color: white;
    line-height: 1.5;
    font-style: normal; }

.cookie-consent__grid {
  align-items: flex-end;
  display: flex;
  flex-direction: column; }
  @media (min-width: 768px) {
    .cookie-consent__grid {
      flex-direction: row;
      align-items: center;
      justify-content: space-between; } }

.cookie-consent__information {
  flex: 0.75;
  margin-right: 1rem; }

.cookie-consent__title {
  color: white; }

.cookie-consent__description {
  margin-bottom: 0; }

.cookie-consent__segment-list {
  list-style: none;
  padding: 0;
  margin: 0 0 2rem; }

.cookie-consent__buttons {
  display: flex;
  align-items: center;
  margin-top: 1rem; }
  @media (min-width: 768px) {
    .cookie-consent__buttons {
      margin-top: 0; } }

.cookie-consent__accept {
  display: block;
  margin-right: 1rem;
  margin-bottom: 0; }
  .cookie-consent__accept:hover {
    cursor: pointer; }

.cookie-consent__learn-more {
  color: white;
  display: block;
  margin-bottom: 0; }

.cookie-opt-out {
  background-color: #c99e9f;
  color: white;
  padding: 4rem;
  margin: 2rem 0; }

.horizontal-list {
  display: flex;
  flex-wrap: wrap; }

.horizontal-list__item {
  margin: 0 2.1rem 0 0; }
  .horizontal-list__item:last-child:after {
    display: none; }

.horizontal-list__item--dash-separator:after {
  content: '';
  display: block;
  position: relative;
  top: -50%;
  left: 100%;
  height: 2px;
  width: 10px;
  background: #000;
  transform: translate(0.65rem, 50%); }

#main-header .panel {
  display: none; }

.page-wrapper {
  position: relative;
  z-index: 1;
  padding-top: calc(58rem / 14); }
  @media (min-width: 1380px) {
    .page-wrapper {
      padding-top: 8.6rem; } }

.ReactModalPortal {
  position: relative;
  z-index: 10; }

.page-wrapper .page-header {
  z-index: 11; }

.page-header .header.content {
  z-index: 2;
  display: grid; }
  @media (max-width: 1379px) {
    .page-header .header.content {
      padding: 0; } }
  @media (min-width: 1380px) {
    .page-header .header.content {
      align-items: center;
      grid-template-columns: calc(((100 / 12) * 3) * 1%) 1fr 100px 100px calc( 50rem / 14 + 60px); }
      .page-header .header.content:after {
        display: none; } }
  @media (min-width: 1380px) {
    .page-header .header.content {
      grid-template-columns: calc(((100 / 12) * 2) * 1%) 1fr 100px 100px calc( 50rem / 14 + 60px); } }

@media (max-width: 1380px) {
  body.nav-open .page-header .header.content {
    display: grid;
    grid-template-rows: repeat(4, min-content);
    height: 100vh;
    padding-bottom: 0;
    overflow-y: scroll;
    overflow-x: hidden; } }

@media (max-width: 1379px) {
  .header.content .block-search {
    order: 1; }
  .header.content .navigation {
    order: 2; }
  .header.content .header.links {
    order: 3; }
  .header.content .minicart-wrapper {
    order: 4; }
  .header.content .help {
    order: 5; } }

.header .contact {
  display: none !important; }

.header.content {
  height: 100%; }

.header__mobile-container,
.header.content {
  position: relative; }

@media (min-width: 1380px) {
  .header__mobile-container {
    grid-column: span 2; } }

/* Style help based on context (mobile header) */
.header.content .help {
  align-self: end;
  padding: 30px 1rem;
  display: none; }

body.nav-open .header.content .help {
  display: block; }
  @media (min-width: 1380px) {
    body.nav-open .header.content .help {
      display: none; } }

.page-header:before {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  content: '';
  z-index: 1;
  opacity: 0;
  top: 0;
  transition: opacity 280ms ease; }

.customer-account-createpassword .page-header:before,
.contentmanager-content-view:not(.contentmanager-contenttype-landingpage) .page-header:before,
.checkout-onepage-success .page-header:before,
.page-header.is-active:before,
.nav-open .page-header:before,
.page-header.is-scrolled:before,
.catalog-product-view .page-header:before,
.checkout-index-index .page-header:before,
.checkout-cart-index .page-header:before,
.customer-account-index .page-header:before,
.sales-order-history .page-header:before,
.customer-address-index .page-header:before,
.customer-account-edit .page-header:before,
.newsletter-manage-index .page-header:before,
.customer-address-form .page-header:before,
.customer-account-login .page-header:before,
.customer-account-create .page-header:before,
.customer-account-forgotpassword .page-header:before,
.account .page-header:before,
.cms-no-route .page-header:before,
.cms-page-view .page-header:before {
  opacity: 1;
  top: 0;
  transition: opacity 280ms ease; }

.customer-account-createpassword .page-header .page-header__svg,
.customer-account-createpassword .page-header .nav-toggle__nav,
.contentmanager-content-view:not(.contentmanager-contenttype-landingpage) .page-header .page-header__svg,
.contentmanager-content-view:not(.contentmanager-contenttype-landingpage) .page-header .nav-toggle__nav,
.checkout-onepage-success .page-header .page-header__svg,
.checkout-onepage-success .page-header .nav-toggle__nav,
.page-header.is-active .page-header__svg,
.page-header.is-active .nav-toggle__nav,
.nav-open .page-header .page-header__svg,
.nav-open .page-header .nav-toggle__nav,
.page-header.is-scrolled .page-header__svg,
.page-header.is-scrolled .nav-toggle__nav,
.catalog-product-view .page-header .page-header__svg,
.catalog-product-view .page-header .nav-toggle__nav,
.checkout-index-index .page-header .page-header__svg,
.checkout-index-index .page-header .nav-toggle__nav,
.checkout-cart-index .page-header .page-header__svg,
.checkout-cart-index .page-header .nav-toggle__nav,
.customer-account-index .page-header .page-header__svg,
.customer-account-index .page-header .nav-toggle__nav,
.sales-order-history .page-header .page-header__svg,
.sales-order-history .page-header .nav-toggle__nav,
.customer-address-index .page-header .page-header__svg,
.customer-address-index .page-header .nav-toggle__nav,
.customer-account-edit .page-header .page-header__svg,
.customer-account-edit .page-header .nav-toggle__nav,
.newsletter-manage-index .page-header .page-header__svg,
.newsletter-manage-index .page-header .nav-toggle__nav,
.customer-address-form .page-header .page-header__svg,
.customer-address-form .page-header .nav-toggle__nav,
.customer-account-login .page-header .page-header__svg,
.customer-account-login .page-header .nav-toggle__nav,
.customer-account-create .page-header .page-header__svg,
.customer-account-create .page-header .nav-toggle__nav,
.customer-account-forgotpassword .page-header .page-header__svg,
.customer-account-forgotpassword .page-header .nav-toggle__nav,
.account .page-header .page-header__svg,
.account .page-header .nav-toggle__nav,
.cms-no-route .page-header .page-header__svg,
.cms-no-route .page-header .nav-toggle__nav,
.cms-page-view .page-header .page-header__svg,
.cms-page-view .page-header .nav-toggle__nav {
  fill: black; }

.customer-account-createpassword .page-header .nav-toggle__close,
.customer-account-createpassword .page-header .nav-toggle__nav,
.contentmanager-content-view:not(.contentmanager-contenttype-landingpage) .page-header .nav-toggle__close,
.contentmanager-content-view:not(.contentmanager-contenttype-landingpage) .page-header .nav-toggle__nav,
.checkout-onepage-success .page-header .nav-toggle__close,
.checkout-onepage-success .page-header .nav-toggle__nav,
.page-header.is-active .nav-toggle__close,
.page-header.is-active .nav-toggle__nav,
.nav-open .page-header .nav-toggle__close,
.nav-open .page-header .nav-toggle__nav,
.page-header.is-scrolled .nav-toggle__close,
.page-header.is-scrolled .nav-toggle__nav,
.catalog-product-view .page-header .nav-toggle__close,
.catalog-product-view .page-header .nav-toggle__nav,
.checkout-index-index .page-header .nav-toggle__close,
.checkout-index-index .page-header .nav-toggle__nav,
.checkout-cart-index .page-header .nav-toggle__close,
.checkout-cart-index .page-header .nav-toggle__nav,
.customer-account-index .page-header .nav-toggle__close,
.customer-account-index .page-header .nav-toggle__nav,
.sales-order-history .page-header .nav-toggle__close,
.sales-order-history .page-header .nav-toggle__nav,
.customer-address-index .page-header .nav-toggle__close,
.customer-address-index .page-header .nav-toggle__nav,
.customer-account-edit .page-header .nav-toggle__close,
.customer-account-edit .page-header .nav-toggle__nav,
.newsletter-manage-index .page-header .nav-toggle__close,
.newsletter-manage-index .page-header .nav-toggle__nav,
.customer-address-form .page-header .nav-toggle__close,
.customer-address-form .page-header .nav-toggle__nav,
.customer-account-login .page-header .nav-toggle__close,
.customer-account-login .page-header .nav-toggle__nav,
.customer-account-create .page-header .nav-toggle__close,
.customer-account-create .page-header .nav-toggle__nav,
.customer-account-forgotpassword .page-header .nav-toggle__close,
.customer-account-forgotpassword .page-header .nav-toggle__nav,
.account .page-header .nav-toggle__close,
.account .page-header .nav-toggle__nav,
.cms-no-route .page-header .nav-toggle__close,
.cms-no-route .page-header .nav-toggle__nav,
.cms-page-view .page-header .nav-toggle__close,
.cms-page-view .page-header .nav-toggle__nav {
  stroke: black; }

.customer-account-createpassword .page-header .showcart .text,
.customer-account-createpassword .page-header .header.links a,
.contentmanager-content-view:not(.contentmanager-contenttype-landingpage) .page-header .showcart .text,
.contentmanager-content-view:not(.contentmanager-contenttype-landingpage) .page-header .header.links a,
.checkout-onepage-success .page-header .showcart .text,
.checkout-onepage-success .page-header .header.links a,
.page-header.is-active .showcart .text,
.page-header.is-active .header.links a,
.nav-open .page-header .showcart .text,
.nav-open .page-header .header.links a,
.page-header.is-scrolled .showcart .text,
.page-header.is-scrolled .header.links a,
.catalog-product-view .page-header .showcart .text,
.catalog-product-view .page-header .header.links a,
.checkout-index-index .page-header .showcart .text,
.checkout-index-index .page-header .header.links a,
.checkout-cart-index .page-header .showcart .text,
.checkout-cart-index .page-header .header.links a,
.customer-account-index .page-header .showcart .text,
.customer-account-index .page-header .header.links a,
.sales-order-history .page-header .showcart .text,
.sales-order-history .page-header .header.links a,
.customer-address-index .page-header .showcart .text,
.customer-address-index .page-header .header.links a,
.customer-account-edit .page-header .showcart .text,
.customer-account-edit .page-header .header.links a,
.newsletter-manage-index .page-header .showcart .text,
.newsletter-manage-index .page-header .header.links a,
.customer-address-form .page-header .showcart .text,
.customer-address-form .page-header .header.links a,
.customer-account-login .page-header .showcart .text,
.customer-account-login .page-header .header.links a,
.customer-account-create .page-header .showcart .text,
.customer-account-create .page-header .header.links a,
.customer-account-forgotpassword .page-header .showcart .text,
.customer-account-forgotpassword .page-header .header.links a,
.account .page-header .showcart .text,
.account .page-header .header.links a,
.cms-no-route .page-header .showcart .text,
.cms-no-route .page-header .header.links a,
.cms-page-view .page-header .showcart .text,
.cms-page-view .page-header .header.links a {
  color: #000; }

/* The header cart */
@media (min-width: 1024px) {
  .page-header .minicart-wrapper {
    display: flex;
    justify-content: flex-end; } }

.page-header .minicart-wrapper > a {
  padding: 10px 1rem; }
  @media (hover: hover) {
    .page-header .minicart-wrapper > a:hover .text {
      text-decoration: underline; } }
  @media (min-width: 1024px) {
    .page-header .minicart-wrapper > a {
      display: flex;
      align-items: center;
      padding-left: 0;
      padding-right: 0; } }

.page-header .minicart-wrapper .showcart {
  color: #fff; }

.page-header .minicart-wrapper .showcart:after,
.page-header .minicart-wrapper .showcart:before {
  display: none; }

.page-header .minicart-wrapper .counter {
  margin-left: 0.6rem; }

.counter-number {
  background: #feac00;
  font-size: 10px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 18px;
  text-indent: 1px; }

/* Mobile -- seperate file*/
@media (max-width: 1379px) {
  .header.content > .minicart-wrapper {
    display: none; } }

.mobile-header-cart {
  position: absolute;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  right: 50px; }
  @media (min-width: 1380px) {
    .mobile-header-cart {
      display: none; } }

/*
  By default it shows the empty black cart icon
  The cart icon only turns white when the page is not scrolled and one of types:

  - Home
  - Overview
  - Search

  Because these pages have a background-color or -image.
*/
.mobile-header-cart__img img {
  display: none; }

@media (max-width: 1379px) {
  .mobile-header-cart__img {
    /* By default show the empty black one, because the JS takes some time to load */
    /* If there are items, show the filled icon */ }
    .mobile-header-cart__img img.empty {
      display: block; }
    .mobile-header-cart__img.filled img.filled {
      display: block; }
    .mobile-header-cart__img.filled img.empty--white,
    .mobile-header-cart__img.filled img.empty {
      display: none; } }

.cms-home,
.catalog-category-view,
.catalogsearch-result-index {
  /* Only turn white when the page is NOT scrolled (because scrolled header always has a white background */ }
  @media (max-width: 1379px) {
    .cms-home .page-header:not(.is-scrolled) .mobile-header-cart__img,
    .catalog-category-view .page-header:not(.is-scrolled) .mobile-header-cart__img,
    .catalogsearch-result-index .page-header:not(.is-scrolled) .mobile-header-cart__img {
      /* By default show the empty white one, because the JS takes some time to load */
      /* If there are items, show the filled icon */ }
      .cms-home .page-header:not(.is-scrolled) .mobile-header-cart__img img.empty--white,
      .catalog-category-view .page-header:not(.is-scrolled) .mobile-header-cart__img img.empty--white,
      .catalogsearch-result-index .page-header:not(.is-scrolled) .mobile-header-cart__img img.empty--white {
        display: block; }
      .cms-home .page-header:not(.is-scrolled) .mobile-header-cart__img img.empty,
      .catalog-category-view .page-header:not(.is-scrolled) .mobile-header-cart__img img.empty,
      .catalogsearch-result-index .page-header:not(.is-scrolled) .mobile-header-cart__img img.empty {
        display: none; }
      .cms-home .page-header:not(.is-scrolled) .mobile-header-cart__img.filled img.empty--white,
      .catalog-category-view .page-header:not(.is-scrolled) .mobile-header-cart__img.filled img.empty--white,
      .catalogsearch-result-index .page-header:not(.is-scrolled) .mobile-header-cart__img.filled img.empty--white {
        display: none; }
      .cms-home .page-header:not(.is-scrolled) .mobile-header-cart__img.filled img.filled,
      .catalog-category-view .page-header:not(.is-scrolled) .mobile-header-cart__img.filled img.filled,
      .catalogsearch-result-index .page-header:not(.is-scrolled) .mobile-header-cart__img.filled img.filled {
        display: block; } }

.mobile-header-cart__link .counter-number {
  display: none; }

body.nav-open .mobile-header-cart__link .counter-number {
  display: flex; }

body.nav-open .mobile-header-cart__link .mobile-header-cart__img {
  display: none; }

/* This wraps the logo and the hamburger/close button */
.header__mobile-wrapper {
  position: relative; }

/* Logo */
.page-header__logo {
  display: flex;
  align-items: center;
  width: calc(78px + 2rem);
  padding: 1rem;
  margin-bottom: 0; }
  .page-header__logo:before, .page-header__logo:after {
    display: none; }
  @media (min-width: 1380px) {
    .page-header__logo {
      width: 160px;
      padding: 0;
      grid-column: span 2;
      display: block; } }
  .page-header__logo img {
    display: block;
    max-width: 100%;
    height: auto; }

.page-header__svg {
  width: 100%;
  fill: black; }

.page-header__nav-icon {
  width: 1.75rem;
  fill: none;
  stroke: white;
  stroke-miterlimit: 10;
  stroke-width: 0.1rem; }

.page-header .greet {
  display: none; }

/* My Account link */
@media (min-width: 1380px) {
  .page-header .header.links {
    text-align: center; } }

.page-header .header.links li {
  padding: 10px 1rem; }
  @media (min-width: 1380px) {
    .page-header .header.links li {
      padding-left: 0;
      padding-right: 0; } }

.page-header .header.links a {
  color: #fff;
  display: block; }
  @media (hover: hover) {
    .page-header .header.links a:hover {
      text-decoration: underline; } }

.page-footer {
  /* Screwed selector because markup comes from the database? */ }
  .page-footer ul {
    list-style: none;
    padding: 0;
    margin: 0 0 1.6em; }
    .page-footer ul a {
      margin: 0 0 0 0;
      display: inline; }
  .page-footer input,
  .page-footer select {
    font-style: italic; }
  .page-footer .subscribe fieldset {
    max-width: none; }
  .page-footer .legal p,
  .page-footer .legal a {
    font-size: 12px; }
    .page-footer .legal p:before, .page-footer .legal p:after,
    .page-footer .legal a:before,
    .page-footer .legal a:after {
      content: none; }
  .page-footer .legal p {
    margin: 0 0 0; }
  .page-footer .review p {
    font-size: 24px;
    margin: 0 0 0.3em; }
    .page-footer .review p strong {
      font-weight: 800; }
  .page-footer .navigation {
    display: block; }
    .page-footer .navigation span {
      font-family: "Rubik light"; }
  .page-footer .footer-form-title {
    margin-top: 0;
    margin-bottom: calc(14 / 10 * 1rem); }
    @media (min-width: 1024px) {
      .page-footer .footer-form-title {
        margin-bottom: 2em; } }
  @media (hover: hover) {
    .page-footer .footer .legal a:hover,
    .page-footer .footer .navigation a:hover {
      text-decoration: underline; }
      .page-footer .footer .legal a:hover:before, .page-footer .footer .legal a:hover:after,
      .page-footer .footer .navigation a:hover:before,
      .page-footer .footer .navigation a:hover:after {
        content: none; } }

.page-footer {
  background: #f5f5ef;
  padding-bottom: 50px; }

.footer {
  margin: 0 auto;
  padding-top: 2.8rem;
  padding-bottom: 1.4rem; }
  @supports (display: grid) {
    .footer {
      display: grid;
      grid-template-columns: repeat(12, [col-start] 1fr);
      row-gap: 0;
      column-gap: 2px; } }
  .footer:after {
    content: '';
    display: block;
    clear: both; }

.footer__heading {
  margin-bottom: 2em; }

.footer__bottom {
  border-top: 2px solid white; }

.footer .column {
  grid-column: auto / span 12;
  margin: 0 0 calc(40 / 14 * 1rem); }
  @media (min-width: 1024px) {
    .footer .column {
      padding: 0 8.3% 0 0;
      margin-bottom: calc(50 / 14 * 1rem); } }
  @media (min-width: 1024px) {
    .footer .column:first-child {
      grid-column: auto / span 4;
      padding-right: 25%; } }
  @media (min-width: 1024px) {
    .footer .column {
      margin: 0;
      padding: 0 33.333333% 0 0;
      grid-column: auto / span 3; } }
  .footer .column > h3:first-child {
    margin: 0 0 2em 0; }

/* Right side of the footer */
.footer .column--right {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column: 1 / -1; }
  @media (min-width: 1024px) {
    .footer .column--right {
      grid-column: span 5;
      grid-template-columns: repeat(6, 1fr); } }

/* Column review */
.footer .column--product {
  grid-column: span 6; }

/* Column exception (2 columns) -- Products (last one) */
.footer .column--small {
  grid-column: span 6; }
  @media (min-width: 1024px) {
    .footer .column--small {
      grid-column: auto / span 2;
      padding-right: 0; } }

/* Column full size */
.footer .column--full {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: calc(45 / 14 * 1rem); }
  @media (min-width: 1024px) {
    .footer .column--full {
      grid-column: 1 / -1; } }

.footer .review {
  grid-column: 1 / -1; }
  .footer .review a:hover {
    text-decoration: none; }

.footer .navigation ul {
  flex-direction: column; }
  .footer .navigation ul li {
    margin: 0;
    padding: 0; }
  .footer .navigation ul span {
    color: #000; }

/* Social */
.footer__social-list li {
  width: 20px; }
  @media (hover: hover) {
    .footer__social-list li:hover img {
      opacity: 0.6; } }

.footer__social-list a {
  display: block; }
  .footer__social-list a:before, .footer__social-list a:after {
    content: none; }

.footer__social-list img {
  max-width: 100%;
  display: block; }

/* Country custom dropdown */
.select-country {
  margin: 0 0 3.5rem; }

/* Subscribe form */
.footer__subscribe-form fieldset {
  border: 0;
  padding: 0;
  margin: 0; }

/* Legal text */
.legal {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  .legal ul {
    margin-bottom: 0; }

.legal__title {
  margin-right: 2.1rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 300;
  font-size: calc(12rem / 14);
  line-height: 1.8;
  flex-basis: 100%; }
  @media (min-width: 1024px) {
    .legal__title {
      flex-basis: auto;
      margin-top: 0;
      margin-bottom: 0; } }

/* Store */
.store ul {
  margin-bottom: 0; }
  @media (min-width: 768px) {
    .store ul {
      margin-bottom: initial; } }

/* Footer shops */
.page-footer .shops {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  justify-content: flex-start; }

.shops__item {
  margin-left: 10px;
  width: 20px; }
  .shops__item:first-child {
    margin-left: 0; }
  @media (min-width: 1024px) {
    .shops__item {
      margin-left: 20px;
      width: 25px;
      margin-bottom: 0; } }
  .shops__item img {
    display: block;
    max-width: 100%; }

#newsletter-error {
  color: #e02b27;
  font-weight: bold; }

.shops__item--klarna {
  width: 60px; }

.shops__item--ideal {
  width: 40px; }

.shops__item--mastercard {
  width: 90px; }

.shops__item--paypal {
  width: 50px; }

.shops__item--thuiswinkel {
  width: 80px; }

.shops__item--visa {
  width: 40px; }

.shops__item--bancontact {
  width: 40px; }

.shops__item--ecommerce {
  width: 50px; }

.shops__item--becommerce {
  width: 40px; }

.shops__item--giropay {
  width: 50px; }

.shops__item--sofort {
  width: 50px; }

.shops__item--trusted {
  width: 40px; }

.shops__item--eps {
  width: 50px; }

.header.content .contact,
.minicart-wrapper,
.header.links,
.navigation {
  display: none; }
  .nav-open .header.content .contact, .nav-open
  .minicart-wrapper, .nav-open
  .header.links, .nav-open
  .navigation {
    display: block; }

.minicart-wrapper {
  overflow: initial !important; }

.header.links,
.ui-menu {
  list-style: none;
  padding: 0;
  margin: 0; }

.header.links .link, .header.links .link-button--link,
.header.links button.action--link, .header.links .page-footer a, .page-footer .header.links a, .header.links .page-footer .footer > .column:first-child ul:not(.horizontal-list) a, .page-footer .footer > .column:first-child ul:not(.horizontal-list) .header.links a, .header.links .page-header .navigation a.level0.first.level-top.menu-item-active, .page-header .navigation .header.links a.level0.first.level-top.menu-item-active, .header.links .page-header a.level0.level-top, .page-header .header.links a.level0.level-top, .header.links body .yotpo-reviews-carousel .carousel-display-wrapper .carousel-read-more, body .yotpo-reviews-carousel .carousel-display-wrapper .header.links .carousel-read-more, .header.links #layered-filter-block-container .filter-options-item.has-items .filter-options-title > span, #layered-filter-block-container .filter-options-item.has-items .header.links .filter-options-title > span, .header.links .product-info-main .add-sample-to-cart button[type='submit'], .product-info-main .add-sample-to-cart .header.links button[type='submit'], .header.links .product-info-main #uploadBtn.active, .product-info-main .header.links #uploadBtn.active, .header.links .search-autocomplete li .qs-option-name, .search-autocomplete li .header.links .qs-option-name, .header.links .account-nav-content .current strong, .account-nav-content .current .header.links strong, .header.links .related-header > a, .header.links .hyvareactcheckout-reactcheckout-index .shipping-information .action, .hyvareactcheckout-reactcheckout-index .shipping-information .header.links .action, .header.links .sales-order-print .order-links a, .sales-order-print .order-links .header.links a,
.header.links .sales-order-invoice .order-links a, .sales-order-invoice .order-links .header.links a,
.header.links .sales-order-view .order-links a, .sales-order-view .order-links .header.links a,
.header.links a,
.ui-menu-item a,
.ui-menu a,
.minicart-wrapper .action.showcart .text {
  font-size: 16px; }
  .header.links .link:before, .header.links .link-button--link:before,
  .header.links button.action--link:before, .header.links .page-footer a:before, .page-footer .header.links a:before, .header.links .page-footer .footer > .column:first-child ul:not(.horizontal-list) a:before, .page-footer .footer > .column:first-child ul:not(.horizontal-list) .header.links a:before, .header.links .page-header .navigation a.level0.first.level-top.menu-item-active:before, .page-header .navigation .header.links a.level0.first.level-top.menu-item-active:before, .header.links .page-header a.level0.level-top:before, .page-header .header.links a.level0.level-top:before, .header.links body .yotpo-reviews-carousel .carousel-display-wrapper .carousel-read-more:before, body .yotpo-reviews-carousel .carousel-display-wrapper .header.links .carousel-read-more:before, .header.links #layered-filter-block-container .filter-options-item.has-items .filter-options-title > span:before, #layered-filter-block-container .filter-options-item.has-items .header.links .filter-options-title > span:before, .header.links .product-info-main .add-sample-to-cart button[type='submit']:before, .product-info-main .add-sample-to-cart .header.links button[type='submit']:before, .header.links .product-info-main #uploadBtn.active:before, .product-info-main .header.links #uploadBtn.active:before, .header.links .search-autocomplete li .qs-option-name:before, .search-autocomplete li .header.links .qs-option-name:before, .header.links .account-nav-content .current strong:before, .account-nav-content .current .header.links strong:before, .header.links .related-header > a:before, .header.links .hyvareactcheckout-reactcheckout-index .shipping-information .action:before, .hyvareactcheckout-reactcheckout-index .shipping-information .header.links .action:before, .header.links .sales-order-print .order-links a:before, .sales-order-print .order-links .header.links a:before,
  .header.links .sales-order-invoice .order-links a:before, .sales-order-invoice .order-links .header.links a:before,
  .header.links .sales-order-view .order-links a:before, .sales-order-view .order-links .header.links a:before, .header.links .link:after, .header.links .link-button--link:after,
  .header.links button.action--link:after, .header.links .page-footer a:after, .page-footer .header.links a:after, .header.links .page-footer .footer > .column:first-child ul:not(.horizontal-list) a:after, .page-footer .footer > .column:first-child ul:not(.horizontal-list) .header.links a:after, .header.links .page-header .navigation a.level0.first.level-top.menu-item-active:after, .page-header .navigation .header.links a.level0.first.level-top.menu-item-active:after, .header.links .page-header a.level0.level-top:after, .page-header .header.links a.level0.level-top:after, .header.links body .yotpo-reviews-carousel .carousel-display-wrapper .carousel-read-more:after, body .yotpo-reviews-carousel .carousel-display-wrapper .header.links .carousel-read-more:after, .header.links #layered-filter-block-container .filter-options-item.has-items .filter-options-title > span:after, #layered-filter-block-container .filter-options-item.has-items .header.links .filter-options-title > span:after, .header.links .product-info-main .add-sample-to-cart button[type='submit']:after, .product-info-main .add-sample-to-cart .header.links button[type='submit']:after, .header.links .product-info-main #uploadBtn.active:after, .product-info-main .header.links #uploadBtn.active:after, .header.links .search-autocomplete li .qs-option-name:after, .search-autocomplete li .header.links .qs-option-name:after, .header.links .account-nav-content .current strong:after, .account-nav-content .current .header.links strong:after, .header.links .related-header > a:after, .header.links .hyvareactcheckout-reactcheckout-index .shipping-information .action:after, .hyvareactcheckout-reactcheckout-index .shipping-information .header.links .action:after, .header.links .sales-order-print .order-links a:after, .sales-order-print .order-links .header.links a:after,
  .header.links .sales-order-invoice .order-links a:after, .sales-order-invoice .order-links .header.links a:after,
  .header.links .sales-order-view .order-links a:after, .sales-order-view .order-links .header.links a:after,
  .header.links a:before,
  .header.links a:after,
  .ui-menu-item a:before,
  .ui-menu-item a:after,
  .ui-menu a:before,
  .ui-menu a:after,
  .minicart-wrapper .action.showcart .text:before,
  .minicart-wrapper .action.showcart .text:after {
    display: none; }

.page-wrapper .page-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  transition: transform 600ms cubic-bezier(0.6, 0, 0.01, 0.99); }
  .page-wrapper .page-header.scroll-hidden {
    transform: translateY(-100%); }

@media (min-width: 1380px) {
  .page-header {
    height: 8.6rem;
    padding: 0; }
    .page-header.is-scrolled {
      height: 5.5rem; }
  .header.links {
    display: block; } }

@media (min-width: 1380px) {
  .nav-toggle,
  .mobile {
    display: none; }
  .navigation {
    margin: 0;
    padding: 0; }
    .navigation ul li {
      margin: 0 2.1rem 0 0; }
      .navigation ul li:last-child {
        margin: 0; }
    .navigation .submenu.level0 {
      margin: 0 auto;
      padding: 4.2rem;
      z-index: 1; }
      @supports (display: grid) {
        .navigation .submenu.level0 {
          display: grid;
          grid-template-columns: repeat(12, [col-start] 1fr);
          row-gap: 0;
          column-gap: 2px; } }
      .navigation .submenu.level0:after {
        content: '';
        display: block;
        clear: both; }
      .navigation .submenu.level0 li.first {
        grid-column-start: 3; }
    @supports (display: grid) {
      .navigation .submenu {
        display: grid;
        grid-template-columns: repeat(12, [col-start] 1fr);
        row-gap: 0;
        column-gap: 2px; } }
    .navigation .submenu:after {
      content: '';
      display: block;
      clear: both; }
    .navigation .submenu li {
      display: block; }
    .navigation .submenu.level1 {
      display: block !important;
      position: initial !important; } }

@media (min-width: 1380px) {
  .page-header .navigation > ul {
    display: inline-flex;
    height: 100%; } }

@media (min-width: 1380px) {
  .page-header .navigation {
    margin-bottom: 0;
    height: 100%;
    display: flex; } }

.page-header .navigation a {
  display: block;
  width: 100%;
  margin-bottom: 0; }
  .page-header .navigation a span {
    text-decoration: none; }

.page-header .navigation a.level0.first.level-top.menu-item-active {
  /* We want the underlines on mobile on the span because the link needs to be 100% width but the underline doesnt */ }
  @media (max-width: 1023px) {
    .page-header .navigation a.level0.first.level-top.menu-item-active:before, .page-header .navigation a.level0.first.level-top.menu-item-active:after {
      display: none; }
    .page-header .navigation a.level0.first.level-top.menu-item-active span {
      color: #000;
      text-decoration: none;
      position: relative;
      display: inline-block; }
      .page-header .navigation a.level0.first.level-top.menu-item-active span:after, .page-header .navigation a.level0.first.level-top.menu-item-active span:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        height: 2px;
        width: 100%;
        background: #feac00; }
      .page-header .navigation a.level0.first.level-top.menu-item-active span:before {
        width: 0;
        transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99); }
      .page-header .navigation a.level0.first.level-top.menu-item-active span:after {
        right: 0;
        transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99) 100ms; } }
  @media (max-width: 1023px) and (hover: hover) {
    .page-header .navigation a.level0.first.level-top.menu-item-active span:hover:after {
      width: 0;
      transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99); }
    .page-header .navigation a.level0.first.level-top.menu-item-active span:hover:before {
      width: 100%;
      transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99) 100ms; } }
  @media (max-width: 1023px) {
      .page-header .navigation a.level0.first.level-top.menu-item-active span--clean:before, .page-header .navigation a.level0.first.level-top.menu-item-active span--clean:after {
        display: none; }
      .page-header .navigation a.level0.first.level-top.menu-item-active span.white {
        color: white; } }

.menu-item-active {
  text-decoration: none; }

.page-header .navigation a.level2.menu-item-active > span {
  text-decoration: none; }

.page-header .submenu {
  list-style: none; }

@media (min-width: 1380px) {
  .page-header .navigation li.level0.level-top {
    margin: 0;
    padding: 0 1rem;
    display: flex;
    align-items: center; }
    .page-header .navigation li.level0.level-top:first-child {
      padding-left: 0; } }

@media (hover: hover) and (min-width: 1380px) {
  .page-header .navigation li.level0.level-top:hover ul.level0.submenu {
    display: grid; } }

@media (min-width: 1380px) {
  .page-header .navigation li.level0.level-top > .level0.submenu {
    top: auto !important;
    max-width: 110.76923rem;
    width: 100vw;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%) translateY(100%); } }

.page-header .navigation li.level0.level-top > .level0.submenu:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 1; }
  @media (min-width: 1550px) {
    .page-header .navigation li.level0.level-top > .level0.submenu:before {
      left: calc((-100vw + 110.76923rem) / 2); } }

.page-header .navigation li.level0.level-top > .level0.submenu > li {
  position: relative;
  z-index: 2; }

.page-header .navigation li.level0.level-top:nth-child(4n + 1) .level0.submenu:before {
  background-color: #e7cfcf; }

.page-header .navigation li.level0.level-top:nth-child(4n + 2) .level0.submenu:before {
  background-color: #ddd8cc; }

.page-header .navigation li.level0.level-top:nth-child(4n + 3) .level0.submenu:before {
  background-color: #eeeddb; }

.page-header .navigation li.level0.level-top:nth-child(4n + 4) .level0.submenu:before {
  background-color: #c1c9b3; }

.page-header a.level0.level-top {
  font-size: 1rem;
  padding: 10px 1rem;
  margin: 0;
  line-height: calc(32rem / 14); }
  @media (min-width: 1380px) {
    .page-header a.level0.level-top {
      padding: 0; } }
  .page-header a.level0.level-top span {
    text-transform: uppercase;
    font-family: "Rubik medium";
    letter-spacing: 1px;
    color: #000; }
    @media (min-width: 1380px) {
      .page-header a.level0.level-top span {
        letter-spacing: 3px; } }
  @media (min-width: 1380px) {
    .page-header a.level0.level-top:after {
      display: none; } }
  .page-header a.level0.level-top:before {
    display: none; }
    @media (min-width: 1380px) {
      .page-header a.level0.level-top:before {
        display: block; } }

.page-header .navigation .has-sub-menu {
  position: relative; }
  .page-header .navigation .has-sub-menu:after {
    content: '';
    background-image: url("../images/icons/arrow.svg");
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    height: 20px;
    width: 20px;
    display: block;
    position: absolute;
    right: 1rem;
    top: 15px;
    left: auto;
    pointer-events: none; }
    @media (min-width: 1380px) {
      .page-header .navigation .has-sub-menu:after {
        display: none; } }
  .page-header .navigation .has-sub-menu.active:after {
    transform: rotate(90deg); }
  .page-header .navigation .has-sub-menu.active > a + .submenu {
    display: block;
    position: relative; }

.page-header .navigation .submenu.level0 > li > a {
  font-family: "Rubik medium";
  text-transform: uppercase;
  font-size: 0.8571428571rem;
  letter-spacing: 3px;
  line-height: 2.6666666667rem;
  margin-top: 20px; }
  @media (min-width: 1380px) {
    .page-header .navigation .submenu.level0 > li > a {
      margin-top: 0; } }

.page-header .navigation .submenu.level0 > li:first-child a {
  margin-top: 0; }

.page-header .navigation .level0.submenu {
  margin: 0;
  padding: 2rem 1rem;
  width: 100%;
  display: none; }
  @media (min-width: 1380px) {
    .page-header .navigation .level0.submenu {
      padding-left: 4.2rem;
      padding-right: 4.2rem; } }

@media (min-width: 1380px) {
  .page-header .navigation .level0.submenu > li {
    grid-column: span 2; }
    .page-header .navigation .level0.submenu > li.first {
      grid-column: 4 / span 2; } }

@media (min-width: 1380px) {
  .page-header .navigation .level0.submenu > li.first {
    grid-column: 3 / span 2; } }

.page-header .navigation a.level0.level-top.active + .submenu {
  position: relative;
  display: block; }

.page-header .navigation li.level1.first {
  display: block;
  padding: 0; }

.page-header .level1.submenu {
  margin: 0;
  padding: 0; }
  .page-header .level1.submenu span {
    margin: 0; }

.page-header a.level1.first {
  font-family: "Rubik medium";
  text-transform: uppercase;
  font-size: 0.8571428571rem;
  letter-spacing: 3px;
  line-height: 2.6666666667rem; }

.page-header .navigation .level1.submenu a {
  padding: 15px 0;
  line-height: 1; }
  @media (min-width: 1380px) {
    .page-header .navigation .level1.submenu a {
      padding: 8px 0; } }
  @media (hover: hover) {
    .page-header .navigation .level1.submenu a:hover {
      text-decoration: underline; } }
  .page-header .navigation .level1.submenu a span {
    font-family: "Rubik light"; }

.page-header .navigation .level0.level-top > span {
  color: white; }

.page-header .navigation .level0.level-top > span {
  color: black; }

.page-header.is-active .navigation .level0.level-top > span,
.page-header.is-scrolled .navigation .level0.level-top > span {
  color: black; }

.cms-home .page-header .navigation .level0.level-top > span,
.page-products .page-header .navigation .level0.level-top > span {
  color: black; }
  @media (min-width: 1380px) {
    .cms-home .page-header .navigation .level0.level-top > span,
    .page-products .page-header .navigation .level0.level-top > span {
      color: white; } }

.cms-home .page-header.is-active .navigation .level0.level-top > span,
.cms-home .page-header.is-scrolled .navigation .level0.level-top > span,
.page-products .page-header.is-active .navigation .level0.level-top > span,
.page-products .page-header.is-scrolled .navigation .level0.level-top > span {
  color: black; }

.nav-toggle {
  cursor: pointer;
  background: none;
  border: 0;
  height: 100%;
  position: absolute;
  top: 21px;
  right: 1rem;
  width: 50px;
  transform: none;
  outline: none;
  padding: 0;
  display: flex;
  justify-content: flex-end; }
  @media (min-width: 1380px) {
    .nav-toggle {
      display: none; } }
  .nav-toggle img {
    width: 15px; }

.nav-toggle__close {
  display: none; }

.nav-toggle__nav {
  display: block; }

body.nav-open .nav-toggle__close {
  display: block; }

body.nav-open .nav-toggle__nav {
  display: none; }

body .yotpo-container + p {
  display: none; }

body .yotpo .yotpo-stars,
body .yotpo .carousel-review-title,
body .yotpo .product-link-container,
body .yotpo .yotpo-label {
  display: none; }

body .yotpo-reviews-carousel .carousel-display-wrapper .carousel-read-more {
  display: none;
  color: #000;
  width: auto;
  margin-left: 1rem;
  cursor: pointer; }
  @media (min-width: 769px) {
    body .yotpo-reviews-carousel .carousel-display-wrapper .carousel-read-more {
      display: inline-block; } }

body.cms-index-index .yotpo-reviews-carousel .carousel-display-wrapper .carousel-read-more {
  color: #fff; }

body .yotpo-reviews-carousel .carousel-display-wrapper {
  padding-left: 0;
  padding-right: 0; }

body .yotpo-reviews-carousel .carousel-display-wrapper .scroller {
  overflow-y: hidden; }

body .yotpo-reviews-carousel .carousel-display-wrapper #carousel-top-panel {
  float: none;
  border: 0;
  padding: 0;
  margin: 0 0 15px 0; }
  @media (min-width: 1024px) {
    body .yotpo-reviews-carousel .carousel-display-wrapper #carousel-top-panel {
      margin-bottom: 25px; } }

body .yotpo-reviews-carousel .carousel-display-wrapper .yotpo-carousel {
  padding-top: 0; }

body .yotpo-reviews-carousel .carousel-display-wrapper .stars-container {
  height: 0 !important; }

body .yotpo-reviews-carousel .carousel-display-wrapper .yotpo-carousel .small-version {
  position: relative; }

body .yotpo-reviews-carousel .carousel-display-wrapper .yotpo-carousel .big-version .right-side,
body .yotpo-reviews-carousel .carousel-display-wrapper .yotpo-carousel .small-version .right-side {
  width: 100%;
  padding-bottom: 30px;
  position: relative; }

body .yotpo-reviews-carousel .carousel-display-wrapper .yotpo-carousel .carousel-review-body {
  margin: 0;
  color: black;
  font-style: normal !important;
  font-family: "Rubik light";
  line-height: 1.7857142857rem; }

body .yotpo-reviews-carousel .carousel-display-wrapper #carousel-top-panel .headline {
  color: black;
  font-family: "Noe book";
  font-size: 1.5rem;
  line-height: normal;
  font-weight: 300;
  margin: 0; }

body .yotpo-reviews-carousel .carousel-display-wrapper .yotpo-carousel .single-carousel .carousel-review-date {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 61px;
  padding-right: 0;
  padding-left: 0;
  line-height: 1;
  font-style: normal !important;
  font-size: 14px;
  font-family: "Rubik medium";
  color: black; }

body .yotpo-reviews-carousel .carousel-display-wrapper .yotpo-carousel .carousel-review-author {
  position: absolute;
  font-size: 14px;
  left: 81px;
  bottom: 0;
  margin: 0;
  font-weight: normal;
  float: none;
  color: black;
  line-height: 1;
  padding-left: 50px;
  font-family: "Rubik medium"; }
  body .yotpo-reviews-carousel .carousel-display-wrapper .yotpo-carousel .carousel-review-author:before {
    position: absolute;
    width: 30px;
    height: 2px;
    background-color: black;
    top: 50%;
    left: 0;
    content: ''; }

body.cms-home .yotpo-reviews-carousel .carousel-display-wrapper .yotpo-carousel .carousel-review-body,
body.cms-home .yotpo-reviews-carousel .carousel-display-wrapper #carousel-top-panel .headline,
body.cms-home .yotpo-reviews-carousel .carousel-display-wrapper .yotpo-carousel .single-carousel .carousel-review-date,
body.cms-home .yotpo-reviews-carousel .carousel-display-wrapper .yotpo-carousel .carousel-review-author {
  color: white; }

body.cms-home .yotpo-reviews-carousel .carousel-display-wrapper .yotpo-carousel .carousel-review-author:before {
  background-color: white; }

.yotpo-reviews-carousel .carousel-tool-tip .yotpo-tool-tip {
  border-radius: 0; }

.yotpo .yotpo-tool-tip .tool-tip-header .tool-tip-header-span.header-blue {
  color: #000; }

.yotpo-reviews-carousel .carousel-tool-tip .yotpo-tool-tip {
  transform: translateY(-50%);
  background: #f5f5f5 !important; }

.yotpo-reviews-carousel .carousel-display-wrapper div {
  color: #000; }

.product-item {
  background-color: #fff;
  position: relative;
  flex: 1;
  display: flex; }
  .product-item a {
    display: block;
    width: 100%; }
  .product-item a:focus {
    outline: 0; }
    .product-item a:focus .name {
      font-weight: 600; }

.product-item-info {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px 15px;
  position: relative; }
  @media (min-width: 1024px) {
    .product-item-info {
      padding: 30px 40px; } }
  .product-item-info a:after,
  .product-item-info a:before {
    display: none; }
  .product-item-info .name {
    display: block; }
    @media (min-width: 769px) {
      .product-item-info .name {
        line-height: calc(32 / 12);
        margin-bottom: 15px; } }
  .product-item-info .old-price {
    display: none; }
    @media (min-width: 520px) {
      .product-item-info .old-price {
        display: block; } }
    @media (min-width: 1024px) {
      .product-item-info .old-price {
        display: inline-block; } }

.product-item-details {
  margin-top: auto; }
  .product-item-details [role='title'] {
    font-weight: bold; }

.product-item-photo {
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .product-item-photo:focus {
    outline: none; }
  @media (min-width: 1024px) {
    .product-item-photo {
      margin-bottom: 30px;
      overflow: hidden; } }
  @media (min-width: 1024px) {
    .product-item-photo:hover .product-image-photo {
      transform: scale(1.33); } }
  @media (min-width: 1200px) {
    .product-item-photo:hover .product-image-photo {
      transform: scale(1.2); } }
  @media (min-width: 1400px) {
    .product-item-photo:hover .product-image-photo {
      transform: scale(2); } }

.product-image-photo {
  display: block;
  margin: 0 auto;
  margin-bottom: 1rem;
  width: 200px;
  max-width: 80%; }
  @media (min-width: 520px) {
    .product-image-photo {
      max-width: 100%; } }
  @media (min-width: 1024px) {
    .product-image-photo {
      width: auto;
      transition: transform 250ms ease;
      transform-origin: left top; } }

.product-item-actions {
  display: none; }

.description {
  display: none; }
  @media (min-width: 1024px) {
    .description {
      display: block; } }

.product-label {
  position: absolute;
  left: 0;
  top: 50%;
  display: inline-block;
  padding: 5px 13px;
  color: #fff;
  transform: translateY(-50%);
  z-index: 2; }

.product-label--special {
  background-color: #c99e9f; }

.product-label--complete {
  background-color: #868b7c; }

.price-label {
  display: none; }

.product-item__size {
  font-size: 12px;
  color: #9b9b9b;
  display: block;
  font-family: "Rubik light";
  line-height: 1.7857142857rem; }

.catalog-category-view {
  background-color: #868b7c; }

.products-grid .items {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-gap: 1px; }

.products-grid .product-item {
  grid-column: auto / span 6; }
  @media (min-width: 530px) {
    .products-grid .product-item {
      grid-column: auto / span 4; } }
  @media (min-width: 1024px) {
    .products-grid .product-item {
      grid-column: auto / span 3; } }

.products-grid li {
  list-style-type: none; }

@keyframes image {
  0% {
    transform: scale(1.1); }
  100% {
    transform: scale(1); } }

.background-image ~ .page-main {
  background-image: linear-gradient(transparent 0%, rgba(0, 0, 0, 0.5) 60%, rgba(0, 0, 0, 0.6) 67.5%); }

.page-wrapper > * {
  position: relative;
  z-index: 10; }

.page-wrapper > .background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  transform: scale(1.1);
  animation: image 800ms cubic-bezier(0.6, 0, 0.01, 0.9) forwards 400ms;
  overflow: hidden;
  /* The overlay for the image */ }
  .page-wrapper > .background-image:before, .page-wrapper > .background-image:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .page-wrapper > .background-image:after {
    background: rgba(0, 0, 0, 0.3); }
  .page-wrapper > .background-image:before {
    background: #868b7c;
    animation: right 900ms cubic-bezier(0.6, 0, 0.01, 0.9) forwards 200ms; }
  .page-wrapper > .background-image img {
    object-fit: cover;
    width: 100%;
    height: 100%; }

.home-background__gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
  height: 33.333333vh;
  transform: translateY(100%);
  animation: up 1100ms cubic-bezier(0.6, 0, 0.01, 0.9) forwards 700ms; }

.cms-home.cms-index-index .homepage-header__title {
  animation-delay: 1100ms; }

.cms-home.cms-index-index .homepage-header__buttons .inner {
  animation-delay: 1500ms; }

@media (min-width: 1024px) {
  .cms-home.cms-index-index .steps {
    overflow: hidden; }
    .cms-home.cms-index-index .steps > * {
      transform: translateY(100%);
      animation: up 1000ms cubic-bezier(0.6, 0, 0.01, 0.9) forwards;
      animation-delay: 1000ms; } }

@media (min-width: 1024px) {
  .cms-home.cms-index-index .header.content .header.links,
  .cms-home.cms-index-index .header.content .block-search,
  .cms-home.cms-index-index .header.content .minicart-wrapper {
    overflow: hidden; }
    .cms-home.cms-index-index .header.content .header.links > *:not(#mini-search-dropdown),
    .cms-home.cms-index-index .header.content .block-search > *:not(#mini-search-dropdown),
    .cms-home.cms-index-index .header.content .minicart-wrapper > *:not(#mini-search-dropdown) {
      transform: translateY(-100%);
      animation: down 1000ms cubic-bezier(0.6, 0, 0.01, 0.9) forwards;
      animation-delay: 800ms; } }

@media (min-width: 1024px) {
  .cms-home.cms-index-index .header.content .navigation li.level0 > a {
    overflow: hidden;
    display: block; }
    .cms-home.cms-index-index .header.content .navigation li.level0 > a > * {
      display: block;
      transform: translateY(-100%);
      transform: translateY(-100%);
      animation: down 1000ms cubic-bezier(0.6, 0, 0.01, 0.9) forwards;
      animation-delay: 800ms; } }

@media (min-width: 1024px) {
  .cms-home.cms-index-index .page-header.is-active .navigation {
    overflow: visible; } }

.guarantee-list {
  list-style-image: url("../images/icons/checkmark.svg");
  padding: 0 0 0 1.8rem; }

.guarantee-list__inner {
  margin: 0; }

.guarantee-list__item {
  font-family: "Rubik light";
  font-size: 1.35rem;
  padding: 0.7rem 0 0.7rem 0.4rem; }

@media (min-width: 1024px) {
  .guarantee-list__item {
    font-size: 1.7rem; } }

.extra-info {
  grid-column: auto / span 12;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  padding: 2.28rem 2.14rem;
  height: auto;
  color: #fff;
  background-color: #c99e9f; }
  @media (min-width: 769px) {
    .extra-info {
      grid-column: auto / span 6;
      padding-left: 7.57rem; } }

.extra-info__title {
  font-family: "Noe book";
  font-size: 1.28rem;
  margin-bottom: 2rem; }

.extra-info__paragraph {
  margin-top: 0;
  margin-bottom: 2rem;
  font-size: 1.14rem; }

.extra-info-list {
  list-style: none;
  padding: 0;
  margin: 0;
  color: white;
  font-size: 1.14rem; }
  .extra-info-list li {
    color: white;
    font-size: 1.14rem;
    line-height: 1;
    margin-bottom: 1rem; }
    .extra-info-list li a {
      font-size: 1.14rem;
      color: #fff;
      line-height: 1; }
      .extra-info-list li a:before, .extra-info-list li a:after {
        display: none; }
    .extra-info-list li:last-child {
      margin-bottom: 0; }

.extra-info--background-image {
  background-image: url("../images/extra-info-background.jpg");
  background-size: cover; }

.steps {
  padding: 0;
  margin-left: -1rem;
  padding-left: 1rem; }
  @media (min-width: 769px) {
    .steps {
      width: 100%;
      display: block;
      padding: 0;
      margin-left: 0; } }

@media (min-width: 769px) {
  .steps__inner {
    display: flex;
    background-color: #868b7c;
    align-items: center; } }

.steps__container {
  list-style: none;
  display: grid;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  margin: 0;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1px;
  padding: 0;
  padding-right: 1rem; }
  @media (min-width: 769px) {
    .steps__container {
      overflow: initial;
      display: block;
      padding: 4rem;
      background-color: #868b7c;
      width: 100%; } }

.steps__title {
  display: block;
  font-family: "Noe book";
  text-transform: none;
  letter-spacing: 0;
  line-height: 1;
  font-size: 1.2857142857rem;
  font-weight: 300;
  color: white;
  margin-bottom: 15px; }
  @media (min-width: 769px) {
    .steps__title {
      display: none; } }

@media (min-width: 769px) {
  .steps__item:first-child .steps__title {
    display: block;
    margin-bottom: 30px; } }

.steps__item {
  padding: 30px;
  background-color: #868b7c;
  grid-column: span 1;
  width: calc(100vw - 3.1rem);
  scroll-snap-align: start; }
  @media (min-width: 769px) {
    .steps__item {
      background-color: transparent;
      padding: 0;
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      width: 100%; } }
  .steps__item:last-child {
    margin-bottom: 0; }

.steps__item-title {
  color: white;
  font-family: "Noe book";
  font-size: 2rem;
  line-height: 1.2142857143;
  margin: 0 0 30px;
  font-weight: 400; }
  @media (min-width: 769px) {
    .steps__item-title {
      line-height: 1.1176470588;
      font-size: 2.4285714286rem;
      margin-bottom: 12px; } }

.steps__item-subtitle {
  font-size: 0.8571428571rem;
  margin: 0 0 15px;
  line-height: 2.2857142857;
  display: block; }

.steps__link {
  color: white; }

.steps__image-wrapper {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 250px;
  max-height: 350px; }

.steps__item-image {
  display: block;
  max-width: 100%; }
  @media (min-width: 769px) {
    .steps__item-image {
      display: block; } }

.steps__desktop-image {
  display: none; }
  @media (min-width: 1024px) {
    .steps__desktop-image {
      display: none; } }
  .steps__desktop-image img {
    max-width: 100%;
    display: block; }

.more-info-bar {
  background-color: black;
  color: white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  margin: 0; }
  @media (min-width: 769px) {
    .more-info-bar {
      padding: 3rem 4rem;
      margin: 0; } }
  .more-info-bar a {
    color: white; }

.more-info-bar__header {
  padding-right: 1rem; }

.more-info-bar__title, .more-info-bar--large__title {
  margin: 0; }
  @media (max-width: 769px) {
    .more-info-bar__title, .more-info-bar--large__title {
      font-size: 1.2857142857rem; } }

@media (max-width: 769px) {
  .more-info-bar__select.large {
    display: block; } }

@media (min-width: 769px) {
  .more-info-bar__select {
    margin-right: 1.0714285714rem;
    margin-left: 1.0714285714rem; } }

.more-info-bar__select select {
  padding: 0;
  margin: 0;
  border-color: white;
  font-size: 1rem; }

@media (min-width: 769px) {
  .more-info-bar__select.large .mock-select {
    padding-right: 5rem; } }

.more-info-bar__select .mock-select__text {
  font-size: 1.7142857143rem;
  padding: 0;
  font-style: normal; }
  @media (min-width: 769px) {
    .more-info-bar__select .mock-select__text {
      text-transform: lowercase; } }

/* Variations */
.more-info-bar--large {
  display: block;
  padding-top: 2.8571428571rem;
  padding-bottom: 2.8571428571rem; }
  @media (min-width: 769px) {
    .more-info-bar--large {
      display: flex; } }
  .more-info-bar--large .desktop {
    display: none; }
    @media (min-width: 769px) {
      .more-info-bar--large .desktop {
        display: block; } }
  .more-info-bar--large .mobile {
    display: block; }
    @media (min-width: 769px) {
      .more-info-bar--large .mobile {
        display: none; } }

.more-info-bar--large__title {
  display: block;
  color: white;
  font-family: "Rubik light";
  font-weight: 100;
  line-height: 1.5;
  font-size: 1.7142857143rem;
  margin-bottom: 2.8571428571rem; }
  @media (min-width: 769px) {
    .more-info-bar--large__title {
      margin-bottom: 0;
      display: flex; } }

.more-info-bar--large__btn,
#react-configure-frame-flow {
  margin: 0; }

@media (max-width: 769px) {
  .more-info-bar #react-configure-frame-flow button {
    max-width: none;
    width: 100%; } }

.home-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-bottom: 60px; }
  @media (min-width: 55rem) {
    .home-grid {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 120px; } }
  .home-grid .steps {
    grid-column: 1 / -1; }
    @media (min-width: 769px) {
      .home-grid .steps {
        grid-column: span 6; } }
  .home-grid .more-info-bar {
    grid-column: 1 / -1; }
  .home-grid .product-preview {
    grid-column: 1 / -1;
    margin: 1px 0 0; }
    .home-grid .product-preview > p {
      display: none; }
    @media (min-width: 769px) {
      .home-grid .product-preview {
        display: grid;
        align-items: end;
        grid-template-rows: 1fr auto;
        grid-column: span 6;
        margin: 0; } }
  .home-grid .product-preview__link {
    display: none; }
    .home-grid .product-preview__link a {
      margin-bottom: 0; }
    @media (min-width: 769px) {
      .home-grid .product-preview__link {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 20px; } }
  .home-grid .product-items {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1px; }

.swatch-attribute-options {
  display: flex;
  flex-wrap: wrap; }
  .swatch-attribute-options:focus {
    outline: none; }

.product-options-wrapper:focus,
.product-options-wrapper > .fieldset:focus,
.swatch-opt:focus,
.swatch-attribute:focus {
  outline: none; }

.swatch-attribute-label,
.swatch-attribute-selected-option,
.super-attribute-select,
.swatch-label {
  display: none; }

.swatch-opt {
  margin: 0 0 2.1rem 0.33rem; }

.swatch-option {
  position: relative;
  width: 1.15rem;
  height: 1.4rem;
  margin: 0 1.28rem 0 0;
  opacity: 0.5;
  transition: opacity 600ms cubic-bezier(0.6, 0, 0.01, 0.99);
  cursor: pointer; }
  .swatch-option[option-tooltip-value='#ffffff'] {
    border: 1px solid grey; }
    .swatch-option[option-tooltip-value='#ffffff'].selected {
      border: none; }
  .swatch-option:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1.8rem;
    height: 2rem;
    border: 2px solid black;
    transform: translate(-50%, -50%) scale(0.5);
    transition: transform 600ms cubic-bezier(0.6, 0, 0.01, 0.99); }
  .swatch-option:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: inherit; }
  .swatch-option:focus {
    outline: none; }
  .swatch-option:hover:before {
    transform: translate(-50%, -50%) scale(1); }
  .swatch-option.selected {
    opacity: 1; }
    .swatch-option.selected:before {
      transform: translate(-50%, -50%) scale(1); }
  .swatch-option.image {
    width: 42px !important;
    height: 44px !important;
    background-size: contain !important;
    opacity: 1; }
    .swatch-option.image:before {
      width: 53px !important;
      height: 55px !important;
      transform: translate(-50%, -50%) scale(1); }
    .swatch-option.image.selected:before {
      border: solid 2px #000; }
    .swatch-option.image:not(.selected):before {
      border: solid 2px #ccc; }

.portraitLandscapeSwitch {
  display: flex;
  gap: 20px;
  margin-bottom: 40px; }
  .portraitLandscapeSwitch .switch {
    border: none;
    background: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    border: solid 2px #ccc;
    display: flex;
    justify-content: end;
    padding: 10px; }
  .portraitLandscapeSwitch .portrait.active, .portraitLandscapeSwitch .landscape.active {
    border: solid 2px #000; }
  .portraitLandscapeSwitch .portrait:before, .portraitLandscapeSwitch .landscape:before {
    content: '';
    width: 20px;
    height: 40px;
    border: dashed 2px #000;
    display: block;
    margin-bottom: 5px; }
  .portraitLandscapeSwitch .landscape:before {
    width: 40px;
    height: 20px;
    margin-bottom: 15px; }

.guarantee-list {
  list-style-image: url("../images/icons/checkmark.svg");
  padding: 0 0 0 1.8rem; }

.guarantee-list__inner {
  margin: 0; }

.guarantee-list__item {
  font-family: "Rubik light";
  font-size: 1.35rem;
  padding: 0.7rem 0 0.7rem 0.4rem; }

@media (min-width: 1024px) {
  .guarantee-list__item {
    font-size: 1.7rem; } }

.extra-info {
  grid-column: auto / span 12;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  padding: 2.28rem 2.14rem;
  height: auto;
  color: #fff;
  background-color: #c99e9f; }
  @media (min-width: 769px) {
    .extra-info {
      grid-column: auto / span 6;
      padding-left: 7.57rem; } }

.extra-info__title {
  font-family: "Noe book";
  font-size: 1.28rem;
  margin-bottom: 2rem; }

.extra-info__paragraph {
  margin-top: 0;
  margin-bottom: 2rem;
  font-size: 1.14rem; }

.extra-info-list {
  list-style: none;
  padding: 0;
  margin: 0;
  color: white;
  font-size: 1.14rem; }
  .extra-info-list li {
    color: white;
    font-size: 1.14rem;
    line-height: 1;
    margin-bottom: 1rem; }
    .extra-info-list li a {
      font-size: 1.14rem;
      color: #fff;
      line-height: 1; }
      .extra-info-list li a:before, .extra-info-list li a:after {
        display: none; }
    .extra-info-list li:last-child {
      margin-bottom: 0; }

.extra-info--background-image {
  background-image: url("../images/extra-info-background.jpg");
  background-size: cover; }

.loader {
  display: none; }

.pages {
  margin: 0 auto;
  padding-bottom: 2.14rem; }
  @media (min-width: 769px) {
    .pages {
      max-width: auto; } }

.pages-items {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0; }
  .pages-items .item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.42rem;
    width: 2.28rem; }
    .pages-items .item.current {
      border: 2px solid #fff; }
  .pages-items .pages-item-previous,
  .pages-items .pages-item-next {
    width: auto; }
    .pages-items .pages-item-previous span,
    .pages-items .pages-item-next span {
      display: none;
      vertical-align: middle;
      text-transform: lowercase; }
      @media (min-width: 769px) {
        .pages-items .pages-item-previous span,
        .pages-items .pages-item-next span {
          display: inline-block; } }
    .pages-items .pages-item-previous img,
    .pages-items .pages-item-next img {
      display: inline-block;
      vertical-align: middle;
      height: 1rem;
      margin-left: 0.64rem; }
  .pages-items .pages-item-previous {
    margin-right: auto; }
    @media (min-width: 769px) {
      .pages-items .pages-item-previous {
        margin-right: 2.14rem; } }
    .pages-items .pages-item-previous img {
      margin-left: 0;
      margin-right: 0.64rem;
      transform: rotate(180deg); }
  .pages-items .pages-item-next {
    margin-left: auto; }
    @media (min-width: 769px) {
      .pages-items .pages-item-next {
        margin-left: 2.14rem; } }
  .pages-items .action,
  .pages-items .page {
    font-family: "Rubik medium";
    font-weight: 500;
    color: #fff;
    margin: 0;
    letter-spacing: 1.17px; }
    .pages-items .action:before, .pages-items .action:after,
    .pages-items .page:before,
    .pages-items .page:after {
      display: none; }

#layered-filter-block-container {
  position: relative;
  background: #fff;
  cursor: pointer;
  padding: 1.42rem 1rem;
  z-index: 1; }
  @media (min-width: 769px) {
    #layered-filter-block-container {
      padding: 0;
      z-index: 3; } }
  @media (min-width: 769px) {
    #layered-filter-block-container #layered-filter-block {
      display: flex;
      align-items: center;
      padding: 0 calc(100 / 24 * 1%); } }
  #layered-filter-block-container .block-title {
    display: flex;
    justify-content: space-between;
    font-family: "Rubik medium";
    font-weight: 500;
    letter-spacing: 1px; }
    #layered-filter-block-container .block-title span:focus {
      font-weight: 600;
      outline: 0; }
    @media (min-width: 769px) {
      #layered-filter-block-container .block-title {
        display: none; } }
    #layered-filter-block-container .block-title .chevron-expand {
      transform: rotate(90deg); }
  #layered-filter-block-container .filter-current-subtitle,
  #layered-filter-block-container .filter-subtitle,
  #layered-filter-block-container .filter-count-label {
    display: none; }
  @media (min-width: 769px) {
    #layered-filter-block-container .filter-actions {
      margin-left: auto; } }
  #layered-filter-block-container .filter-clear {
    color: #9b9b9b;
    font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 0.85rem;
    text-decoration: underline;
    margin-bottom: 0; }
  #layered-filter-block-container .filter-options-item {
    padding-bottom: 1.42rem; }
    #layered-filter-block-container .filter-options-item:last-child {
      padding-bottom: 0; }
    @media (min-width: 769px) {
      #layered-filter-block-container .filter-options-item {
        padding-bottom: 0; }
        #layered-filter-block-container .filter-options-item:last-child {
          margin-right: 0; } }
    @media (min-width: 1024px) {
      #layered-filter-block-container .filter-options-item {
        margin-right: calc(1440vw / 150); } }
    @media (min-width: 1440px) {
      #layered-filter-block-container .filter-options-item {
        margin-right: 150px; } }
  #layered-filter-block-container .filter-options-title {
    display: flex;
    justify-content: space-between;
    position: relative;
    font-family: "Rubik medium";
    font-weight: 500;
    letter-spacing: 1px;
    cursor: pointer; }
    @media (min-width: 769px) {
      #layered-filter-block-container .filter-options-title {
        display: inline-block;
        padding-top: 1.42rem;
        padding-bottom: 1.42rem; } }
    #layered-filter-block-container .filter-options-title:focus {
      outline: none; }
    #layered-filter-block-container .filter-options-title span:after {
      display: block;
      width: 100%;
      height: 2px;
      background-color: #000; }
    #layered-filter-block-container .filter-options-title .chevron-expand {
      transition: transform 250ms cubic-bezier(0.6, 0, 0.01, 0.9);
      display: block; }
      @media (min-width: 769px) {
        #layered-filter-block-container .filter-options-title .chevron-expand {
          top: 50%;
          right: -15px;
          position: absolute;
          transform: translateY(-50%) rotate(90deg); } }
  #layered-filter-block-container .active > .filter-options-title .chevron-expand {
    transform: translateY(-50%) rotate(-90deg); }
  @media (min-width: 769px) {
    #layered-filter-block-container .active > .filter-options-content .items,
    #layered-filter-block-container .active > .filter-options-content .swatch-layered {
      animation: SubMenuIn 700ms cubic-bezier(0.6, 0, 0.01, 0.9) forwards; } }
  #layered-filter-block-container .filter-title strong:focus {
    outline: none; }
  #layered-filter-block-container .filter-content {
    display: none;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%; }
    @media (min-width: 769px) {
      #layered-filter-block-container .filter-content {
        display: block;
        overflow: inherit;
        position: inherit;
        flex: 1;
        padding-right: 50px; } }
  #layered-filter-block-container .filter-options {
    background-color: #fff;
    padding: 1rem; }
    @media (min-width: 769px) {
      #layered-filter-block-container .filter-options {
        display: flex;
        padding: 0;
        align-items: center;
        justify-content: space-between; } }
    @media (min-width: 1024px) {
      #layered-filter-block-container .filter-options {
        justify-content: flex-start; } }
  #layered-filter-block-container .filter-options-item.has-items .filter-options-title > span {
    pointer-events: none;
    line-height: 1.4; }
    #layered-filter-block-container .filter-options-item.has-items .filter-options-title > span:before, #layered-filter-block-container .filter-options-item.has-items .filter-options-title > span:after {
      background: #000; }
  #layered-filter-block-container .filter-options-item.active .filter-options-content {
    display: block; }
  #layered-filter-block-container .filter-options-content {
    overflow: hidden;
    margin: 0;
    display: none; }
    @media (min-width: 769px) {
      #layered-filter-block-container .filter-options-content {
        position: absolute;
        width: 100%;
        margin-top: 0;
        transform: translateX(-50%);
        left: 50%; } }
    #layered-filter-block-container .filter-options-content .items,
    #layered-filter-block-container .filter-options-content .swatch-layered {
      margin: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      background-color: #f5f5ef;
      padding: 1rem; }
      @media (min-width: 769px) {
        #layered-filter-block-container .filter-options-content .items,
        #layered-filter-block-container .filter-options-content .swatch-layered {
          padding: calc(100 / 24 * 1%); } }
    #layered-filter-block-container .filter-options-content .item,
    #layered-filter-block-container .filter-options-content .swatch-attribute-options li {
      margin: 0.175rem;
      display: inline-block; }
      #layered-filter-block-container .filter-options-content .item.active a .price,
      #layered-filter-block-container .filter-options-content .item.active a,
      #layered-filter-block-container .filter-options-content .swatch-attribute-options li.active a .price,
      #layered-filter-block-container .filter-options-content .swatch-attribute-options li.active a {
        background-color: #000;
        color: #f5f5ef; }
      #layered-filter-block-container .filter-options-content .item .count,
      #layered-filter-block-container .filter-options-content .swatch-attribute-options li .count {
        color: #9b9b9b; }
    #layered-filter-block-container .filter-options-content .item a .price,
    #layered-filter-block-container .filter-options-content .item a,
    #layered-filter-block-container .filter-options-content .swatch-attribute-options li a {
      font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
      display: inline-block;
      font-size: 0.9rem;
      padding: 0.71rem;
      background-color: #fff;
      border-radius: 2px;
      margin-bottom: 0;
      line-height: 1; }
    #layered-filter-block-container .filter-options-content .item a .price {
      padding: 0; }
    #layered-filter-block-container .filter-options-content .swatch-attribute-options a {
      margin-bottom: 0; }
  #layered-filter-block-container .ln_overlay {
    display: none; }
  #layered-filter-block-container .dimension {
    display: flex;
    flex-wrap: wrap; }
  #layered-filter-block-container .dimension__input {
    flex: 1;
    font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-bottom: 0; }
    @media (min-width: 769px) {
      #layered-filter-block-container .dimension__input {
        flex-basis: calc(35% - 0.71rem); } }
  #layered-filter-block-container .dimension__unit {
    font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: flex;
    align-self: center;
    flex-basis: auto;
    margin-left: 0.71rem; }
  #layered-filter-block-container .dimension__seperator {
    font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: flex;
    align-self: center;
    flex-basis: auto;
    margin: 0 1rem; }
    @media (min-width: 769px) {
      #layered-filter-block-container .dimension__seperator {
        flex-basis: 10%;
        padding-left: 0.335rem; } }
  #layered-filter-block-container ol.sy-multiple-layered-navigation-items li:only-child {
    width: 100%; }
  #layered-filter-block-container ol.sy-multiple-layered-navigation-items li a {
    background-image: none !important;
    background-color: #fff !important; }
  #layered-filter-block-container ol.sy-multiple-layered-navigation-items li.active a {
    background-color: #000 !important; }
  #layered-filter-block-container .filter-current {
    display: none; }
  #layered-filter-block-container .filter-options-content {
    /* Dimensions */ }
    #layered-filter-block-container .filter-options-content .swatch-attribute-options {
      margin: 0;
      padding: 0;
      list-style: none; }
    #layered-filter-block-container .filter-options-content .swatch-attribute-options li a {
      display: flex;
      align-items: center; }
    #layered-filter-block-container .filter-options-content .swatch-attribute-options li.selected a {
      background-color: black;
      color: white; }
    #layered-filter-block-container .filter-options-content .swatch-option {
      opacity: 1;
      margin-right: 0.71rem; }
    #layered-filter-block-container .filter-options-content .swatch-label {
      display: block; }
    #layered-filter-block-container .filter-options-content .swatch-cnt.selected .swatch-option[option-tooltip-value='#000000'] {
      border: 1px solid white !important; }
    #layered-filter-block-container .filter-options-content .swatch-cnt.selected .swatch-option[option-tooltip-value='#ffffff'] {
      border: 1px solid white !important; }
    #layered-filter-block-container .filter-options-content .dimension fieldset {
      margin: 0;
      padding: 0;
      border: 0;
      width: 100%; }
    #layered-filter-block-container .filter-options-content .dimension__cnt {
      display: flex;
      align-items: center;
      flex-wrap: wrap; }
      @media (min-width: 769px) {
        #layered-filter-block-container .filter-options-content .dimension__cnt {
          flex-wrap: nowrap;
          max-width: 500px; } }
    @media (min-width: 769px) {
      #layered-filter-block-container .filter-options-content .dimension__submit {
        margin-left: 1rem; } }
  #layered-filter-block-container .swatch-option:before,
  #layered-filter-block-container .swatch-option:after {
    display: none; }

#layer-product-list .filter-current {
  display: flex;
  flex: 1;
  align-content: flex-start;
  padding: 0; }
  #layer-product-list .filter-current .items {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding-left: 0;
    list-style: none; }
    @media (min-width: 769px) {
      #layer-product-list .filter-current .items {
        padding-right: 1rem; } }
  #layer-product-list .filter-current .item {
    background-color: #000;
    border-radius: 2px;
    height: 30px;
    padding-left: 9px;
    padding-right: 9px;
    margin: 0 0.175rem 0 0;
    display: flex;
    align-items: center;
    position: relative; }
    #layer-product-list .filter-current .item span {
      padding: 0;
      font-size: 11px;
      font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
      line-height: 1;
      color: #fff;
      display: block;
      position: relative;
      z-index: 2; }
    #layer-product-list .filter-current .item a {
      line-height: 1;
      display: block;
      width: 8px;
      margin-left: 0.175rem; }
      #layer-product-list .filter-current .item a:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.3);
        content: '';
        display: block;
        opacity: 0;
        z-index: 1;
        transition: opacity 120ms ease-out;
        pointer-events: none; }
      #layer-product-list .filter-current .item a img {
        max-width: 100%;
        position: relative;
        z-index: 2; }
      #layer-product-list .filter-current .item a:hover:before {
        opacity: 1; }

#layer-product-list .filter-current-subtitle {
  display: none; }

/* Some resets */
.filter-mobile__header {
  padding: 1rem;
  position: relative;
  background-color: white;
  display: none; }
  @media (min-width: 769px) {
    .filter-mobile__header {
      display: block;
      padding: 0;
      order: 2; } }

.filter-mobile__footer,
#close-mobile-filter,
#filter-mobile__results-btn {
  display: block; }
  @media (min-width: 769px) {
    .filter-mobile__footer,
    #close-mobile-filter,
    #filter-mobile__results-btn {
      display: none; } }

#close-mobile-filter,
#filter-mobile__results-btn,
#filter-mobile__back {
  border: 0;
  margin: 0;
  outline: none; }

#filter-mobile__back {
  display: none;
  position: absolute;
  left: 0;
  background: none;
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  justify-content: center;
  transform: translateX(-25%); }
  #filter-mobile__back img {
    max-width: 20px;
    transform: rotate(180deg); }
  @media (min-width: 769px) {
    #filter-mobile__back {
      display: none; } }

#close-mobile-filter {
  position: absolute;
  right: 1rem;
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  transform: translateX(25%); }
  #close-mobile-filter img {
    max-width: 15px; }
  @media (min-width: 769px) {
    #close-mobile-filter {
      display: none; } }

.filter-mobile__title-cnt {
  margin-right: auto;
  position: relative;
  display: flex;
  align-items: center; }

.filter-mobile__title {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1;
  font-family: "Rubik medium"; }
  @media (min-width: 769px) {
    .filter-mobile__title {
      display: none; } }

.is-layered #filter-mobile__back + .filter-mobile__title {
  margin-left: 35px; }

@media (max-width: 769px) {
  .is-layered #filter-mobile__back {
    display: flex; } }

@media (max-width: 769px) {
  .is-layered .filter-actions {
    display: none; } }

.filter-mobile__footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  z-index: 2; }

@media (max-width: 1025px) {
  #layer-product-list .filter-current {
    display: none; } }

/* When the filter is active.. */
.filter-active .filter-mobile__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  position: relative;
  z-index: 2; }

@media (max-width: 1025px) {
  .filter-active #layered-filter-block {
    padding-bottom: 50px; } }

.filter-active #layered-filter-block-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  overflow: auto;
  padding: 0;
  z-index: 15; }
  .filter-active #layered-filter-block-container .block-title {
    display: none; }
  .filter-active #layered-filter-block-container .block-actions {
    margin-left: 1rem; }
  .filter-active #layered-filter-block-container .filter-content {
    display: block;
    left: auto;
    top: auto;
    position: relative;
    overflow: visible; }
    @media (max-width: 1025px) {
      .filter-active #layered-filter-block-container .filter-content {
        position: static; } }
  @media (max-width: 1025px) {
    .filter-active #layered-filter-block-container .filter-options {
      padding: 0; } }
  .filter-active #layered-filter-block-container .filter-options .filter-actions {
    display: none; }
  @media (max-width: 1025px) {
    .filter-active #layered-filter-block-container .filter-options-content {
      margin: 0; } }
  .filter-active #layered-filter-block-container .filter-options-content .item,
  .filter-active #layered-filter-block-container .filter-options-content .swatch-cnt {
    margin: calc(4 / 14 * 1rem); }
  .filter-active #layered-filter-block-container .filter-options-content .item a,
  .filter-active #layered-filter-block-container .filter-options-content .swatch-cnt a {
    font-size: 1rem;
    padding: 1rem; }
    .filter-active #layered-filter-block-container .filter-options-content .item a:active,
    .filter-active #layered-filter-block-container .filter-options-content .swatch-cnt a:active {
      background-color: #f5f5f5; }
  .filter-active #layered-filter-block-container .filter-options-content .swatch-option {
    margin-right: 1rem; }
  .filter-active #layered-filter-block-container .filter-options-item {
    padding-bottom: 0;
    border-bottom: 2px solid #f5f5ef; }
    .filter-active #layered-filter-block-container .filter-options-item:first-child {
      border-top: 2px solid #f5f5ef; }
  @media (max-width: 1025px) {
    .filter-active #layered-filter-block-container .filter-options-title {
      padding: 1rem;
      font-family: "Rubik light"; } }
  .filter-active #layered-filter-block-container .filter-options-title .chevron-expand {
    transform: none; }
  .filter-active #layered-filter-block-container .dimension__submit {
    flex-basis: 100%;
    margin-top: 1rem; }

@media (max-width: 769px) {
  .filter-active .filter-options-item.active .filter-options-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f5f5ef;
    z-index: 1;
    padding-top: 60px; } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item,
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt,
  body:not(.prevent-filter-animation) .filter-options-item .dimension {
    opacity: 0;
    transform: translateX(35px); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item,
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt,
  body:not(.prevent-filter-animation) .filter-options-item .dimension {
    animation: slideIn 400ms cubic-bezier(0.6, 0, 0.01, 0.9) forwards; } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(1),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(1),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(1) {
    animation-delay: calc(100ms + (1 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(2),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(2),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(2) {
    animation-delay: calc(100ms + (2 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(3),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(3),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(3) {
    animation-delay: calc(100ms + (3 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(4),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(4),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(4) {
    animation-delay: calc(100ms + (4 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(5),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(5),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(5) {
    animation-delay: calc(100ms + (5 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(6),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(6),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(6) {
    animation-delay: calc(100ms + (6 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(7),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(7),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(7) {
    animation-delay: calc(100ms + (7 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(8),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(8),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(8) {
    animation-delay: calc(100ms + (8 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(9),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(9),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(9) {
    animation-delay: calc(100ms + (9 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(10),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(10),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(10) {
    animation-delay: calc(100ms + (10 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(11),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(11),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(11) {
    animation-delay: calc(100ms + (11 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(12),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(12),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(12) {
    animation-delay: calc(100ms + (12 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(13),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(13),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(13) {
    animation-delay: calc(100ms + (13 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(14),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(14),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(14) {
    animation-delay: calc(100ms + (14 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(15),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(15),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(15) {
    animation-delay: calc(100ms + (15 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(16),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(16),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(16) {
    animation-delay: calc(100ms + (16 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(17),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(17),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(17) {
    animation-delay: calc(100ms + (17 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(18),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(18),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(18) {
    animation-delay: calc(100ms + (18 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(19),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(19),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(19) {
    animation-delay: calc(100ms + (19 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(20),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(20),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(20) {
    animation-delay: calc(100ms + (20 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(21),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(21),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(21) {
    animation-delay: calc(100ms + (21 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(22),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(22),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(22) {
    animation-delay: calc(100ms + (22 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(23),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(23),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(23) {
    animation-delay: calc(100ms + (23 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(24),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(24),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(24) {
    animation-delay: calc(100ms + (24 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(25),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(25),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(25) {
    animation-delay: calc(100ms + (25 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(26),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(26),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(26) {
    animation-delay: calc(100ms + (26 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(27),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(27),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(27) {
    animation-delay: calc(100ms + (27 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(28),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(28),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(28) {
    animation-delay: calc(100ms + (28 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(29),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(29),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(29) {
    animation-delay: calc(100ms + (29 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(30),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(30),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(30) {
    animation-delay: calc(100ms + (30 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(31),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(31),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(31) {
    animation-delay: calc(100ms + (31 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(32),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(32),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(32) {
    animation-delay: calc(100ms + (32 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(33),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(33),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(33) {
    animation-delay: calc(100ms + (33 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(34),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(34),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(34) {
    animation-delay: calc(100ms + (34 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(35),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(35),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(35) {
    animation-delay: calc(100ms + (35 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(36),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(36),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(36) {
    animation-delay: calc(100ms + (36 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(37),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(37),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(37) {
    animation-delay: calc(100ms + (37 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(38),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(38),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(38) {
    animation-delay: calc(100ms + (38 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(39),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(39),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(39) {
    animation-delay: calc(100ms + (39 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(40),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(40),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(40) {
    animation-delay: calc(100ms + (40 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(41),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(41),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(41) {
    animation-delay: calc(100ms + (41 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(42),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(42),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(42) {
    animation-delay: calc(100ms + (42 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(43),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(43),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(43) {
    animation-delay: calc(100ms + (43 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(44),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(44),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(44) {
    animation-delay: calc(100ms + (44 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(45),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(45),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(45) {
    animation-delay: calc(100ms + (45 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(46),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(46),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(46) {
    animation-delay: calc(100ms + (46 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(47),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(47),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(47) {
    animation-delay: calc(100ms + (47 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(48),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(48),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(48) {
    animation-delay: calc(100ms + (48 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(49),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(49),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(49) {
    animation-delay: calc(100ms + (49 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(50),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(50),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(50) {
    animation-delay: calc(100ms + (50 * 20ms)); } }

/* Some resets */
.filter-mobile__header {
  padding: 1rem;
  position: relative;
  background-color: white;
  display: none; }
  @media (min-width: 769px) {
    .filter-mobile__header {
      display: block;
      padding: 0;
      order: 2; } }

.filter-mobile__footer,
#close-mobile-filter,
#filter-mobile__results-btn {
  display: block; }
  @media (min-width: 769px) {
    .filter-mobile__footer,
    #close-mobile-filter,
    #filter-mobile__results-btn {
      display: none; } }

#close-mobile-filter,
#filter-mobile__results-btn,
#filter-mobile__back {
  border: 0;
  margin: 0;
  outline: none; }

#filter-mobile__back {
  display: none;
  position: absolute;
  left: 0;
  background: none;
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  justify-content: center;
  transform: translateX(-25%); }
  #filter-mobile__back img {
    max-width: 20px;
    transform: rotate(180deg); }
  @media (min-width: 769px) {
    #filter-mobile__back {
      display: none; } }

#close-mobile-filter {
  position: absolute;
  right: 1rem;
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  transform: translateX(25%); }
  #close-mobile-filter img {
    max-width: 15px; }
  @media (min-width: 769px) {
    #close-mobile-filter {
      display: none; } }

.filter-mobile__title-cnt {
  margin-right: auto;
  position: relative;
  display: flex;
  align-items: center; }

.filter-mobile__title {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1;
  font-family: "Rubik medium"; }
  @media (min-width: 769px) {
    .filter-mobile__title {
      display: none; } }

.is-layered #filter-mobile__back + .filter-mobile__title {
  margin-left: 35px; }

@media (max-width: 769px) {
  .is-layered #filter-mobile__back {
    display: flex; } }

@media (max-width: 769px) {
  .is-layered .filter-actions {
    display: none; } }

.filter-mobile__footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  z-index: 2; }

@media (max-width: 1025px) {
  #layer-product-list .filter-current {
    display: none; } }

/* When the filter is active.. */
.filter-active .filter-mobile__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  position: relative;
  z-index: 2; }

@media (max-width: 1025px) {
  .filter-active #layered-filter-block {
    padding-bottom: 50px; } }

.filter-active #layered-filter-block-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  overflow: auto;
  padding: 0;
  z-index: 15; }
  .filter-active #layered-filter-block-container .block-title {
    display: none; }
  .filter-active #layered-filter-block-container .block-actions {
    margin-left: 1rem; }
  .filter-active #layered-filter-block-container .filter-content {
    display: block;
    left: auto;
    top: auto;
    position: relative;
    overflow: visible; }
    @media (max-width: 1025px) {
      .filter-active #layered-filter-block-container .filter-content {
        position: static; } }
  @media (max-width: 1025px) {
    .filter-active #layered-filter-block-container .filter-options {
      padding: 0; } }
  .filter-active #layered-filter-block-container .filter-options .filter-actions {
    display: none; }
  @media (max-width: 1025px) {
    .filter-active #layered-filter-block-container .filter-options-content {
      margin: 0; } }
  .filter-active #layered-filter-block-container .filter-options-content .item,
  .filter-active #layered-filter-block-container .filter-options-content .swatch-cnt {
    margin: calc(4 / 14 * 1rem); }
  .filter-active #layered-filter-block-container .filter-options-content .item a,
  .filter-active #layered-filter-block-container .filter-options-content .swatch-cnt a {
    font-size: 1rem;
    padding: 1rem; }
    .filter-active #layered-filter-block-container .filter-options-content .item a:active,
    .filter-active #layered-filter-block-container .filter-options-content .swatch-cnt a:active {
      background-color: #f5f5f5; }
  .filter-active #layered-filter-block-container .filter-options-content .swatch-option {
    margin-right: 1rem; }
  .filter-active #layered-filter-block-container .filter-options-item {
    padding-bottom: 0;
    border-bottom: 2px solid #f5f5ef; }
    .filter-active #layered-filter-block-container .filter-options-item:first-child {
      border-top: 2px solid #f5f5ef; }
  @media (max-width: 1025px) {
    .filter-active #layered-filter-block-container .filter-options-title {
      padding: 1rem;
      font-family: "Rubik light"; } }
  .filter-active #layered-filter-block-container .filter-options-title .chevron-expand {
    transform: none; }
  .filter-active #layered-filter-block-container .dimension__submit {
    flex-basis: 100%;
    margin-top: 1rem; }

@media (max-width: 769px) {
  .filter-active .filter-options-item.active .filter-options-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f5f5ef;
    z-index: 1;
    padding-top: 60px; } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item,
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt,
  body:not(.prevent-filter-animation) .filter-options-item .dimension {
    opacity: 0;
    transform: translateX(35px); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item,
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt,
  body:not(.prevent-filter-animation) .filter-options-item .dimension {
    animation: slideIn 400ms cubic-bezier(0.6, 0, 0.01, 0.9) forwards; } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(1),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(1),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(1) {
    animation-delay: calc(100ms + (1 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(2),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(2),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(2) {
    animation-delay: calc(100ms + (2 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(3),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(3),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(3) {
    animation-delay: calc(100ms + (3 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(4),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(4),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(4) {
    animation-delay: calc(100ms + (4 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(5),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(5),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(5) {
    animation-delay: calc(100ms + (5 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(6),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(6),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(6) {
    animation-delay: calc(100ms + (6 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(7),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(7),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(7) {
    animation-delay: calc(100ms + (7 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(8),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(8),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(8) {
    animation-delay: calc(100ms + (8 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(9),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(9),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(9) {
    animation-delay: calc(100ms + (9 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(10),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(10),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(10) {
    animation-delay: calc(100ms + (10 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(11),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(11),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(11) {
    animation-delay: calc(100ms + (11 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(12),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(12),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(12) {
    animation-delay: calc(100ms + (12 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(13),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(13),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(13) {
    animation-delay: calc(100ms + (13 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(14),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(14),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(14) {
    animation-delay: calc(100ms + (14 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(15),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(15),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(15) {
    animation-delay: calc(100ms + (15 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(16),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(16),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(16) {
    animation-delay: calc(100ms + (16 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(17),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(17),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(17) {
    animation-delay: calc(100ms + (17 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(18),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(18),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(18) {
    animation-delay: calc(100ms + (18 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(19),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(19),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(19) {
    animation-delay: calc(100ms + (19 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(20),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(20),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(20) {
    animation-delay: calc(100ms + (20 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(21),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(21),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(21) {
    animation-delay: calc(100ms + (21 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(22),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(22),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(22) {
    animation-delay: calc(100ms + (22 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(23),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(23),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(23) {
    animation-delay: calc(100ms + (23 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(24),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(24),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(24) {
    animation-delay: calc(100ms + (24 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(25),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(25),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(25) {
    animation-delay: calc(100ms + (25 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(26),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(26),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(26) {
    animation-delay: calc(100ms + (26 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(27),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(27),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(27) {
    animation-delay: calc(100ms + (27 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(28),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(28),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(28) {
    animation-delay: calc(100ms + (28 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(29),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(29),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(29) {
    animation-delay: calc(100ms + (29 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(30),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(30),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(30) {
    animation-delay: calc(100ms + (30 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(31),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(31),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(31) {
    animation-delay: calc(100ms + (31 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(32),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(32),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(32) {
    animation-delay: calc(100ms + (32 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(33),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(33),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(33) {
    animation-delay: calc(100ms + (33 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(34),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(34),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(34) {
    animation-delay: calc(100ms + (34 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(35),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(35),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(35) {
    animation-delay: calc(100ms + (35 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(36),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(36),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(36) {
    animation-delay: calc(100ms + (36 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(37),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(37),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(37) {
    animation-delay: calc(100ms + (37 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(38),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(38),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(38) {
    animation-delay: calc(100ms + (38 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(39),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(39),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(39) {
    animation-delay: calc(100ms + (39 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(40),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(40),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(40) {
    animation-delay: calc(100ms + (40 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(41),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(41),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(41) {
    animation-delay: calc(100ms + (41 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(42),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(42),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(42) {
    animation-delay: calc(100ms + (42 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(43),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(43),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(43) {
    animation-delay: calc(100ms + (43 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(44),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(44),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(44) {
    animation-delay: calc(100ms + (44 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(45),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(45),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(45) {
    animation-delay: calc(100ms + (45 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(46),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(46),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(46) {
    animation-delay: calc(100ms + (46 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(47),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(47),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(47) {
    animation-delay: calc(100ms + (47 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(48),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(48),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(48) {
    animation-delay: calc(100ms + (48 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(49),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(49),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(49) {
    animation-delay: calc(100ms + (49 * 20ms)); } }

@media (max-width: 769px) {
  body:not(.prevent-filter-animation) .filter-options-item .item:nth-child(50),
  body:not(.prevent-filter-animation) .filter-options-item .swatch-cnt:nth-child(50),
  body:not(.prevent-filter-animation) .filter-options-item .dimension:nth-child(50) {
    animation-delay: calc(100ms + (50 * 20ms)); } }

.toolbar-products {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.7rem 0 0.7rem 0; }
  @media (min-width: 769px) {
    .toolbar-products {
      padding: 1.2rem calc(100 / 24 * 1%); } }
  .toolbar-products .custom-select-container {
    border-bottom: 2px solid #fff;
    top: 6px;
    min-width: 140px; }
  .toolbar-products .toolbar-amount {
    align-items: center;
    font-family: "Rubik medium";
    font-weight: 500;
    color: #fff;
    margin: 0.175rem 0; }
    @media (min-width: 768px) {
      .toolbar-products .toolbar-amount {
        margin-right: 2.14rem;
        margin-left: auto; } }
  .toolbar-products .toolbar-sorter {
    display: flex;
    align-items: flex-start; }
  .toolbar-products .mock-select__text,
  .toolbar-products .sorter-label {
    display: none;
    font-family: "Rubik medium";
    font-weight: 500;
    color: #fff;
    line-height: 1.8; }
    @media (min-width: 768px) {
      .toolbar-products .mock-select__text,
      .toolbar-products .sorter-label {
        display: block;
        margin-right: 0.64rem; } }
  .toolbar-products .sorter-label {
    margin: 0.175rem 0.64rem 0.175rem 0; }
  .toolbar-products .mock-select {
    z-index: 0; }
  .toolbar-products .mock-select__text {
    font-style: normal;
    display: block;
    padding-right: 1.71rem;
    padding-bottom: 0;
    padding-top: 0;
    margin: 0.175rem 0;
    line-height: 1; }
  .toolbar-products .sorter-options {
    font-family: "Rubik medium";
    font-weight: 500;
    padding: 0.3rem 0;
    border-bottom: none; }

.toolbar .pages,
.toolbar .limiter,
.toolbar .modes {
  display: none; }

.toolbar:last-of-type .pages {
  display: block; }

.toolbar:last-of-type .toolbar-amount,
.toolbar:last-of-type .sorter {
  display: none; }

/* Product title */
.product-info-main {
  padding-top: calc(30 / 14 * 1rem);
  margin-bottom: calc(60 / 14 * 1rem);
  padding-left: 1rem;
  padding-right: 1rem; }
  @media (min-width: 1024px) {
    .product-info-main {
      padding-top: 0;
      margin-bottom: 0;
      padding-left: 0;
      padding-right: 0; } }
  .product-info-main .page-title {
    font-size: calc(40 / 14 * 1rem);
    line-height: calc(40 / 32);
    margin-bottom: calc(30 / 14 * 1rem); }
    @media (min-width: 1024px) {
      .product-info-main .page-title {
        font-size: calc(30 / 14 * 1rem);
        margin-bottom: calc(50 / 14 * 1rem); } }
    .product-info-main .page-title span {
      font-size: inherit; }
  .product-info-main .product-info-stock-sku {
    display: none; }
  .product-info-main .product-info-price {
    margin-bottom: calc(30 / 14 * 1rem); }
    @media (min-width: 1024px) {
      .product-info-main .product-info-price {
        margin-bottom: calc(45 / 14 * 1rem); } }
  .product-info-main .swatch-opt {
    margin-bottom: 1rem; }
  .product-info-main .product.attribute.overview {
    margin-bottom: calc(20 / 14 * 1rem); }
    @media (min-width: 1024px) {
      .product-info-main .product.attribute.overview {
        margin-bottom: calc(25 / 14 * 1rem); } }
  .product-info-main .product.attribute.overview p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: calc(25 / 14); }
    @media (min-width: 1024px) {
      .product-info-main .product.attribute.overview p {
        line-height: calc(28 / 16);
        font-size: calc(16 / 14 * 1rem); } }
  .product-info-main .price-final_price {
    margin-bottom: calc(60 / 14 * 1rem); }
    @media (min-width: 1024px) {
      .product-info-main .price-final_price {
        margin-bottom: calc(55 / 14 * 1rem); } }
  .product-info-main .order-sample-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; }
    @media (min-width: 768px) {
      .product-info-main .order-sample-wrapper {
        flex-direction: row; } }
  .product-info-main .add-sample-to-cart input {
    display: none; }
  @media (min-width: 768px) {
    .product-info-main .add-sample-to-cart {
      margin-right: 2.14rem; } }
  .product-info-main .add-sample-to-cart button[type='submit'],
  .product-info-main .product-attachment {
    display: block;
    font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.8; }
  .product-info-main .product-attachment,
  .product-info-main .add-sample-to-cart {
    margin-bottom: 25px; }
  .product-info-main .add-sample-to-cart button[type='submit'], .product-info-main #uploadBtn.active {
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
    line-height: 1.8;
    font-size: 1rem;
    letter-spacing: 0; }

.header.content .block-search {
  display: none; }
  .nav-open .header.content .block-search {
    display: block; }
  @media (min-width: 1380px) {
    .header.content .block-search {
      display: block; } }
  .header.content .block-search .block-content {
    display: block; }
    @media (min-width: 1380px) {
      .header.content .block-search .block-content {
        display: none;
        height: 8.6rem; } }
    .header.content .block-search .block-content.active {
      display: block; }
      @media (min-width: 1380px) {
        .header.content .block-search .block-content.active {
          display: grid; } }
    @media (min-width: 1380px) {
      .header.content .block-search .block-content {
        grid-template-columns: repeat(12, [col-start] 1fr);
        row-gap: 0;
        column-gap: 2px;
        max-width: 110.769rem;
        width: 100vw;
        position: absolute;
        left: 50%;
        bottom: 1px;
        transform: translateX(-50%) translateY(100%);
        top: auto !important;
        padding: 2rem 4.2rem; }
        .header.content .block-search .block-content:before {
          content: '';
          position: absolute;
          left: calc((-100vw + 110.7692307692rem) / 2);
          top: 0;
          left: 0;
          width: 100vw;
          height: 100%;
          transition: background 250ms cubic-bezier(0.6, 0, 0.01, 0.9);
          background-color: #f5f5ef;
          z-index: -1; } }
    @media (min-width: 1380px) and (min-width: 1550px) {
      .header.content .block-search .block-content:before {
        left: calc((-100vw + 110.7692307692rem) / 2); } }

.block-search label,
.block-search .block-title {
  display: none;
  cursor: pointer; }

@media (min-width: 1380px) {
  .block-search .block-title {
    display: block;
    padding: 1rem 0; }
    .block-search .block-title svg {
      display: block;
      margin: 0 auto;
      height: 1.375rem; }
      .block-search .block-title svg g {
        stroke: #fff; } }

.block-search .control {
  background: #f5f5ef; }

.block-search input {
  border: none;
  margin: 0;
  padding: 1.2rem 1rem; }

.customer-account-createpassword .block-title svg g,
.contentmanager-content-view .block-title svg g,
.checkout-onepage-success .block-title svg g,
.cms-page-view .page-header .block-title svg g,
.checkout-cart-index .page-header .block-title svg g,
.account .page-header .block-title svg g,
.cms-no-route .page-header .block-title svg g,
.customer-account-create .page-header .block-title svg g,
.customer-account-login .page-header .block-title svg g,
.catalog-product-view .page-header .block-title svg g,
.page-header.is-scrolled .block-title svg g,
.page-header.is-active .block-title svg g {
  stroke: #000; }

.header input#search {
  padding: 0 1rem;
  height: 50px;
  font-size: 0.8571428571rem;
  font-family: "Rubik regular";
  background: #f5f5ef; }
  @media (min-width: 1380px) {
    .header input#search {
      font-size: 24px;
      padding: 0; } }
  .header input#search:focus {
    outline: none; }
  .header input#search::placeholder {
    font-style: italic; }
    @media (min-width: 1380px) {
      .header input#search::placeholder {
        font-size: 24px; } }

.header .minisearch {
  display: flex;
  align-items: center; }
  @media (min-width: 1380px) {
    .header .minisearch {
      grid-column: 1 / span 13; } }
  .header .minisearch .search {
    flex: 100%; }
    @media (min-width: 1380px) {
      .header .minisearch .search {
        flex: 91.666; } }
  @media (min-width: 1380px) {
    .header .minisearch .actions {
      width: 8.333%; }
      .header .minisearch .actions .action {
        width: 100%; } }

.header .block-search .actions button {
  height: 50px;
  width: 50px;
  background: #f5f5ef;
  padding-right: 1rem;
  outline: none;
  text-align: center;
  color: #000; }
  @media (min-width: 1380px) {
    .header .block-search .actions button {
      padding-right: 0; } }
  .header .block-search .actions button:before {
    content: '';
    background-image: url("../images/icons/search.svg");
    background-size: 21px;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    padding-right: 0; }
  .header .block-search .actions button span {
    display: none; }

.header .block-search {
  margin-bottom: 2rem; }
  @media (min-width: 1380px) {
    .header .block-search {
      margin-bottom: 0; } }

.search-autocomplete {
  display: none;
  background: #fff;
  width: 100vw !important;
  left: 50%;
  bottom: 1px;
  transform: translate(-50%, 100%); }
  @media (max-width: 1380px) {
    .search-autocomplete {
      display: none !important; } }
  .search-autocomplete ul {
    max-width: 110.769rem;
    list-style: none;
    margin: 0 auto;
    padding: 0 4.2rem; }
  .search-autocomplete li {
    position: relative;
    padding: 1rem 0; }
    .search-autocomplete li.selected .qs-option-name:before {
      width: 100%;
      transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99) 100ms; }
    .search-autocomplete li:before {
      content: '';
      position: absolute;
      left: calc((-100vw + 110.7692307692rem) / 2);
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      transition: background 250ms cubic-bezier(0.6, 0, 0.01, 0.9); }
      @media (min-width: 1550px) {
        .search-autocomplete li:before {
          left: calc((-100vw + 110.7692307692rem) / 2); } }
    .search-autocomplete li .qs-option-name {
      font-size: 24px;
      cursor: pointer; }
      .search-autocomplete li .qs-option-name:after {
        display: none; }
    .search-autocomplete li .amount {
      display: none; }

#search-overlay {
  display: none;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  top: 8.59rem;
  left: 50%;
  transform: translateX(-50%); }
  #search-overlay:before {
    content: '';
    display: block;
    position: absolute;
    top: -8.59rem;
    width: 100%;
    height: 8.59rem;
    background-color: rgba(255, 255, 255, 0.5); }
  #search-overlay.active {
    display: block; }

.prevent-scroll {
  overflow: hidden; }

.header.content .block-search label,
.header.content .block-search .control {
  background: #f5f5ef; }

.header.content .block-search input {
  border: none;
  margin: 0;
  padding: 1.2rem 1rem; }

.search .not-found-message {
  color: #ffffff; }

#password-strength-meter-container,
.mage-error {
  margin: 1rem 0; }

.message.info,
.mage-error:not(input),
.password-strength-meter,
.message-default,
.password-none {
  font-size: 0.85em;
  color: #9b9b9b; }

#email-error,
.message-error.error,
#password-confirmation-error.mage-error,
.password-weak .password-strength-meter,
.message-critical {
  background-color: #e7cfcf;
  color: #000;
  padding: 1.125rem; }

.message-warning,
.password-medium .password-strength-meter {
  background-color: #f8e5d5;
  color: #000;
  padding: 1.125rem; }

.message-success,
.password-very-strong .password-strength-meter,
.password-strong .password-strength-meter {
  background-color: #dee6cf;
  color: #000;
  padding: 1.125rem; }

.page.messages, .page.agreement-notice {
  z-index: 13;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(4.142rem);
  transition: transform 600ms cubic-bezier(0.6, 0, 0.01, 0.99); }
  @media (min-width: 1024px) {
    .page.messages, .page.agreement-notice {
      transform: translateY(8.6rem); } }
  .scroll-hidden + .page-main .page.messages, .scroll-hidden + .page-main .page.agreement-notice {
    transform: translateY(0); }

.messages .message, .agreement-notice .message {
  animation: 0.2s ease-in 10s fadeout forwards; }

@keyframes fadeout {
  from {
    display: initial;
    opacity: 1; }
  to {
    display: none;
    opacity: 0; } }

.tooltip.toggle:hover ~ .tooltip.content {
  display: block; }

.tooltip.content {
  display: none;
  position: absolute;
  background: #f5f5ef;
  padding: 1rem;
  z-index: 5;
  max-width: 15rem;
  animation: FadeIn 400ms cubic-bezier(0.6, 0, 0.01, 0.9) forwards; }

@keyframes FadeIn {
  from {
    opacity: 0;
    transform: translateY(1rem); }
  to {
    opacity: 1;
    transform: translateY(0); } }

.account-nav {
  position: relative;
  margin-bottom: 1.785rem; }
  @media (min-width: 768px) {
    .account-nav {
      position: initial;
      padding-right: 2rem; } }

.account-nav-title {
  padding: 10px 0; }
  @media (min-width: 768px) {
    .account-nav-title {
      display: none; } }
  .account-nav-title strong {
    font-family: "Rubik medium";
    font-weight: 300;
    display: block;
    position: relative;
    line-height: 2.2857142857rem; }
  .account-nav-title strong:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 7px;
    height: 13px;
    background-image: url("../images/icons/chevron-bold.svg");
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(90deg);
    transition: transform 250ms cubic-bezier(0.6, 0, 0.01, 0.9); }
  .account-nav-title.active strong:after {
    transform: rotate(-90deg); }

.account-nav-content {
  display: none;
  position: absolute;
  background-color: #f5f5ef;
  width: calc(100% + 2rem);
  margin-left: -1rem;
  padding: 1rem;
  z-index: 2; }
  .account-nav-content.active {
    display: block; }
  @media (min-width: 768px) {
    .account-nav-content {
      display: block;
      position: inherit;
      width: auto;
      background: none; } }
  .account-nav-content .nav {
    list-style: none;
    padding: 0;
    margin: 0; }
  .account-nav-content .item {
    padding: 0.25rem 0; }
  .account-nav-content a,
  .account-nav-content strong {
    line-height: 2.2857142857rem;
    margin: 0; }
  .account-nav-content a:after {
    display: none; }
  .account-nav-content .current strong {
    font-weight: 300; }
  .account-nav-content .delimiter {
    display: block;
    height: 2px;
    background: #000; }
    @media (min-width: 768px) {
      .account-nav-content .delimiter {
        background: #f5f5ef; } }

.block-reorder .product-items {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 1.785rem; }

.block-reorder .custom-checkbox:before, .block-reorder .product-info-price .product-options-wrapper .field.choice input[type='checkbox'] + .label:before, .product-info-price .product-options-wrapper .field.choice .block-reorder input[type='checkbox'] + .label:before {
  border: 2px solid #000; }

.block-reorder .custom-checkbox__text {
  line-height: 1.5;
  font-style: normal; }

@media (min-width: 768px) {
  .account .block-reorder {
    padding-left: 25%; } }

.authentication-wrapper {
  position: absolute;
  top: 0;
  right: 1rem;
  margin-bottom: 3.57rem;
  z-index: 1; }
  @media (min-width: 768px) {
    .authentication-wrapper {
      right: 4rem; } }

.authentication-dropdown {
  position: absolute;
  width: calc(100vw - 2rem);
  background: #f5f5ef;
  padding: 1rem;
  right: 0; }
  @media (min-width: 1024px) {
    .authentication-dropdown {
      width: 500px; } }
  .authentication-dropdown:focus {
    outline: none; }
  .authentication-dropdown .modal-header {
    position: absolute;
    right: 1rem;
    top: 1rem; }
  .authentication-dropdown .input-text,
  .authentication-dropdown .label span {
    font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.8;
    margin-bottom: 25px; }
    .authentication-dropdown .input-text + .mage-error,
    .authentication-dropdown .label span + .mage-error {
      margin-top: -1rem; }
  .authentication-dropdown .label {
    margin-bottom: 0; }
  .authentication-dropdown .action-close {
    padding: 0;
    background: none;
    border: none;
    top: 0;
    right: 0; }
    .authentication-dropdown .action-close span {
      display: none; }
    .authentication-dropdown .action-close:before {
      content: '';
      display: block;
      background-image: url("../images/icons/close.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      height: 1rem;
      width: 1rem; }

.opc-summary-wrapper:focus {
  outline: none; }

.opc-block-summary > .title {
  display: block;
  font-family: "Noe semibold";
  font-size: 1.71rem;
  font-weight: 600;
  line-height: 1.2;
  color: #262626; }

.opc-block-summary .table-caption {
  display: none; }

.opc-block-summary tr {
  text-align: left; }

.opc-block-summary .table-totals {
  width: 100%; }

.opc-block-summary,
.cart-totals,
.table-totals {
  margin-bottom: 3.92rem;
  font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.8;
  margin-bottom: 25px; }
  .opc-block-summary th,
  .opc-block-summary .excl th strong,
  .opc-block-summary .shipping .mark .label,
  .cart-totals th,
  .cart-totals .excl th strong,
  .cart-totals .shipping .mark .label,
  .table-totals th,
  .table-totals .excl th strong,
  .table-totals .shipping .mark .label {
    font-weight: normal; }
  .opc-block-summary .table,
  .cart-totals .table,
  .table-totals .table {
    width: 100%; }
  .opc-block-summary .mark,
  .cart-totals .mark,
  .table-totals .mark {
    text-align: left; }
    .opc-block-summary .mark .label,
    .cart-totals .mark .label,
    .table-totals .mark .label {
      font-weight: bold; }
  .opc-block-summary tbody,
  .cart-totals tbody,
  .table-totals tbody {
    display: flex;
    flex-direction: column; }
  .opc-block-summary .totals-tax .mark,
  .opc-block-summary .totals .mark,
  .cart-totals .totals-tax .mark,
  .cart-totals .totals .mark,
  .table-totals .totals-tax .mark,
  .table-totals .totals .mark {
    width: 100%; }
  .opc-block-summary .totals-tax.grand.incl,
  .opc-block-summary .totals-tax .grand.incl,
  .opc-block-summary .totals.grand.incl,
  .opc-block-summary .totals .grand.incl,
  .cart-totals .totals-tax.grand.incl,
  .cart-totals .totals-tax .grand.incl,
  .cart-totals .totals.grand.incl,
  .cart-totals .totals .grand.incl,
  .table-totals .totals-tax.grand.incl,
  .table-totals .totals-tax .grand.incl,
  .table-totals .totals.grand.incl,
  .table-totals .totals .grand.incl {
    border-top: 2px solid #f5f5ef;
    font-size: 1.25rem;
    font-family: "Noe semibold";
    order: 1;
    padding-top: 1rem; }
  .opc-block-summary .totals-tax .amount,
  .opc-block-summary .totals .amount,
  .cart-totals .totals-tax .amount,
  .cart-totals .totals .amount,
  .table-totals .totals-tax .amount,
  .table-totals .totals .amount {
    text-align: right; }
  .opc-block-summary .totals-tax .price,
  .opc-block-summary .totals .price,
  .cart-totals .totals-tax .price,
  .cart-totals .totals .price,
  .table-totals .totals-tax .price,
  .table-totals .totals .price {
    font-size: 18px; }
  .opc-block-summary .totals-tax.incl:not(.shipping) .price,
  .opc-block-summary .totals-tax .incl:not(.shipping) .price,
  .opc-block-summary .totals.incl:not(.shipping) .price,
  .opc-block-summary .totals .incl:not(.shipping) .price,
  .cart-totals .totals-tax.incl:not(.shipping) .price,
  .cart-totals .totals-tax .incl:not(.shipping) .price,
  .cart-totals .totals.incl:not(.shipping) .price,
  .cart-totals .totals .incl:not(.shipping) .price,
  .table-totals .totals-tax.incl:not(.shipping) .price,
  .table-totals .totals-tax .incl:not(.shipping) .price,
  .table-totals .totals.incl:not(.shipping) .price,
  .table-totals .totals .incl:not(.shipping) .price {
    font-size: 25px; }
  .opc-block-summary .shipping span,
  .cart-totals .shipping span,
  .table-totals .shipping span {
    display: inline; }

.summary.title {
  display: none; }

.payment-method {
  padding: 1rem 0;
  border-bottom: 2px solid #f5f5ef; }
  @media (min-width: 768px) {
    .payment-method {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start; } }
  .payment-method .custom-radio, .payment-method .product-info-price .product-options-wrapper .field.choice input[type='radio'] + .label, .product-info-price .product-options-wrapper .field.choice .payment-method input[type='radio'] + .label {
    display: inline-block;
    margin-right: 1rem; }
  .payment-method .payment-icon {
    display: block; }
  .payment-method .messages, .payment-method .agreement-notice,
  .payment-method .select {
    margin: 0; }
  .payment-method .select {
    margin-bottom: 1.785rem;
    align-self: flex-start; }
    @media (min-width: 768px) {
      .payment-method .select {
        margin-bottom: 0;
        width: 50%; } }
  .payment-method .payment-method-content .messages, .payment-method .payment-method-content .agreement-notice {
    flex-basis: 0; }
  .payment-method .custom-radio-container, .payment-method .product-info-price .product-options-wrapper .field.choice, .product-info-price .product-options-wrapper .payment-method .field.choice {
    margin-bottom: 0; }
    .payment-method .custom-radio-container > span, .payment-method .product-info-price .product-options-wrapper .field.choice > span, .product-info-price .product-options-wrapper .payment-method .field.choice > span {
      margin-right: 1rem; }
      @media (min-width: 768px) {
        .payment-method .custom-radio-container > span, .payment-method .product-info-price .product-options-wrapper .field.choice > span, .product-info-price .product-options-wrapper .payment-method .field.choice > span {
          margin-right: 0;
          width: 50%; } }
  .payment-method .payment-method-title.field {
    margin: 0;
    display: flex;
    flex-direction: column;
    width: 100%; }
    @media (min-width: 768px) {
      .payment-method .payment-method-title.field {
        margin-bottom: 0;
        width: 50%;
        flex-direction: row; } }
  .payment-method .payment-method-content {
    display: flex;
    width: 100%;
    align-items: flex-end; }
    @media (min-width: 768px) {
      .payment-method .payment-method-content {
        width: 50%;
        align-items: center; } }
    .payment-method .payment-method-content .select {
      margin-bottom: 0; }
      @media (min-width: 768px) {
        .payment-method .payment-method-content .select {
          margin-bottom: inherit; } }
  .payment-method .custom-radio-container, .payment-method .product-info-price .product-options-wrapper .field.choice, .product-info-price .product-options-wrapper .payment-method .field.choice {
    display: flex;
    align-items: center;
    flex-basis: 100%;
    cursor: pointer; }
  .payment-method .actions-toolbar {
    margin-left: 0; }
    .payment-method .actions-toolbar button {
      background-color: transparent;
      border: none;
      padding: 0; }
  .payment-method .checkout-agreements-block,
  .payment-method .actions-toolbar {
    display: none; }
  .payment-method._active .checkout-agreements-block,
  .payment-method._active .actions-toolbar {
    display: block; }
  .payment-method .custom-radio span, .payment-method .product-info-price .product-options-wrapper .field.choice input[type='radio'] + .label span, .product-info-price .product-options-wrapper .field.choice .payment-method input[type='radio'] + .label span {
    font-size: 1.14rem; }

.minicart-items {
  display: flex;
  flex-wrap: wrap; }
  .minicart-items .minicart-items-wrapper {
    width: 100%; }
  .minicart-items .product-item {
    flex-basis: 50%;
    margin-bottom: 1.785rem; }
  .minicart-items .label {
    display: inline; }

.opc-progress-bar {
  display: flex;
  flex-basis: 100%; }
  .opc-progress-bar .opc-progress-bar-item {
    flex: 50%;
    text-align: center;
    position: relative; }
    .opc-progress-bar .opc-progress-bar-item:after, .opc-progress-bar .opc-progress-bar-item:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      height: 2px;
      width: 100%;
      background-color: #f8f5ed;
      z-index: -1; }
    .opc-progress-bar .opc-progress-bar-item:after {
      background-color: transparent; }
    .opc-progress-bar .opc-progress-bar-item._complete span,
    .opc-progress-bar .opc-progress-bar-item._active span {
      border: 2px solid #feac00; }
    .opc-progress-bar .opc-progress-bar-item._active:before {
      background-color: #feac00;
      animation: ProgressBar 700ms cubic-bezier(0.6, 0, 0.01, 0.9) forwards; }
    .opc-progress-bar .opc-progress-bar-item._complete:before {
      background-color: #feac00; }
  .opc-progress-bar span {
    font-family: "Rubik light";
    padding: 0.2rem;
    border: 2px solid #f8f5ed;
    background: #fff; }

.tag-line {
  display: flex;
  align-items: center; }

.tag-line__item {
  font-family: "Rubik light";
  font-weight: 300;
  display: flex;
  align-items: center;
  flex: 1; }
  @media (min-width: 500px) {
    .tag-line__item {
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 1; } }
  .tag-line__item:after {
    display: block;
    content: '';
    height: 2px;
    width: 30px;
    margin: 0 30px;
    background-color: white; }
  .tag-line__item:last-child:after {
    display: none; }

.link-block-container {
  grid-gap: 1px;
  justify-content: center; }

.link-block {
  display: block;
  background: white;
  grid-column: span 12;
  padding: 20px;
  position: relative;
  padding-bottom: calc(20px + 15px + 5px);
  margin: 0; }
  @media (min-width: 769px) {
    .link-block {
      grid-column: auto / span 6;
      padding: calc(40 / 1440 * 100vw);
      padding-bottom: calc(65 / 1440 * 100vw); } }
  @media (min-width: 1024px) {
    .link-block {
      grid-column: auto / span 3; } }
  @media (min-width: 1440px) {
    .link-block {
      padding: 40px;
      padding-bottom: calc(40px + 15px + 5px); } }
  .link-block:before, .link-block:after {
    display: none; }

.link-block__img {
  position: relative;
  margin-bottom: calc(20rem / 14);
  padding-bottom: 100%; }
  .link-block__img:before {
    content: '';
    background-image: inherit;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 2;
    display: block;
    width: 100%; }
  .link-block__img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    max-width: 100%;
    display: block;
    object-fit: cover; }

.link-block__title {
  font-family: "Noe book";
  text-transform: none;
  letter-spacing: 0;
  font-size: calc(18rem / 14);
  line-height: calc(28 / 18);
  margin-bottom: 0; }

.link-block__cat {
  display: block;
  color: #9b9b9b;
  font-size: calc(12rem / 14);
  position: absolute;
  height: 15px;
  line-height: 15px;
  bottom: 20px; }
  @media (min-width: 1440px) {
    .link-block__cat {
      bottom: 40px; } }

.contact-form fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

.contact-form legend {
  opacity: 0;
  visibility: hidden; }

.contact-form:last-child {
  margin-bottom: 5rem; }

.contact-form__inner {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }
  .contact-form__inner .field {
    grid-column: 1 / -1; }
    @media (min-width: 769px) {
      .contact-form__inner .field {
        grid-column: 3 / -3; } }
    @media (min-width: 1024px) {
      .contact-form__inner .field {
        grid-column: 5 / -5; } }

.agreement-notice {
  display: block;
  color: #333;
  font-weight: 700;
  font-style: normal;
  margin: 1rem 0; }

.help__title {
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 3px;
  margin-bottom: 1rem;
  line-height: calc(32 / 14);
  font-family: "Rubik medium"; }

.modal-popup._show .modal-inner-wrap {
  transform: translateY(0); }

.modal-popup .modal-inner-wrap {
  transition: transform 0.2s ease; }

.modal-popup {
  position: absolute;
  top: -9999px;
  left: 50%;
  width: 100%;
  max-width: 70rem;
  padding: 0;
  margin-top: 0;
  background: #fff;
  transform: translateX(-50%); }
  .modal-popup._show {
    position: absolute;
    top: 0;
    padding: 2.1rem;
    padding-top: 4.2rem;
    margin-top: 4.2rem; }
    @media (min-width: 768px) {
      .modal-popup._show {
        padding: 4.2rem; } }
  .modal-popup .field {
    margin-bottom: 4rem; }
    .modal-popup .field .field {
      margin-bottom: 0; }
  .modal-popup .select {
    width: 100%; }
  .modal-popup .custom-checkbox__text {
    line-height: 1.5;
    font-style: normal; }
  .modal-popup .modal-content,
  .modal-popup .field-tooltip-content,
  .modal-popup .label {
    font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.8; }
  .modal-popup .action-close {
    position: absolute;
    top: -3.15rem;
    right: -1.75rem;
    border: none;
    background: none; }
    @media (min-width: 758px) {
      .modal-popup .action-close {
        top: -1.75rem; } }
    .modal-popup .action-close span {
      display: none; }
    .modal-popup .action-close:before {
      content: '';
      background-image: url("../images/icons/close.svg");
      height: 10px;
      width: 10px;
      display: block; }

.modals-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); }

.category-seo-top-container,
.category-seo-bottom-container {
  background-color: #000;
  padding: 30px 14px; }
  .category-seo-top-container h1,
  .category-seo-top-container h2,
  .category-seo-top-container h3,
  .category-seo-top-container h4,
  .category-seo-top-container h5,
  .category-seo-top-container h6,
  .category-seo-bottom-container h1,
  .category-seo-bottom-container h2,
  .category-seo-bottom-container h3,
  .category-seo-bottom-container h4,
  .category-seo-bottom-container h5,
  .category-seo-bottom-container h6 {
    color: #fff;
    margin-bottom: 14px;
    font-size: 40px;
    font-family: "Noe semibold"; }
  .category-seo-top-container p,
  .category-seo-bottom-container p {
    color: #fff;
    margin: 0;
    font-size: 14px;
    line-height: 26px; }
    .category-seo-top-container p a,
    .category-seo-bottom-container p a {
      color: #fff;
      text-decoration: underline; }
  @media (min-width: 769px) {
    .category-seo-top-container,
    .category-seo-bottom-container {
      padding: 47px calc(100 / 24 * 1%) 44px; }
      .category-seo-top-container h1,
      .category-seo-top-container h2,
      .category-seo-top-container h3,
      .category-seo-top-container h4,
      .category-seo-top-container h5,
      .category-seo-top-container h6,
      .category-seo-bottom-container h1,
      .category-seo-bottom-container h2,
      .category-seo-bottom-container h3,
      .category-seo-bottom-container h4,
      .category-seo-bottom-container h5,
      .category-seo-bottom-container h6 {
        font-size: 30px; }
      .category-seo-top-container p,
      .category-seo-bottom-container p {
        font-size: 16px;
        line-height: 28px; } }

body .breadcrumbs {
  opacity: 0;
  position: absolute;
  left: -9999px; }

@media (min-width: 1380px) {
  body.catalog-category-view .breadcrumbs, body.catalog-product-view .breadcrumbs {
    opacity: 1;
    left: auto; } }

.page-header .breadcrumbs {
  position: absolute;
  top: 35px; }
  .page-header .breadcrumbs ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    list-style: none; }
    .page-header .breadcrumbs ul li {
      color: #fff; }
      .page-header .breadcrumbs ul li:not(:first-child) {
        padding-left: 4px; }
        .page-header .breadcrumbs ul li:not(:first-child):before {
          content: '>'; }
      .page-header .breadcrumbs ul li a {
        color: #fff; }

.page-header.is-scrolled .breadcrumbs ul li {
  color: #000; }
  .page-header.is-scrolled .breadcrumbs ul li a {
    color: #000; }

.catalog-product-view .breadcrumbs {
  top: 40px; }
  .catalog-product-view .breadcrumbs ul li {
    color: #000; }
    .catalog-product-view .breadcrumbs ul li:not(:first-child):before {
      color: #000; }
    .catalog-product-view .breadcrumbs ul li a {
      color: #000; }

.hyvareactcheckout-reactcheckout-index .navigation,
.hyvareactcheckout-reactcheckout-index .block.block-search,
.hyvareactcheckout-reactcheckout-index .minicart-wrapper,
.hyvareactcheckout-reactcheckout-index .header.links {
  display: none !important; }

.hyvareactcheckout-reactcheckout-index .page-wrapper {
  padding-top: 0; }
  .hyvareactcheckout-reactcheckout-index .page-wrapper .page-header {
    position: relative !important; }

.product.detailed .title {
  margin: 0 0 2.5rem 0; }
  .product.detailed .title label {
    font-family: "Noe semibold";
    font-size: 2.14rem; }

.product.detailed .description {
  display: block; }

.product.detailed .table {
  width: 100%; }
  .product.detailed .table tr {
    display: block;
    padding: 0.8rem 0; }
    @media (min-width: 769px) {
      .product.detailed .table tr {
        width: 50%; } }
  .product.detailed .table th,
  .product.detailed .table td {
    display: block; }

.product.detailed .col {
  text-align: left; }
  .product.detailed .col.data {
    font-family: "Rubik light";
    margin: 0 0 0.71rem 0; }
    .product.detailed .col.data .price {
      font-family: "Rubik light";
      font-size: 1rem; }

.product.detailed .label {
  margin: 0 0 0.3rem 0; }

@media (min-width: 769px) {
  .product.detailed tbody {
    display: flex;
    flex-wrap: wrap; } }

.product.info.detailed .page-title-wrapper {
  display: block; }

.product.info.detailed .tocart {
  width: 100%; }
  @media (min-width: 769px) {
    .product.info.detailed .tocart {
      width: auto; } }

.product.info.detailed .data.item.content {
  margin-bottom: calc(110 / 1440 * 100vw); }
  .product.info.detailed .data.item.content:last-child {
    margin-bottom: 0; }

.product.data.items h2,
.additional-attributes-wrapper h2,
.data.item.content ~ h2 {
  font-size: calc(30 / 14 * 1rem);
  font-family: "Noe semibold";
  line-height: calc(40 / 14 * 1rem);
  margin-bottom: calc(50 / 1440 * 100vw); }

.product.description h2 {
  font-family: 'Noe semibold';
  font-size: 30px;
  margin: 0 0 1.2em 0; }

.product.description p {
  line-height: 2;
  margin: 0 0 2.58rem 0; }

.product.description strong {
  font-weight: bold; }

.product.description ul {
  list-style: none;
  padding: 0;
  margin: 0 0 2.58rem 0; }
  .product.description ul li {
    position: relative;
    padding: 0 0 0 1rem;
    width: 100%;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.8; }
    .product.description ul li:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0.65rem;
      width: 6px;
      height: 6px;
      background: black; }

.product.description *:last-child {
  margin-bottom: 0; }

.swatch-attribute-options {
  display: flex;
  flex-wrap: wrap; }
  .swatch-attribute-options:focus {
    outline: none; }

.product-options-wrapper:focus,
.product-options-wrapper > .fieldset:focus,
.swatch-opt:focus,
.swatch-attribute:focus {
  outline: none; }

.swatch-attribute-label,
.swatch-attribute-selected-option,
.super-attribute-select,
.swatch-label {
  display: none; }

.swatch-opt {
  margin: 0 0 2.1rem 0.33rem; }

.swatch-option {
  position: relative;
  width: 1.15rem;
  height: 1.4rem;
  margin: 0 1.28rem 0 0;
  opacity: 0.5;
  transition: opacity 600ms cubic-bezier(0.6, 0, 0.01, 0.99);
  cursor: pointer; }
  .swatch-option[option-tooltip-value='#ffffff'] {
    border: 1px solid grey; }
    .swatch-option[option-tooltip-value='#ffffff'].selected {
      border: none; }
  .swatch-option:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1.8rem;
    height: 2rem;
    border: 2px solid black;
    transform: translate(-50%, -50%) scale(0.5);
    transition: transform 600ms cubic-bezier(0.6, 0, 0.01, 0.99); }
  .swatch-option:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: inherit; }
  .swatch-option:focus {
    outline: none; }
  .swatch-option:hover:before {
    transform: translate(-50%, -50%) scale(1); }
  .swatch-option.selected {
    opacity: 1; }
    .swatch-option.selected:before {
      transform: translate(-50%, -50%) scale(1); }
  .swatch-option.image {
    width: 42px !important;
    height: 44px !important;
    background-size: contain !important;
    opacity: 1; }
    .swatch-option.image:before {
      width: 53px !important;
      height: 55px !important;
      transform: translate(-50%, -50%) scale(1); }
    .swatch-option.image.selected:before {
      border: solid 2px #000; }
    .swatch-option.image:not(.selected):before {
      border: solid 2px #ccc; }

.portraitLandscapeSwitch {
  display: flex;
  gap: 20px;
  margin-bottom: 40px; }
  .portraitLandscapeSwitch .switch {
    border: none;
    background: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    border: solid 2px #ccc;
    display: flex;
    justify-content: end;
    padding: 10px; }
  .portraitLandscapeSwitch .portrait.active, .portraitLandscapeSwitch .landscape.active {
    border: solid 2px #000; }
  .portraitLandscapeSwitch .portrait:before, .portraitLandscapeSwitch .landscape:before {
    content: '';
    width: 20px;
    height: 40px;
    border: dashed 2px #000;
    display: block;
    margin-bottom: 5px; }
  .portraitLandscapeSwitch .landscape:before {
    width: 40px;
    height: 20px;
    margin-bottom: 15px; }

.related-header {
  margin-bottom: 2rem;
  grid-column: 1 / -1; }
  @media (min-width: 768px) {
    .related-header {
      grid-column: auto / span 5;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      padding-bottom: 8.57rem; } }

.related {
  grid-column: 1 / -1; }
  @media (min-width: 600px) {
    .related {
      margin-left: 0;
      grid-column: 4 / -4; } }
  @media (min-width: 1024px) {
    .related {
      grid-column: auto / span 7; } }
  .related .wrapper {
    overflow: auto;
    padding: 0 0 4.28rem 1rem;
    margin: 0 -1rem; }
    @media (min-width: 1024px) {
      .related .wrapper {
        margin: 0;
        padding: 0 0 8.57rem 0; } }
  .related .list {
    display: grid;
    width: 40rem;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1px;
    margin: 0; }
    @media (min-width: 769px) {
      .related .list {
        width: 100%; } }
    .related .list .product-item-photo {
      margin: 1.1rem auto 2.85rem auto; }
  .related .item {
    width: 100%;
    padding: 0 1rem; }
    @media (min-width: 768px) {
      .related .item {
        padding: 0 12.5%;
        grid-column: auto / span 1; } }
  .related .product-item-info {
    padding: 0;
    width: 100%; }
  .related .product-image-container {
    display: block;
    width: 100% !important; }
  .related .product-image-wrapper {
    display: block;
    padding-bottom: 0 !important; }
  .related .product-image-photo {
    width: auto; }
    @media (min-width: 1400px) {
      .related .product-image-photo {
        max-width: 100%; } }

@media (min-width: 768px) {
  .catalog-product-view .product-details-wrapper .related-header {
    grid-column: auto / span 5; } }

.catalog-product-view .product-details-wrapper .related {
  grid-column: 1 / -1; }
  @media (min-width: 600px) {
    .catalog-product-view .product-details-wrapper .related {
      margin-left: 0; } }
  @media (min-width: 768px) {
    .catalog-product-view .product-details-wrapper .related {
      grid-column: auto / span 7; } }

/* Product title */
.product-info-main {
  padding-top: calc(30 / 14 * 1rem);
  margin-bottom: calc(60 / 14 * 1rem);
  padding-left: 1rem;
  padding-right: 1rem; }
  @media (min-width: 1024px) {
    .product-info-main {
      padding-top: 0;
      margin-bottom: 0;
      padding-left: 0;
      padding-right: 0; } }
  .product-info-main .page-title {
    font-size: calc(40 / 14 * 1rem);
    line-height: calc(40 / 32);
    margin-bottom: calc(30 / 14 * 1rem); }
    @media (min-width: 1024px) {
      .product-info-main .page-title {
        font-size: calc(30 / 14 * 1rem);
        margin-bottom: calc(50 / 14 * 1rem); } }
    .product-info-main .page-title span {
      font-size: inherit; }
  .product-info-main .product-info-stock-sku {
    display: none; }
  .product-info-main .product-info-price {
    margin-bottom: calc(30 / 14 * 1rem); }
    @media (min-width: 1024px) {
      .product-info-main .product-info-price {
        margin-bottom: calc(45 / 14 * 1rem); } }
  .product-info-main .swatch-opt {
    margin-bottom: 1rem; }
  .product-info-main .product.attribute.overview {
    margin-bottom: calc(20 / 14 * 1rem); }
    @media (min-width: 1024px) {
      .product-info-main .product.attribute.overview {
        margin-bottom: calc(25 / 14 * 1rem); } }
  .product-info-main .product.attribute.overview p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: calc(25 / 14); }
    @media (min-width: 1024px) {
      .product-info-main .product.attribute.overview p {
        line-height: calc(28 / 16);
        font-size: calc(16 / 14 * 1rem); } }
  .product-info-main .price-final_price {
    margin-bottom: calc(60 / 14 * 1rem); }
    @media (min-width: 1024px) {
      .product-info-main .price-final_price {
        margin-bottom: calc(55 / 14 * 1rem); } }
  .product-info-main .order-sample-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; }
    @media (min-width: 768px) {
      .product-info-main .order-sample-wrapper {
        flex-direction: row; } }
  .product-info-main .add-sample-to-cart input {
    display: none; }
  @media (min-width: 768px) {
    .product-info-main .add-sample-to-cart {
      margin-right: 2.14rem; } }
  .product-info-main .add-sample-to-cart button[type='submit'],
  .product-info-main .product-attachment {
    display: block;
    font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.8; }
  .product-info-main .product-attachment,
  .product-info-main .add-sample-to-cart {
    margin-bottom: 25px; }
  .product-info-main .add-sample-to-cart button[type='submit'], .product-info-main #uploadBtn.active {
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
    line-height: 1.8;
    font-size: 1rem;
    letter-spacing: 0; }

.fotorama-stretch, .fotorama-focus-overlay:after, .fotorama__nav__frame:focus:after, .fotorama__nav__frame:focus .fotorama__thumb:after, .fotorama__stage__shaft, .fotorama__stage__frame, .fotorama__html, .fotorama__video iframe {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%; }

.fotorama-grab-cursor, .fotorama__wrap .fotorama__grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab; }

.fotorama-grabbing-cursor, .fotorama__grabbing * {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing; }

.fotorama-gpu, .fotorama--fullscreen, .fotorama__caption, .fotorama__wrap--css3 .fotorama__stage,
.fotorama__wrap--css3 .fotorama__nav,
.fotorama__wrap--css3 .fotorama__stage__frame, .fotorama__wrap--css3 .fotorama__html, .fotorama__stage:before, .fotorama__stage:after,
.fotorama__nav:before,
.fotorama__nav:after, .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full {
  transform: translateZ(0); }

.fotorama-focus, .fotorama__nav__frame, .fotorama__fullscreen-icon:focus,
.fotorama__zoom-out:focus,
.fotorama__zoom-in:focus {
  outline: 0; }

.fotorama-focus-overlay:after, .fotorama__nav__frame:focus:after, .fotorama__nav__frame:focus .fotorama__thumb:after {
  background-color: blue;
  border-radius: inherit;
  content: ''; }

.fotorama-transform-disabled, .fotorama__wrap--video .fotorama__stage__shaft,
.fotorama__wrap--video .fotorama__stage,
.fotorama__wrap--video .fotorama__stage__frame--video, .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__img,
.fotorama__wrap--video .fotorama__stage__frame--video .fotorama__html {
  transform: none !important; }

.fotorama-transition-for-slide, .fotorama__wrap--css3 .fotorama__stage__shaft,
.fotorama__wrap--css3 .fotorama__nav__shaft,
.fotorama__wrap--css3 .fotorama__thumb-border {
  transition-duration: 0ms;
  transition-property: transform, width;
  transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1); }

.fotorama-no-select, .fotorama__wrap, .fotorama__no-select, .fotorama__arr,
.fotorama__fullscreen-icon,
.fotorama__video-close {
  user-select: none; }

.fotorama-select, .fotorama__select {
  user-select: text; }

.fotorama-empty-bg {
  background: url(data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=); }

.fotorama-auto-margin, .fotorama__nav, .fotorama__nav__frame {
  margin: auto;
  padding: 0; }

.fotorama-inline-block, .fotorama__nav__shaft, .fotorama__nav__frame, .fotorama__caption__wrap {
  display: inline;
  zoom: 1;
  -moz-box-orient: vertical;
  display: inline-block;
  vertical-align: middle; }

.fotorama-content-box, .fotorama__nav__frame {
  box-sizing: content-box; }

.fotorama-border-box, .fotorama__thumb-border, .fotorama__caption__wrap, .fotorama__arr {
  box-sizing: border-box; }

.fotorama-hidden, .fotorama--hidden, .fotorama__load {
  left: -99999px;
  position: absolute;
  top: -99999px;
  z-index: -10; }

.fotorama-visible {
  left: auto;
  opacity: 1;
  position: relative;
  top: auto;
  z-index: auto; }

.fotorama-no-tap, .fotorama__stage__shaft, .fotorama__stage__frame, .fotorama__nav, .fotorama__nav__shaft, .fotorama__nav__frame, .fotorama__arr,
.fotorama__fullscreen-icon,
.fotorama__video-close {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.transitionDuration {
  transition-duration: 333ms; }

.transitionDurationZero {
  transition-duration: 0ms; }

@media print {
  .fotorama-print-background, .fotorama-sprite, .fotorama__fullscreen .fotorama__zoom-out, .fotorama__fullscreen .fotorama__zoom-in, .fotorama__arr .fotorama__arr__arr,
  .fotorama__fullscreen-icon, .fotorama__video-play, .fotorama__thumb--icon,
  .fotorama__video-close, .fotorama__thumb-border {
    background: none !important; } }

.fotorama__fullscreen-icon:focus,
.fotorama__zoom-out:focus,
.fotorama__zoom-in:focus,
.fotorama__arr:focus,
.fotorama__stage__shaft:focus,
.fotorama__nav__frame--thumb:focus .fotorama__thumb,
.fotorama__nav__frame--dot:focus .fotorama__dot {
  box-shadow: none; }
  .fotorama__fullscreen-icon:focus:after,
  .fotorama__zoom-out:focus:after,
  .fotorama__zoom-in:focus:after,
  .fotorama__arr:focus:after,
  .fotorama__stage__shaft:focus:after,
  .fotorama__nav__frame--thumb:focus .fotorama__thumb:after,
  .fotorama__nav__frame--dot:focus .fotorama__dot:after {
    border-radius: inherit;
    bottom: 3px;
    box-shadow: 0 0 3px 1px #68a8e0;
    content: '';
    left: 3px;
    position: absolute;
    right: 3px;
    top: 3px;
    z-index: 10; }

.fotorama__nav__frame--thumb:focus .fotorama__thumb:after,
.fotorama__nav__frame--thumb:focus .fotorama__dot:after,
.fotorama__nav__frame--dot:focus .fotorama__thumb:after,
.fotorama__nav__frame--dot:focus .fotorama__dot:after {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0; }

.fotorama__nav__frame--thumb:focus .fotorama__thumb.fotorama_vertical_ratio:after,
.fotorama__nav__frame--dot:focus .fotorama__thumb.fotorama_vertical_ratio:after {
  left: 2px;
  right: 2px; }

.fotorama__nav__frame--thumb:focus .fotorama__thumb,
.fotorama__nav__frame--dot:focus .fotorama__thumb {
  overflow: inherit; }

.fotorama__nav__frame:nth-child(2):focus .fotorama__thumb:after {
  left: 1px; }

.fotorama__nav__frame:nth-child(2):focus .fotorama__thumb.fotorama_vertical_ratio:after {
  top: 1px; }

.fotorama__nav__frame:last-child:focus .fotorama__thumb:after {
  right: 1px; }

.fotorama__nav__frame:last-child:focus .fotorama__thumb.fotorama_vertical_ratio:after {
  bottom: 1px; }

.fotorama__thumb__arr {
  box-shadow: none; }

.fotorama--fullscreen {
  background: #fff;
  bottom: 0 !important;
  float: none !important;
  left: 0 !important;
  margin: 0 !important;
  position: fixed !important;
  right: 0 !important;
  top: 0 !important;
  width: 100% !important;
  z-index: 10 !important; }
  .fotorama--fullscreen .fotorama__wrap {
    max-width: 100% !important; }

.fotorama__fullscreen {
  overflow: hidden;
  position: relative; }
  .fotorama__fullscreen .fotorama__zoom-in,
  .fotorama__fullscreen .fotorama__zoom-out {
    cursor: pointer;
    display: block;
    height: 80px;
    overflow: hidden;
    position: absolute;
    width: 80px; }
  .fotorama__fullscreen .fotorama__zoom-out {
    background-position: 0 -80px !important;
    top: 80px; }
    .fotorama__fullscreen .fotorama__zoom-out.fotorama__zoom-out--disabled {
      display: none; }
  .fotorama__fullscreen .fotorama__zoom-in {
    background-position: 0 0 !important;
    top: 0; }
    .fotorama__fullscreen .fotorama__zoom-in.fotorama__zoom-in--disabled {
      display: none; }
  .fotorama__fullscreen .fotorama__video-close {
    display: none; }

.fotorama--fullscreen .fotorama__loaded--full .fotorama__img {
  display: none; }

.fotorama--fullscreen .fotorama__loaded--full .fotorama__img--full {
  display: block; }

.fotorama__zoom-in,
.fotorama__zoom-out {
  display: none; }

.fotorama {
  min-width: 1px;
  overflow: hidden; }

.fotorama__wrap {
  -webkit-text-size-adjust: 100%;
  direction: ltr;
  position: relative;
  z-index: 0;
  cursor: grab; }

.fotorama__wrap--rtl .fotorama__stage__frame {
  direction: rtl; }

.fotorama__stage,
.fotorama__nav {
  max-width: 100%;
  overflow: hidden;
  position: relative; }

.fotorama__wrap--pan-y {
  -ms-touch-action: pan-y; }

.fotorama__stage__shaft {
  position: relative; }

.fotorama__wrap .fotorama__pointer {
  cursor: pointer; }

.fotorama__wrap--slide .fotorama__stage__frame {
  opacity: 1 !important; }

.fotorama__stage__frame {
  overflow: hidden; }
  .fotorama__stage__frame.fotorama__active {
    z-index: 8; }

.fotorama__wrap--fade .fotorama__stage__frame {
  display: none; }

.fotorama__wrap--fade .fotorama__stage__frame.fotorama__active,
.fotorama__wrap--fade .fotorama__fade-front,
.fotorama__wrap--fade .fotorama__fade-rear {
  display: block;
  left: 0;
  top: 0; }

.fotorama__wrap--fade .fotorama__fade-front {
  z-index: 8; }

.fotorama__wrap--fade .fotorama__fade-rear {
  z-index: 7; }
  .fotorama__wrap--fade .fotorama__fade-rear.fotorama__active {
    z-index: 9; }

.fotorama__wrap--fade .fotorama__stage .fotorama__shadow {
  display: none; }

.fotorama__img {
  border: none !important;
  max-width: inherit;
  opacity: 0; }
  .fotorama__loaded .fotorama__img,
  .fotorama__error .fotorama__img {
    opacity: 1; }

.fotorama__img--full {
  display: none; }

.fotorama__nav {
  display: none;
  font-size: 0;
  line-height: 0;
  white-space: nowrap;
  z-index: 5; }

.fotorama__nav__shaft {
  left: 0;
  position: relative;
  text-align: left;
  top: 0; }

.fotorama__nav__frame {
  position: relative; }

.fotorama__nav--dots {
  display: block;
  text-align: center; }
  .fotorama__nav--dots .fotorama__nav__frame {
    height: 30px;
    width: 18px; }
  .fotorama__nav--dots .fotorama__nav__frame--thumb,
  .fotorama__nav--dots .fotorama__thumb-border {
    display: none; }

.fotorama__nav--thumbs {
  display: block; }
  .fotorama__nav--thumbs .fotorama__nav__frame {
    padding-left: 0 !important; }
    .fotorama__nav--thumbs .fotorama__nav__frame:last-child {
      padding-right: 0 !important; }
  .fotorama__nav--thumbs .fotorama__nav__frame--dot {
    display: none; }

.fotorama__active .fotorama__dot {
  background-color: #ff5501;
  border-color: #ff5501; }

.fotorama__nav__frame.fotorama__active .fotorama__nav__frame {
  border-width: 3px;
  height: 0;
  width: 0; }
  .fotorama__nav__frame.fotorama__active .fotorama__nav__frame:after {
    left: -3px;
    padding: 3px;
    top: -3px; }

.fotorama__nav__frame:focus {
  box-shadow: none; }
  .fotorama__nav__frame:focus:after {
    left: -1px;
    padding: 1px;
    top: -1px; }

.fotorama__dot {
  border-radius: 6px;
  border: 1px solid #858585;
  display: block;
  height: 4px;
  left: 6px;
  position: relative;
  top: 12px;
  width: 4px; }

.fotorama__thumb {
  background-color: #ebebeb;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%; }
  .fotorama__nav__frame:focus .fotorama__thumb {
    z-index: 2; }

.fotorama__thumb-border {
  backface-visibility: hidden;
  background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.25), rgba(64, 64, 64, 0.1));
  border: 1px solid #ff5501;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 9; }

.fotorama__caption {
  bottom: 0;
  color: #000;
  font-size: 14px;
  left: 0;
  line-height: 1.5;
  position: absolute;
  right: 0;
  z-index: 12; }
  .fotorama__caption a {
    border-bottom: 1px solid;
    border-color: fade(#000, 0.5);
    color: #000;
    text-decoration: none; }
    .fotorama__caption a:hover {
      border-color: fade(#000, 0.5);
      color: #000; }
  .fotorama__wrap--rtl .fotorama__caption {
    left: auto;
    right: 0; }
  .fotorama__wrap--video .fotorama__caption,
  .fotorama__wrap--no-captions .fotorama__caption {
    display: none; }

.fotorama__caption__wrap {
  background-color: #fff;
  padding: 5px 10px; }

@keyframes spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.fotorama__spinner {
  transform: translate3d(-50%, -50%, 0);
  left: 50%;
  position: absolute;
  top: 50%; }

.fotorama__wrap--css3 .fotorama__html {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: linear; }

.fotorama__wrap--video .fotorama__stage__frame--video .fotorama__img,
.fotorama__wrap--video .fotorama__stage__frame--video .fotorama__html {
  opacity: 0; }

.fotorama__select {
  cursor: auto; }

.fotorama__video {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 32px;
  z-index: 10; }

@-moz-document url-prefix() {
  .fotorama__active {
    box-shadow: 0 0 0 transparent; } }

.fotorama__arr,
.fotorama__fullscreen-icon,
.fotorama__video-close {
  cursor: pointer;
  position: absolute; }

.fotorama__arr {
  z-index: 9; }

.fotorama__fullscreen-icon,
.fotorama__zoom-out,
.fotorama__zoom-in,
.fotorama__video-close {
  z-index: 10; }

.fotorama__arr {
  bottom: 0;
  position: absolute;
  top: 0;
  width: 80px; }
  .fotorama__arr .fotorama__arr__arr {
    transform: translate3d(-50%, -50%, 0);
    left: 50%;
    position: absolute;
    top: 50%;
    height: 80px;
    width: 80px; }

.fotorama__arr--prev {
  left: 0; }
  .fotorama__arr--prev .fotorama__arr__arr {
    background-position: -160px; }

.fotorama__arr--next {
  right: 0; }
  .fotorama__arr--next .fotorama__arr__arr {
    background-position: -160px -80px; }

.fotorama__arr--disabled {
  display: none;
  cursor: default;
  opacity: 0.1;
  pointer-events: none; }

.fotorama__fullscreen-icon {
  background-position: 0 0;
  display: none;
  height: 80px;
  right: 0;
  top: 0;
  width: 80px;
  z-index: 10; }

.fotorama--fullscreen-icons .fotorama__fullscreen-icon {
  display: none; }

.fotorama--fullscreen .fotorama__fullscreen-icon {
  background-position: -80px 0;
  display: inline-block; }

.fotorama__video-play {
  transform: translate3d(-50%, -50%, 0);
  left: 50%;
  position: absolute;
  top: 50%;
  background-position: -80px -160px;
  height: 160px;
  width: 160px; }
  .fotorama__wrap--css2 .fotorama__video-play,
  .fotorama__wrap--video .fotorama__stage .fotorama__video-play {
    display: none; }
  .fotorama__loaded .fotorama__video-play,
  .fotorama__error .fotorama__video-play,
  .fotorama__nav__frame .fotorama__video-play {
    display: block;
    opacity: 1; }
  .fotorama__nav__frame .fotorama__video-play {
    background-position: 0 -240px;
    height: 80px;
    width: 80px; }

.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr,
.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon {
  opacity: 0; }
  .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr:focus,
  .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:focus {
    opacity: 1; }

.fotorama__wrap--toggle-arrows:not(.fotorama__wrap--video) .fotorama__video-close {
  display: none; }

.fotorama__wrap--toggle-arrows.fotorama__wrap--video .fotorama__video-close {
  opacity: 1;
  right: 93px;
  top: 97px; }

.fotorama__wrap--toggle-arrows:not(.fotorama__wrap--video) .fotorama__video-close {
  display: none; }

.fotorama__wrap--toggle-arrows.fotorama__wrap--video .fotorama__video-close {
  opacity: 1;
  right: 93px;
  top: 97px; }

.fotorama__wrap--toggle-arrows:not(.fotorama__wrap--video) .fotorama__video-close {
  display: none; }

.fotorama__wrap--toggle-arrows.fotorama__wrap--video .fotorama__video-close {
  opacity: 1;
  right: 93px;
  top: 97px; }

.fotorama__wrap--video .fotorama__arr,
.fotorama__wrap--video .fotorama__fullscreen-icon {
  opacity: 0 !important; }

.fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr,
.fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon {
  display: none; }
  .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr:focus,
  .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:focus {
    display: block; }

.fotorama__wrap--css2.fotorama__wrap--video .fotorama__arr,
.fotorama__wrap--css2.fotorama__wrap--video .fotorama__fullscreen-icon {
  display: none !important; }

.fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:not(:focus) {
  transform: translate3d(80px, -80px, 0); }

.fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--prev:not(:focus) {
  transform: translate3d(-120px, 0, 0); }

.fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--next:not(:focus) {
  transform: translate3d(120px, 0, 0); }

.fotorama__wrap--css3.fotorama__wrap--video .fotorama__fullscreen-icon {
  transform: translate3d(80px, -80px, 0 !important); }

.fotorama__wrap--css3.fotorama__wrap--video .fotorama__arr--prev {
  transform: translate3d(-120px, 0, 0 !important); }

.fotorama__wrap--css3.fotorama__wrap--video .fotorama__arr--next {
  transform: translate3d(120px, 0, 0 !important); }

.fotorama__wrap--css3 .fotorama__arr:not(:focus),
.fotorama__wrap--css3 .fotorama__fullscreen-icon:not(:focus),
.fotorama__wrap--css3 .fotorama__video-play:not(:focus),
.fotorama__wrap--css3 .fotorama__video-close:not(:focus) {
  transition-duration: 0.3s;
  transition-property: transform, opacity, background-color; }

.fotorama__stage:before, .fotorama__stage:after,
.fotorama__nav:before,
.fotorama__nav:after {
  background-repeat: no-repeat;
  content: '';
  display: block;
  pointer-events: none;
  position: absolute;
  text-decoration: none;
  z-index: 10; }

.fotorama__stage:before,
.fotorama__nav:before {
  left: -10px;
  top: -10px; }

.fotorama__stage:after,
.fotorama__nav:after {
  right: -10px;
  bottom: -10px; }

.fotorama__stage.fotorama__shadows--left:before, .fotorama__stage.fotorama__shadows--right:after,
.fotorama__nav.fotorama__shadows--left:before,
.fotorama__nav.fotorama__shadows--right:after {
  background-size: 1px 100%, 5px 100%;
  bottom: 0;
  height: auto;
  top: 0;
  width: 10px; }

.fotorama__stage.fotorama__shadows--top:before, .fotorama__stage.fotorama__shadows--bottom:after,
.fotorama__nav.fotorama__shadows--top:before,
.fotorama__nav.fotorama__shadows--bottom:after {
  background-size: 100% 1px, 100% 5px;
  height: 10px;
  left: 0;
  right: 0;
  width: auto; }

.fotorama__stage.fotorama__shadows--left:before,
.fotorama__nav.fotorama__shadows--left:before {
  left: 0; }

.fotorama__stage.fotorama__shadows--right:after,
.fotorama__nav.fotorama__shadows--right:after {
  right: 0; }

.fotorama__stage.fotorama__shadows--top:before,
.fotorama__nav.fotorama__shadows--top:before {
  top: 0; }

.fotorama__stage.fotorama__shadows--bottom:after,
.fotorama__nav.fotorama__shadows--bottom:after {
  bottom: 0; }

.fotorama--fullscreen .fotorama__stage:before, .fotorama--fullscreen .fotorama__stage:after,
.fotorama--fullscreen .fotorama__nav:before,
.fotorama--fullscreen .fotorama__nav:after,
.fotorama__wrap--no-shadows .fotorama__stage:before,
.fotorama__wrap--no-shadows .fotorama__stage:after,
.fotorama__wrap--no-shadows .fotorama__nav:before,
.fotorama__wrap--no-shadows .fotorama__nav:after,
.fotorama__wrap--fade .fotorama__stage:before,
.fotorama__wrap--fade .fotorama__stage:after {
  display: none; }

.fotorama__nav-wrap .fotorama_vertical_ratio .fotorama__img {
  transform: translateY(-50%);
  height: auto;
  position: absolute;
  top: 50%;
  width: 100%; }

.fotorama__nav-wrap .fotorama_horizontal_ratio .fotorama__img {
  transform: translateX(-50%);
  height: 100%;
  left: 50%;
  position: absolute;
  width: auto; }

.magnifier-thumb-wrapper {
  display: block;
  left: 0;
  position: relative;
  top: 0; }

.magnifier-lens {
  border: solid 1px #bbb;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 8; }

.magnify-lens {
  background: rgba(255, 255, 255, 0.5);
  border: solid 1px #bbb;
  color: #fff;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  z-index: 10;
  overflow: hidden; }
  .magnify-lens .magnifier-large {
    width: auto;
    height: auto;
    max-height: none;
    max-width: none;
    border: none;
    position: absolute;
    z-index: 1; }

.magnifier-loader-text {
  margin-top: 10px; }

.magnifier-preview {
  bottom: 0;
  left: 58%;
  overflow: hidden;
  padding: 0;
  position: absolute;
  z-index: 2;
  top: 215px; }
  .magnifier-preview:not(.hidden) {
    background-color: #fff; }
  .magnifier-preview img {
    left: 0;
    max-width: inherit;
    position: absolute;
    top: 0; }

.fotorama__stage__frame {
  text-align: center; }
  .fotorama__stage__frame .fotorama__img {
    height: auto;
    left: 50%;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    transition-duration: 0.3s;
    transition-property: width, height, top, left;
    vertical-align: middle;
    width: auto; }

.magnify-opaque {
  opacity: 0.5; }

.magnify-hidden {
  display: none; }

.fotorama__nav-wrap--vertical.fotorama__nav-wrap {
  display: inline-block;
  left: 0;
  position: absolute;
  top: 0; }

.fotorama__nav-wrap--vertical .fotorama__nav__shaft {
  background-color: white;
  width: 100%; }
  .fotorama__nav-wrap--vertical .fotorama__nav__shaft .fotorama__nav__frame--thumb {
    display: block;
    padding-bottom: inherit !important; }

.fotorama--fullscreen .fotorama__stage__frame .fotorama__img {
  display: none; }

.fotorama--fullscreen .fotorama__stage__frame .fotorama__img,
.fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full {
  bottom: 0;
  left: 0;
  margin: auto;
  max-height: inherit;
  max-width: inherit;
  position: absolute;
  right: 0;
  top: 0; }

.fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full {
  cursor: default;
  display: block;
  height: auto;
  left: 0;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
  top: 0;
  transition: 0.3s linear;
  vertical-align: middle;
  width: auto; }
  .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full.fotorama__img--zoommable {
    cursor: pointer;
    max-height: none;
    max-width: none;
    transition-property: width, height, bottom, right, top, left; }
  .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full.fotorama__img--draggable {
    cursor: move;
    transition-property: none; }

.fotorama--fullscreen .fotorama__stage__frame iframe {
  left: 80px; }

.fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left,
.fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right {
  bottom: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 30px;
  z-index: 10; }
  .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left .fotorama__thumb--icon,
  .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right .fotorama__thumb--icon {
    transform: translate3d(-50%, -50%, 0);
    left: 50%;
    position: absolute;
    top: 50%;
    width: 100%; }

.fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left {
  left: 0; }

.fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right {
  right: 0; }

.fotorama__nav-wrap--vertical .fotorama__thumb__arr--left,
.fotorama__nav-wrap--vertical .fotorama__thumb__arr--right {
  cursor: pointer;
  height: 30px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 10; }
  .fotorama__nav-wrap--vertical .fotorama__thumb__arr--left .fotorama__thumb--icon,
  .fotorama__nav-wrap--vertical .fotorama__thumb__arr--right .fotorama__thumb--icon {
    transform: rotate(90deg);
    margin: auto;
    width: 30px; }

.fotorama__nav-wrap--vertical .fotorama__thumb__arr--left {
  top: 0; }

.fotorama__nav-wrap--vertical .fotorama__thumb__arr--right {
  bottom: 0; }

.fotorama__wrap--only-active .fotorama__stage,
.fotorama__wrap--only-active .fotorama__nav {
  max-width: 99999px !important; }

.fotorama__wrap--only-active .fotorama__stage__frame {
  visibility: hidden; }

.fotorama__wrap--only-active .fotorama__stage__frame.fotorama__active {
  visibility: visible; }

.fotorama__thumb--icon {
  font-size: 0.001px;
  padding-bottom: 30px; }

.fotorama__thumb__arr--left .fotorama__thumb--icon {
  background-position: -25px -265px; }

.fotorama__thumb__arr--right .fotorama__thumb--icon {
  background-position: -25px -350px; }

.magnify-fullimage {
  display: none; }

.fotorama__arr,
.fotorama__thumb__arr {
  background-color: rgba(255, 255, 255, 0.3);
  transition: background-color 0.3s ease-in-out; }
  .fotorama__arr:hover,
  .fotorama__thumb__arr:hover {
    background-color: rgba(255, 255, 255, 0.5); }
  .fotorama__arr:active,
  .fotorama__thumb__arr:active {
    background-color: rgba(213, 213, 213, 0.5); }

.fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__fullscreen-icon,
.fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__zoom-out,
.fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__zoom-in {
  background-color: rgba(255, 255, 255, 0.3);
  transition: background-color 0.3s ease-in-out; }
  .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__fullscreen-icon:hover,
  .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__zoom-out:hover,
  .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__zoom-in:hover {
    background-color: rgba(255, 255, 255, 0.5); }
  .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__fullscreen-icon:active,
  .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__zoom-out:active,
  .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__zoom-in:active {
    background-color: rgba(213, 213, 213, 0.5); }

.fotorama__video-close {
  background-position: -80px 0;
  height: 80px;
  opacity: 0;
  right: 0;
  top: 0;
  transform: translate3d(80px, -80px, 0);
  transition: opacity 0.3s ease-in-out;
  width: 80px; }
  @media all and (max-width: 768px) {
    .fotorama__video-close {
      background-position: -100px -20px;
      top: 10px;
      height: 40px;
      width: 40px; } }
  .fotorama__video-close.fotorama-show-control {
    opacity: 1;
    transform: translate3d(0, -10px, 0); }

.gallery-placeholder .loading-mask {
  padding: 0 0 50%;
  position: static; }

.gallery-placeholder .loader img {
  position: absolute; }

.fotorama__spinner {
  background-image: url(../images/loader-1.gif);
  display: none;
  height: 64px;
  margin: auto;
  position: absolute;
  width: 235px;
  z-index: 1; }
  .fotorama__spinner.fotorama__spinner--show {
    display: block; }

.fotorama__product-video--loaded .fotorama__img,
.fotorama__product-video--loaded .fotorama__img--full {
  display: none !important; }

.fotorama__stage .fotorama__arr--shown {
  display: block !important; }

.fotorama__stage .fotorama__arr--hidden {
  display: none !important; }

.fotorama__video-close {
  display: none; }

.catalog-product-view {
  /* Main images */ }
  .catalog-product-view .action-skip-wrapper {
    display: none; }
  .catalog-product-view .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .catalog-product-view .fotorama__arr__arr {
    background: none;
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    display: none; }
  @media (min-width: 1024px) {
    .catalog-product-view .gallery-placeholder,
    .catalog-product-view .fotorama__stage,
    .catalog-product-view .product.media:before {
      max-height: 625px !important;
      height: 44.4444444444vw !important; } }
  .catalog-product-view .fotorama__arr--prev,
  .catalog-product-view .fotorama__arr--next {
    opacity: 1 !important;
    top: auto;
    background-color: transparent;
    width: 50px;
    padding-top: 30px;
    padding-bottom: 30px;
    height: 16px;
    box-sizing: content-box; }
    .catalog-product-view .fotorama__arr--prev:active,
    .catalog-product-view .fotorama__arr--next:active {
      background-color: rgba(255, 255, 255, 0.6); }
    .catalog-product-view .fotorama__arr--prev:before,
    .catalog-product-view .fotorama__arr--next:before {
      background-image: url("../images/icons/chevron-white.svg");
      background-size: cover;
      background-repeat: no-repeat;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 7px;
      height: 13px; }
    @media (min-width: 1024px) {
      .catalog-product-view .fotorama__arr--prev,
      .catalog-product-view .fotorama__arr--next {
        display: none; } }
  .catalog-product-view .fotorama,
  .catalog-product-view .fotorama__stage,
  .catalog-product-view .fotorama__nav {
    overflow: visible !important; }
  .catalog-product-view .fotorama__arr--prev:before {
    transform: translate(-50%, -50%) rotate(180deg); }
  .catalog-product-view .fotorama__thumb__arr {
    display: none !important; }
  .catalog-product-view .fotorama__nav-wrap {
    display: none !important; }
    @media (min-width: 1024px) {
      .catalog-product-view .fotorama__nav-wrap {
        display: block !important;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 30px; } }
  @media (min-width: 769px) {
    .catalog-product-view .fotorama__nav__shaft {
      display: flex;
      flex-direction: column; } }
  @media (min-width: 769px) {
    .catalog-product-view .fotorama__nav.fotorama__nav--thumbs {
      width: auto !important;
      height: auto !important; } }
  .catalog-product-view .fotorama__thumb-border {
    display: none !important; }
  @media (min-width: 769px) {
    .catalog-product-view .fotorama__nav__frame.fotorama__nav__frame--thumb {
      height: 80px !important;
      width: 60px !important;
      margin-bottom: 8px;
      padding: 0 !important;
      border: 2px solid transparent;
      cursor: pointer; } }
  @media (min-width: 769px) {
    .catalog-product-view .fotorama__nav__frame.fotorama__nav__frame--thumb.fotorama__active {
      border: 2px solid white; } }
  .catalog-product-view .fotorama__img {
    object-fit: cover;
    max-width: 100%;
    height: 100%; }
    @media (min-width: 769px) {
      .catalog-product-view .fotorama__img {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        left: auto; } }
  .catalog-product-view .fotorama__stage__shaft > .fotorama__loaded--img.fotorama_horizontal_ratio .fotorama__img {
    width: 100%; }
  .catalog-product-view .fotorama__stage__shaft > .fotorama__loaded--img .fotorama__img {
    object-fit: contain; }
  .catalog-product-view .fotorama__slide-data {
    display: flex;
    position: absolute;
    color: white;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
    align-items: center; }
    @media (min-width: 1024px) {
      .catalog-product-view .fotorama__slide-data {
        display: none; } }
    .catalog-product-view .fotorama__slide-data:after {
      display: block;
      content: '';
      width: 30px;
      height: 2px;
      background-color: white;
      margin: 0 10px;
      order: 2; }
    .catalog-product-view .fotorama__slide-data .current {
      order: 1; }
    .catalog-product-view .fotorama__slide-data .total {
      order: 3; }
    .catalog-product-view .fotorama__slide-data .current,
    .catalog-product-view .fotorama__slide-data .total {
      font-size: 12px;
      font-weight: 600; }
  .catalog-product-view .fotorama__nav-wrap .fotorama_horizontal_ratio .fotorama__img {
    top: 0; }

.product-info-price .product-options-wrapper .fieldset > .field {
  margin-bottom: 25px; }
  @media (min-width: 769px) {
    .product-info-price .product-options-wrapper .fieldset > .field {
      margin-bottom: calc(60 / 1440 * 100vw); } }
  @media (min-width: 1440px) {
    .product-info-price .product-options-wrapper .fieldset > .field {
      margin-bottom: 60px; } }
  .product-info-price .product-options-wrapper .fieldset > .field:last-of-type {
    margin-bottom: 0; }

.product-info-price .product-options-wrapper .fieldset > .field > .label {
  font-family: "Rubik medium";
  display: block;
  line-height: calc(25 / 14);
  margin-bottom: 10px; }

.product-info-price .product-options-wrapper .field.choice {
  margin-bottom: calc(20rem / 14); }
  .product-info-price .product-options-wrapper .field.choice input[type='radio'],
  .product-info-price .product-options-wrapper .field.choice input[type='checkbox'] {
    position: absolute; }
  .product-info-price .product-options-wrapper .field.choice input[type='radio'] + .label,
  .product-info-price .product-options-wrapper .field.choice input[type='checkbox'] + .label {
    padding-left: calc(0.8571428571rem + 1.2rem);
    min-height: 1.2rem; }
    .product-info-price .product-options-wrapper .field.choice input[type='radio'] + .label:before,
    .product-info-price .product-options-wrapper .field.choice input[type='checkbox'] + .label:before {
      border: 2px solid black;
      position: absolute;
      left: 0; }
    .product-info-price .product-options-wrapper .field.choice input[type='radio'] + .label:after,
    .product-info-price .product-options-wrapper .field.choice input[type='checkbox'] + .label:after {
      border: 2px solid black; }
    .product-info-price .product-options-wrapper .field.choice input[type='radio'] + .label:before, .product-info-price .product-options-wrapper .field.choice input[type='radio'] + .label:after,
    .product-info-price .product-options-wrapper .field.choice input[type='checkbox'] + .label:before,
    .product-info-price .product-options-wrapper .field.choice input[type='checkbox'] + .label:after {
      top: -1px;
      height: 17px;
      width: 17px; }
  .product-info-price .product-options-wrapper .field.choice input[type='radio'] + .label > span {
    font-size: 1rem;
    font-family: "Rubik light"; }

.product-info-price .product-options-wrapper .mage-error {
  color: #e02b27;
  font-weight: bold; }

.product-info-price .product-options-wrapper .mock-select .mage-error {
  position: absolute;
  top: 100%; }

/* Blog components */
.article-hero {
  height: calc(100vh - 10px);
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  color: white; }
  .article-hero:before {
    content: '';
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

.article-hero__content-wrapper {
  padding-left: 0;
  padding-right: 0;
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  width: 100%; }

.article-hero__grid {
  grid-column: 2 / -2; }
  @media (min-width: 769px) {
    .article-hero__grid {
      grid-column: 2 / 8; } }

.article-hero__title {
  color: inherit;
  line-height: calc(60 / 50);
  margin-bottom: 35px;
  font-size: calc(32rem / 14); }
  @media (min-width: 769px) {
    .article-hero__title {
      font-size: calc(50rem / 14); } }

.article-hero__intro {
  padding-left: 0;
  padding-right: 0;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding-top: calc(110 / 1440 * 100vw);
  padding-bottom: calc(110 / 1440 * 100vw);
  background-color: white;
  position: relative;
  z-index: 2;
  margin-top: calc(-220 / 1440 * 100vw);
  min-height: calc(-220 / 1440 * 100vw); }

.article-hero__intro-text {
  margin: 0;
  font-size: calc(18rem / 14);
  line-height: calc(36 / 24); }
  @media (min-width: 769px) {
    .article-hero__intro-text {
      font-size: calc(24rem / 14); } }

.article-hero__title--landing {
  margin-bottom: 0;
  font-family: "Rubik light";
  font-weight: 300; }
  @media (min-width: 767px) {
    .article-hero__title--landing {
      font-size: calc(50rem / 14); } }

.article-hero--landing__framed {
  margin-bottom: 0; }
  @media (max-width: 767px) {
    .article-hero--landing__framed {
      font-size: calc(40rem / 14); } }

.u-pull-up {
  margin-top: calc(-220 / 1440 * 100vw); }

.paragraph-heading {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: calc( 50 / 1440 * 100vw); }

.paragraph-heading__title {
  margin: 0;
  font-size: calc(24rem / 14); }
  @media (min-width: 769px) {
    .paragraph-heading__title {
      font-size: calc(30rem / 14); } }

.paragraph-heading__subtitle {
  display: block;
  margin-bottom: 0.5rem;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: calc(10rem / 14); }
  @media (min-width: 769px) {
    .paragraph-heading__subtitle {
      margin-bottom: 1rem;
      font-size: calc(12rem / 14); } }

.text-image.grid {
  padding-left: 0;
  padding-right: 0;
  align-items: center; }
  @media (min-width: 769px) {
    .text-image.grid {
      grid-auto-flow: dense; } }
  @media (min-width: 769px) {
    .text-image.grid.reversed .text-image__text {
      grid-column: 7 / 12; } }
  @media (min-width: 769px) {
    .text-image.grid.reversed .text-image__img {
      grid-column: 2 / 6; } }

.text-image__text {
  grid-column: 2 / -2;
  /* WYSIWYG items */ }
  @media (min-width: 769px) {
    .text-image__text {
      grid-column: 2 / 7;
      margin-bottom: 0; } }
  .text-image__text p {
    line-height: calc(28 / 16); }
    @media (min-width: 769px) {
      .text-image__text p {
        font-size: calc(16rem / 14); } }
  .text-image__text a {
    font-size: inherit; }
  .text-image__text .link-button:last-child {
    margin-bottom: 0; }

.text-image__img {
  margin: 0;
  grid-column: 2 / -2; }
  @media (min-width: 769px) {
    .text-image__img {
      grid-column: 8 / 12; } }
  .text-image__img img {
    max-width: 100%;
    display: block;
    width: 100%; }

/* Inline version*/
.text-image.grid.text-image--inline .text-image__text {
  grid-column: 1 / -1; }
  @media (min-width: 769px) {
    .text-image.grid.text-image--inline .text-image__text {
      grid-column: 7 / 13; } }

.text-image.grid.text-image--inline .text-image__img {
  grid-column: 1 / -1; }
  @media (min-width: 769px) {
    .text-image.grid.text-image--inline .text-image__img {
      grid-column: 1 / 6; } }

.text-image.grid.text-image--inline.base-grid, .page-header .text-image.grid.text-image--inline.header.content, .text-image.grid.text-image--inline.footer, body .text-image.grid.text-image--inline.yotpo-container, .text-image.grid.text-image--inline.home-grid, .catalog-product-view .text-image.grid.text-image--inline.product-top-wrapper, .catalog-product-view .text-image.grid.text-image--inline.product-details-wrapper, .catalog-category-view .text-image.grid.text-image--inline.columns, .hyvareactcheckout-reactcheckout-index .text-image.grid.text-image--inline.page-main, .checkout-onepage-success .text-image.grid.text-image--inline.page-main,
.checkout-onepage-failure .text-image.grid.text-image--inline.page-main, .checkout-cart-index .text-image.grid.text-image--inline.page-main, .customer-account-login .text-image.grid.text-image--inline.page-main, .customer-account-create .text-image.grid.text-image--inline.columns, .catalogsearch-result-index .text-image.grid.text-image--inline.page-main, .customer-account-index .text-image.grid.text-image--inline.page-main, .sales-order-history .text-image.grid.text-image--inline.page-main, .sales-order-print .text-image.grid.text-image--inline.page-main,
.sales-order-invoice .text-image.grid.text-image--inline.page-main,
.sales-order-view .text-image.grid.text-image--inline.page-main, .customer-address-index .text-image.grid.text-image--inline.page-main, .customer-account-edit .text-image.grid.text-image--inline.page-main, .newsletter-manage-index .text-image.grid.text-image--inline.page-main, .customer-address-form .text-image.grid.text-image--inline.page-main, .customer-account-forgotpassword .text-image.grid.text-image--inline.page-main, .customer-account-createpassword .text-image.grid.text-image--inline.page-main, .customer-account-logoutsuccess .text-image.grid.text-image--inline.page-main, .cms-noroute-index .text-image.grid.text-image--inline.page-main, .cms-page-view .text-image.grid.text-image--inline.page-main {
  margin-top: 3em; }

.text-image--inline__main-title {
  font-size: calc(18rem / 14);
  line-height: calc(28 / 18);
  text-transform: none;
  letter-spacing: 0;
  font-family: "Noe book";
  margin: 20px 0;
  font-weight: 300; }
  @media (min-width: 769px) {
    .text-image--inline__main-title {
      margin: 0 0 calc(35 / 1440 * 100vw) 0; } }
  @media (min-width: 1440px) {
    .text-image--inline__main-title {
      margin-bottom: 35px; } }

.quote {
  padding-left: 0;
  padding-right: 0;
  margin: 0 auto; }

.quote__text {
  position: relative;
  display: flex;
  font-family: "Noe semibold";
  font-weight: 400;
  font-style: italic;
  font-size: calc(32rem / 14);
  line-height: calc(60 / 50);
  grid-column: 2 / -2;
  margin-bottom: calc(45 / 1440 * 100vw); }
  @media (min-width: 769px) {
    .quote__text {
      font-size: calc(50rem / 14);
      grid-column: 2 / 9; } }
  @media (min-width: 1440px) {
    .quote__text {
      margin-bottom: 45px; } }
  .quote__text:before {
    content: '“';
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%) translateX(-5px); }

.quote__author {
  grid-column: 2 / 9;
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: calc(25 / 14);
  font-style: normal; }
  .quote__author:before {
    height: 2px;
    width: 30px;
    background-color: black;
    margin-right: 30px;
    content: ''; }

.image-quote {
  padding: calc(175 / 1440 * 100vmax) 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  position: relative; }
  .image-quote:before {
    content: '';
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

.image-quote__inner {
  padding-left: 0;
  padding-right: 0;
  position: relative;
  z-index: 2; }

.image-quote__quote {
  grid-column: 2 / -2; }
  @media (min-width: 769px) {
    .image-quote__quote {
      grid-column: 2 / 8; } }

.image-quote .quote__author:before {
  background-color: white; }

.image-grid {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: calc(150 / 1440 * 110vw); }
  @media (min-width: 500px) {
    .image-grid {
      margin-bottom: calc(-110 / 1440 * 110vw); } }
  @media (min-width: 1440px) {
    .image-grid {
      margin-bottom: -110px; } }

.image-grid__item {
  margin: 0 0 calc(150 / 1440 * 110vw) 0;
  grid-column: 2 / -2; }
  @media (min-width: 500px) {
    .image-grid__item {
      margin-bottom: calc(110 / 1440 * 110vw); } }
  @media (min-width: 1440px) {
    .image-grid__item {
      margin-bottom: 110px; } }

.image-grid__caption {
  display: block;
  font-size: 1rem;
  line-height: calc(22 / 14);
  margin-top: calc(24rem / 14);
  position: relative;
  margin-left: calc(30rem / 14);
  margin-right: calc(30rem / 14);
  font-family: "Rubik light"; }
  .image-grid__caption:before {
    content: '';
    width: 2px;
    height: 30px;
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%) translateY(-7px); }

.image-grid__image {
  max-width: 100%;
  display: block; }

/* Different sizes */
@media (min-width: 769px) {
  .image-grid__item:nth-child(4n + 1) {
    grid-column: 2 / 5;
    align-self: end; } }

@media (min-width: 769px) {
  .image-grid__item:nth-child(4n + 2) {
    grid-column: 6 / 12; } }

@media (min-width: 769px) {
  .image-grid__item:nth-child(4n + 3) {
    grid-column: 2 / 6;
    align-self: center; } }

@media (min-width: 769px) {
  .image-grid__item:nth-child(4n + 4) {
    grid-column: 7 / 12;
    align-self: center; } }

.article-step {
  padding: calc(110 / 1440 * 100vw) 0;
  color: white;
  background: #868b7c; }

.article-step__title {
  color: white;
  margin-bottom: calc(70 / 1440 * 100vw); }
  @media (min-width: 1440px) {
    .article-step__title {
      margin-bottom: 70px; } }

.article-step__img {
  margin-bottom: calc(40rem / 14); }
  @media (min-width: 769px) {
    .article-step__img {
      margin-bottom: 0; } }

.article-next {
  padding: calc(80 / 1440 * 100vw) 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  margin-bottom: 0; }
  .article-next:after {
    display: none; }
  .article-next:before {
    background-color: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

.article-next__inner {
  padding-left: 0;
  padding-right: 0;
  position: relative;
  z-index: 2; }

.article-next__heading {
  color: white; }

.article-next__subtitle {
  font-family: "Rubik medium"; }

.article-next__title {
  color: white;
  font-size: calc(24rem / 14); }
  @media (min-width: 769px) {
    .article-next__title {
      font-size: calc(50rem / 14); } }

.visual-blocks {
  background-size: 100%;
  padding-top: calc(120 / 1440 * 100vw);
  padding-bottom: calc(120 / 1440 * 100vw);
  position: relative; }
  .visual-blocks:before {
    background-color: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    content: ''; }

.visual-blocks__inner {
  position: relative;
  z-index: 2; }

.visual-blocks__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px; }
  @media (min-width: 769px) {
    .visual-blocks__header {
      margin-bottom: calc(50 / 1440 * 100vw); } }
  @media (min-width: 1440px) {
    .visual-blocks__header {
      margin-bottom: 50px; } }

.visual-blocks__title {
  color: white;
  margin: 0 20px 0 0;
  font-size: calc(24rem / 14);
  line-height: calc(40 / 30);
  font-family: "Noe semibold";
  flex: 1; }
  @media (min-width: 769px) {
    .visual-blocks__title {
      font-size: calc(30rem / 14); } }

.visual-blocks__more-link {
  margin: 0;
  color: white; }

.blog-yt {
  padding-left: 0;
  padding-right: 0; }

.blog-yt__inner {
  padding-bottom: 56.25%;
  position: relative; }
  .blog-yt__inner iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.cms-home .column.main {
  padding-bottom: 3.92rem; }
  @media (min-width: 1024px) {
    .cms-home .column.main {
      padding-bottom: 4.57rem; } }

.cms-home .products-grid .product-item {
  grid-column: auto / span 1; }

.cms-home .page-header__svg {
  fill: white; }

.cms-home .block-products-list,
.cms-home .products-grid {
  display: block; }

.cms-home .product-item-info {
  width: 100%; }

@media (max-width: 767px) {
  .catalog-product-view .page-header {
    z-index: 12; } }

@media (max-width: 767px) {
  .catalog-product-view .page-main {
    z-index: 11; } }

.catalog-product-view #launcher {
  display: none; }

@media (min-width: 1024px) {
  .catalog-product-view #launcher {
    display: block; } }

.catalog-product-view .page-header__svg {
  fill: black; }

@media (max-width: 1025px) {
  .catalog-product-view .product-top-wrapper {
    padding-left: 0;
    padding-right: 0; } }

@media (min-width: 1024px) {
  .catalog-product-view .product-top-wrapper {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    max-width: none; } }

.catalog-product-view .product.media {
  position: relative; }
  @media (min-width: 1024px) {
    .catalog-product-view .product.media {
      align-self: flex-start;
      position: sticky;
      top: 5.5rem; } }
  @media (min-width: 1026px) {
    .catalog-product-view .product.media {
      transform: translateX(-4.2rem); } }
  .catalog-product-view .product.media:before,
  .catalog-product-view .product.media .product__js-bg {
    height: 100%;
    max-height: 625px !important;
    content: '';
    top: 0;
    position: absolute;
    background: #c99e9f;
    left: 0;
    width: 100%; }
    @media (min-width: 1024px) {
      .catalog-product-view .product.media:before,
      .catalog-product-view .product.media .product__js-bg {
        left: -1rem;
        width: calc(100% + 1rem); } }
  @media (min-width: 1024px) {
    .catalog-product-view .product.media {
      grid-column: auto / span 7;
      left: -4.2rem;
      width: calc(100% + 4.2rem); } }

@media (min-width: 1024px) {
  .catalog-product-view .product-info-wrapper {
    padding: 25px 0;
    display: flex;
    align-items: center;
    grid-column: 9 / 13; } }

@media (min-width: 1024px) {
  .catalog-product-view .product.description {
    grid-column: auto / span 6; } }

.catalog-product-view .product-details-wrapper {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  overflow: hidden; }
  @media (min-width: 1024px) {
    .catalog-product-view .product-details-wrapper {
      padding-top: calc(120 / 14 * 1rem); } }
  .catalog-product-view .product-details-wrapper > * {
    grid-column: 1 / -1; }

.catalog-product-view .product.info.detailed {
  margin-bottom: calc(60 / 14 * 1rem); }
  @media (min-width: 1024px) {
    .catalog-product-view .product.info.detailed {
      margin-bottom: calc(110 / 1440 * 100vw);
      grid-column: 1 / 8; } }

.catalog-product-view .guarantee-list {
  padding-left: 0;
  margin-bottom: calc(60 / 14 * 1rem); }
  @media (min-width: 1024px) {
    .catalog-product-view .guarantee-list {
      margin-bottom: calc(110 / 1440 * 100vw);
      grid-column: 9 / -1;
      padding-top: calc(80 / 14 * 1rem); } }

.catalog-product-view .yotpo-container {
  max-width: none;
  padding: 0;
  display: none; }
  @media (min-width: 1024px) {
    .catalog-product-view .yotpo-container {
      display: block;
      margin: 0 0 calc(120 / 14 * 1rem); } }

.catalog-product-view .yotpo-reviews-carousel .carousel-display-wrapper #carousel-top-panel .headline {
  font-size: calc(30 / 14 * 1rem);
  line-height: calc(40 / 30);
  margin-bottom: calc(60 / 14 * 1rem);
  font-weight: 600; }

.catalog-product-view .product-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }
  @media (min-width: 769px) {
    .catalog-product-view .product-grid {
      grid-template-rows: 1fr 1fr; } }

.catalog-product-view .steps {
  grid-column: auto / span 12; }
  @media (min-width: 769px) {
    .catalog-product-view .steps {
      grid-row: span 2;
      grid-column: auto / span 6; } }
  .catalog-product-view .steps .steps__link {
    display: none; }

@media (min-width: 1550px) {
  .catalog-product-view .steps__container {
    padding-left: calc((100vw - 110.76923rem) / 2 + 4.2rem); } }

@media (min-width: 1550px) {
  .catalog-product-view .extra-info {
    padding-right: calc((100vw - 110.76923rem) / 2 + 4.2rem); } }

.catalog-category-view .pages {
  padding: 2.85rem 0 2.14rem 0; }
  @media (min-width: 768px) {
    .catalog-category-view .pages {
      padding: 4.28rem 0; } }

.catalog-category-view .message.info.empty {
  color: white;
  font-family: "Rubik light";
  font-size: 24px;
  font-weight: 300;
  line-height: 1.5;
  padding: 8rem 0; }

.catalog-category-view .page-main.filter-active {
  z-index: 12; }

.catalog-category-view .page-header__svg {
  fill: white; }

.catalog-category-view .products-grid {
  display: block; }

.hyvareactcheckout-reactcheckout-index .country svg {
  height: 14px !important;
  margin-right: -10px; }

.hyvareactcheckout-reactcheckout-index h3 {
  font-family: "Noe semibold";
  font-size: 1.71rem;
  letter-spacing: normal;
  text-transform: none; }

.hyvareactcheckout-reactcheckout-index div[class$="-control"] {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid black; }

.hyvareactcheckout-reactcheckout-index div[class$="-control"] div:first-child {
  padding: 2px 0; }

.hyvareactcheckout-reactcheckout-index #shipping_method .disabled-method .title:after {
  color: #fff; }

.hyvareactcheckout-reactcheckout-index #shipping_method div[class$="-control"], .hyvareactcheckout-reactcheckout-index #shipping_address .mt-2.form-control, .hyvareactcheckout-reactcheckout-index #payment_method .mt-2.form-control {
  border-bottom: none; }

.hyvareactcheckout-reactcheckout-index span[class$="-indicatorSeparator"] {
  display: none; }

.hyvareactcheckout-reactcheckout-index div[class$="-indicatorContainer"] svg {
  fill: black; }

.hyvareactcheckout-reactcheckout-index .btn {
  border: none;
  height: 3.9rem; }

.hyvareactcheckout-reactcheckout-index [type='checkbox'], .hyvareactcheckout-reactcheckout-index [type='radio'] {
  color: #000; }

.hyvareactcheckout-reactcheckout-index .page-footer {
  z-index: auto; }

.hyvareactcheckout-reactcheckout-index #overview .hidden .pb-2 {
  border-color: #f5f5ef; }

@media (min-width: 1024px) {
  .hyvareactcheckout-reactcheckout-index .logo.page-header__logo {
    margin-top: -8px; } }

.hyvareactcheckout-reactcheckout-index #payment_method .disabled-method .title {
  color: #9B9B9B; }
  .hyvareactcheckout-reactcheckout-index #payment_method .disabled-method .title:after {
    color: #fff; }
  .hyvareactcheckout-reactcheckout-index #payment_method .disabled-method .title:before {
    background-color: #9B9B9B; }

.hyvareactcheckout-reactcheckout-index #payment_method .disabled-method .py-4 {
  display: none; }

@media (min-width: 1024px) {
  .hyvareactcheckout-reactcheckout-index #payment_method .py-4 {
    padding-left: 42px; } }

.hyvareactcheckout-reactcheckout-index #coupon_code input[name=text] {
  border-color: #000; }

.hyvareactcheckout-reactcheckout-index #coupon_code input[name=text] ~ .feedback:after {
  display: none; }

@media (min-width: 1024px) {
  .hyvareactcheckout-reactcheckout-index #login .my-4 {
    padding-left: 42px; } }

.hyvareactcheckout-reactcheckout-index .nav-toggle, .hyvareactcheckout-reactcheckout-index .mobile-header-cart {
  display: none; }

.hyvareactcheckout-reactcheckout-index .page-title-wrapper {
  display: block; }

@media (min-width: 1024px) {
  .hyvareactcheckout-reactcheckout-index .checkout-container {
    display: flex;
    flex-wrap: wrap; } }

.hyvareactcheckout-reactcheckout-index ol,
.hyvareactcheckout-reactcheckout-index ul {
  list-style: none;
  padding: 0;
  margin: 0; }

.hyvareactcheckout-reactcheckout-index fieldset {
  border: none;
  margin: 0;
  padding: 0; }

.hyvareactcheckout-reactcheckout-index .checkout-shipping-address .select,
.hyvareactcheckout-reactcheckout-index .checkout-shipping-address .input-text {
  padding: 0.5rem;
  width: 100%; }

.hyvareactcheckout-reactcheckout-index .authentication-wrapper,
.hyvareactcheckout-reactcheckout-index .opc-estimated-wrapper {
  flex: 100%; }

.hyvareactcheckout-reactcheckout-index .field {
  margin-bottom: 1rem; }
  .hyvareactcheckout-reactcheckout-index .field .field-tooltip {
    background: #f5f5ef;
    padding: 1rem;
    display: inline-block; }
    .hyvareactcheckout-reactcheckout-index .field .field-tooltip #tooltip-label.label {
      font-weight: bold; }
  .hyvareactcheckout-reactcheckout-index .field span.note {
    display: block;
    padding: 1rem; }

.hyvareactcheckout-reactcheckout-index p {
  color: #262626; }

.hyvareactcheckout-reactcheckout-index label {
  display: block;
  margin-bottom: 0.5rem;
  letter-spacing: 0.25px; }

.hyvareactcheckout-reactcheckout-index .bg-custom-gray-6 {
  background-color: #F5F5F0; }

.hyvareactcheckout-reactcheckout-index #shipping_address label, .hyvareactcheckout-reactcheckout-index #login label:not(.custom-radio-container) {
  font-family: "Rubik medium";
  color: #262626; }

.hyvareactcheckout-reactcheckout-index .custom-radio-container, .hyvareactcheckout-reactcheckout-index .product-info-price .product-options-wrapper .field.choice, .product-info-price .product-options-wrapper .hyvareactcheckout-reactcheckout-index .field.choice {
  margin-bottom: 0;
  color: #262626; }

.hyvareactcheckout-reactcheckout-index .field.street.required span:after,
.hyvareactcheckout-reactcheckout-index .field._required .label:after {
  content: '*';
  font-size: 1rem; }

.hyvareactcheckout-reactcheckout-index .street .field {
  margin-bottom: 0; }
  .hyvareactcheckout-reactcheckout-index .street .field .input-text {
    margin-bottom: 0.5rem; }

.hyvareactcheckout-reactcheckout-index .street.field.required > .label > span {
  display: none; }

.hyvareactcheckout-reactcheckout-index .street .field._required .label:after {
  display: none; }

.hyvareactcheckout-reactcheckout-index .step-title {
  font-family: "Noe semibold";
  font-size: 1.71rem;
  font-weight: 600;
  line-height: 1.2;
  color: #262626;
  margin-bottom: 1.785rem; }

.hyvareactcheckout-reactcheckout-index .authentication-dropdown {
  display: none; }
  .hyvareactcheckout-reactcheckout-index .authentication-dropdown._show {
    display: block; }

.hyvareactcheckout-reactcheckout-index .opc-progress-bar,
.hyvareactcheckout-reactcheckout-index .estimated-block {
  margin-bottom: 3.57rem; }

.hyvareactcheckout-reactcheckout-index .estimated-label,
.hyvareactcheckout-reactcheckout-index .estimated-price {
  font-family: "Noe book";
  font-size: 19px;
  font-weight: 300;
  line-height: 1.2; }

.hyvareactcheckout-reactcheckout-index .minicart-wrapper {
  display: none; }

.hyvareactcheckout-reactcheckout-index .table-checkout-shipping-method {
  width: 100%;
  margin-bottom: 3.57rem; }
  .hyvareactcheckout-reactcheckout-index .table-checkout-shipping-method thead {
    display: none; }
  .hyvareactcheckout-reactcheckout-index .table-checkout-shipping-method .col-price {
    width: 150px; }
  .hyvareactcheckout-reactcheckout-index .table-checkout-shipping-method .custom-radio, .hyvareactcheckout-reactcheckout-index .table-checkout-shipping-method .product-info-price .product-options-wrapper .field.choice input[type='radio'] + .label, .product-info-price .product-options-wrapper .field.choice .hyvareactcheckout-reactcheckout-index .table-checkout-shipping-method input[type='radio'] + .label {
    margin-right: 1rem; }
  .hyvareactcheckout-reactcheckout-index .table-checkout-shipping-method .col-method {
    width: 1.42rem; }
  .hyvareactcheckout-reactcheckout-index .table-checkout-shipping-method .row {
    cursor: pointer; }

.hyvareactcheckout-reactcheckout-index .custom-radio:before, .hyvareactcheckout-reactcheckout-index .product-info-price .product-options-wrapper .field.choice input[type='radio'] + .label:before, .product-info-price .product-options-wrapper .field.choice .hyvareactcheckout-reactcheckout-index input[type='radio'] + .label:before {
  border: 2px solid #000; }

@media (min-width: 768px) {
  .hyvareactcheckout-reactcheckout-index .shipping-address-items {
    display: flex;
    flex-wrap: wrap; } }

.hyvareactcheckout-reactcheckout-index .shipping-address-item {
  border: 2px solid #f8f5ed;
  padding: 1rem; }
  @media (min-width: 768px) {
    .hyvareactcheckout-reactcheckout-index .shipping-address-item {
      width: 50%;
      padding-right: 1.4rem;
      padding-bottom: 1.4rem; } }
  .hyvareactcheckout-reactcheckout-index .shipping-address-item.selected-item {
    border-color: #feac00; }

.hyvareactcheckout-reactcheckout-index .action-select-shipping-item {
  margin-top: 1rem; }

.hyvareactcheckout-reactcheckout-index .opc-wrapper {
  margin-bottom: 3.57rem; }
  @media (min-width: 1024px) {
    .hyvareactcheckout-reactcheckout-index .opc-wrapper {
      flex: 60%;
      padding-right: 8.333%; } }

.hyvareactcheckout-reactcheckout-index .opc-summary-wrapper .modal-header {
  display: none; }

@media (min-width: 1024px) {
  .hyvareactcheckout-reactcheckout-index .opc-summary-wrapper {
    flex: 40%; } }

.hyvareactcheckout-reactcheckout-index .items-in-cart .title {
  display: none; }

.hyvareactcheckout-reactcheckout-index .items-in-cart .minicart-items {
  display: flex !important; }

.hyvareactcheckout-reactcheckout-index .checkout-shipping-address {
  margin-bottom: 3.57rem; }
  @media (min-width: 768px) {
    .hyvareactcheckout-reactcheckout-index .checkout-shipping-address {
      padding-right: 8.333%; } }

.hyvareactcheckout-reactcheckout-index .messages, .hyvareactcheckout-reactcheckout-index .agreement-notice {
  flex-basis: 100%; }

.hyvareactcheckout-reactcheckout-index [data-role='email-with-possible-login'],
.hyvareactcheckout-reactcheckout-index .opc-payment,
.hyvareactcheckout-reactcheckout-index .items-in-cart,
.hyvareactcheckout-reactcheckout-index .table-totals,
.hyvareactcheckout-reactcheckout-index .checkout-billing-address {
  margin-bottom: 3.57rem; }

.hyvareactcheckout-reactcheckout-index .product-item-name {
  font-family: "Noe semibold";
  font-style: normal;
  text-transform: none;
  font-size: 1.375rem;
  letter-spacing: 0px; }

.hyvareactcheckout-reactcheckout-index .title {
  margin-bottom: 1.785rem;
  font-size: 24px !important;
  padding: 7px 0 5px 55px; }

.hyvareactcheckout-reactcheckout-index .action-edit-address {
  margin-top: 1.785rem; }

.hyvareactcheckout-reactcheckout-index .checkout-billing-address .custom-checkbox__text {
  line-height: 1.5; }

.hyvareactcheckout-reactcheckout-index .checkout-billing-address .custom-checkbox:before, .hyvareactcheckout-reactcheckout-index .checkout-billing-address .product-info-price .product-options-wrapper .field.choice input[type='checkbox'] + .label:before, .product-info-price .product-options-wrapper .field.choice .hyvareactcheckout-reactcheckout-index .checkout-billing-address input[type='checkbox'] + .label:before {
  border: 2px solid #000; }

.hyvareactcheckout-reactcheckout-index .select {
  background-color: transparent;
  width: auto; }

.hyvareactcheckout-reactcheckout-index .field-select-billing .label {
  font-family: "Rubik light"; }

@media (min-width: 768px) {
  .hyvareactcheckout-reactcheckout-index .shipping-information {
    display: flex; } }

.hyvareactcheckout-reactcheckout-index .shipping-information .action {
  margin: 0;
  padding: 0;
  background-color: transparent;
  font-family: "Rubik light";
  line-height: 1.5; }
  .hyvareactcheckout-reactcheckout-index .shipping-information .action:before {
    top: auto; }

.hyvareactcheckout-reactcheckout-index .shipping-information .shipping-information-title {
  font-family: "Rubik medium";
  font-size: 12px;
  font-weight: 500;
  line-height: 1.8;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #262626; }

@media (min-width: 768px) {
  .hyvareactcheckout-reactcheckout-index .shipping-information .ship-to,
  .hyvareactcheckout-reactcheckout-index .shipping-information .ship-via {
    flex-basis: 50%; } }

.hyvareactcheckout-reactcheckout-index .discount-code .payment-option-title:focus {
  outline: none;
  cursor: pointer; }

.hyvareactcheckout-reactcheckout-index .discount-code._active .action-toggle:after {
  transform: rotate(-90deg); }

.hyvareactcheckout-reactcheckout-index .discount-code .action-toggle {
  font-family: "Rubik medium";
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 0.5rem; }
  .hyvareactcheckout-reactcheckout-index .discount-code .action-toggle:after {
    content: '';
    display: inline-block;
    background-image: url("../images/icons/chevron-bold.svg");
    height: 9px;
    width: 5px;
    transform: rotate(90deg);
    margin-left: 0.78rem;
    transition: transform 250ms cubic-bezier(0.6, 0, 0.01, 0.9); }

.hyvareactcheckout-reactcheckout-index .item-options {
  background: #f5f5ef;
  padding: 1rem;
  margin-top: 2.14rem;
  width: calc(100% - 2rem);
  z-index: 2; }
  @media (min-width: 768px) {
    .hyvareactcheckout-reactcheckout-index .item-options {
      margin-top: 0.71rem;
      width: auto; } }
  .hyvareactcheckout-reactcheckout-index .item-options dt,
  .hyvareactcheckout-reactcheckout-index .item-options dd {
    font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0 0 5px 0; }
  .hyvareactcheckout-reactcheckout-index .item-options dt {
    font-weight: bold; }

.checkout-onepage-success .page-title-wrapper,
.checkout-onepage-failure .page-title-wrapper {
  display: block; }

@keyframes ProgressBar {
  from {
    width: 0; }
  to {
    width: 100%; } }

.checkout-onepage-success .page-main {
  padding-bottom: 3.92rem; }

.agreements-modal {
  display: none; }
  ._has-modal .agreements-modal {
    display: block; }
  .agreements-modal .checkout-agreements-item-content {
    height: 70vh;
    overflow-y: auto; }

.payment-method .payment-method-content {
  align-items: flex-start;
  flex-direction: column;
  padding: 0 4px;
  width: 100%; }

.payment-method .payment-method-title {
  width: 45%; }

.payment-method._active .payment-method-content {
  margin-top: 1rem; }
  .payment-method._active .payment-method-content .select {
    align-self: flex-start; }
  .payment-method._active .payment-method-content .checkout-agreements-block .checkout-agreements .checkout-agreement {
    margin: 1rem 0; }
    .payment-method._active .payment-method-content .checkout-agreements-block .checkout-agreements .checkout-agreement button {
      cursor: pointer;
      background: white;
      border: none;
      color: #000;
      text-decoration: none;
      position: relative;
      display: inline-block;
      padding: 0.72rem 0.45rem;
      margin: 0; }
      .payment-method._active .payment-method-content .checkout-agreements-block .checkout-agreements .checkout-agreement button:before {
        display: none; }
      .payment-method._active .payment-method-content .checkout-agreements-block .checkout-agreements .checkout-agreement button:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        height: 2px;
        width: 100%;
        background: #feac00;
        right: 0;
        -webkit-transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99) 100ms;
        transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99) 100ms; }
      .payment-method._active .payment-method-content .checkout-agreements-block .checkout-agreements .checkout-agreement button:hover:after {
        width: 0;
        -webkit-transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99);
        transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99); }

.payment-method._active .actions-toolbar .primary button, .payment-method._active .actions-toolbar .cookie-opt-out .action button, .cookie-opt-out .payment-method._active .actions-toolbar .action button {
  box-sizing: border-box;
  padding: 1.21rem 1.43rem;
  border: none;
  z-index: 1;
  position: relative;
  cursor: pointer;
  background-color: #feac00;
  color: #000;
  font-family: 'Rubik medium'; }
  .payment-method._active .actions-toolbar .primary button:before, .payment-method._active .actions-toolbar .cookie-opt-out .action button:before, .cookie-opt-out .payment-method._active .actions-toolbar .action button:before {
    content: '';
    background: #f5f5ef;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    -webkit-transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99) 200ms;
    transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99) 200ms;
    z-index: -1; }
  .payment-method._active .actions-toolbar .primary button:hover:before, .payment-method._active .actions-toolbar .cookie-opt-out .action button:hover:before, .cookie-opt-out .payment-method._active .actions-toolbar .action button:hover:before {
    width: 100%;
    left: 0; }
  .payment-method._active .actions-toolbar .primary button:after, .payment-method._active .actions-toolbar .cookie-opt-out .action button:after, .cookie-opt-out .payment-method._active .actions-toolbar .action button:after {
    display: none; }

.checkout-cart-index .loading-mask {
  position: fixed;
  top: 0;
  right: 0;
  background: #fff;
  z-index: 99;
  width: 100%;
  height: 100%; }
  @media (min-width: 1024px) {
    .checkout-cart-index .loading-mask {
      width: 40%; } }

.checkout-cart-index .loader {
  display: block;
  position: absolute;
  top: 50%;
  width: 235px;
  height: 64px;
  left: 50%;
  transform: translate(-50%, -50%); }

.checkout-cart-index .discount {
  margin-bottom: 3.92rem; }

.checkout-cart-index .cart-container {
  margin-bottom: 3.92rem; }
  @media (min-width: 1024px) {
    .checkout-cart-index .cart-container {
      display: flex;
      flex-wrap: wrap; } }

@media (min-width: 768px) {
  .checkout-cart-index .cart-summary,
  .checkout-cart-index .cart.items {
    width: 100%; } }

@media (min-width: 1024px) {
  .checkout-cart-index .cart-summary,
  .checkout-cart-index .cart.items {
    max-width: inherit; } }

.checkout-cart-index .cart-summary > .item,
.checkout-cart-index .cart.items > .item {
  display: block;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: 2px solid #f5f5ef; }
  @media (min-width: 768px) {
    .checkout-cart-index .cart-summary > .item,
    .checkout-cart-index .cart.items > .item {
      display: flex;
      align-items: center; } }

@media (min-width: 1024px) {
  .checkout-cart-index .cart-summary {
    flex-basis: 40%;
    padding-left: 4.1665%; } }

.checkout-cart-index .table-caption {
  text-align: left;
  font-family: "Rubik light";
  font-size: 24px;
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 2.14rem; }

.checkout-cart-index #block-shipping {
  display: none; }

.checkout-cart-index .page-title-wrapper {
  display: block; }

.checkout-cart-index .discount .title {
  font-family: "Rubik medium";
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
  cursor: pointer; }
  .checkout-cart-index .discount .title:focus {
    outline: none; }

.checkout-cart-index .discount .chevron-expand {
  margin-left: 0.78rem;
  transform: rotate(90deg);
  transition: transform 250ms cubic-bezier(0.6, 0, 0.01, 0.9); }

.checkout-cart-index .discount.active .chevron-expand {
  transform: rotate(-90deg); }

.checkout-cart-index .table-wrapper {
  overflow: auto;
  margin-bottom: 3.92rem; }
  .checkout-cart-index .table-wrapper.cart {
    max-width: inherit; }
  @media (min-width: 768px) {
    .checkout-cart-index .table-wrapper {
      overflow: initial; } }

.checkout-cart-index .cart.actions {
  display: block; }
  @media (min-width: 768px) {
    .checkout-cart-index .cart.actions {
      display: block; } }
  .checkout-cart-index .cart.actions .action,
  .checkout-cart-index .cart.actions .update {
    align-self: flex-start; }

.checkout-cart-index .label,
.checkout-cart-index .cart-totals {
  margin-bottom: 3.92rem;
  font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.8;
  margin-bottom: 25px; }
  .checkout-cart-index .label .table-caption,
  .checkout-cart-index .cart-totals .table-caption {
    font-family: "Noe semibold"; }
  .checkout-cart-index .label .table,
  .checkout-cart-index .cart-totals .table {
    width: 100%; }
  .checkout-cart-index .label .mark,
  .checkout-cart-index .cart-totals .mark {
    text-align: left;
    font-weight: 300; }
    .checkout-cart-index .label .mark .label,
    .checkout-cart-index .cart-totals .mark .label {
      font-weight: inherit; }

.checkout-cart-index .product-item-name a {
  font-family: "Noe semibold";
  font-style: normal;
  text-transform: none;
  font-size: 1.375rem;
  letter-spacing: 0px; }
  .checkout-cart-index .product-item-name a:after, .checkout-cart-index .product-item-name a:before {
    display: none; }

.checkout-cart-index .form-cart td span {
  font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.8; }

.checkout-cart-index .form-cart .label,
.checkout-cart-index .form-cart thead span {
  font-family: "Rubik medium";
  font-size: 12px;
  font-weight: 500;
  line-height: 1.8;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #262626; }

.checkout-cart-index .form-cart .col {
  text-align: left;
  font-size: 12px;
  width: 25%; }

@media (min-width: 1024px) {
  .checkout-cart-index .form-cart {
    flex-basis: 60%;
    padding-right: 4.1665%; } }

.checkout-cart-index .checkout-methods-items {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end; }
  @media (min-width: 768px) {
    .checkout-cart-index .checkout-methods-items {
      justify-content: flex-start; }
      .checkout-cart-index .checkout-methods-items .checkout {
        position: static; } }

@media (min-width: 768px) {
  .checkout-cart-index .product-item-photo {
    margin-right: 1rem; } }

.checkout-cart-index .item-info {
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 768px) {
    .checkout-cart-index .item-info {
      align-items: center;
      padding-top: 1.5em;
      padding-bottom: 1.5em;
      width: 100%; } }
  .checkout-cart-index .item-info .col {
    padding: 0 1rem 0 0; }
    @media (min-width: 768px) {
      .checkout-cart-index .item-info .col {
        padding: 0 2rem 0 0; } }
    .checkout-cart-index .item-info .col:last-child {
      padding: 0; }
  .checkout-cart-index .item-info .item {
    flex-basis: 100%;
    margin-bottom: 1.78rem; }
    @media (min-width: 768px) {
      .checkout-cart-index .item-info .item {
        flex-basis: 40%;
        margin-bottom: 0;
        display: flex;
        align-items: center; } }
    @media (min-width: 768px) {
      .checkout-cart-index .item-info .item img {
        margin: 0; } }
    .checkout-cart-index .item-info .item .product-item-details {
      display: flex;
      justify-content: space-between; }
      @media (min-width: 768px) {
        .checkout-cart-index .item-info .item .product-item-details {
          display: block;
          margin: 0; } }
    @media (min-width: 768px) {
      .checkout-cart-index .item-info .item .product-item-details,
      .checkout-cart-index .item-info .item .product-item-photo {
        flex-basis: 50%;
        margin-bottom: 0; } }
  .checkout-cart-index .item-info .col.price,
  .checkout-cart-index .item-info .col.qty,
  .checkout-cart-index .item-info .col.subtotal {
    flex-basis: 33.333%; }
    .checkout-cart-index .item-info .col.price span,
    .checkout-cart-index .item-info .col.qty span,
    .checkout-cart-index .item-info .col.subtotal span {
      display: block; }
    @media (min-width: 768px) {
      .checkout-cart-index .item-info .col.price,
      .checkout-cart-index .item-info .col.qty,
      .checkout-cart-index .item-info .col.subtotal {
        flex-basis: 20%; }
        .checkout-cart-index .item-info .col.price .label,
        .checkout-cart-index .item-info .col.qty .label,
        .checkout-cart-index .item-info .col.subtotal .label {
          display: none; } }
  .checkout-cart-index .item-info .qty {
    padding-top: 0;
    padding-bottom: 0;
    margin: 0; }
  .checkout-cart-index .item-info .product-item-details {
    height: 2.85rem; }
  @media (min-width: 1400px) {
    .checkout-cart-index .item-info .product-image-photo {
      max-width: 100%; } }

.checkout-cart-index .item-actions {
  text-align: right; }
  @media (max-width: 787px) {
    .checkout-cart-index .item-actions {
      display: block; } }
  @media (max-width: 787px) {
    .checkout-cart-index .item-actions td {
      margin-left: auto;
      display: block;
      margin-top: -29px; } }

.checkout-cart-index thead {
  display: none;
  border-bottom: 2px solid #f5f5ef; }
  @media (min-width: 768px) {
    .checkout-cart-index thead {
      display: block;
      padding-right: 1.42rem;
      padding-bottom: 1rem; } }
  @media (min-width: 768px) {
    .checkout-cart-index thead tr {
      display: flex; } }
  @media (min-width: 768px) {
    .checkout-cart-index thead th {
      flex-basis: 20%; } }
  @media (min-width: 768px) {
    .checkout-cart-index thead th.item {
      flex-basis: 40%; } }

.checkout-cart-index .action-delete:before, .checkout-cart-index .action-delete:after {
  display: none; }

.checkout-cart-index .action-delete img {
  height: 1rem; }

.checkout-cart-index .continue {
  margin-bottom: 1.96rem; }

.checkout-cart-index .update {
  border: none;
  padding: 0;
  background-color: transparent;
  line-height: 1.5; }
  @media (min-width: 768px) {
    .checkout-cart-index .update {
      margin-left: 1rem; } }
  .checkout-cart-index .update:before {
    top: auto;
    height: 2px; }

.checkout-cart-index .item-options {
  position: absolute;
  display: none;
  background: #f5f5ef;
  padding: 1rem;
  margin-top: 2.14rem;
  width: calc(100% - 2rem);
  z-index: 2; }
  @media (min-width: 768px) {
    .checkout-cart-index .item-options {
      margin-top: 0.71rem;
      width: auto; } }
  .checkout-cart-index .item-options dt,
  .checkout-cart-index .item-options dd {
    font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0 0 5px 0; }
  .checkout-cart-index .item-options dt {
    font-weight: bold; }

.checkout-cart-index .toggle-item-options {
  display: block;
  position: relative; }
  .checkout-cart-index .toggle-item-options:focus {
    outline: none; }
  .checkout-cart-index .toggle-item-options:after {
    content: '';
    display: inline-block;
    background-image: url("../images/icons/chevron-bold.svg");
    background-repeat: no-repeat;
    background-position: center;
    height: 0.71rem;
    width: 0.71rem;
    transform: rotate(90deg);
    transition: transform 250ms cubic-bezier(0.6, 0, 0.01, 0.9);
    margin-left: 0.78rem; }
  .checkout-cart-index .toggle-item-options.active:after {
    transform: rotate(-90deg); }

.checkout-cart-index .toggle-item-options.active ~ .item-options {
  display: block; }

.customer-account-login .page-main {
  padding-top: 2.1rem; }

.customer-account-login .login-container {
  display: flex;
  flex-wrap: wrap; }

.customer-account-login .block-customer-login,
.customer-account-login .block-new-customer {
  flex-basis: 100%;
  margin-bottom: 3.92rem; }
  @media (min-width: 768px) {
    .customer-account-login .block-customer-login,
    .customer-account-login .block-new-customer {
      flex-basis: 50%;
      padding-right: 8.33%; } }

.customer-account-login fieldset {
  border: none;
  margin: 0;
  padding: 0; }

.customer-account-login .block-title {
  font-family: "Noe semibold";
  font-size: 1.71rem;
  font-weight: 600;
  line-height: 1.2;
  color: #262626; }

.customer-account-login .label,
.customer-account-login .custom-checkbox__text,
.customer-account-login .note {
  font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.8; }

.customer-account-login .custom-checkbox__text {
  font-style: normal;
  line-height: 1.5; }

.customer-account-login .note {
  margin-bottom: 2rem; }

.customer-account-login .persistent {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1.78rem; }

.customer-account-login .tooltip.wrapper {
  margin-left: 1rem;
  margin-bottom: -0.15rem; }

.customer-account-login .actions-toolbar .login {
  margin-bottom: 1.78rem; }

.customer-account-login .custom-checkbox:before, .customer-account-login .product-info-price .product-options-wrapper .field.choice input[type='checkbox'] + .label:before, .product-info-price .product-options-wrapper .field.choice .customer-account-login input[type='checkbox'] + .label:before {
  border: 2px solid grey; }

.customer-account-login .field:not(.search) {
  margin-bottom: 4rem; }

.customer-account-login .no-margin-bottom:not(.search) {
  margin-bottom: 0; }

.tooltip.remember-me__tooltip {
  display: inline-block;
  background: #feac00;
  padding: 0.3rem;
  color: #000;
  font-style: normal;
  line-height: 1; }
  .tooltip.remember-me__tooltip:before, .tooltip.remember-me__tooltip:after {
    display: none; }

.customer-account-create .columns {
  padding-top: 2.1rem; }

.customer-account-create .form-create-account {
  display: flex;
  flex-direction: column; }

.customer-account-create fieldset {
  flex-basis: 100%;
  border: none;
  margin: 0;
  padding: 0;
  margin-bottom: 3.92rem; }
  @media (min-width: 768px) {
    .customer-account-create fieldset {
      max-width: 50%; } }

.customer-account-create .password-strength-meter,
.customer-account-create .mage-error,
.customer-account-create .label,
.customer-account-create .custom-checkbox__text {
  font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.8; }

.customer-account-create .input-text {
  font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.customer-account-create .legend {
  font-family: "Noe semibold";
  font-size: 1.71rem;
  font-weight: 600;
  line-height: 1.2;
  color: #262626; }

.customer-account-create .custom-checkbox__text {
  font-style: normal;
  line-height: 1.5; }

.customer-account-create .custom-checkbox:before, .customer-account-create .product-info-price .product-options-wrapper .field.choice input[type='checkbox'] + .label:before, .product-info-price .product-options-wrapper .field.choice .customer-account-create input[type='checkbox'] + .label:before {
  border: 2px solid grey; }
  .page-footer .customer-account-create .custom-checkbox:before, .page-footer .customer-account-create .product-info-price .product-options-wrapper .field.choice input[type='checkbox'] + .label:before, .product-info-price .product-options-wrapper .field.choice .page-footer .customer-account-create input[type='checkbox'] + .label:before {
    border: none; }

.customer-account-create .persistent {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1.78rem; }

.customer-account-create .tooltip.wrapper {
  margin-left: 1rem; }

.customer-account-create .no-margin-bottom:not(.search) {
  margin-bottom: 0; }

.customer-account-create .field:not(.search) {
  margin-bottom: 4rem; }

.customer-account-create .no-margin-bottom:not(.search) {
  margin-bottom: 0; }

.catalogsearch-result-index {
  background-color: #868b7c; }
  .catalogsearch-result-index .page-title-wrapper {
    display: block; }
    .catalogsearch-result-index .page-title-wrapper .base {
      color: #fff; }
  .catalogsearch-result-index .page-header__svg {
    fill: white; }
  .catalogsearch-result-index .layered-filter-block-container,
  .catalogsearch-result-index .sub-search-for-content,
  .catalogsearch-result-index .sidebar-main {
    display: none; }
  .catalogsearch-result-index .products-grid {
    display: block; }
  .catalogsearch-result-index .product-item > a {
    display: none; }

.customer-account-index .page-main {
  padding-top: 2.1rem; }

.customer-account-index .page-title-wrapper {
  display: block; }
  @media (min-width: 768px) {
    .customer-account-index .page-title-wrapper {
      padding-left: 25%; } }

.customer-account-index .main {
  display: grid;
  grid-template-columns: 25% auto;
  grid-template-areas: 'side-nav side-nav side-nav' 'info info info' 'addresses addresses addresses' 'orders orders orders'; }
  @media (min-width: 768px) {
    .customer-account-index .main {
      grid-template-areas: 'side-nav info info' 'side-nav addresses addresses' 'side-nav orders orders'; } }

.customer-account-index .account-nav {
  grid-area: side-nav; }

.customer-account-index .block-dashboard-info {
  grid-area: info; }

.customer-account-index .block-dashboard-addresses {
  grid-area: addresses; }

.customer-account-index .block-dashboard-orders {
  grid-area: orders; }

.customer-account-index .block {
  margin-bottom: 3.57rem; }

.customer-account-index .block-search {
  margin-bottom: 0; }
  .customer-account-index .block-search .block {
    margin-bottom: 0; }

.customer-account-index .box {
  margin-bottom: 1.785rem; }
  @media (min-width: 768px) {
    .customer-account-index .box {
      flex-basis: 50%; } }
  .customer-account-index .box:last-child {
    margin-bottom: 0; }

@media (min-width: 768px) {
  .customer-account-index .block-content {
    display: flex;
    flex-wrap: wrap; } }

.customer-account-index .table-wrapper {
  width: 100%; }

.customer-account-index .block-reorder {
  display: none; }

.customer-account-index .block-dashboard-orders .block-title,
.customer-account-index .block-dashboard-addresses .block-title {
  margin-bottom: 1.5rem; }
  .customer-account-index .block-dashboard-orders .block-title h2,
  .customer-account-index .block-dashboard-addresses .block-title h2 {
    margin-bottom: 0; }
    .customer-account-index .block-dashboard-orders .block-title h2 + a,
    .customer-account-index .block-dashboard-addresses .block-title h2 + a {
      display: inline-block;
      margin-top: 0.3rem; }

.customer-account-index .table-order-items {
  width: 100%; }
  .customer-account-index .table-order-items thead {
    display: none; }
  .customer-account-index .table-order-items tr {
    display: block;
    margin-bottom: 1.785rem; }
    @media (min-width: 768px) {
      .customer-account-index .table-order-items tr {
        padding-right: 4.166%; } }
  .customer-account-index .table-order-items .price {
    font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1rem; }
  .customer-account-index .table-order-items .col {
    display: block;
    width: 100%;
    font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif; }
    .customer-account-index .table-order-items .col:before {
      content: attr(data-th) ": ";
      display: inline-block;
      font-family: "Rubik medium";
      font-size: 12px;
      font-weight: 500;
      line-height: 1.8;
      letter-spacing: 3px;
      text-transform: uppercase;
      color: #262626;
      width: 50%; }
    .customer-account-index .table-order-items .col.actions a {
      margin-right: 1rem; }
  @media (min-width: 768px) {
    .customer-account-index .table-order-items tbody {
      display: flex;
      flex-wrap: wrap; } }
  .customer-account-index .table-order-items tbody tr {
    flex-basis: 50%; }
  .customer-account-index .table-order-items .field .control input {
    margin-bottom: 4rem; }

.sales-order-history .page-main {
  padding-top: 2.1rem; }

.sales-order-history .page-title-wrapper {
  display: block; }
  @media (min-width: 768px) {
    .sales-order-history .page-title-wrapper {
      padding-left: 25%; } }

.sales-order-history .main {
  display: grid;
  grid-template-columns: 25% auto;
  grid-template-areas: 'side-nav side-nav side-nav' 'orders orders orders' 'products products products' 'toolbar toolbar toolbar'; }
  @media (min-width: 768px) {
    .sales-order-history .main {
      grid-template-areas: 'side-nav orders orders' 'side-nav products products' 'side-nav toolbar toolbar'; } }

.sales-order-history .account-nav {
  grid-area: side-nav; }

.sales-order-history .orders-history {
  grid-area: orders; }

.sales-order-history .order-products-toolbar {
  grid-area: products; }

.sales-order-history .actions-toolbar {
  grid-area: toolbar; }

.sales-order-history .block-reorder,
.sales-order-history .action.back {
  display: none; }

.sales-order-history .message.empty {
  font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #000;
  font-size: 14px; }

.sales-order-history .table-order-items .table-caption {
  text-align: left; }

.sales-order-history .table-order-items thead {
  display: none; }

.sales-order-history .table-order-items tr {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 1.78rem;
  padding-bottom: 1.78rem;
  border-bottom: 2px solid #f5f5ef; }

.sales-order-history .table-order-items .price {
  font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem; }

.sales-order-history .table-order-items .col {
  font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  width: 100%;
  padding: 0.2rem 0; }
  .sales-order-history .table-order-items .col:before {
    content: attr(data-th) ": ";
    display: inline-block;
    width: 50%;
    font-family: "Rubik medium";
    font-size: 12px;
    font-weight: 500;
    line-height: 1.8;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #262626; }

.sales-order-history .table-order-items .field .control input {
  margin-bottom: 4rem; }

.sales-order-print .page-main,
.sales-order-invoice .page-main,
.sales-order-view .page-main {
  padding-top: 2.1rem; }

.sales-order-print .page-title-wrapper,
.sales-order-invoice .page-title-wrapper,
.sales-order-view .page-title-wrapper {
  display: block; }
  @media (min-width: 768px) {
    .sales-order-print .page-title-wrapper,
    .sales-order-invoice .page-title-wrapper,
    .sales-order-view .page-title-wrapper {
      padding-left: 25%; } }

.sales-order-print .main,
.sales-order-invoice .main,
.sales-order-view .main {
  display: grid;
  grid-template-columns: 25% auto;
  grid-template-areas: 'side-nav side-nav side-nav' 'order-links order-links order-links' 'ordered ordered ordered' 'addresses addresses addresses'; }
  @media (min-width: 768px) {
    .sales-order-print .main,
    .sales-order-invoice .main,
    .sales-order-view .main {
      grid-template-areas: 'side-nav order-links order-links' 'side-nav ordered ordered' 'side-nav addresses addresses'; } }

.sales-order-print .account-nav,
.sales-order-invoice .account-nav,
.sales-order-view .account-nav {
  grid-area: side-nav; }

.sales-order-print .order-links,
.sales-order-invoice .order-links,
.sales-order-view .order-links {
  grid-area: order-links;
  margin-bottom: 3.57rem; }

.sales-order-print .ordered,
.sales-order-invoice .ordered,
.sales-order-view .ordered {
  grid-area: ordered;
  margin-bottom: 3.57rem; }

.sales-order-print .block-order-details-view,
.sales-order-invoice .block-order-details-view,
.sales-order-view .block-order-details-view {
  grid-area: addresses;
  margin-bottom: 3.57rem; }

@media (min-width: 768px) {
  .sales-order-print .block-content,
  .sales-order-invoice .block-content,
  .sales-order-view .block-content {
    display: flex;
    flex-wrap: wrap; } }

@media (min-width: 768px) {
  .sales-order-print .box,
  .sales-order-invoice .box,
  .sales-order-view .box {
    flex-basis: 50%; } }

.sales-order-print .block-reorder,
.sales-order-print .action.back,
.sales-order-invoice .block-reorder,
.sales-order-invoice .action.back,
.sales-order-view .block-reorder,
.sales-order-view .action.back {
  display: none; }

.sales-order-print .order-status,
.sales-order-print .order-date,
.sales-order-print .box-content,
.sales-order-invoice .order-status,
.sales-order-invoice .order-date,
.sales-order-invoice .box-content,
.sales-order-view .order-status,
.sales-order-view .order-date,
.sales-order-view .box-content {
  font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.8; }
  .sales-order-print .order-status dl,
  .sales-order-print .order-date dl,
  .sales-order-print .box-content dl,
  .sales-order-invoice .order-status dl,
  .sales-order-invoice .order-date dl,
  .sales-order-invoice .box-content dl,
  .sales-order-view .order-status dl,
  .sales-order-view .order-date dl,
  .sales-order-view .box-content dl {
    margin-top: 0;
    padding-top: 0; }

.sales-order-print .order-links,
.sales-order-invoice .order-links,
.sales-order-view .order-links {
  margin: 0;
  padding: 2rem 0;
  list-style: none; }
  .sales-order-print .order-links li,
  .sales-order-invoice .order-links li,
  .sales-order-view .order-links li {
    padding: 0.35rem 0; }

.sales-order-print .table-order-items .table-caption,
.sales-order-invoice .table-order-items .table-caption,
.sales-order-view .table-order-items .table-caption {
  text-align: left; }

.sales-order-print .table-order-items thead,
.sales-order-invoice .table-order-items thead,
.sales-order-view .table-order-items thead {
  display: none; }

.sales-order-print .table-order-items tr,
.sales-order-invoice .table-order-items tr,
.sales-order-view .table-order-items tr {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #f5f5ef; }

.sales-order-print .table-order-items .price,
.sales-order-invoice .table-order-items .price,
.sales-order-view .table-order-items .price {
  font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem; }

.sales-order-print .table-order-items .col,
.sales-order-invoice .table-order-items .col,
.sales-order-view .table-order-items .col {
  font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  width: 100%;
  padding: 0.2rem 0; }
  .sales-order-print .table-order-items .col:before,
  .sales-order-invoice .table-order-items .col:before,
  .sales-order-view .table-order-items .col:before {
    content: attr(data-th) ": ";
    display: inline-block;
    width: 50%;
    font-family: "Rubik medium";
    font-size: 12px;
    font-weight: 500;
    line-height: 1.8;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #262626;
    margin-right: -3px; }

.sales-order-print .table-order-items .col.name,
.sales-order-invoice .table-order-items .col.name,
.sales-order-view .table-order-items .col.name {
  padding: 0; }

.sales-order-print .table-order-items .mark,
.sales-order-invoice .table-order-items .mark,
.sales-order-view .table-order-items .mark {
  font-family: "Rubik medium";
  font-size: 12px;
  font-weight: 500;
  line-height: 1.8;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #262626;
  text-align: left;
  width: 50%; }

.sales-order-print .table-order-items .items-qty,
.sales-order-invoice .table-order-items .items-qty,
.sales-order-view .table-order-items .items-qty {
  display: inline-block;
  vertical-align: top;
  list-style: none;
  padding: 0;
  margin: 0; }

.sales-order-print .item-options,
.sales-order-invoice .item-options,
.sales-order-view .item-options {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0; }
  .sales-order-print .item-options dt,
  .sales-order-print .item-options dd,
  .sales-order-invoice .item-options dt,
  .sales-order-invoice .item-options dd,
  .sales-order-view .item-options dt,
  .sales-order-view .item-options dd {
    flex-basis: 50%;
    margin: 0; }
  .sales-order-print .item-options dt,
  .sales-order-invoice .item-options dt,
  .sales-order-view .item-options dt {
    font-family: "Rubik medium";
    font-size: 12px;
    font-weight: 500;
    line-height: 1.8;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #262626;
    padding: 0.2rem 0; }

.customer-address-index .page-main {
  padding-top: 2.1rem;
  margin-bottom: 3.57rem; }

.customer-address-index .page-title-wrapper {
  display: block; }
  @media (min-width: 768px) {
    .customer-address-index .page-title-wrapper {
      padding-left: 25%; } }

.customer-address-index .main {
  display: grid;
  grid-template-columns: 25% auto;
  grid-template-areas: 'side-nav side-nav side-nav' 'addresses-default addresses-default addresses-default' 'addresses-list addresses-list addresses-list' 'toolbar toolbar toolbar'; }
  @media (min-width: 768px) {
    .customer-address-index .main {
      grid-template-areas: 'side-nav addresses-default addresses-default' 'side-nav addresses-list addresses-list' 'side-nav toolbar toolbar'; } }

.customer-address-index .account-nav {
  grid-area: side-nav; }

.customer-address-index .block-addresses-default {
  grid-area: addresses-default; }

.customer-address-index .block-addresses-list {
  grid-area: addresses-list; }

.customer-address-index .actions-toolbar {
  grid-area: toolbar; }

.customer-address-index .block {
  margin-bottom: 3.57rem; }

.customer-address-index .block-search {
  margin-bottom: 0; }
  .customer-address-index .block-search .block {
    margin-bottom: 0; }

@media (min-width: 768px) {
  .customer-address-index .block-content {
    display: flex;
    flex-wrap: wrap; } }

@media (min-width: 768px) {
  .customer-address-index .box {
    flex-basis: 50%; } }

.customer-address-index .block-reorder,
.customer-address-index .action.back {
  display: none; }

.customer-address-index .block-addresses-list .addresses {
  list-style: none;
  margin: 0;
  padding: 0; }

.customer-address-index .block-addresses-list address {
  font-style: normal; }

.customer-address-index .block-addresses-list .items {
  width: 100%; }
  @media (min-width: 768px) {
    .customer-address-index .block-addresses-list .items {
      display: flex;
      flex-wrap: wrap; } }

@media (min-width: 768px) {
  .customer-address-index .block-addresses-list .item {
    flex-basis: 50%; } }

.customer-address-index .block-addresses-list .field .control input {
  margin-bottom: 4rem; }

.customer-address-index .additional-addresses {
  width: 100%; }
  .customer-address-index .additional-addresses table {
    width: 100%; }
  .customer-address-index .additional-addresses tbody {
    display: flex;
    flex-wrap: wrap; }
  .customer-address-index .additional-addresses tr {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    margin-bottom: 1.875rem; }
    @media (min-width: 768px) {
      .customer-address-index .additional-addresses tr {
        flex-basis: 50%; } }
  .customer-address-index .additional-addresses td {
    padding: 0;
    font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1.8; }

.customer-account-edit .page-main {
  padding-top: 2.1rem; }

.customer-account-edit .page-title-wrapper {
  display: block; }
  @media (min-width: 768px) {
    .customer-account-edit .page-title-wrapper {
      padding-left: 25%; } }

.customer-account-edit .main {
  display: grid;
  grid-template-columns: 25% auto;
  grid-template-areas: 'side-nav side-nav side-nav' 'edit-account edit-account edit-account'; }
  @media (min-width: 768px) {
    .customer-account-edit .main {
      grid-template-areas: 'side-nav edit-account edit-account'; } }

.customer-account-edit .account-nav {
  grid-area: side-nav; }

.customer-account-edit .form-edit-account {
  grid-area: edit-account;
  margin-bottom: 3.57rem; }

.customer-account-edit .block-reorder,
.customer-account-edit .action.back {
  display: none; }

.customer-account-edit .password-info {
  display: none; }

.customer-account-edit .custom-checkbox:before, .customer-account-edit .product-info-price .product-options-wrapper .field.choice input[type='checkbox'] + .label:before, .product-info-price .product-options-wrapper .field.choice .customer-account-edit input[type='checkbox'] + .label:before {
  border: 2px solid #000; }

.customer-account-edit .custom-checkbox__text {
  line-height: 1.5;
  font-style: normal; }

@media (min-width: 768px) {
  .customer-account-edit .form-edit-account {
    display: flex;
    flex-wrap: wrap; } }

.customer-account-edit .fieldset {
  margin: 0;
  padding: 0;
  border: none;
  margin-bottom: 1.785rem; }
  @media (min-width: 768px) {
    .customer-account-edit .fieldset {
      flex-basis: 50%;
      padding-right: 8.333%; } }

@media (min-width: 768px) {
  .customer-account-edit .actions-toolbar {
    flex-basis: 100%; } }

.customer-account-edit .label span {
  font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.8;
  margin-bottom: 25px; }

.customer-account-edit .field:not(.search) {
  margin-bottom: 4rem; }

.customer-account-edit .field-name-lastname,
.customer-account-edit .no-margin-bottom:not(.search) {
  margin-bottom: 0; }

.newsletter-manage-index .page-main {
  padding-top: 2.1rem; }

.newsletter-manage-index .page-title-wrapper {
  display: block;
  word-break: break-all; }
  @media (min-width: 768px) {
    .newsletter-manage-index .page-title-wrapper {
      padding-left: 25%; } }

.newsletter-manage-index .main {
  display: grid;
  grid-template-columns: 25% auto;
  grid-template-areas: 'side-nav side-nav side-nav' 'newsletter newsletter newsletter'; }
  @media (min-width: 768px) {
    .newsletter-manage-index .main {
      grid-template-areas: 'side-nav newsletter newsletter'; } }

.newsletter-manage-index .account-nav {
  grid-area: side-nav; }

.newsletter-manage-index .form-newsletter-manage {
  grid-area: newsletter;
  margin-bottom: 3.57rem; }

.newsletter-manage-index .block-reorder,
.newsletter-manage-index .action.back {
  display: none; }

.newsletter-manage-index .fieldset {
  border: none;
  padding: 0;
  margin: 0;
  margin-bottom: 1.785rem; }

.newsletter-manage-index .custom-checkbox:before, .newsletter-manage-index .product-info-price .product-options-wrapper .field.choice input[type='checkbox'] + .label:before, .product-info-price .product-options-wrapper .field.choice .newsletter-manage-index input[type='checkbox'] + .label:before {
  border: 2px solid #000; }

.newsletter-manage-index .custom-checkbox__text {
  line-height: 1.5;
  font-style: normal; }

.newsletter-manage-index .field .control input:not(#search) {
  margin-bottom: 4rem; }

.customer-address-form .page-main {
  padding-top: 2.1rem; }

.customer-address-form .page-title-wrapper {
  display: block; }
  @media (min-width: 768px) {
    .customer-address-form .page-title-wrapper {
      padding-left: 25%; } }

.customer-address-form .main {
  display: grid;
  grid-template-columns: 25% auto;
  grid-template-areas: 'side-nav side-nav side-nav' 'form-address form-address form-address'; }
  @media (min-width: 768px) {
    .customer-address-form .main {
      grid-template-areas: 'side-nav form-address form-address'; } }

.customer-address-form .account-nav {
  grid-area: side-nav; }

.customer-address-form .form-address-edit {
  grid-area: form-address; }

.customer-address-form .block-reorder,
.customer-address-form .action.back {
  display: none; }

.customer-address-form .block-search {
  margin-bottom: 0; }
  .customer-address-form .block-search .block {
    margin-bottom: 0; }

.customer-address-form .form-address-edit {
  margin-bottom: 3.57rem; }
  @media (min-width: 768px) {
    .customer-address-form .form-address-edit {
      display: flex;
      flex-wrap: wrap; } }

.customer-address-form .fieldset {
  padding: 0;
  margin: 0;
  border: none;
  margin-bottom: 1.785rem; }
  @media (min-width: 768px) {
    .customer-address-form .fieldset {
      flex-basis: 50%;
      padding-right: 8.333%; } }
  .customer-address-form .fieldset:last-child {
    margin-bottom: 0; }

.customer-address-form input,
.customer-address-form .label span {
  font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.8;
  margin-bottom: 25px; }

.customer-address-form .custom-checkbox:before, .customer-address-form .product-info-price .product-options-wrapper .field.choice input[type='checkbox'] + .label:before, .product-info-price .product-options-wrapper .field.choice .customer-address-form input[type='checkbox'] + .label:before {
  border: 2px solid #000; }

.customer-address-form .custom-checkbox__text {
  line-height: 1.5;
  font-style: normal; }

.customer-address-form .field .control input:not(#search) {
  margin-bottom: 4rem; }

.customer-account-forgotpassword .page-main {
  padding-top: 2.1rem; }

.customer-account-forgotpassword .page-title-wrapper {
  display: block; }

.customer-account-forgotpassword .fieldset {
  border: none;
  margin: 0;
  padding: 0;
  margin-bottom: 1.78rem; }
  @media (min-width: 768px) {
    .customer-account-forgotpassword .fieldset {
      max-width: 50%; } }

.customer-account-forgotpassword input,
.customer-account-forgotpassword .label {
  font-family: "Rubik light"; }

.customer-account-forgotpassword .actions-toolbar > .primary, .customer-account-forgotpassword .cookie-opt-out .actions-toolbar > .action, .cookie-opt-out .customer-account-forgotpassword .actions-toolbar > .action {
  margin-bottom: 3.57rem; }

.customer-account-forgotpassword .captcha-image {
  display: flex;
  flex-direction: column; }
  .customer-account-forgotpassword .captcha-image img {
    margin-bottom: 1.78rem; }
  .customer-account-forgotpassword .captcha-image img,
  .customer-account-forgotpassword .captcha-image button {
    align-self: flex-start; }

.customer-account-forgotpassword .block-reorder,
.customer-account-forgotpassword .action.back {
  display: none; }

.customer-account-forgotpassword .field .control input {
  margin-bottom: 4rem; }

.customer-account-createpassword .page-title-wrapper {
  display: block; }

.customer-account-createpassword .fieldset {
  max-width: 768px; }

.customer-account-logoutsuccess .page-main {
  padding-top: 2.1rem; }

.customer-account-logoutsuccess .page-title-wrapper {
  display: block; }

@media (max-width: 767px) {
  .sales-order-invoice .order-details-items {
    grid-column: auto / span 3; } }

.sales-order-invoice .order-details-items .actions-toolbar {
  margin-bottom: 2rem; }

.sales-order-invoice .order-title {
  font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.8;
  margin-bottom: 25px; }
  .sales-order-invoice .order-title strong {
    display: block;
    font-weight: 600; }

.sales-order-invoice .order-links {
  margin: 0;
  padding: 2rem 0;
  list-style: none; }

.sales-order-invoice .order-links li {
  padding: 0.35rem 0; }

.sales-order-print .page-footer {
  display: none !important; }

/* This file contains all the implementation detail for blog blocks on the blog page */
.blog-item {
  margin-bottom: 40px; }
  @media (min-width: 769px) {
    .blog-item {
      margin-bottom: calc(80 / 1440 * 100vw); } }
  @media (min-width: 1440px) {
    .blog-item {
      margin-bottom: 80px; } }

.cms-noroute-index .column.main {
  padding-bottom: 3.92rem; }
  @media (min-width: 1024px) {
    .cms-noroute-index .column.main {
      padding-bottom: 4.57rem; } }

.cms-noroute-index .page-title-wrapper {
  display: block; }

.cms-noroute-index .block-reorder {
  display: none; }

.cms-noroute-index dd {
  font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.8; }

.cms-noroute-index dt {
  font-family: "Rubik light";
  font-size: 24px;
  font-weight: 300;
  line-height: 1.5; }

.cms-noroute-index .main a {
  color: #000;
  text-decoration: none;
  position: relative;
  display: inline-block; }
  .cms-noroute-index .main a:after, .cms-noroute-index .main a:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 100%;
    background: #feac00; }
  .cms-noroute-index .main a:before {
    width: 0;
    transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99); }
  .cms-noroute-index .main a:after {
    right: 0;
    transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99) 100ms; }
  @media (hover: hover) {
    .cms-noroute-index .main a:hover:after {
      width: 0;
      transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99); }
    .cms-noroute-index .main a:hover:before {
      width: 100%;
      transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99) 100ms; } }
  .cms-noroute-index .main a--clean:before, .cms-noroute-index .main a--clean:after {
    display: none; }
  .cms-noroute-index .main a.white {
    color: white; }

.cms-noroute-index .disc, .cms-noroute-index .cms-page-view .main ul, .cms-page-view .main .cms-noroute-index ul {
  list-style: none;
  padding: 0;
  margin: 0 0 2.58rem 0; }
  .cms-noroute-index .disc li, .cms-noroute-index .cms-page-view .main ul li, .cms-page-view .main .cms-noroute-index ul li {
    position: relative;
    padding: 0 0 0 1rem;
    width: 100%;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.8; }
    .cms-noroute-index .disc li:before, .cms-noroute-index .cms-page-view .main ul li:before, .cms-page-view .main .cms-noroute-index ul li:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0.65rem;
      width: 6px;
      height: 6px;
      background: black; }

.cms-page-view .column.main {
  padding-bottom: 3.92rem; }
  @media (min-width: 1024px) {
    .cms-page-view .column.main {
      padding-bottom: 4.57rem; } }

.cms-page-view .page-title-wrapper {
  display: block; }

.cms-page-view .block-reorder {
  display: none; }

.cms-page-view dd {
  font-family: "Rubik light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.8; }

.cms-page-view dt {
  font-family: "Rubik light";
  font-size: 24px;
  font-weight: 300;
  line-height: 1.5; }

.cms-page-view .main a {
  color: #000;
  text-decoration: none;
  position: relative;
  display: inline-block; }
  .cms-page-view .main a:after, .cms-page-view .main a:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 100%;
    background: #feac00; }
  .cms-page-view .main a:before {
    width: 0;
    transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99); }
  .cms-page-view .main a:after {
    right: 0;
    transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99) 100ms; }
  @media (hover: hover) {
    .cms-page-view .main a:hover:after {
      width: 0;
      transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99); }
    .cms-page-view .main a:hover:before {
      width: 100%;
      transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99) 100ms; } }
  .cms-page-view .main a--clean:before, .cms-page-view .main a--clean:after {
    display: none; }
  .cms-page-view .main a.white {
    color: white; }

.cms-page-view .disc, .cms-page-view .main ul {
  list-style: none;
  padding: 0;
  margin: 0 0 2.58rem 0; }
  .cms-page-view .disc li, .cms-page-view .main ul li {
    position: relative;
    padding: 0 0 0 1rem;
    width: 100%;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.8; }
    .cms-page-view .disc li:before, .cms-page-view .main ul li:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0.65rem;
      width: 6px;
      height: 6px;
      background: black; }

.cms-page-view .main ol li {
  line-height: 1.5; }

.cms-page-view .main strong {
  font-weight: bold; }

.cms-page-view .main h1 strong,
.cms-page-view .main h2 strong,
.cms-page-view .main h3 strong,
.cms-page-view .main h4 strong,
.cms-page-view .main h5 strong,
.cms-page-view .main h6 strong {
  font-weight: inherit; }

.contentmanager-contenttype-landingpage .mobile-header-cart .navigation,
.contentmanager-contenttype-landingpage .nav-toggle .navigation,
.contentmanager-contenttype-landingpage .minicart-wrapper .navigation,
.contentmanager-contenttype-landingpage .header.links .navigation,
.contentmanager-contenttype-landingpage .block-search .navigation,
.contentmanager-contenttype-landingpage .header .navigation {
  display: none; }

@media (min-width: 1024px) {
  .contentmanager-contenttype-landingpage .header.content .block-search {
    display: none; } }

.contentmanager-contenttype-landingpage .page-wrapper {
  padding-top: 0; }

.contentmanager-contenttype-landingpage .page-header__svg {
  fill: white; }

.contentmanager-contenttype-landingpage .grid.product-items {
  padding-left: 0; }
  .contentmanager-contenttype-landingpage .grid.product-items > .product-item {
    grid-column: auto / span 6; }
    @media (min-width: 530px) {
      .contentmanager-contenttype-landingpage .grid.product-items > .product-item {
        grid-column: auto / span 4; } }
    @media (min-width: 1024px) {
      .contentmanager-contenttype-landingpage .grid.product-items > .product-item {
        grid-column: auto / span 3; } }
    .contentmanager-contenttype-landingpage .grid.product-items > .product-item .product-item-info {
      width: 100%; }

.page-layout-landingspage .page-header:before {
  background-color: transparent; }

.page-layout-landingspage .page-header .page-header__svg {
  fill: white; }

.page-layout-landingspage .page-header .nav-toggle__nav, .page-layout-landingspage .page-header .block-title svg g {
  stroke: white; }

.page-layout-landingspage .page-header .header.links a, .page-layout-landingspage .page-header .showcart .text {
  color: #fff; }

@media (min-width: 1024px) {
  .page-layout-landingspage .page-header a.level0.level-top span {
    color: #fff; } }

.page-layout-landingspage .page-header.is-scrolled:before {
  background-color: #fff; }

.page-layout-landingspage .page-header.is-scrolled .page-header__svg {
  fill: #000; }

.page-layout-landingspage .page-header.is-scrolled .nav-toggle__nav,
.page-layout-landingspage .page-header.is-scrolled .nav-toggle__nav, .page-layout-landingspage .page-header.is-scrolled .block-title svg g {
  stroke: #000; }

.page-layout-landingspage .page-header.is-scrolled .header.links a, .page-layout-landingspage .page-header.is-scrolled .showcart .text {
  color: #000; }

.page-layout-landingspage .hero {
  margin-top: -58px; }
  @media (min-width: 1024px) {
    .page-layout-landingspage .hero {
      margin-top: -120px; } }
  .page-layout-landingspage .hero .pagebuilder-banner-wrapper {
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    color: white; }
    .page-layout-landingspage .hero .pagebuilder-banner-wrapper > div {
      height: calc(100vh);
      width: 100%;
      display: flex;
      align-items: center; }
  .page-layout-landingspage .hero .pagebuilder-poster-content {
    margin-top: calc(-220 / 1440 * 100vw);
    padding-left: 0;
    padding-right: 0;
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    width: 100%; }
    .page-layout-landingspage .hero .pagebuilder-poster-content [data-element="content"] {
      grid-column: 2 / -2; }
    .page-layout-landingspage .hero .pagebuilder-poster-content h1, .page-layout-landingspage .hero .pagebuilder-poster-content h2, .page-layout-landingspage .hero .pagebuilder-poster-content h3, .page-layout-landingspage .hero .pagebuilder-poster-content h4, .page-layout-landingspage .hero .pagebuilder-poster-content h5 {
      font-family: "Rubik light";
      font-weight: 300;
      margin: 0;
      line-height: calc(60 / 50);
      font-size: calc(32rem / 14);
      color: #fff; }
      @media (min-width: 768px) {
        .page-layout-landingspage .hero .pagebuilder-poster-content h1, .page-layout-landingspage .hero .pagebuilder-poster-content h2, .page-layout-landingspage .hero .pagebuilder-poster-content h3, .page-layout-landingspage .hero .pagebuilder-poster-content h4, .page-layout-landingspage .hero .pagebuilder-poster-content h5 {
          font-size: calc(50rem / 14); } }
    .page-layout-landingspage .hero .pagebuilder-poster-content p {
      font-size: calc(40rem / 14);
      margin-top: 0;
      line-height: 1;
      font-family: "Noe Display"; }
      @media (min-width: 768px) {
        .page-layout-landingspage .hero .pagebuilder-poster-content p {
          font-size: 4.2857142857rem; } }

.page-layout-landingspage .halfText {
  padding-left: 0;
  padding-right: 0;
  display: grid !important;
  grid-template-columns: repeat(12, 1fr);
  padding-top: calc(110 / 1440 * 100vw);
  padding-bottom: calc(110 / 1440 * 100vw);
  background-color: white;
  position: relative;
  z-index: 2;
  margin-top: calc(-220 / 1440 * 100vw);
  min-height: calc(-220 / 1440 * 100vw);
  max-width: 110.76923rem;
  margin-left: auto;
  margin-right: auto; }
  .page-layout-landingspage .halfText > * {
    margin: 0;
    grid-column: 2 / -2; }
    @media (min-width: 768px) {
      .page-layout-landingspage .halfText > * {
        grid-column: 2/8; } }
    .page-layout-landingspage .halfText > * p, .page-layout-landingspage .halfText > * ul, .page-layout-landingspage .halfText > * li, .page-layout-landingspage .halfText > * span {
      font-size: calc(18rem / 14);
      line-height: calc(36 / 24);
      margin: 0; }
      @media (min-width: 768px) {
        .page-layout-landingspage .halfText > * p, .page-layout-landingspage .halfText > * ul, .page-layout-landingspage .halfText > * li, .page-layout-landingspage .halfText > * span {
          font-size: calc(24rem / 14); } }

.page-layout-landingspage [data-content-type="row"]:not(.hero):not(.halfText):not(.halfImage):not(.halfImageRight) {
  padding-left: 0;
  padding-right: 0;
  display: grid !important;
  grid-template-columns: repeat(12, 1fr);
  padding-top: calc(110 / 1440 * 100vw);
  background-color: white;
  position: relative;
  z-index: 2;
  min-height: calc(-220 / 1440 * 100vw);
  max-width: 110.76923rem;
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 768px) {
    .page-layout-landingspage [data-content-type="row"]:not(.hero):not(.halfText):not(.halfImage):not(.halfImageRight) {
      margin-bottom: calc( 50 / 1440 * 100vw);
      padding-top: 0; } }
  .page-layout-landingspage [data-content-type="row"]:not(.hero):not(.halfText):not(.halfImage):not(.halfImageRight) > div {
    grid-column: 2/12; }
    .page-layout-landingspage [data-content-type="row"]:not(.hero):not(.halfText):not(.halfImage):not(.halfImageRight) > div > div h1, .page-layout-landingspage [data-content-type="row"]:not(.hero):not(.halfText):not(.halfImage):not(.halfImageRight) > div > div h2, .page-layout-landingspage [data-content-type="row"]:not(.hero):not(.halfText):not(.halfImage):not(.halfImageRight) > div > div h3, .page-layout-landingspage [data-content-type="row"]:not(.hero):not(.halfText):not(.halfImage):not(.halfImageRight) > div > div h4, .page-layout-landingspage [data-content-type="row"]:not(.hero):not(.halfText):not(.halfImage):not(.halfImageRight) > div > div h5 {
      margin: 0;
      font-size: calc(24rem / 14);
      font-family: "Noe semibold"; }
      @media (min-width: 768px) {
        .page-layout-landingspage [data-content-type="row"]:not(.hero):not(.halfText):not(.halfImage):not(.halfImageRight) > div > div h1, .page-layout-landingspage [data-content-type="row"]:not(.hero):not(.halfText):not(.halfImage):not(.halfImageRight) > div > div h2, .page-layout-landingspage [data-content-type="row"]:not(.hero):not(.halfText):not(.halfImage):not(.halfImageRight) > div > div h3, .page-layout-landingspage [data-content-type="row"]:not(.hero):not(.halfText):not(.halfImage):not(.halfImageRight) > div > div h4, .page-layout-landingspage [data-content-type="row"]:not(.hero):not(.halfText):not(.halfImage):not(.halfImageRight) > div > div h5 {
          font-size: calc(30rem / 14); } }

.page-layout-landingspage [data-appearance="full-bleed"] {
  max-width: none !important;
  padding-top: calc(120 / 1440 * 100vw) !important;
  padding-bottom: calc(120 / 1440 * 100vw); }
  .page-layout-landingspage [data-appearance="full-bleed"] h1, .page-layout-landingspage [data-appearance="full-bleed"] h2, .page-layout-landingspage [data-appearance="full-bleed"] h3, .page-layout-landingspage [data-appearance="full-bleed"] h4, .page-layout-landingspage [data-appearance="full-bleed"] h5 {
    color: #fff;
    margin-bottom: 30px !important; }
    @media (min-width: 769px) {
      .page-layout-landingspage [data-appearance="full-bleed"] h1, .page-layout-landingspage [data-appearance="full-bleed"] h2, .page-layout-landingspage [data-appearance="full-bleed"] h3, .page-layout-landingspage [data-appearance="full-bleed"] h4, .page-layout-landingspage [data-appearance="full-bleed"] h5 {
        margin-bottom: calc(50 / 1440 * 100vw) !important; } }
    @media (min-width: 1440px) {
      .page-layout-landingspage [data-appearance="full-bleed"] h1, .page-layout-landingspage [data-appearance="full-bleed"] h2, .page-layout-landingspage [data-appearance="full-bleed"] h3, .page-layout-landingspage [data-appearance="full-bleed"] h4, .page-layout-landingspage [data-appearance="full-bleed"] h5 {
        margin-bottom: 50px !important; } }
  .page-layout-landingspage [data-appearance="full-bleed"] .pagebuilder-column-group {
    z-index: 10; }
  .page-layout-landingspage [data-appearance="full-bleed"]:before {
    content: '';
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%; }
  .page-layout-landingspage [data-appearance="full-bleed"] .block-products-list, .page-layout-landingspage [data-appearance="full-bleed"] .products-grid {
    display: block; }
    .page-layout-landingspage [data-appearance="full-bleed"] .block-products-list .product-items, .page-layout-landingspage [data-appearance="full-bleed"] .products-grid .product-items {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: 1px;
      width: 100%;
      justify-content: space-between;
      padding: 0;
      margin: 0; }
      .page-layout-landingspage [data-appearance="full-bleed"] .block-products-list .product-items .product-item, .page-layout-landingspage [data-appearance="full-bleed"] .products-grid .product-items .product-item {
        grid-column: span 6; }
        @media (min-width: 1024px) {
          .page-layout-landingspage [data-appearance="full-bleed"] .block-products-list .product-items .product-item, .page-layout-landingspage [data-appearance="full-bleed"] .products-grid .product-items .product-item {
            grid-column: span 3; } }
        .page-layout-landingspage [data-appearance="full-bleed"] .block-products-list .product-items .product-item .product-item-link:before, .page-layout-landingspage [data-appearance="full-bleed"] .block-products-list .product-items .product-item .product-item-link:after, .page-layout-landingspage [data-appearance="full-bleed"] .products-grid .product-items .product-item .product-item-link:before, .page-layout-landingspage [data-appearance="full-bleed"] .products-grid .product-items .product-item .product-item-link:after {
          display: none; }
        .page-layout-landingspage [data-appearance="full-bleed"] .block-products-list .product-items .product-item .product-item-link .product-image-photo, .page-layout-landingspage [data-appearance="full-bleed"] .products-grid .product-items .product-item .product-item-link .product-image-photo {
          width: auto; }

.page-layout-landingspage [data-content-type="banner"] blockquote {
  position: relative;
  display: flex;
  font-family: "Noe semibold";
  font-weight: 400;
  font-style: italic;
  font-size: calc(32rem / 14);
  line-height: calc(60 / 50);
  grid-column: 2 / -2;
  margin: 70px 0 calc(45 / 1440 * 100vw) 0;
  color: #fff; }
  @media (min-width: 769px) {
    .page-layout-landingspage [data-content-type="banner"] blockquote {
      font-size: calc(50rem / 14);
      max-width: 50%; } }
  @media (min-width: 1440px) {
    .page-layout-landingspage [data-content-type="banner"] blockquote {
      margin-bottom: 45px; } }
  .page-layout-landingspage [data-content-type="banner"] blockquote:before {
    content: '“';
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%) translateX(-5px); }
  .page-layout-landingspage [data-content-type="banner"] blockquote + p {
    color: #fff;
    font-size: 1rem;
    display: flex !important;
    align-items: center;
    position: relative; }
    .page-layout-landingspage [data-content-type="banner"] blockquote + p:before {
      display: block;
      height: 2px;
      width: 30px;
      background-color: #fff;
      margin-right: 30px;
      content: ''; }

.page-layout-landingspage .halfImage, .page-layout-landingspage .halfImageRight {
  padding-left: 0;
  padding-right: 0;
  display: grid !important;
  grid-template-columns: repeat(12, 1fr);
  padding-bottom: calc(110 / 1440 * 100vw);
  background-color: white;
  position: relative;
  z-index: 2;
  min-height: calc(-220 / 1440 * 100vw);
  max-width: 110.76923rem;
  margin-left: auto;
  margin-right: auto; }
  .page-layout-landingspage .halfImage .row-full-width-inner, .page-layout-landingspage .halfImageRight .row-full-width-inner {
    grid-column: 2/12;
    display: grid; }
  .page-layout-landingspage .halfImage figure, .page-layout-landingspage .halfImageRight figure {
    margin: 0;
    grid-column: 2 / -2; }
    @media (min-width: 769px) {
      .page-layout-landingspage .halfImage figure, .page-layout-landingspage .halfImageRight figure {
        grid-column: 2 / 6;
        align-self: center;
        padding-right: 100px; } }
    .page-layout-landingspage .halfImage figure .pagebuilder-mobile-hidden, .page-layout-landingspage .halfImageRight figure .pagebuilder-mobile-hidden {
      display: none;
      width: 100%; }
    @media (min-width: 769px) {
      .page-layout-landingspage .halfImage figure, .page-layout-landingspage .halfImageRight figure {
        grid-column: 1 / 6; }
        .page-layout-landingspage .halfImage figure .pagebuilder-mobile-only, .page-layout-landingspage .halfImageRight figure .pagebuilder-mobile-only {
          display: none; }
        .page-layout-landingspage .halfImage figure .pagebuilder-mobile-hidden, .page-layout-landingspage .halfImageRight figure .pagebuilder-mobile-hidden {
          display: block; } }
  .page-layout-landingspage .halfImage .pagebuilder-column-group, .page-layout-landingspage .halfImageRight .pagebuilder-column-group {
    grid-column: 2 / -2; }
    @media (min-width: 769px) {
      .page-layout-landingspage .halfImage .pagebuilder-column-group, .page-layout-landingspage .halfImageRight .pagebuilder-column-group {
        grid-column: 7 / 12; }
        .page-layout-landingspage .halfImage .pagebuilder-column-group p, .page-layout-landingspage .halfImage .pagebuilder-column-group a, .page-layout-landingspage .halfImage .pagebuilder-column-group ul, .page-layout-landingspage .halfImage .pagebuilder-column-group li, .page-layout-landingspage .halfImage .pagebuilder-column-group span, .page-layout-landingspage .halfImageRight .pagebuilder-column-group p, .page-layout-landingspage .halfImageRight .pagebuilder-column-group a, .page-layout-landingspage .halfImageRight .pagebuilder-column-group ul, .page-layout-landingspage .halfImageRight .pagebuilder-column-group li, .page-layout-landingspage .halfImageRight .pagebuilder-column-group span {
          font-size: calc(16rem / 14);
          line-height: calc(28 / 16); } }

@media (min-width: 769px) {
  .page-layout-landingspage .halfImageRight [data-content-type="image"] {
    grid-column: 7 / 12;
    padding: 0 0 0 100px;
    order: 2;
    justify-self: end; }
    .page-layout-landingspage .halfImageRight [data-content-type="image"] img {
      max-width: 490px !important; } }

@media (min-width: 769px) {
  .page-layout-landingspage .halfImageRight .pagebuilder-column-group {
    grid-column: 1 / 6;
    order: 1;
    align-self: center !important; } }

.page-layout-landingspage a.pagebuilder-button-primary {
  font-family: "Rubik medium";
  box-sizing: border-box;
  padding: 1.21rem 1.43rem;
  border: none;
  z-index: 1;
  position: relative;
  cursor: pointer;
  font-weight: 500;
  letter-spacing: 1px;
  background: #000;
  color: #fff;
  white-space: nowrap; }
  .page-layout-landingspage a.pagebuilder-button-primary:after {
    display: none; }
  .page-layout-landingspage a.pagebuilder-button-primary:before {
    background: #48474b;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99) 200ms;
    z-index: -1; }
  .page-layout-landingspage a.pagebuilder-button-primary:hover:before {
    width: 100%;
    left: 0; }

.page-layout-landingspage .pagebuilder-video-container {
  position: relative;
  padding-top: 56.25%;
  margin-bottom: calc(110 / 1440 * 100vw); }
  .page-layout-landingspage .pagebuilder-video-container iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%; }

.page-layout-giftcategory .layered-filter-block-container {
  display: none; }

.page-layout-giftcategory .columns {
  padding-bottom: 30px; }

.page-layout-giftcategory .products-grid .product-item {
  grid-column: auto / span 12; }
  .page-layout-giftcategory .products-grid .product-item .photo, .page-layout-giftcategory .products-grid .product-item .product-image-photo {
    width: 100%; }
  .page-layout-giftcategory .products-grid .product-item .product-item-name {
    font-size: 18px; }

.page-layout-giftcategory .category-seo-top-container, .page-layout-giftcategory .category-seo-bottom-container {
  background-color: #fff; }
  .page-layout-giftcategory .category-seo-top-container h2, .page-layout-giftcategory .category-seo-top-container p, .page-layout-giftcategory .category-seo-bottom-container h2, .page-layout-giftcategory .category-seo-bottom-container p {
    color: #262626; }
  .page-layout-giftcategory .category-seo-top-container p, .page-layout-giftcategory .category-seo-bottom-container p {
    font-size: 18px; }

.special_layout_image_swatches .swatch-attribute-options {
  gap: 20px; }

@media (min-width: 768px) {
  .page-layout-giftcategory .category-seo-top-container, .page-layout-giftcategory .category-seo-bottom-container {
    background-color: #fff; }
    .page-layout-giftcategory .category-seo-top-container h2, .page-layout-giftcategory .category-seo-bottom-container h2 {
      font-size: 50px; }
    .page-layout-giftcategory .category-seo-top-container p, .page-layout-giftcategory .category-seo-bottom-container p {
      font-size: 24px; }
  .page-layout-giftcategory .products-grid .product-item {
    grid-column: auto / span 4; }
    .page-layout-giftcategory .products-grid .product-item .product-item-photo {
      margin: 0 0 14px; }
      .page-layout-giftcategory .products-grid .product-item .product-item-photo .product-image-photo {
        transform-origin: center center;
        margin: 0; }
      .page-layout-giftcategory .products-grid .product-item .product-item-photo:hover .product-image-photo {
        transform: scale(1.15); }
      .page-layout-giftcategory .products-grid .product-item .product-item-photo .product-item__size {
        display: none; } }

/* The header cart */
.page-header .minicart-wrapper {
  @media (min-width: 1024px) {
    display: flex;
    justify-content: flex-end;
  }

  & > a {
    padding: 10px 1rem;

    @media (hover: hover) {
      &:hover .text {
        text-decoration: underline;
      }
    }

    @media (min-width: 1024px) {
      display: flex;
      align-items: center;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .showcart {
    color: $verno-white;
  }

  .showcart:after,
  .showcart:before {
    display: none;
  }

  .counter {
    margin-left: 0.6rem;
  }
}

.counter-number {
  background: $verno-cart-button-bg;
  font-size: 10px;
  color: $verno-black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 18px;
  text-indent: 1px; // Don't know why this is off..
}

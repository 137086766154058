.customer-account-logoutsuccess {
  .page-main {
    @extend .base-grid;
    padding-top: 2.1rem;
  }

  .page-title-wrapper {
    display: block;
  }
}

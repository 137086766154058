.cms-home {
  .column.main {
    padding-bottom: 3.92rem;

    @media (min-width: 1024px) {
      padding-bottom: 4.57rem;
    }
  }

  .products-grid .product-item {
    grid-column: auto / span 1;
  }

  .page-header__svg {
    fill: white;
  }

  .block-products-list,
  .products-grid {
    display: block;
  }

  .product-item-info {
    width: 100%;
  }
}

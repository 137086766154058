$margin: 5px;

.link-block-container {
  grid-gap: 1px;
  justify-content: center;
}

.link-block {
  display: block;
  background: white;
  grid-column: span 12;
  padding: 20px;
  position: relative;

  // 20px is base padding bottom
  // 15px is height of the absolute positioned category
  // $margin is the margin between the title and the category
  padding-bottom: calc(20px + 15px + #{$margin});
  margin: 0;

  @media (min-width: 769px) {
    grid-column: auto / span 6;
    padding: calc(40 / 1440 * 100vw);
    padding-bottom: calc(65 / 1440 * 100vw);
  }

  @media (min-width: 1024px) {
    grid-column: auto / span 3;
  }

  @media (min-width: 1440px) {
    padding: 40px;
    padding-bottom: calc(40px + 15px + #{$margin});
  }

  &:before,
  &:after {
    display: none;
  }
}

.link-block__img {
  position: relative;
  margin-bottom: calc(20rem / 14);
  padding-bottom: 100%;

  &:before {
    content: '';
    background-image: inherit;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 2;
    display: block;
    width: 100%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    max-width: 100%;
    display: block;
    object-fit: cover;
  }
}

.link-block__title {
  font-family: $font-family-name__h4;
  text-transform: none;
  letter-spacing: 0;
  font-size: calc(18rem / 14);
  line-height: calc(28 / 18);
  margin-bottom: 0;
}

.link-block__cat {
  display: block;
  color: $verno-light-gray;
  font-size: calc(12rem / 14);
  position: absolute;
  height: 15px;
  line-height: 15px;

  bottom: 20px;

  @media (min-width: 1440px) {
    bottom: 40px;
  }
}

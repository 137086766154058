.product.detailed {
  .title {
    margin: 0 0 2.5rem 0;

    label {
      font-family: $font-family-name__h1;
      font-size: 2.14rem;
    }
  }

  .description {
    display: block;
  }

  .table {
    width: 100%;

    tr {
      display: block;
      padding: 0.8rem 0;

      @media (min-width: 769px) {
        width: 50%;
      }
    }

    th,
    td {
      display: block;
    }
  }

  .col {
    text-align: left;

    &.data {
      font-family: $font-family-name__base;
      margin: 0 0 0.71rem 0;

      .price {
        font-family: $font-family-name__base;
        font-size: 1rem;
      }
    }
  }

  .label {
    margin: 0 0 0.3rem 0;
  }

  @media (min-width: 769px) {
    tbody {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.contentmanager-contenttype-landingpage {
  .mobile-header-cart,
  .nav-toggle,
  .minicart-wrapper,
  .header.links,
  .block-search,
  .header {
    .navigation {
      display: none;
    }
  }

  .header.content .block-search {
    @media (min-width: 1024px) {
      display: none;
    }
  }

  .page-wrapper {
    padding-top: 0;
  }

  .page-header__svg {
    fill: white;
  }

  .grid.product-items {
    padding-left: 0;

    & > .product-item {
      grid-column: auto / span 6;

      @media (min-width: 530px) {
        grid-column: auto / span 4;
      }

      @media (min-width: 1024px) {
        grid-column: auto / span 3;
      }

      .product-item-info {
        width: 100%;
      }
    }
  }
}

.catalog-product-view {
  .page-header {
    @media (max-width: 767px) {
      z-index: 12;
    }
  }

  .page-main {
    @media (max-width: 767px) {
      z-index: 11;
    }
  }

  #launcher {
    display: none;
  }

  @media (min-width: 1024px) {
    #launcher {
      display: block;
    }
  }

  .page-header__svg {
    fill: black;
  }

  .product-top-wrapper {
    @extend .base-grid;

    @media (max-width: 1025px) {
      padding-left: 0;
      padding-right: 0;
    }

    @media (min-width: 1024px) {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      max-width: none;
    }
  }

  .product.media {
    position: relative;

    @media (min-width: 1024px) {
      align-self: flex-start;
      position: sticky;
      top: 5.5rem;
    }

    @media (min-width: 1026px) {
      transform: translateX(-4.2rem);
    }

    &:before,
    .product__js-bg {
      height: 100%;
      max-height: 625px !important;
      content: '';
      top: 0;
      position: absolute;
      background: #c99e9f;
      left: 0;
      width: 100%;

      @media (min-width: 1024px) {
        left: -1rem;
        width: calc(100% + 1rem);
      }
    }

    @media (min-width: 1024px) {
      grid-column: auto / span 7;
      left: -4.2rem;
      width: calc(100% + 4.2rem);
    }
  }

  .product-info-wrapper {
    @media (min-width: 1024px) {
      padding: 25px 0;
      display: flex;
      align-items: center;
      grid-column: 9 / 13;
    }
  }

  .product.description {
    @media (min-width: 1024px) {
      grid-column: auto / span 6;
    }
  }

  .product-details-wrapper {
    @extend .base-grid;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    overflow: hidden;

    @media (min-width: 1024px) {
      padding-top: calc(120 / 14 * 1rem);
    }

    & > * {
      grid-column: 1 / -1;
    }
  }

  .product.info.detailed {
    margin-bottom: calc(60 / 14 * 1rem);

    @media (min-width: 1024px) {
      margin-bottom: calc(110 / 1440 * 100vw);
      grid-column: 1 / 8; // Actually 1 / 9, but we need one column right padding
    }
  }

  .guarantee-list {
    padding-left: 0;
    margin-bottom: calc(60 / 14 * 1rem);

    @media (min-width: 1024px) {
      margin-bottom: calc(110 / 1440 * 100vw);
      grid-column: 9 / -1;
      padding-top: calc(80 / 14 * 1rem);
    }
  }

  // Let the container fix this
  .yotpo-container {
    max-width: none;
    padding: 0;
    display: none;

    @media (min-width: 1024px) {
      display: block;
      margin: 0 0 calc(120 / 14 * 1rem);
    }
  }

  .yotpo-reviews-carousel
    .carousel-display-wrapper
    #carousel-top-panel
    .headline {
    font-size: calc(30 / 14 * 1rem);
    line-height: calc(40 / 30);
    margin-bottom: calc(60 / 14 * 1rem);
    font-weight: 600;
  }

  .product-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    @media (min-width: 769px) {
      grid-template-rows: 1fr 1fr;
    }
  }

  .steps {
    grid-column: auto / span 12;

    @media (min-width: 769px) {
      grid-row: span 2;
      grid-column: auto / span 6;
    }

    .steps__link {
      display: none;
    }
  }

  .steps__container {
    @media (min-width: 1550px) {
      padding-left: calc((100vw - 110.76923rem) / 2 + 4.2rem);
    }
  }

  .extra-info {
    @media (min-width: 1550px) {
      padding-right: calc((100vw - 110.76923rem) / 2 + 4.2rem);
    }
  }
}

/* Mobile -- seperate file*/
.header.content > .minicart-wrapper {
  @media (max-width: 1379px) {
    display: none;
  }
}

.mobile-header-cart {
  position: absolute;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  right: 50px;

  @media (min-width: 1380px) {
    display: none;
  }
}

/*
  By default it shows the empty black cart icon
  The cart icon only turns white when the page is not scrolled and one of types:

  - Home
  - Overview
  - Search

  Because these pages have a background-color or -image.
*/

.mobile-header-cart__img {
  img {
    display: none;
  }

  @media (max-width: 1379px) {
    /* By default show the empty black one, because the JS takes some time to load */
    img.empty {
      display: block;
    }

    /* If there are items, show the filled icon */
    &.filled {
      img.filled {
        display: block;
      }

      img.empty--white,
      img.empty {
        display: none;
      }
    }
  }
}

// Change the color to white if the page is one of above mentioned types
.cms-home, // home
.catalog-category-view, // product overview
.catalogsearch-result-index {
  //search
  /* Only turn white when the page is NOT scrolled (because scrolled header always has a white background */
  .page-header:not(.is-scrolled) .mobile-header-cart__img {
    @media (max-width: 1379px) {
      /* By default show the empty white one, because the JS takes some time to load */
      img.empty--white {
        display: block;
      }

      img.empty {
        display: none;
      }

      /* If there are items, show the filled icon */
      &.filled {
        img.empty--white {
          display: none;
        }

        img.filled {
          display: block;
        }
      }
    }
  }
}

.mobile-header-cart__link {
  .counter-number {
    display: none;
  }
}

body.nav-open .mobile-header-cart__link {
  .counter-number {
    display: flex;
  }

  .mobile-header-cart__img {
    display: none;
  }
}

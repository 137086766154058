.blog-yt {
  padding-left: 0;
  padding-right: 0;
}

.blog-yt__inner {
  padding-bottom: 56.25%;
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

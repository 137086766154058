.guarantee-list {
  list-style-image: url('../images/icons/checkmark.svg');
  padding: 0 0 0 1.8rem;
}

.guarantee-list__inner {
  margin: 0;
}

.guarantee-list__item {
  font-family: $font-family-name__base;
  font-size: 1.35rem;
  padding: 0.7rem 0 0.7rem 0.4rem;
}

@media (min-width: 1024px) {
  .guarantee-list__item {
    font-size: 1.7rem;
  }
}

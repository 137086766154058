// Mixins without utilities
@import 'mixins/media-queries';

// Vendor libs
@import 'vendor/normalize/lib'; // Normalize.css
@import 'vendor/magento-ui/lib'; // Magento UI

// Reusable helpers/utilities
@import 'utilities/_variables.scss';
@import 'utilities/_utilities';
@import 'utilities/_animations';

// Typography
@import 'typography/_font-face';
@import 'typography/_typography';

// Mixins
@import 'mixins/link';
@import 'mixins/_ac';

// Layout/Grids
@import 'layout/_layout';
@import 'layout/_grid';

// Reusable helpers/utilities
@import 'utilities/_utilities';
@import 'utilities/_animations';

/* Form components/typography/utilities etc.  */
@import 'forms/_utilities.scss';
@import 'forms/_custom-select.scss';
@import 'forms/_custom-radio.scss';
@import 'forms/_custom-checkbox.scss';
@import 'forms/_input-icon.scss';
@import 'forms/_input-line.scss';

// Components
@import 'blocks/link';
@import 'blocks/buttons';
@import 'blocks/forms';
@import 'blocks/homepage-header';
@import 'blocks/cookie-consent';
@import 'blocks/cookie-opt-out';
@import 'blocks/horizontal-list';
@import 'blocks/header/index';
@import 'blocks/footer';
@import 'blocks/navigation';
@import 'blocks/nav-toggle';
@import 'blocks/testimonial';
@import 'blocks/product-item';
@import 'blocks/products-grid';
@import 'blocks/background-image';
@import 'blocks/checkmark-list';
@import 'blocks/extra-info';
@import 'blocks/steps';
@import 'blocks/more-info-bar';
@import 'blocks/home-grid';
@import 'blocks/product-swatch';
@import 'blocks/checkmark-list';
@import 'blocks/extra-info';
@import 'blocks/pagination';
@import 'blocks/filter-bar';
@import 'blocks/filterbar/mobile-filter-bar';
@import 'blocks/overview-toolbar';
@import 'blocks/product-info-main';
@import 'blocks/search';
@import 'blocks/messages';
@import 'blocks/tooltip';
@import 'blocks/account-menu';
@import 'blocks/reorder';
@import 'blocks/login-modal';
@import 'blocks/price-summary';
@import 'blocks/payment-method';
@import 'blocks/checkout-cart';
@import 'blocks/progress-bar';
@import 'blocks/tag-line';
@import 'blocks/link-block';
@import 'blocks/contact-form';
@import 'blocks/agreement-notice';
@import 'blocks/help';
@import 'blocks/modals';
@import 'blocks/category-seo';
@import 'blocks/breadcrumbs';
@import 'blocks/checkout-react';

// Product components
@import 'blocks/product-specification-table';
@import 'blocks/product-description';
@import 'blocks/product-swatch';
@import 'blocks/product-related';
@import 'blocks/product-info-main';
@import 'blocks/product-detail-slider';
@import 'blocks/product-detail-custom-options';

/* Blog components */
@import 'blocks/blog/article-hero';
@import 'blocks/blog/paragraph-heading';
@import 'blocks/blog/text-image';
@import 'blocks/blog/quote';
@import 'blocks/blog/image-quote';
@import 'blocks/blog/image-grid';
@import 'blocks/blog/article-step';
@import 'blocks/blog/article-next';
@import 'blocks/blog/visual-blocks';
@import 'blocks/blog/blog-yt';

// Page specific styles
@import 'pages/home';
@import 'pages/product-detail';
@import 'pages/product-overview';
@import 'pages/checkout';
@import 'pages/cart';
@import 'pages/login';
@import 'pages/register';
@import 'pages/search';
@import 'pages/account';
@import 'pages/blog';
@import 'pages/404';
@import 'pages/basic';
@import 'pages/blog/landingspage';
@import 'pages/landingspage';
@import 'pages/gift-category';
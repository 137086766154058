.newsletter-manage-index {
  .page-main {
    @extend .base-grid;
    padding-top: 2.1rem;
  }

  .page-title-wrapper {
    display: block;
    word-break: break-all;

    @media (min-width: 768px) {
      padding-left: 25%;
    }
  }

  .main {
    display: grid;
    grid-template-columns: 25% auto;
    grid-template-areas:
      'side-nav side-nav side-nav'
      'newsletter newsletter newsletter';

    @media (min-width: 768px) {
      grid-template-areas: 'side-nav newsletter newsletter';
    }
  }

  .account-nav {
    grid-area: side-nav;
  }

  .form-newsletter-manage {
    grid-area: newsletter;
    margin-bottom: 3.57rem;
  }

  .block-reorder,
  .action.back {
    display: none;
  }
}

.newsletter-manage-index {
  .fieldset {
    border: none;
    padding: 0;
    margin: 0;
    margin-bottom: 1.785rem;
  }

  .custom-checkbox:before {
    border: 2px solid #000;
  }

  .custom-checkbox__text {
    line-height: 1.5;
    font-style: normal;
  }

  .field .control input:not(#search) {
    margin-bottom: 4rem;
  }
}

.nav-toggle {
  cursor: pointer;
  background: none;
  border: 0;
  height: 100%;
  position: absolute;
  top: 21px;
  right: 1rem;
  width: 50px;
  transform: none;
  outline: none;
  padding: 0;
  display: flex;
  justify-content: flex-end;

  @media (min-width: 1380px) {
    display: none;
  }

  img {
    width: 15px;
  }
}

.nav-toggle__close {
  display: none;
}

.nav-toggle__nav {
  display: block;
}

body.nav-open {
  .nav-toggle__close {
    display: block;
  }
  .nav-toggle__nav {
    display: none;
  }
}

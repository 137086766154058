// /**
//  * Override the default grid system
//  */

//
//  New generic grid system
//  _____________________________________________

// TODO: test fallback grid with floats

@mixin lib-grid-columns($_colcount: $total-columns) {
  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat($_colcount, [col-start] 1fr);
    row-gap: 0;
    column-gap: 2px;
  }

  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

//
//  Extends
//  _____________________________________________

//@mixin lib-layout-column($_columns , $_order , $_width ) when (isnumber($_width )) {
//  // do nothing, whoever calls this function needs to use our grid variant
//}
@mixin lib-layout-column($_columns, $_colstart: auto, $_width: '') {
  // don't use $_width parameter, that's only used for mixin recognition
  float: left;
  width: ($_columns * 3.75%) + (($_columns - 1) * 5%);

  @if type-of($_colstart) == number {
    @if ($_colstart > 1) {
      // TODO: test if this is the correct SASS syntax
      float: right;
    }
  }

  @supports (display: grid) {
    width: auto;
    grid-column: $_colstart / span $_columns;
  }
}

@mixin lib-layout-row($_rows: auto, $_rowstart: auto) {
  @supports (display: grid) {
    @if type-of($_rows) == number {
      grid-row: $_rowstart / span $_rows;
    } @else {
      grid-row: $_rowstart / $_rows;
    }
  }
}

@mixin lib-layout-columns() {
  @include lib-grid-columns();
  @supports (display: grid) {
    grid-template-rows: auto 1fr;
  }
}

@mixin lib-layout-columns__main() {
  .#{$layout-class-1column} & {
    @include lib-layout-column($total-columns);
  }

  .#{$layout-class-3columns} & {
    @include lib-layout-column(6, 4);
    @include lib-layout-row(1, 1);
    display: inline-block;
    float: none;
  }

  .#{$layout-class-2columns__left} & {
    @include lib-layout-column(9, 4);
    @include lib-layout-row(2, 1);
  }

  .#{$layout-class-2columns__right} & {
    @include lib-layout-column(9, 1);
    @include lib-layout-row(2, 1);
  }
}

@mixin lib-layout-columns__left() {
  .#{$layout-class-1column} & {
    @include lib-layout-column($total-columns);
  }

  // .#{$layout-class-3columns} & {}

  .#{$layout-class-2columns__left} & {
    @include lib-layout-column(3, 1);
    @include lib-layout-row(auto, 1);
  }

  .#{$layout-class-2columns__right} & {
    @include lib-layout-column(3, 10);
    @include lib-layout-row(auto, 1);
  }
}

@mixin lib-layout-columns__right() {
  .#{$layout-class-1column} & {
    @include lib-layout-column($total-columns);
  }

  .#{$layout-class-3columns} & {
    @include lib-layout-column(3, 10);
    @include lib-layout-row(auto, 1);
  }

  .#{$layout-class-2columns__left} & {
    @include lib-layout-column(3, 1);
    @include lib-layout-row(auto, 2);
  }

  .#{$layout-class-2columns__right} & {
    @include lib-layout-column(3, 10);
    @include lib-layout-row(auto, 2);
  }
}

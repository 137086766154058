@import 'header-positioning';
@import 'header-colors';
@import 'header-cart';
@import 'header-mobile';

/* This wraps the logo and the hamburger/close button */
.header__mobile-wrapper {
  position: relative;
}

/* Logo */
.page-header__logo {
  display: flex;
  align-items: center;
  width: calc(78px + 2rem);
  padding: 1rem;
  margin-bottom: 0;

  &:before,
  &:after {
    display: none;
  }

  @media (min-width: 1380px) {
    width: 160px;
    padding: 0;
    grid-column: span 2;
    display: block;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.page-header__svg {
  width: 100%;
  fill: black;
}

.page-header__nav-icon {
  width: 1.75rem;
  fill: none;
  stroke: white;
  stroke-miterlimit: 10;
  stroke-width: 0.1rem;
}

.page-header .greet {
  display: none;
}

/* My Account link */
.page-header .header.links {
  @media (min-width: 1380px) {
    text-align: center;
  }

  li {
    padding: 10px 1rem;

    @media (min-width: 1380px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  a {
    color: $verno-white;
    display: block;

    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.customer-account-forgotpassword {
  .page-main {
    @extend .base-grid;
    padding-top: 2.1rem;
  }

  .page-title-wrapper {
    display: block;
  }

  .fieldset {
    border: none;
    margin: 0;
    padding: 0;
    margin-bottom: 1.78rem;

    @media (min-width: 768px) {
      max-width: 50%;
    }
  }

  input,
  .label {
    font-family: $font-family-name__base;
  }

  .actions-toolbar > .primary {
    margin-bottom: 3.57rem;
  }

  .captcha-image {
    display: flex;
    flex-direction: column;

    img {
      margin-bottom: 1.78rem;
    }

    img,
    button {
      align-self: flex-start;
    }
  }

  .block-reorder,
  .action.back {
    display: none;
  }

  .field .control input {
    margin-bottom: 4rem;
  }
}

.customer-account-createpassword {
  .page-main {
    @extend .base-grid;
  }

  .page-title-wrapper {
    display: block;
  }

  .fieldset {
    max-width: 768px;
  }
}

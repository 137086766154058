.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

.no-scroll {
  overflow: hidden;
}

.white {
  color: white;
}

.ac {
  overflow: hidden;
}

.ac--up .inner {
  transform: translateY(-100%);
  animation: up 1000ms $easing forwards;
}

.ac--down .inner {
  transform: translateY(100%);
  animation: down 1000ms $easing forwards;
}

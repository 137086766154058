.ec-gtm-cookie-directive {
  position: initial;
  bottom: initial;
  left: initial;
  right: initial;
  text-align: initial;
  z-index: 1;
  opacity: 1;

  // DEFAULTS:
  // position: fixed;
  // bottom:20px;
  // left: 0;
  // right: 0;
  // text-align: center;
  // z-index:9999;
}

.ec-gtm-cookie-directive-segments {
  background: initial;
  margin: initial;
  padding: initial;

  // DEFAULTS
  // background: #f7f7f7;
  // margin: 10px -20px 10px -20px;
  // padding: 10px;
}

.cookie-consent {
  background-color: $verno-black;
  color: white;
  padding: 2rem 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  @media (min-width: 768px) {
    padding: 2rem 0;
  }

  & .custom-checkbox__text {
    color: white;
    line-height: 1.5;
    font-style: normal;
  }
}

.cookie-consent__grid {
  align-items: flex-end;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.cookie-consent__information {
  flex: 0.75;
  margin-right: 1rem;
}

.cookie-consent__title {
  color: white;
}

.cookie-consent__description {
  margin-bottom: 0;
}

.cookie-consent__segment-list {
  list-style: none;
  padding: 0;
  margin: 0 0 2rem;
}

.cookie-consent__buttons {
  display: flex;
  align-items: center;
  margin-top: 1rem;

  @media (min-width: 768px) {
    margin-top: 0;
  }
}

.cookie-consent__accept {
  display: block;
  margin-right: 1rem;
  margin-bottom: 0;

  &:hover {
    cursor: pointer;
  }
}

.cookie-consent__learn-more {
  color: white;
  display: block;
  margin-bottom: 0;
}

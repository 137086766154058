// Creating separation between blocked inputs
.input-container {
  margin-bottom: 12px;
}

// fix iOS zoom on focus.
option,
select,
input {
  font-size: 16px;

  @media (min-width: 768px) {
    font-size: 14px;
  }

  &::placeholder {
    font-size: 14px;
  }
}

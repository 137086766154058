.extra-info {
  grid-column: auto / span 12;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  padding: 2.28rem 2.14rem;
  height: auto;
  color: #fff;
  background-color: $verno-burgundy;

  @media (min-width: 769px) {
    grid-column: auto / span 6;
    padding-left: 7.57rem;
  }
}

.extra-info__title {
  font-family: $font-family-name__header;
  font-size: 1.28rem;
  margin-bottom: 2rem;
}

.extra-info__paragraph {
  margin-top: 0;
  margin-bottom: 2rem;
  font-size: 1.14rem;
}

.extra-info-list {
  list-style: none;
  padding: 0;
  margin: 0;
  color: white;
  font-size: 1.14rem;

  li {
    color: white;
    font-size: 1.14rem;
    line-height: 1;
    margin-bottom: 1rem;

    a {
      font-size: 1.14rem;
      color: $verno-white;
      line-height: 1;

      &:before,
      &:after {
        display: none;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.extra-info--background-image {
  background-image: url('../images/extra-info-background.jpg');
  background-size: cover;
}

body {
    .breadcrumbs {
        opacity: 0;
        position: absolute;
        left: -9999px;
    }
    &.catalog-category-view,
    &.catalog-product-view {
        @media (min-width: 1380px) {
            .breadcrumbs {
                opacity: 1;
                left: auto;
            }
        }
    }
}
.page-header {
    .breadcrumbs {
        position: absolute;
        top: 35px;
        ul {
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            list-style: none;
            li {
                color: #fff;
                &:not(:first-child) {
                    padding-left: 4px;
                    &:before {
                        content: '>';
                    }
                }
                a {
                    color: #fff;
                }
            }
        }
    }
    &.is-scrolled {
        .breadcrumbs {
            ul {
                li {
                    color: #000;
                    a {
                        color: #000;
                    }
                }
            }
        }
    }
}
.catalog-product-view {
    .breadcrumbs {
        top: 40px;
        ul {
            li {
                color: #000;
                &:not(:first-child) {
                    &:before {
                        color: #000;
                    }
                }
                a {
                    color: #000;
                }
            }
        }
    }
}
.article-hero {
  height: calc(100vh - 10px);
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  color: white;

  &:before {
    content: '';
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
.article-hero__content-wrapper {
  padding-left: 0;
  padding-right: 0;
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
}

.article-hero__grid {
  grid-column: 2 / -2;

  @media (min-width: 769px) {
    grid-column: 2 / 8;
  }
}

.article-hero__title {
  color: inherit;
  line-height: calc(60 / 50);
  margin-bottom: 35px;
  font-size: calc(32rem / 14);

  @media (min-width: 769px) {
    font-size: calc(50rem / 14);
  }
}

.article-hero__intro {
  padding-left: 0;
  padding-right: 0;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding-top: calc(110 / 1440 * 100vw);
  padding-bottom: calc(110 / 1440 * 100vw);
  background-color: white;
  position: relative;
  z-index: 2;
  margin-top: calc(-220 / 1440 * 100vw);
  min-height: calc(-220 / 1440 * 100vw);
}

.article-hero__intro-text {
  margin: 0;
  font-size: calc(18rem / 14);
  line-height: calc(36 / 24);

  @media (min-width: 769px) {
    font-size: calc(24rem / 14);
  }
}

.article-hero__title--landing {
  margin-bottom: 0;
  font-family: $font-family-name__base;
  font-weight: 300;

  @media (min-width: 767px) {
    font-size: calc(50rem / 14);
  }
}

.article-hero--landing__framed {
  margin-bottom: 0;

  @media (max-width: 767px) {
    font-size: calc(40rem / 14);
  }
}

// Utility
.u-pull-up {
  margin-top: calc(-220 / 1440 * 100vw);
}

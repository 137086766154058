.related-header {
  margin-bottom: 2rem;
  grid-column: 1 / -1;

  @media (min-width: 768px) {
    grid-column: auto / span 5;

    display: flex;
    flex-wrap: wrap;
    align-content: center;
    padding-bottom: 8.57rem;
  }

  & > a {
    @extend .link;
  }
}

.related {
  grid-column: 1 / -1;

  @media (min-width: 600px) {
    margin-left: 0;
    grid-column: 4 / -4;
  }

  @media (min-width: 1024px) {
    grid-column: auto / span 7;
  }

  .wrapper {
    overflow: auto;
    padding: 0 0 4.28rem 1rem;
    margin: 0 -1rem;

    @media (min-width: 1024px) {
      margin: 0;
      padding: 0 0 8.57rem 0;
    }
  }

  .list {
    display: grid;
    width: 40rem;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1px;
    margin: 0;

    @media (min-width: 769px) {
      width: 100%;
    }

    .product-item-photo {
      margin: 1.1rem auto 2.85rem auto;
    }
  }

  .item {
    width: 100%;
    padding: 0 1rem;

    @media (min-width: 768px) {
      padding: 0 12.5%; // Needs a quarter of a column space
      grid-column: auto / span 1;
    }
  }

  .product-item-info {
    padding: 0;
    width: 100%;
  }

  .product-image-container {
    display: block;
    width: 100% !important;
  }

  .product-image-wrapper {
    display: block;
    padding-bottom: 0 !important;
  }

  .product-image-photo {
    width: auto;

    @media (min-width: 1400px) {
      max-width: 100%;
    }
  }
}

.catalog-product-view .product-details-wrapper {
  .related-header {
    @media (min-width: 768px) {
      grid-column: auto / span 5;
    }
  }

  .related {
    grid-column: 1 / -1;

    @media (min-width: 600px) {
      margin-left: 0;
    }

    @media (min-width: 768px) {
      grid-column: auto / span 7;
    }
  }
}

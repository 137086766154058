select,
input[type='text'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='tel'],
textarea {
  box-sizing: border-box;
  width: 100%;
  padding: 0.5rem 0.5rem 0.5rem 0;
  background: none;
  border: none;
  border-bottom: 2px solid black;
  border-radius: 0;
  margin: 0 0 1rem 0;

  &::placeholder {
    color: black;
    opacity: 1; // fix Firefox default opacity on placeholder
  }
}

textarea {
  border: 2px solid black;
  margin-top: 1rem;
  min-height: 100px;
  padding-left: 0.5rem;
}

select {
  appearance: none;
  outline: none;
  cursor: pointer;
}

label {
  cursor: pointer;
}

[role='button'] {
  cursor: pointer;
}

[role='tab'] {
  cursor: pointer;
}

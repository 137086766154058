.base-grid {
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: $maxWidth;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1380px) {
    padding-left: $gutterPadding;
    padding-right: $gutterPadding;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.grid--desktop {
  @media (max-width: 768px) {
    display: block;
    grid-template-columns: none;
  }
}

/* All the fullwidth repeater fields need to be on the grid-column */
.grid__blog-item {
  grid-column: 2 / -2;
}

.product.info.detailed {
  .page-title-wrapper {
    display: block;
  }

  .tocart {
    width: 100%;

    @media (min-width: 769px) {
      width: auto;
    }
  }

  .data.item.content {
    margin-bottom: calc(110 / 1440 * 100vw);
    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Heading styling -- No control over classnames
.product.data.items h2,
.additional-attributes-wrapper h2,
.data.item.content ~ h2 {
  font-size: calc(30 / 14 * 1rem);
  font-family: $font-family-name__h1;
  line-height: calc(40 / 14 * 1rem);
  margin-bottom: calc(50 / 1440 * 100vw);
}

.product.description {
  h2 {
    font-family: 'Noe semibold';
    font-size: 30px;
    margin: 0 0 1.2em 0;
  }

  p {
    line-height: 2;
    margin: 0 0 2.58rem 0;
  }

  strong {
    font-weight: bold;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0 0 2.58rem 0;

    li {
      position: relative;
      padding: 0 0 0 1rem;
      width: 100%;
      font-size: $root__font-size;
      font-weight: $font-weight__light;
      line-height: $line-height__base;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0.65rem;
        width: 6px;
        height: 6px;
        background: black;
      }
    }
  }

  & *:last-child {
    margin-bottom: 0;
  }
}

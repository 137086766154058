.customer-address-form {
  .page-main {
    @extend .base-grid;
    padding-top: 2.1rem;
  }

  .page-title-wrapper {
    display: block;

    @media (min-width: 768px) {
      padding-left: 25%;
    }
  }

  .main {
    display: grid;
    grid-template-columns: 25% auto;
    grid-template-areas:
      'side-nav side-nav side-nav'
      'form-address form-address form-address';

    @media (min-width: 768px) {
      grid-template-areas: 'side-nav form-address form-address';
    }
  }

  .account-nav {
    grid-area: side-nav;
  }

  .form-address-edit {
    grid-area: form-address;
  }

  .block-reorder,
  .action.back {
    display: none;
  }

  .block-search {
    margin-bottom: 0;

    .block {
      margin-bottom: 0;
    }
  }
}

.customer-address-form {
  .form-address-edit {
    margin-bottom: 3.57rem;

    @media (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .fieldset {
    padding: 0;
    margin: 0;
    border: none;
    margin-bottom: 1.785rem;

    @media (min-width: 768px) {
      flex-basis: 50%;
      padding-right: 8.333%;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  input,
  .label span {
    font-family: $font-family__base;
    font-size: $root__font-size;
    font-weight: $font-weight__light;
    line-height: $line-height__base;
    margin-bottom: $indent__base;
  }

  .custom-checkbox:before {
    border: 2px solid #000;
  }

  .custom-checkbox__text {
    line-height: 1.5;
    font-style: normal;
  }

  .field .control input:not(#search) {
    margin-bottom: 4rem;
  }
}

.input-icon {
  display: block;
  position: relative;

  input[type='email'],
  input[type='text'],
  input[type='number'] {
    width: 100%;
    padding-right: 20px;
    margin-bottom: 0;
    outline: none;
    font-weight: 300;
  }
}

.input-icon__icon,
.input-icon__submit {
  position: absolute;
  width: 16px;
  right: 0;
  top: 1em;
}

.input-icon__submit {
  border: 0;
  background: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.input-icon__icon--submit {
  position: relative;
  top: auto;
  transform: none;
  right: auto;
  height: 12px;
  width: 16px;
  display: block;
}

.minicart-items {
  display: flex;
  flex-wrap: wrap;

  .minicart-items-wrapper {
    width: 100%;
  }

  .product-item {
    flex-basis: 50%;
    margin-bottom: 1.785rem;
  }

  .label {
    display: inline;
  }
}

.customer-address-index {
  .page-main {
    @extend .base-grid;
    padding-top: 2.1rem;
    margin-bottom: 3.57rem;
  }

  .page-title-wrapper {
    display: block;

    @media (min-width: 768px) {
      padding-left: 25%;
    }
  }

  .main {
    display: grid;
    grid-template-columns: 25% auto;
    grid-template-areas:
      'side-nav side-nav side-nav'
      'addresses-default addresses-default addresses-default'
      'addresses-list addresses-list addresses-list'
      'toolbar toolbar toolbar';

    @media (min-width: 768px) {
      grid-template-areas:
        'side-nav addresses-default addresses-default'
        'side-nav addresses-list addresses-list'
        'side-nav toolbar toolbar';
    }
  }

  .account-nav {
    grid-area: side-nav;
  }

  .block-addresses-default {
    grid-area: addresses-default;
  }

  .block-addresses-list {
    grid-area: addresses-list;
  }

  .actions-toolbar {
    grid-area: toolbar;
  }

  .block {
    margin-bottom: 3.57rem;
  }

  .block-search {
    margin-bottom: 0;

    .block {
      margin-bottom: 0;
    }
  }

  .block-content {
    @media (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .box {
    @media (min-width: 768px) {
      flex-basis: 50%;
    }
  }

  .block-reorder,
  .action.back {
    display: none;
  }
}

.customer-address-index .block-addresses-list {
  .addresses {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  address {
    font-style: normal;
  }

  .items {
    width: 100%;

    @media (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .item {
    @media (min-width: 768px) {
      flex-basis: 50%;
    }
  }

  .field .control input {
    margin-bottom: 4rem;
  }
}

.customer-address-index .additional-addresses {
  width: 100%;

  table {
    width: 100%;
  }

  tbody {
    display: flex;
    flex-wrap: wrap;
  }

  tr {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    margin-bottom: 1.875rem;

    @media (min-width: 768px) {
      flex-basis: 50%;
    }
  }

  td {
    padding: 0;
    font-family: $font-family__base;
    line-height: $line-height__base;
  }
}

.sales-order-invoice {
  .order-details-items {
    @media (max-width: 767px) {
      grid-column: auto / span 3;
    }

    .actions-toolbar {
      margin-bottom: 2rem;
    }
  }

  .order-title {
    font-family: $font-family__base;
    font-size: $root__font-size;
    font-weight: $font-weight__light;
    line-height: $line-height__base;
    margin-bottom: $indent__base;

    strong {
      display: block;
      font-weight: $font-weight__semibold;
    }
  }

  .order-links {
    margin: 0;
    padding: 2rem 0;
    list-style: none;
  }

  .order-links a {
    @extend .link;
  }

  .order-links li {
    padding: 0.35rem 0;
  }
}

/* Refactor to own folder when it gets too big */
@keyframes SubMenuIn {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes FadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(35px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes down {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

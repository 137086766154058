.opc-progress-bar {
  display: flex;
  flex-basis: 100%;

  .opc-progress-bar-item {
    flex: 50%;
    text-align: center;
    position: relative;

    &:after,
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      height: 2px;
      width: 100%;
      background-color: #f8f5ed;
      z-index: -1;
    }

    &:after {
      background-color: transparent;
    }

    &._complete span,
    &._active span {
      border: 2px solid $verno-gold;
    }

    &._active:before {
      background-color: $verno-gold;
      animation: ProgressBar 700ms cubic-bezier(0.6, 0, 0.01, 0.9) forwards;
    }

    &._complete:before {
      background-color: $verno-gold;
    }
  }

  span {
    font-family: $font-family-name__base;
    padding: 0.2rem;
    border: 2px solid #f8f5ed;
    background: #fff;
  }
}

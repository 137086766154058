.product-item {
  background-color: #fff;
  position: relative;
  flex: 1;
  display: flex; // stretch the <a>

  a {
    display: block;
    width: 100%;
  }

  a:focus {
    outline: 0;

    .name {
      font-weight: 600;
    }
  }
}

.product-item-info {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px 15px;
  position: relative;

  @media (min-width: 1024px) {
    padding: 30px 40px;
  }

  a:after,
  a:before {
    display: none;
  }

  .name {
    display: block;

    @media (min-width: 769px) {
      line-height: calc(32 / 12);
      margin-bottom: 15px;
    }
  }

  .old-price {
    display: none;

    @media (min-width: 520px) {
      display: block;
    }

    @media (min-width: 1024px) {
      display: inline-block;
    }
  }
}

.product-item-details {
  margin-top: auto;

  [role='title'] {
    font-weight: bold;
  }
}

.product-item-photo {
  display: block;
  margin-left: auto;
  margin-right: auto;

  //margin: 15px auto;

  &:focus {
    outline: none;
  }

  @media (min-width: 520px) {
    //margin: 15px auto;
  }

  @media (min-width: 1024px) {
    margin-bottom: 30px;
    overflow: hidden;
  }

  // Commented out until scaled better
  &:hover {
    .product-image-photo {
      @media (min-width: 1024px) {
        transform: scale(1.33);
      }

      @media (min-width: 1200px) {
        transform: scale(1.2);
      }

      @media (min-width: 1400px) {
        transform: scale(2);
      }
    }
  }
}

.product-image-photo {
  display: block;
  margin: 0 auto;
  margin-bottom: 1rem;
  width: 200px;
  max-width: 80%;

  @media (min-width: 520px) {
    max-width: 100%;
  }

  @media (min-width: 1024px) {
    width: auto;
    transition: transform 250ms ease;
    transform-origin: left top;
  }
}

.product-item-actions {
  display: none;
}

.description {
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
}

.product-label {
  position: absolute;
  left: 0;
  top: 50%;
  display: inline-block;
  padding: 5px 13px;
  color: #fff;
  transform: translateY(-50%);
  z-index: 2;
}

.product-label--special {
  background-color: $verno-burgundy;
}

.product-label--complete {
  background-color: $verno-green;
}

.price-label {
  display: none;
}

.product-item__size {
  font-size: 12px;
  color: $verno-light-gray;
  display: block;
  font-family: $font-family-name__base;
  line-height: 1.7857142857rem;

  @media (min-width: 769px) {
  }
}

// body selector because external style specificity is screwed up
body {
  .yotpo-container {
    @extend .base-grid;

    & + p {
      display: none;
    } // TODO: remove from template "CMS homepage content goes here."
  }

  .yotpo {
    // Hide stuff
    .yotpo-stars,
    .carousel-review-title,
    .product-link-container,
    .yotpo-label {
      display: none;
    }
  }

  .yotpo-reviews-carousel .carousel-display-wrapper .carousel-read-more {
    @extend .link;
    display: none;
    color: $verno-black;
    width: auto;
    margin-left: 1rem;
    cursor: pointer;

    @media (min-width: 769px) {
      display: inline-block;
    }
  }

  &.cms-index-index
    .yotpo-reviews-carousel
    .carousel-display-wrapper
    .carousel-read-more {
    color: $verno-white;
  }

  // Wrapper
  .yotpo-reviews-carousel .carousel-display-wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  .yotpo-reviews-carousel .carousel-display-wrapper .scroller {
    overflow-y: hidden;
  }

  // Container title
  .yotpo-reviews-carousel .carousel-display-wrapper #carousel-top-panel {
    float: none;
    border: 0;
    padding: 0;
    margin: 0 0 15px 0;

    @media (min-width: 1024px) {
      margin-bottom: 25px;
    }
  }

  .yotpo-reviews-carousel .carousel-display-wrapper .yotpo-carousel {
    padding-top: 0;
  }

  // Stars container
  .yotpo-reviews-carousel .carousel-display-wrapper .stars-container {
    height: 0 !important;
  }

  // Content
  .yotpo-reviews-carousel
    .carousel-display-wrapper
    .yotpo-carousel
    .small-version {
    position: relative;
  }

  .yotpo-reviews-carousel
    .carousel-display-wrapper
    .yotpo-carousel
    .big-version
    .right-side,
  .yotpo-reviews-carousel
    .carousel-display-wrapper
    .yotpo-carousel
    .small-version
    .right-side {
    width: 100%;
    padding-bottom: 30px; // Create room for absolute name + date
    position: relative;
  }

  .yotpo-reviews-carousel
    .carousel-display-wrapper
    .yotpo-carousel
    .carousel-review-body {
    margin: 0;
    color: black;
    font-style: normal !important;
    font-family: $font-family-name__base;
    line-height: 1.7857142857rem;
  }

  .yotpo-reviews-carousel
    .carousel-display-wrapper
    #carousel-top-panel
    .headline {
    color: black;
    font-family: $font-family-name__h4;
    font-size: 1.5rem;
    line-height: normal;
    font-weight: 300;
    margin: 0;
  }

  // The date
  .yotpo-reviews-carousel
    .carousel-display-wrapper
    .yotpo-carousel
    .single-carousel
    .carousel-review-date {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 61px;
    padding-right: 0;
    padding-left: 0;
    line-height: 1;
    font-style: normal !important;
    font-size: 14px;
    font-family: $font-family-name__cta;
    color: black;
  }

  // THe author
  .yotpo-reviews-carousel
    .carousel-display-wrapper
    .yotpo-carousel
    .carousel-review-author {
    position: absolute;
    font-size: 14px;
    left: 81px; // Width of the author
    bottom: 0;
    margin: 0;
    font-weight: normal;
    float: none;
    color: black;
    line-height: 1;
    padding-left: 50px; // Create space for the line
    font-family: $font-family-name__cta;
    // The line
    &:before {
      position: absolute;
      width: 30px;
      height: 2px;
      background-color: black;
      top: 50%;
      left: 0;
      content: '';
    }
  }
}

// White text -- Dark background
body.cms-home {
  .yotpo-reviews-carousel
    .carousel-display-wrapper
    .yotpo-carousel
    .carousel-review-body,
  .yotpo-reviews-carousel
    .carousel-display-wrapper
    #carousel-top-panel
    .headline,
  .yotpo-reviews-carousel
    .carousel-display-wrapper
    .yotpo-carousel
    .single-carousel
    .carousel-review-date,
  .yotpo-reviews-carousel
    .carousel-display-wrapper
    .yotpo-carousel
    .carousel-review-author {
    color: white;
  }

  .yotpo-reviews-carousel
    .carousel-display-wrapper
    .yotpo-carousel
    .carousel-review-author:before {
    background-color: white;
  }
}

.yotpo-reviews-carousel .carousel-tool-tip .yotpo-tool-tip {
  border-radius: 0;
}

.yotpo .yotpo-tool-tip .tool-tip-header .tool-tip-header-span.header-blue {
  color: $verno-black;
}

.yotpo-reviews-carousel .carousel-tool-tip .yotpo-tool-tip {
  transform: translateY(-50%);
  background: #f5f5f5 !important;
}

.yotpo-reviews-carousel .carousel-display-wrapper div {
  color: #000;
}

.horizontal-list {
  display: flex;
  flex-wrap: wrap;
}

.horizontal-list__item {
  margin: 0 2.1rem 0 0;

  &:last-child:after {
    display: none;
  }
}

.horizontal-list__item--dash-separator:after {
  content: '';
  display: block;
  position: relative;
  top: -50%;
  left: 100%;
  height: 2px;
  width: 10px;
  background: #000;
  transform: translate(0.65rem, 50%);
}

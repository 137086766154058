.tooltip.toggle:hover ~ .tooltip.content {
  display: block;
}

.tooltip.content {
  display: none;
  position: absolute;
  background: $verno-light-green;
  padding: 1rem;
  z-index: 5;
  max-width: 15rem;
  animation: FadeIn 400ms cubic-bezier(0.6, 0, 0.01, 0.9) forwards;
}

@keyframes FadeIn {
  from {
    opacity: 0;
    transform: translateY(1rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

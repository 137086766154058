.tag-line {
  display: flex;
  align-items: center;
}

.tag-line__item {
  font-family: $font-family-name__base;
  font-weight: $font-weight__light;
  display: flex;
  align-items: center;
  flex: 1;

  @media (min-width: 500px) {
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 1;
  }

  &:after {
    display: block;
    content: '';
    height: 2px;
    width: 30px;
    margin: 0 30px;
    background-color: white;
  }

  &:last-child:after {
    display: none;
  }
}

.sales-order-print,
.sales-order-invoice,
.sales-order-view {
  .page-main {
    @extend .base-grid;
    padding-top: 2.1rem;
  }

  .page-title-wrapper {
    display: block;

    @media (min-width: 768px) {
      padding-left: 25%;
    }
  }

  .main {
    display: grid;
    grid-template-columns: 25% auto;
    grid-template-areas:
      'side-nav side-nav side-nav'
      'order-links order-links order-links'
      'ordered ordered ordered'
      'addresses addresses addresses';

    @media (min-width: 768px) {
      grid-template-areas:
        'side-nav order-links order-links'
        'side-nav ordered ordered'
        'side-nav addresses addresses';
    }
  }

  .account-nav {
    grid-area: side-nav;
  }

  .order-links {
    grid-area: order-links;
    margin-bottom: 3.57rem;
  }

  .ordered {
    grid-area: ordered;
    margin-bottom: 3.57rem;
  }

  .block-order-details-view {
    grid-area: addresses;
    margin-bottom: 3.57rem;
  }

  .block-content {
    @media (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .box {
    @media (min-width: 768px) {
      flex-basis: 50%;
    }
  }

  .block-reorder,
  .action.back {
    display: none;
  }

  .order-status,
  .order-date,
  .box-content {
    font-family: $font-family__base;
    line-height: 1.8;

    dl {
      margin-top: 0;
      padding-top: 0;
    }
  }

  .order-links {
    margin: 0;
    padding: 2rem 0;
    list-style: none;

    a {
      @extend .link;
    }

    li {
      padding: 0.35rem 0;
    }
  }
}

.sales-order-print .table-order-items,
.sales-order-invoice .table-order-items,
.sales-order-view .table-order-items {
  .table-caption {
    text-align: left;
  }

  thead {
    display: none;
  }

  tr {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid $verno-light-green;
  }

  .price {
    font-family: $font-family__base;
    font-size: 1rem;
  }

  .col {
    font-family: $font-family__base;
    width: 100%;
    padding: 0.2rem 0;

    &:before {
      content: attr(data-th) ': ';
      display: inline-block;
      width: 50%;
      font-family: $font-family-name__h3;
      font-size: $h3__font-size;
      font-weight: $font-weight__heavier;
      line-height: $h3__line-height;
      letter-spacing: $h3__letter-spacing;
      text-transform: uppercase;
      color: $verno-header-primary;
      margin-right: -3px;
    }
  }

  .col.name {
    padding: 0;
  }

  .mark {
    font-family: $font-family-name__h3;
    font-size: $h3__font-size;
    font-weight: $font-weight__heavier;
    line-height: $h3__line-height;
    letter-spacing: $h3__letter-spacing;
    text-transform: uppercase;
    color: $verno-header-primary;
    text-align: left;
    width: 50%;
  }

  .items-qty {
    display: inline-block;
    vertical-align: top;
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.sales-order-print .item-options,
.sales-order-invoice .item-options,
.sales-order-view .item-options {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;

  dt,
  dd {
    flex-basis: 50%;
    margin: 0;
  }

  dt {
    font-family: $font-family-name__h3;
    font-size: $h3__font-size;
    font-weight: $font-weight__heavier;
    line-height: $h3__line-height;
    letter-spacing: $h3__letter-spacing;
    text-transform: uppercase;
    color: $verno-header-primary;
    padding: 0.2rem 0;
  }
}

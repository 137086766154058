.loader {
  display: none;
}

.pages {
  margin: 0 auto;
  padding-bottom: 2.14rem;

  @media (min-width: 769px) {
    max-width: auto;
  }
}

.pages-items {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;

  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.42rem;
    width: 2.28rem;

    &.current {
      border: 2px solid #fff;
    }
  }

  .pages-item-previous,
  .pages-item-next {
    width: auto;

    span {
      display: none;
      vertical-align: middle;
      text-transform: lowercase;

      @media (min-width: 769px) {
        display: inline-block;
      }
    }

    img {
      display: inline-block;
      vertical-align: middle;
      height: 1rem;
      margin-left: 0.64rem;
    }
  }

  .pages-item-previous {
    margin-right: auto;

    @media (min-width: 769px) {
      margin-right: 2.14rem;
    }

    img {
      margin-left: 0;
      margin-right: 0.64rem;
      transform: rotate(180deg);
    }
  }

  .pages-item-next {
    margin-left: auto;

    @media (min-width: 769px) {
      margin-left: 2.14rem;
    }
  }

  .action,
  .page {
    font-family: $font-family__rubik-semi-bold;
    font-weight: 500;
    color: #fff;
    margin: 0;
    letter-spacing: 1.17px;

    &:before,
    &:after {
      display: none;
    }
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

body.nav-open {
  overflow: hidden;
}

.page-main {
  @media (min-width: 1024px) {
    min-height: calc(
      100vh - 550px - 120px
    ); // Full height - footer height - header height
  }
}

.page-title-wrapper:not(.product) {
  display: none;
}

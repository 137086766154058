@keyframes image {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.background-image ~ .page-main {
  background-image: linear-gradient(
    transparent 0%,
    rgba(0, 0, 0, 0.5) 60%,
    rgba(0, 0, 0, 0.6) 67.5%
  );
}

.page-wrapper > * {
  position: relative;
  z-index: 10;
}

.page-wrapper > .background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  transform: scale(1.1);
  animation: image 800ms cubic-bezier(0.6, 0, 0.01, 0.9) forwards 400ms;
  overflow: hidden;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  /* The overlay for the image */
  &:after {
    background: rgba(0, 0, 0, 0.3);
  }

  &:before {
    background: $verno-green;
    animation: right 900ms $easing forwards 200ms;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.home-background__gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.4)
  );
  height: 33.333333vh;
  transform: translateY(100%);
  animation: up 1100ms $easing forwards 700ms;
}

// Homepage animatie
.cms-home.cms-index-index {
  .homepage-header__title {
    animation-delay: 1100ms;
  }

  .homepage-header__buttons .inner {
    animation-delay: 1500ms;
  }

  .steps {
    @media (min-width: 1024px) {
      @include ac();

      & > * {
        @include innerUp();
        animation-delay: 1000ms;
      }
    }
  }

  .header.content {
    .header.links,
    //.navigation,
    .block-search,
    .minicart-wrapper {
      @media (min-width: 1024px) {
        @include ac();

        & > *:not(#mini-search-dropdown) {
          @include innerDown();
          animation-delay: 800ms;
        }
      }
    }
  }

  .header.content .navigation li.level0 > a {
    @media (min-width: 1024px) {
      @include ac();
      display: block;

      & > * {
        display: block;
        transform: translateY(-100%);
        @include innerDown();
        animation-delay: 800ms;
      }
    }
  }

  .page-header.is-active {
    // Navigation has an existing transform
    .navigation {
      @media (min-width: 1024px) {
        overflow: visible;
      }
    }
  }
}

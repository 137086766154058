.contact-form {
  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }

  legend {
    opacity: 0;
    visibility: hidden;
  }

  &:last-child {
    margin-bottom: 5rem;
  }
}

.contact-form__inner {
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  .field {
    grid-column: 1 / -1;

    @media (min-width: 769px) {
      grid-column: 3 / -3;
    }

    @media (min-width: 1024px) {
      grid-column: 5 / -5;
    }
  }
}

.sales-order-history {
  .page-main {
    @extend .base-grid;
    padding-top: 2.1rem;
  }

  .page-title-wrapper {
    display: block;

    @media (min-width: 768px) {
      padding-left: 25%;
    }
  }

  .main {
    display: grid;
    grid-template-columns: 25% auto;
    grid-template-areas:
      'side-nav side-nav side-nav'
      'orders orders orders'
      'products products products'
      'toolbar toolbar toolbar';

    @media (min-width: 768px) {
      grid-template-areas:
        'side-nav orders orders'
        'side-nav products products'
        'side-nav toolbar toolbar';
    }
  }

  .account-nav {
    grid-area: side-nav;
  }

  .orders-history {
    grid-area: orders;
  }

  .order-products-toolbar {
    grid-area: products;
  }

  .actions-toolbar {
    grid-area: toolbar;
  }

  .block-reorder,
  .action.back {
    display: none;
  }

  .message.empty {
    font-family: $font-family__base;
    color: $verno-black;
    font-size: 14px;
  }
}

.sales-order-history .table-order-items {
  .table-caption {
    text-align: left;
  }

  thead {
    display: none;
  }

  tr {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 1.78rem;
    padding-bottom: 1.78rem;
    border-bottom: 2px solid $verno-light-green;
  }

  .price {
    font-family: $font-family__base;
    font-size: 1rem;
  }

  .col {
    font-family: $font-family__base;
    width: 100%;
    padding: 0.2rem 0;

    &:before {
      content: attr(data-th) ': ';
      display: inline-block;
      width: 50%;
      font-family: $font-family-name__h3;
      font-size: $h3__font-size;
      font-weight: $font-weight__heavier;
      line-height: $h3__line-height;
      letter-spacing: $h3__letter-spacing;
      text-transform: uppercase;
      color: $verno-header-primary;
    }
  }

  .field .control input {
    margin-bottom: 4rem;
  }
}

/* Product title */
.product-info-main {
  padding-top: calc(30 / 14 * 1rem);
  margin-bottom: calc(60 / 14 * 1rem);
  padding-left: 1rem;
  padding-right: 1rem;

  @media (min-width: 1024px) {
    padding-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .page-title {
    font-size: calc(40 / 14 * 1rem);
    line-height: calc(40 / 32);
    margin-bottom: calc(30 / 14 * 1rem);

    @media (min-width: 1024px) {
      font-size: calc(30 / 14 * 1rem);
      margin-bottom: calc(50 / 14 * 1rem);
    }

    span {
      font-size: inherit;
    }
  }

  .product-info-stock-sku {
    display: none;
  }

  // Color swatch
  .product-info-price {
    margin-bottom: calc(30 / 14 * 1rem);

    @media (min-width: 1024px) {
      margin-bottom: calc(45 / 14 * 1rem);
    }
  }

  .swatch-opt {
    margin-bottom: 1rem;
  }

  // Description
  .product.attribute.overview {
    margin-bottom: calc(20 / 14 * 1rem);

    @media (min-width: 1024px) {
      margin-bottom: calc(25 / 14 * 1rem);
    }
  }

  .product.attribute.overview p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: calc(25 / 14);

    @media (min-width: 1024px) {
      line-height: calc(28 / 16);
      font-size: calc(16 / 14 * 1rem);
    }
  }

  // Price
  .price-final_price {
    margin-bottom: calc(60 / 14 * 1rem);

    @media (min-width: 1024px) {
      margin-bottom: calc(55 / 14 * 1rem);
    }
  }

  .order-sample-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  // Bestel sample
  .add-sample-to-cart {
    input {
      display: none;
    }

    @media (min-width: 768px) {
      margin-right: 2.14rem;
    }
  }

  .add-sample-to-cart button[type='submit'],
  .product-attachment {
    display: block;
    font-family: $font-family__base;
    font-size: $root__font-size;
    font-weight: $font-weight__light;
    line-height: $line-height__base;
  }

  .product-attachment,
  .add-sample-to-cart {
    margin-bottom: 25px;
  }

  .add-sample-to-cart button[type='submit'], #uploadBtn.active {
    @extend .link;
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
    line-height: 1.8;
    font-size: 1rem;
    letter-spacing: 0;
  }
}

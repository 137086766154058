.customer-account-index {
  .page-main {
    @extend .base-grid;
    padding-top: 2.1rem;
  }

  .page-title-wrapper {
    display: block;

    @media (min-width: 768px) {
      padding-left: 25%;
    }
  }

  .main {
    display: grid;
    grid-template-columns: 25% auto;
    grid-template-areas:
      'side-nav side-nav side-nav'
      'info info info'
      'addresses addresses addresses'
      'orders orders orders';

    @media (min-width: 768px) {
      grid-template-areas:
        'side-nav info info'
        'side-nav addresses addresses'
        'side-nav orders orders';
    }
  }

  .account-nav {
    grid-area: side-nav;
  }

  .block-dashboard-info {
    grid-area: info;
  }

  .block-dashboard-addresses {
    grid-area: addresses;
  }

  .block-dashboard-orders {
    grid-area: orders;
  }

  .block {
    margin-bottom: 3.57rem;
  }

  .block-search {
    margin-bottom: 0;

    .block {
      margin-bottom: 0;
    }
  }

  .box {
    margin-bottom: 1.785rem;

    @media (min-width: 768px) {
      flex-basis: 50%;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .block-content {
    @media (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .table-wrapper {
    width: 100%;
  }

  .block-reorder {
    display: none;
  }
}

.customer-account-index .block-dashboard-orders,
.customer-account-index .block-dashboard-addresses {
  .block-title {
    margin-bottom: 1.5rem;

    h2 {
      margin-bottom: 0;

      + a {
        display: inline-block;
        margin-top: 0.3rem;
      }
    }
  }
}

.customer-account-index .table-order-items {
  width: 100%;

  thead {
    display: none;
  }

  tr {
    display: block;
    margin-bottom: 1.785rem;

    @media (min-width: 768px) {
      padding-right: 4.166%;
    }
  }

  .price {
    font-family: $font-family__base;
    font-size: 1rem;
  }

  .col {
    display: block;
    width: 100%;
    font-family: $font-family__base;

    &:before {
      content: attr(data-th) ': ';
      display: inline-block;
      font-family: $font-family-name__h3;
      font-size: $h3__font-size;
      font-weight: $font-weight__heavier;
      line-height: $h3__line-height;
      letter-spacing: $h3__letter-spacing;
      text-transform: uppercase;
      color: $verno-header-primary;
      width: 50%;
    }

    &.actions a {
      margin-right: 1rem;
    }
  }

  tbody {
    @media (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
    }

    tr {
      flex-basis: 50%;
    }
  }

  .field .control input {
    margin-bottom: 4rem;
  }
}

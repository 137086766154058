.image-grid {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: calc(150 / 1440 * 110vw);

  @media (min-width: 500px) {
    margin-bottom: calc(-110 / 1440 * 110vw);
  }

  @media (min-width: 1440px) {
    margin-bottom: -110px;
  }
}

.image-grid__item {
  margin: 0 0 calc(150 / 1440 * 110vw) 0;
  grid-column: 2 / -2;

  @media (min-width: 500px) {
    margin-bottom: calc(110 / 1440 * 110vw);
  }

  @media (min-width: 1440px) {
    margin-bottom: 110px;
  }
}

.image-grid__caption {
  display: block;
  font-size: 1rem;
  line-height: calc(22 / 14);
  margin-top: calc(24rem / 14);
  position: relative;
  margin-left: calc(30rem / 14);
  margin-right: calc(30rem / 14);
  font-family: $font-family-name__base;

  &:before {
    content: '';
    width: 2px;
    height: 30px;
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%) translateY(-7px); // Design value
  }
}

.image-grid__image {
  max-width: 100%;
  display: block;
}

/* Different sizes */
.image-grid__item:nth-child(4n + 1) {
  @media (min-width: 769px) {
    grid-column: 2 / 5;
    align-self: end;
  }
}

.image-grid__item:nth-child(4n + 2) {
  @media (min-width: 769px) {
    grid-column: 6 / 12;
  }
}

.image-grid__item:nth-child(4n + 3) {
  @media (min-width: 769px) {
    grid-column: 2 / 6;
    align-self: center;
  }
}

.image-grid__item:nth-child(4n + 4) {
  @media (min-width: 769px) {
    grid-column: 7 / 12;
    align-self: center;
  }
}

.more-info-bar {
  background-color: black;
  color: white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  margin: 0;

  @media (min-width: 769px) {
    padding: 3rem 4rem;
    margin: 0;
  }

  a {
    color: white;
  }
}

.more-info-bar__header {
  padding-right: 1rem;
}

.more-info-bar__title {
  margin: 0;

  @media (max-width: 769px) {
    font-size: 1.2857142857rem;
  }
}

.more-info-bar__select {
  &.large {
    @media (max-width: 769px) {
      display: block;
    }
  }

  @media (min-width: 769px) {
    margin-right: 1.0714285714rem;
    margin-left: 1.0714285714rem;
  }

  select {
    padding: 0;
    margin: 0;
    border-color: white;
    font-size: 1rem;
  }

  &.large .mock-select {
    @media (min-width: 769px) {
      padding-right: 5rem;
    }
  }

  .mock-select__text {
    font-size: 1.7142857143rem;
    padding: 0;
    font-style: normal;

    @media (min-width: 769px) {
      text-transform: lowercase;
    }
  }
}

/* Variations */
.more-info-bar--large {
  display: block;
  padding-top: 2.8571428571rem;
  padding-bottom: 2.8571428571rem;

  @media (min-width: 769px) {
    display: flex;
  }

  .desktop {
    display: none;
    @media (min-width: 769px) {
      display: block;
    }
  }

  .mobile {
    display: block;
    @media (min-width: 769px) {
      display: none;
    }
  }
}

.more-info-bar--large__title {
  @extend .more-info-bar__title; // Inherit original styles
  display: block;
  color: white;
  font-family: $font-family-name__base;
  font-weight: 100;
  line-height: 1.5;
  font-size: 1.7142857143rem;
  margin-bottom: 2.8571428571rem;

  @media (min-width: 769px) {
    margin-bottom: 0;
    display: flex;
  }
}

.more-info-bar--large__btn,
#react-configure-frame-flow {
  margin: 0;
}

.more-info-bar #react-configure-frame-flow button {
  @media (max-width: 769px) {
    max-width: none;
    width: 100%;
  }
}

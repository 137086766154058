.image-quote {
  padding: calc(175 / 1440 * 100vmax) 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  position: relative;

  &:before {
    content: '';
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.image-quote__inner {
  padding-left: 0;
  padding-right: 0;
  position: relative;
  z-index: 2;
}

.image-quote__quote {
  grid-column: 2 / -2;

  @media (min-width: 769px) {
    grid-column: 2 / 8;
  }
}

.image-quote .quote__author:before {
  background-color: white;
}

.hyvareactcheckout-reactcheckout-index {
    .navigation,
    .block.block-search,
    .minicart-wrapper,
    .header.links {
        display: none !important;
    }
    .page-wrapper {
        padding-top: 0;
        .page-header {
            position: relative !important;
        }
    }
}
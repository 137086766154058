.header.content .contact,
.minicart-wrapper,
.header.links,
.navigation {
  display: none;

  .nav-open & {
    display: block;
  }
}

.minicart-wrapper {
  overflow: initial !important;
}

.header.links,
.ui-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.header.links .link,
.header.links a,
.ui-menu-item a,
.ui-menu a,
.minicart-wrapper .action.showcart .text {
  font-size: 16px;

  &:before,
  &:after {
    display: none;
  }
}

.page-wrapper .page-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  transition: transform 600ms cubic-bezier(0.6, 0, 0.01, 0.99);

  &.scroll-hidden {
    transform: translateY(-100%);
  }
}

@media (min-width: 1380px) {
  .page-header {
    height: 8.6rem;
    padding: 0;

    &.is-scrolled {
      height: 5.5rem;
    }
  }

  .header.links {
    display: block;
  }
}

// This is old
@media (min-width: 1380px) {
  .nav-toggle,
  .mobile {
    display: none;
  }

  .navigation {
    margin: 0;
    padding: 0;

    ul {
      li {
        margin: 0 2.1rem 0 0;

        &:last-child {
          margin: 0;
        }
      }
    }

    .submenu.level0 {
      @include lib-grid-columns(12);
      margin: 0 auto;
      padding: 4.2rem;
      z-index: 1;

      li {
        &.first {
          grid-column-start: 3;
        }
      }
    }

    .submenu {
      @include lib-grid-columns(12);

      li {
        display: block;
      }
    }

    .submenu.level1 {
      display: block !important;
      position: initial !important;
    }
  }
}

// Nested, because same navigation gets used in the footer
.page-header .navigation {
  & > ul {
    @media (min-width: 1380px) {
      display: inline-flex;
      height: 100%;
    }
  }

  @media (min-width: 1380px) {
    margin-bottom: 0;
    height: 100%;
    display: flex;
  }

  a {
    display: block;
    width: 100%;
    margin-bottom: 0;

    span {
      text-decoration: none;
    }
  }
}

// Active states
.page-header .navigation a.level0.first.level-top.menu-item-active {
  @extend .link;

  /* We want the underlines on mobile on the span because the link needs to be 100% width but the underline doesnt */
  @media (max-width: 1023px) {
    &:before,
    &:after {
      display: none;
    }

    span {
      @include link();
    }
  }
}

.menu-item-active {
  text-decoration: none;
}

.page-header .navigation a.level2.menu-item-active > span {
  text-decoration: none;
}

.page-header .submenu {
  list-style: none;
}

// First level menu container
.page-header .navigation li.level0.level-top {
  @media (min-width: 1380px) {
    margin: 0;
    padding: 0 1rem;
    display: flex;
    align-items: center;

    &:first-child {
      padding-left: 0;
    }
  }

  @media (hover: hover) and (min-width: 1380px) {
    &:hover ul.level0.submenu {
      display: grid;
    }
  }

  & > .level0.submenu {
    @media (min-width: 1380px) {
      top: auto !important;
      max-width: $maxWidth;
      width: 100vw;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%) translateY(100%);
    }

    // The background
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      z-index: 1;

      @media (min-width: 1550px) {
        left: calc((-100vw + #{$maxWidth}) / 2);
      }
    }

    & > li {
      position: relative;
      z-index: 2;
    }
  }

  &:nth-child(4n + 1) .level0.submenu:before {
    background-color: $venro-nav-pale-red-bg;
  }

  &:nth-child(4n + 2) .level0.submenu:before {
    background-color: $venro-nav-pale-orange-bg;
  }

  &:nth-child(4n + 3) .level0.submenu:before {
    background-color: $venro-nav-pale-yellow-bg;
  }

  &:nth-child(4n + 4) .level0.submenu:before {
    background-color: $verno-nav-pale-green;
  }
}

// First level menu items
.page-header a.level0.level-top {
  font-size: 1rem;
  padding: 10px 1rem;
  margin: 0;
  @extend .link;
  @extend .link--mobile;
  line-height: calc(32rem / 14);

  @media (min-width: 1380px) {
    padding: 0;
  }

  span {
    text-transform: uppercase;
    font-family: $font-family-name__nav;
    letter-spacing: 1px;
    color: $verno-nav-link-black;

    @media (min-width: 1380px) {
      letter-spacing: 3px;
    }
  }

  // Hide lines
  &:after {
    @media (min-width: 1380px) {
      display: none;
    }
  }

  &:before {
    display: none;

    @media (min-width: 1380px) {
      display: block;
    }
  }
}

// Active state for submenu container (arrow)
.page-header .navigation .has-sub-menu {
  position: relative;

  &:after {
    content: '';
    background-image: url('../images/icons/arrow.svg');
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    height: 20px;
    width: 20px;
    display: block;
    position: absolute;
    right: 1rem; // Bad visibility with scrollbar -- not applicable to mobile
    top: 15px;
    left: auto;
    pointer-events: none;

    @media (min-width: 1380px) {
      display: none;
    }
  }

  &.active:after {
    transform: rotate(90deg);
  }

  &.active > a + .submenu {
    display: block;
    position: relative;
  }
}

// Submenu titles
.page-header .navigation .submenu.level0 > li {
  & > a {
    font-family: $font-family-name__nav;
    text-transform: uppercase;
    font-size: 0.8571428571rem;
    letter-spacing: 3px;
    line-height: 2.6666666667rem;
    margin-top: 20px;

    @media (min-width: 1380px) {
      margin-top: 0;
    }
  }

  &:first-child a {
    margin-top: 0;
  }
}

// Second level menu
.page-header .navigation .level0.submenu {
  margin: 0;
  padding: 2rem 1rem;
  width: 100%;
  display: none;

  @media (min-width: 1380px) {
    padding-left: $gutterPadding;
    padding-right: $gutterPadding;
  }
}

// Desktop columns
.page-header .navigation .level0.submenu > li {
  @media (min-width: 1380px) {
    grid-column: span 2;

    &.first {
      grid-column: 4 / span 2;
    }
  }

  @media (min-width: 1380px) {
    &.first {
      grid-column: 3 / span 2;
    }
  }
}

// Active events for submenu (show on click + hover (desktop))
.page-header .navigation a.level0.level-top {
  &.active + .submenu {
    position: relative;
    display: block;
  }
}

// Submenu container
.page-header .navigation li.level1.first {
  display: block;
  padding: 0;
}

// Submenu content
.page-header .level1.submenu {
  margin: 0;
  padding: 0;

  span {
    margin: 0;
  }
}

// Submenu title
.page-header a.level1.first {
  font-family: $font-family-name__nav;
  text-transform: uppercase;
  font-size: 0.8571428571rem;
  letter-spacing: 3px;
  line-height: 2.6666666667rem;
}

// Third level (title -> submenu -> items)
.page-header .navigation .level1.submenu a {
  padding: 15px 0;
  line-height: 1;

  @media (min-width: 1380px) {
    padding: 8px 0;
  }

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }

  span {
    font-family: $font-family-name__base;
  }
}

// Default color
.page-header .navigation .level0.level-top > span {
  color: white;
}

// When the page header is active..
.page-header {
  .navigation .level0.level-top > span {
    color: black;
  }
}

.page-header.is-active,
.page-header.is-scrolled {
  .navigation .level0.level-top > span {
    color: black;
  }
}

// Pages with background images have black links
.cms-home .page-header .navigation .level0.level-top > span,
.page-products .page-header .navigation .level0.level-top > span {
  color: black;

  @media (min-width: 1380px) {
    color: white;
  }
}

.cms-home,
.page-products {
  .page-header.is-active,
  .page-header.is-scrolled {
    .navigation .level0.level-top > span {
      color: black;
    }
  }
}

.product-info-price {
  .product-options-wrapper {
    .fieldset > .field {
      margin-bottom: 25px;

      @media (min-width: 769px) {
        margin-bottom: calc(60 / 1440 * 100vw);
      }

      @media (min-width: 1440px) {
        margin-bottom: 60px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    // Heading per option
    .fieldset > .field > .label {
      font-family: $font-family-name__h3;
      display: block;
      line-height: calc(25 / 14);
      margin-bottom: 10px;
    }

    // Mock custom radio's
    .field.choice {
      // Mock the custom input container
      @extend .custom-radio-container;
      @extend .custom-checkbox-container;

      margin-bottom: calc(20rem / 14);

      // Get input out of the document flow
      input[type='radio'],
      input[type='checkbox'] {
        position: absolute;
      }

      // Style the mock radio
      input[type='radio'] + .label,
      input[type='checkbox'] + .label {
        padding-left: calc(0.8571428571rem + 1.2rem);
        min-height: 1.2rem; // Height of dot

        &:before {
          border: 2px solid black;
          position: absolute;
          left: 0;
        }

        &:after {
          border: 2px solid black;
        }

        &:before,
        &:after {
          top: -1px;
          height: 17px;
          width: 17px;
        }
      }

      // Style the mock radio
      input[type='radio'] + .label {
        @extend .custom-radio;
      }

      // Style the mock checkbox
      input[type='checkbox'] + .label {
        @extend .custom-checkbox;
      }

      // Style the label (typography)
      input[type='radio'] + .label > span {
        font-size: 1rem;
        font-family: $font-family-name__base;
      }
    }

    .mage-error {
      color: $error__color;
      font-weight: bold;
    }

    .mock-select .mage-error {
      position: absolute;
      top: 100%;
    }
  }
}

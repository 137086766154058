.page-layout-landingspage {
    .page-header {
        &:before {
            background-color: transparent;
        }
        .page-header__svg {
            fill: white;
        }
        .nav-toggle__nav, .block-title svg g {
            stroke: white;
        }
        .header.links a, .showcart .text {
            color: #fff;
        }
        @media (min-width: 1024px) {
            a.level0.level-top {
                span {
                    color: #fff;
                }
            }
        }
        &.is-scrolled {
            &:before {
                background-color: #fff;
            }
            .page-header__svg {
                fill: #000;
            }
            .nav-toggle__nav,
            .nav-toggle__nav, .block-title svg g {
                stroke: #000;
            }
            .header.links a, .showcart .text {
                color: #000;
            }
        }
        
    }
    
    .hero {
        margin-top: -58px;
        @media (min-width: 1024px) {
            margin-top: -120px;
        }
        .pagebuilder-banner-wrapper {
            background-size: cover;
            position: relative;
            display: flex;
            align-items: center;
            color: white;
            > div {
                height: calc(100vh);
                width: 100%;
                display: flex;
                align-items: center;
            }
        }
        .pagebuilder-poster-content {
            margin-top: calc(-220 / 1440 * 100vw);
            padding-left: 0;
            padding-right: 0;
            position: relative;
            z-index: 2;
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            width: 100%;
            
            [data-element="content"] {
                grid-column: 2 / -2;
            }
            h1, h2, h3, h4, h5 {
                font-family: "Rubik light";
                font-weight: 300;
                margin: 0;
                line-height: calc(60 / 50);
                font-size: calc(32rem / 14);
                color: #fff;
                @media (min-width: 768px) {
                    font-size: calc(50rem / 14);
                }
            }
            p {
                font-size: calc(40rem / 14);
                margin-top: 0;
                line-height: 1;
                font-family: "Noe Display";
                @media (min-width: 768px) {
                    font-size: 4.2857142857rem;
                }
            }
        }
    }



    .halfText {
        padding-left: 0;
        padding-right: 0;
        display: grid !important;
        grid-template-columns: repeat(12, 1fr);
        padding-top: calc(110 / 1440 * 100vw);
        padding-bottom: calc(110 / 1440 * 100vw);
        background-color: white;
        position: relative;
        z-index: 2;
        margin-top: calc(-220 / 1440 * 100vw);
        min-height: calc(-220 / 1440 * 100vw);
        max-width: 110.76923rem;
        margin-left: auto;
        margin-right: auto;

        > * {
            margin: 0;
            grid-column: 2 / -2;
            @media (min-width: 768px) {
                grid-column: 2/8;
            }
            p, ul, li, span {
                font-size: calc(18rem / 14);
                line-height: calc(36 / 24);
                margin: 0;
                @media (min-width: 768px) {
                    font-size: calc(24rem / 14);
                }
            }
        }
    }

    [data-content-type="row"]:not(.hero):not(.halfText):not(.halfImage):not(.halfImageRight) {
        padding-left: 0;
        padding-right: 0;
        display: grid !important;
        grid-template-columns: repeat(12, 1fr);
        padding-top: calc(110 / 1440 * 100vw);
        background-color: white;
        position: relative;
        z-index: 2;
        min-height: calc(-220 / 1440 * 100vw);
        max-width: 110.76923rem;
        margin-left: auto;
        margin-right: auto;
        @media (min-width: 768px) {
            margin-bottom: calc( 50 / 1440 * 100vw);
            padding-top: 0;
        }
        > div {
            grid-column: 2/12;
            > div {
                // p {
                //     display: block;
                //     margin-bottom: 0.5rem;
                //     line-height: 1.5;
                //     text-transform: uppercase;
                //     letter-spacing: 3px;
                //     font-size: calc(10rem / 14);
                //     @media (min-width: 768px) {
                //         margin-bottom: 1rem;
                //         font-size: calc(12rem / 14);
                //     }
                // }
                h1, h2, h3, h4, h5 {
                    margin: 0;
                    font-size: calc(24rem / 14);
                    font-family: "Noe semibold";
                    @media (min-width: 768px) {
                        font-size: calc(30rem / 14);
                    }
                }
            }
        }
    }

    [data-appearance="full-bleed"] {
        max-width: none !important;
        padding-top: calc(120 / 1440 * 100vw) !important;
        padding-bottom: calc(120 / 1440 * 100vw);
        h1,h2,h3,h4,h5 {
            color:#fff;
            margin-bottom: 30px !important;
            @media (min-width: 769px) {
                margin-bottom: calc(50 / 1440 * 100vw) !important;
            }
            @media (min-width: 1440px) {
                margin-bottom: 50px !important;
            }
        }
        .pagebuilder-column-group {
            z-index: 10;
        }
        &:before {
            content: '';
            background: rgba(0,0,0,0.25);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
        .block-products-list, .products-grid {
            display: block;
            .product-items {
                display: grid;
                grid-template-columns: repeat(12, 1fr);
                gap: 1px;
                width: 100%;
                justify-content: space-between;
                padding: 0;
                margin: 0;

                .product-item {
                    grid-column: span 6;

                    @media (min-width: 1024px) {
                        grid-column: span 3;
                    }
                    .product-item-link {
                        &:before, &:after {
                            display: none;
                        }
                        .product-image-photo {
                            width: auto;
                        }
                    }
                }
            }
        }
    }

    [data-content-type="banner"] {
        blockquote {
            position: relative;
            display: flex;
            font-family: "Noe semibold";
            font-weight: 400;
            font-style: italic;
            font-size: calc(32rem / 14);
            line-height: calc(60 / 50);
            grid-column: 2 / -2;
            margin: 70px 0 calc(45 / 1440 * 100vw) 0;
            color: #fff;
            @media (min-width: 769px) {
                font-size: calc(50rem / 14);
                max-width: 50%;
            }
            @media (min-width: 1440px) {
                margin-bottom: 45px;
            }
            &:before {
                content: '“';
                position: absolute;
                top: 0;
                left: 0;
                transform: translateX(-100%) translateX(-5px);
            }
            + p {
                color: #fff;
                font-size: 1rem;
                display: flex !important;
                align-items: center;
                position: relative;
                &:before {
                    display: block;
                    height: 2px;
                    width: 30px;
                    background-color: #fff;
                    margin-right: 30px;
                    content: '';
                }
            }
        }
    }

    .halfImage, .halfImageRight {
        padding-left: 0;
        padding-right: 0;
        display: grid !important;
        grid-template-columns: repeat(12, 1fr);
        padding-bottom: calc(110 / 1440 * 100vw);
        background-color: white;
        position: relative;
        z-index: 2;
        min-height: calc(-220 / 1440 * 100vw);
        max-width: 110.76923rem;
        margin-left: auto;
        margin-right: auto;
        .row-full-width-inner {
            grid-column: 2/12;
            display: grid;
        }
        figure {
            margin: 0;
            grid-column: 2 / -2;
            @media (min-width: 769px) {
                grid-column: 2 / 6;
                align-self: center;
                padding-right: 100px;
            }
            .pagebuilder-mobile-hidden {
                display: none;
                width: 100%;
            }
            @media (min-width: 769px) {
                grid-column: 1 / 6;
                .pagebuilder-mobile-only {
                    display: none;
                }
                .pagebuilder-mobile-hidden {
                    display: block;
                }
            }
            
        }
        .pagebuilder-column-group {
            grid-column: 2 / -2;
            @media (min-width: 769px) {
                grid-column: 7 / 12;
                p, a, ul, li, span {
                    font-size: calc(16rem / 14);
                    line-height: calc(28 / 16);
                }
            }
        }
    }
    .halfImageRight {
        [data-content-type="image"] {
            @media (min-width: 769px) {
                grid-column: 7 / 12;
                padding: 0 0 0 100px;
                order: 2;
                justify-self: end;
                img {
                    max-width: 490px !important;
                }
            }
            
        }
        .pagebuilder-column-group {
            @media (min-width: 769px) {
                grid-column: 1 / 6;
                order: 1;
                align-self: center !important;
            }
            
        }
    }

    a.pagebuilder-button-primary {
        font-family: "Rubik medium";
        box-sizing: border-box;
        padding: 1.21rem 1.43rem;
        border: none;
        z-index: 1;
        position: relative;
        cursor: pointer;
        font-weight: 500;
        letter-spacing: 1px;
        background: #000;
        color: #fff;
        white-space: nowrap;
        &:after {
            display: none;
        }
        &:before {
            background: #48474b;
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            height: 100%;
            transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99) 200ms;
            z-index: -1;
        }
        &:hover {
            &:before {
                width: 100%;
                left: 0;
            }
        }
    }

    .pagebuilder-video-container {
        position: relative;
        padding-top: 56.25%;
        margin-bottom: calc(110 / 1440 * 100vw);
        iframe {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
    }
}

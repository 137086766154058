// * Copyright © Magento, Inc. All rights reserved.
// * See COPYING.txt for license details.
// */
// /**
$fotorama-arw-size: 80px;
$fotorama-duration-time: 0.3s;
$fotorama-fullscreen-zoom-time: 0.3s;
$fotorama-inner-box-shadow: 3px;
$fotorama-spinner-width: 235px;
$fotorama-spinner-height: 64px;
$fotorama-thumb-arrow: 30px;
$fotorama_close_button: 80px;
$size-fotorama-block: 80px;
$z-index-10: 10;
$z-index-1: 1;
$z-index-8: 8;
$z-index-9: 9;

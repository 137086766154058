.authentication-wrapper {
  position: absolute;
  top: 0;
  right: 1rem;
  margin-bottom: 3.57rem;
  z-index: 1;

  @media (min-width: 768px) {
    right: 4rem;
  }
}

.authentication-dropdown {
  position: absolute;
  width: calc(100vw - 2rem);
  background: $verno-light-green;
  padding: 1rem;
  right: 0;

  @media (min-width: 1024px) {
    width: 500px;
  }

  &:focus {
    outline: none;
  }

  .modal-header {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  .input-text,
  .label span {
    font-family: $font-family__base;
    font-size: $root__font-size;
    font-weight: $font-weight__light;
    line-height: $line-height__base;
    margin-bottom: $indent__base;

    + .mage-error {
      margin-top: -1rem;
    }
  }

  .label {
    margin-bottom: 0;
  }

  .action-close {
    padding: 0;
    background: none;
    border: none;
    top: 0;
    right: 0;

    span {
      display: none;
    }

    &:before {
      content: '';
      display: block;
      background-image: url('../images/icons/close.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      height: 1rem;
      width: 1rem;
    }
  }
}

@mixin ac() {
  overflow: hidden;
}

@mixin innerUp() {
  transform: translateY(100%);
  animation: up 1000ms $easing forwards;
}

@mixin innerDown() {
  transform: translateY(-100%);
  animation: down 1000ms $easing forwards;
}

.page-layout-giftcategory {
    .layered-filter-block-container {
        display: none;
    }
    .columns {
        padding-bottom: 30px;
    }
    .products-grid .product-item {
        grid-column: auto / span 12;
        .photo, .product-image-photo {
            width: 100%;
        }
        .product-item-name {
            font-size: 18px;
        }
    }
    .category-seo-top-container, .category-seo-bottom-container {
        background-color: #fff;
        h2, p {
            color: #262626;
        }
        p {
            font-size: 18px;
        }
    }
}

.special_layout_image_swatches {
    .swatch-attribute-options {
        gap: 20px;
    }
}


@media (min-width: 768px) {
    .page-layout-giftcategory {
        .category-seo-top-container, .category-seo-bottom-container {
            background-color: #fff;
            h2 {
                font-size: 50px;
            }
            p {
                font-size: 24px;
            }
        }
        .products-grid .product-item {
            grid-column: auto / span 4;
            .product-item-photo {
                margin: 0 0 14px;
                .product-image-photo {
                    transform-origin: center center;
                    margin: 0;
                }
                &:hover {
                    .product-image-photo {
                        transform: scale(1.15);
                    }
                }
                .product-item__size {
                    display: none;
                }
            }
        }
    }
}

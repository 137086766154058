.steps {
  padding: 0;
  margin-left: -1rem;
  padding-left: 1rem;

  @media (min-width: 769px) {
    width: 100%;
    display: block;
    padding: 0;
    margin-left: 0;
  }
}

.steps__inner {
  @media (min-width: 769px) {
    display: flex;
    background-color: $verno-green;
    align-items: center;
  }
}

.steps__container {
  list-style: none;
  display: grid;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  margin: 0;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1px;
  padding: 0;
  padding-right: 1rem;

  @media (min-width: 769px) {
    overflow: initial;
    display: block;
    padding: 4rem;
    background-color: $verno-green;
    width: 100%;
  }
}

.steps__title {
  display: block;
  font-family: $font-family-name__h4;
  text-transform: none;
  letter-spacing: 0;
  line-height: 1;
  font-size: 1.2857142857rem; // 18/14
  font-weight: 300;
  color: white;
  margin-bottom: 15px;

  @media (min-width: 769px) {
    display: none;
  }
}

.steps__item:first-child .steps__title {
  @media (min-width: 769px) {
    display: block;
    margin-bottom: 30px;
  }
}

.steps__item {
  padding: 30px;
  background-color: $verno-green;
  grid-column: span 1;
  width: calc(100vw - 3.1rem);
  scroll-snap-align: start;

  @media (min-width: 769px) {
    background-color: transparent;
    padding: 0;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    width: 100%;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.steps__item-title {
  color: white;
  font-family: $font-family-name__h4;
  font-size: 2rem; // 28/14
  line-height: 1.2142857143; //34/28
  margin: 0 0 30px;
  font-weight: 400;

  @media (min-width: 769px) {
    line-height: 1.1176470588;
    font-size: 2.4285714286rem;
    margin-bottom: 12px;
  }
}

.steps__item-subtitle {
  font-size: 0.8571428571rem;
  margin: 0 0 15px;
  line-height: 2.2857142857;
  display: block;
}

.steps__link {
  color: white;
}

.steps__image-wrapper {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 250px;
  max-height: 350px;
}

// Mobile image (per slide)
.steps__item-image {
  display: block;
  //max-width: 250px;
  //margin: 20px auto 0;
  max-width: 100%;

  @media (min-width: 769px) {
    display: block;
  }
}

// Desktop image
.steps__desktop-image {
  display: none;

  @media (min-width: 1024px) {
    display: none;
  }

  img {
    max-width: 100%;
    display: block;
  }
}

button.link {
  line-height: 1.8 !important;
  font-family: $font-family__base;
  letter-spacing: 0;
  outline: none;
}

.link-button,
button.action {
  box-sizing: border-box;
  padding: 1.21rem 1.43rem;
  border: none;
  z-index: 1;
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99) 200ms;
    z-index: -1;
  }

  @media (hover: hover) {
    &:hover:before {
      width: 100%;
      left: 0;
    }
  }

  &.primary {
    background: $verno-button-bg;
    color: $verno-button-text-black;

    &:before {
      background: $verno-button-hover-light-green;
    }
  }

  &.primary--inverted {
    background: $verno-button-bg-reverted;
    color: $verno-button-text-white;

    &:before {
      background: $verno-button-hover-black;
    }
  }

  &--link {
    padding: 0;
    @extend .link;
  }

  &.cta {
    background: $verno-button-bg-cta;
    color: $verno-button-text-black;
  }

  &.full-width {
    width: 100%;
    appearance: button;
    font-size: 16px;
  }

  &.split--left {
    background: $verno-button-bg;
    color: $verno-button-text-black;
  }

  &.light,
  &.split--right {
    background: $verno-button-split-right-bg;
    color: $verno-button-text-white;

    &:before {
      background: $verno-button-split-right-bg;
    }
  }
}

.contentmanager-content-view .link-button,
.contentmanager-content-view button.action {
  font-family: $font-family-name__h3;
}

.link-button {
  display: inline-block;

  &:after {
    background: none;
  }
}

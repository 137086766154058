.home-grid {
  @extend .base-grid;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-bottom: 60px;

  @media (min-width: 55rem) {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 120px;
  }

  .steps {
    grid-column: 1 / -1;

    @media (min-width: 769px) {
      grid-column: span 6;
    }
  }

  .more-info-bar {
    grid-column: 1 / -1;
  }

  .product-preview {
    grid-column: 1 / -1;
    margin: 1px 0 0;

    & > p {
      display: none;
    }

    @media (min-width: 769px) {
      display: grid;
      align-items: end;
      grid-template-rows: 1fr auto;
      grid-column: span 6;
      margin: 0;
    }
  }

  .product-preview__link {
    display: none;

    a {
      margin-bottom: 0;
    }

    @media (min-width: 769px) {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 20px;
    }
  }

  .product-items {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1px;
  }
}

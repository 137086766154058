.toolbar-products {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.7rem 0 0.7rem 0;

  @media (min-width: 769px) {
    padding: 1.2rem $filterGutter;
  }

  .custom-select-container {
    border-bottom: 2px solid #fff;
    top: 6px; // Make up for the custom line-height
    min-width: 140px;
  }

  .toolbar-amount {
    align-items: center;
    font-family: $font-family__rubik-semi-bold;
    font-weight: 500;
    color: #fff;
    margin: 0.175rem 0;

    @media (min-width: 768px) {
      margin-right: 2.14rem;
      margin-left: auto;
    }
  }

  .toolbar-sorter {
    display: flex;
    align-items: flex-start;
  }

  .mock-select__text,
  .sorter-label {
    display: none;
    font-family: $font-family__rubik-semi-bold;
    font-weight: 500;
    color: #fff;
    line-height: 1.8;

    @media (min-width: 768px) {
      display: block;
      margin-right: 0.64rem;
    }
  }

  .sorter-label {
    margin: 0.175rem 0.64rem 0.175rem 0;
  }

  .mock-select {
    z-index: 0;
  }

  .mock-select__text {
    font-style: normal;
    display: block;
    padding-right: 1.71rem;
    padding-bottom: 0;
    padding-top: 0;
    margin: 0.175rem 0;
    line-height: 1;
  }

  .sorter-options {
    font-family: $font-family__rubik-semi-bold;
    font-weight: 500;
    padding: 0.3rem 0;
    border-bottom: none;
  }
}

.toolbar {
  .pages,
  .limiter,
  .modes {
    display: none;
  }
}

.toolbar:last-of-type {
  .pages {
    display: block;
  }

  .toolbar-amount,
  .sorter {
    display: none;
  }
}

// /**
// * Copyright © Magento, Inc. All rights reserved.
// * See COPYING.txt for license details.
// */

@mixin fotorama-translate3d($x, $y, $z) {
  transform: translate3d($x, $y, $z);
}

@mixin fotorama-abs-center() {
  @include fotorama-translate3d(-50%, -50%, 0);
  left: 50%;
  position: absolute;
  top: 50%;
}

@mixin fotorama-rotate($deg) {
  transform: rotate($deg);
}

@mixin translateX($value) {
  transform: translateX($value);
}

@mixin translateY($value) {
  transform: translateY($value);
}

//.fotorama-shadow-gradient($x, $y) {
//    background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at $x $y, rgba(0, 0, 0, 0.4), transparent);
//}

@mixin fotorama-inline-block($va: middle) {
  display: inline;
  zoom: 1;
  -moz-box-orient: vertical;
  display: inline-block;
  vertical-align: $va;
}

@mixin fotorama-button-background() {
  background-color: rgba(255, 255, 255, 0.3);
  transition: background-color $fotorama-duration-time ease-in-out;
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
  &:active {
    background-color: rgba(213, 213, 213, 0.5);
  }
}

@include lib-font-face(
  $family-name: $font-family-name__base,
  $font-path: '../fonts/rubik/rubik-light-webfont',
  $font-weight: $font-weight__light,
  $font-style: normal
);

@include lib-font-face(
  $family-name: $font-family-name__h3,
  $font-path: '../fonts/rubik/rubik-medium-webfont',
  $font-weight: $font-weight__light,
  $font-style: normal
);

@include lib-font-face(
  $family-name: $font-family-name__regular,
  $font-path: '../fonts/rubik/rubik-regular-webfont',
  $font-weight: $font-weight__regular,
  $font-style: normal
);

@include lib-font-face(
  $family-name: $font-family-name__h4,
  $font-path: '../fonts/noe/noe-text-book',
  $font-weight: $font-weight__light,
  $font-style: normal
);

@include lib-font-face(
  $family-name: $font-family-name__h1,
  $font-path: '../fonts/noe/noe-text-semibold',
  $font-weight: $font-weight__semibold,
  $font-style: normal
);

@include lib-font-face(
  $family-name: $font-family-name__title,
  $font-path: '../fonts/noe/NoeDisplay-Black',
  $font-weight: $font-weight__black,
  $font-style: normal
);

@include lib-font-face(
  $family-name: $font-family-name__h1,
  $font-path: '../fonts/noe/NoeText-BoldItalic',
  $font-weight: $font-weight__semibold,
  $font-style: italic
);

.link {
  @include link();
}

/* Mobile link without orange lines utility */
.link--mobile {
  &:after,
  &:before {
    display: none;
  }
}

.cookie-opt-out {
  background-color: $verno-burgundy;
  color: white;
  padding: 4rem;
  margin: 2rem 0;

  .action {
    @extend .primary, .cta;
  }
}

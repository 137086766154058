html {
  font-size: $root__font-size;
  color: $color-black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0.6em 0;
}

input,
.messages,
a,
p {
  font-family: $font-family__base;
  font-size: $root__font-size;
  font-weight: $font-weight__light;
  line-height: $line-height__base;
  margin-bottom: $indent__base;
}

.messages {
  margin-bottom: 0;
}

a {
  margin-bottom: 0;
  text-decoration: none;
  color: $verno-black;
}

h1 {
  font-family: $font-family-name__h1;
  font-size: $h1__font-size-desktop;
  font-weight: $font-weight__semibold;
  line-height: $h1__line-height;
  color: $verno-header-primary;
}

h2 {
  font-family: $font-family-name__h2;
  font-size: $h2__font-size;
  font-weight: $font-weight__light;
  line-height: $h2__line-height;
}

h3,
.product.detailed .label,
.products-grid .product-item-name,
.products-grid .product-item-name a,
.product-label,
.product-item-details strong a,
.product-item-name {
  font-family: $font-family-name__h3;
  font-size: $h3__font-size;
  font-weight: $font-weight__heavier;
  line-height: $h3__line-height;
  letter-spacing: $h3__letter-spacing;
  text-transform: uppercase;
  color: $verno-header-primary;
}

h4,
.price {
  font-family: $font-family-name__h4;
  font-size: $h4__font-size;
  font-weight: $font-weight__light;
  line-height: $h4__line-height;
}

.navigation span,
.navigation a {
  &.small a,
  &.small span {
    font-family: $font-family-name__base;
  }
}

.navigation .level2 span {
  font-family: $font-family-name__regular;
  color: black;
}

ul,
ol {
  font-family: $font-family-name__base;
}

.link-button,
button {
  font-family: $font-family-name__regular;
  font-weight: $font-weight__heavier;
  letter-spacing: 1px;
  text-align: left;

  &.cta {
    font-family: $font-family-name__cta;
  }
}

.header.content {
  .contact {
    h3 {
      font-size: $root__font-size;
      margin: 0 0 1rem 0;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
}

.price {
  font-size: 25px;

  .old-price & {
    font-size: 15px;
    color: #9b9b9b;
    text-decoration: line-through;
    margin-left: 1.5rem;
  }
}

.action.back {
  display: inline-block;
  vertical-align: middle;
  font-family: $font-family__rubik-semi-bold;
  font-weight: 500;
  padding-left: 1.8rem;
  margin: 1.166rem 0;
  line-height: 1;

  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    background-image: url('../images/icons/arrow-bold.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    height: 1rem;
    width: 1rem;
    transform: rotate(180deg) translateX(1.8rem);
  }

  &:after {
    display: none;
  }
}

.shipping-information-content,
.billing-address-details,
address {
  font-family: $font-family__base;
  font-size: $root__font-size;
  font-weight: $font-weight__light;
  line-height: $line-height__base;
  margin-bottom: $indent__base;
  font-style: normal;

  a:before,
  a:after {
    display: none;
  }
}

strong {
  font-weight: bold;
}

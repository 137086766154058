// Typography
.page-footer {
  a {
    @extend .link;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0 0 1.6em;

    a {
      margin: 0 0 0 0;
      display: inline;
    }
  }

  input,
  select {
    font-style: italic;
  }

  .subscribe {
    fieldset {
      max-width: none;
    }
  }

  .legal {
    p,
    a {
      font-size: 12px;

      &:before,
      &:after {
        content: none;
      }
    }
    p {
      margin: 0 0 0;
    }
  }

  .review p {
    font-size: 24px;
    margin: 0 0 0.3em;

    strong {
      font-weight: 800;
    }
  }

  .navigation {
    display: block;

    span {
      font-family: $font-family-name__base;
    }
  }

  .footer-form-title {
    margin-top: 0;
    margin-bottom: calc(14 / 10 * 1rem);

    @media (min-width: 1024px) {
      margin-bottom: 2em;
    }
  }

  /* Screwed selector because markup comes from the database? */
  .footer > .column:first-child ul:not(.horizontal-list) a {
    @extend .link;
  }

  .footer .legal a:hover,
  .footer .navigation a:hover {
    @media (hover: hover) {
      text-decoration: underline;

      &:before,
      &:after {
        content: none;
      }
    }
  }
}

.page-footer {
  background: $verno-footer-bg;
  padding-bottom: 50px; // For the "Help" button
}

.footer {
  @extend .base-grid;
  @include lib-grid-columns(12);
  margin: 0 auto;
  padding-top: 2.8rem;
  padding-bottom: 1.4rem;
}

.footer__heading {
  margin-bottom: 2em;
}

.footer__bottom {
  border-top: 2px solid white;
}

.footer .column {
  grid-column: auto / span 12;
  margin: 0 0 calc(40 / 14 * 1rem);

  @media (min-width: 1024px) {
    padding: 0 8.3% 0 0;
    margin-bottom: calc(50 / 14 * 1rem);
  }

  &:first-child {
    @media (min-width: 1024px) {
      grid-column: auto / span 4;
      padding-right: 25%;
    }
  }

  @media (min-width: 1024px) {
    margin: 0;
    padding: 0 33.333333% 0 0;
    grid-column: auto / span 3;
  }

  // Can we add a class for this?
  & > h3:first-child {
    margin: 0 0 2em 0;
  }
}

/* Right side of the footer */
.footer .column--right {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column: 1 / -1;

  @media (min-width: 1024px) {
    grid-column: span 5;
    grid-template-columns: repeat(6, 1fr);
  }
}

/* Column review */
.footer .column--product {
  grid-column: span 6;
}

/* Column exception (2 columns) -- Products (last one) */
.footer .column--small {
  grid-column: span 6;

  @media (min-width: 1024px) {
    grid-column: auto / span 2;
    padding-right: 0;
  }
}

/* Column full size */
.footer .column--full {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: calc(45 / 14 * 1rem);

  @media (min-width: 1024px) {
    grid-column: 1 / -1;
  }
}

// Review
.footer .review {
  grid-column: 1 / -1;

  a:hover {
    text-decoration: none;
  }
}

// Navigation in Footer
.footer .navigation ul {
  flex-direction: column;

  li {
    margin: 0;
    padding: 0;
  }

  span {
    color: $verno-nav-link-black;
  }
}

/* Social */
.footer__social-list {
  li {
    width: 20px;

    @media (hover: hover) {
      &:hover img {
        opacity: 0.6;
      }
    }
  }

  a {
    display: block;

    &:before,
    &:after {
      content: none;
    }
  }

  img {
    max-width: 100%;
    display: block;
  }
}

/* Country custom dropdown */
.select-country {
  margin: 0 0 3.5rem;
}

/* Subscribe form */
.footer__subscribe-form {
  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
  }
}

/* Legal text */
.legal {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  ul {
    margin-bottom: 0;
  }
}

.legal__title {
  margin-right: 2.1rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 300;
  font-size: calc(12rem / 14);
  line-height: 1.8;
  flex-basis: 100%;

  @media (min-width: 1024px) {
    flex-basis: auto;
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Store */

.store {
  ul {
    margin-bottom: 0;

    @media (min-width: 768px) {
      margin-bottom: initial;
    }
  }
}

/* Footer shops */
.page-footer .shops {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  justify-content: flex-start;
}

.shops__item {
  margin-left: 10px;
  width: 20px;

  &:first-child {
    margin-left: 0;
  }

  @media (min-width: 1024px) {
    margin-left: 20px;
    width: 25px;
    margin-bottom: 0;
  }

  img {
    display: block;
    max-width: 100%;
  }
}

#newsletter-error {
  color: $error__color;
  font-weight: bold;
}

// Individual widths
.shops__item--klarna {
  width: 60px;
}

.shops__item--ideal {
  width: 40px;
}

.shops__item--mastercard {
  width: 90px;
}

.shops__item--paypal {
  width: 50px;
}

.shops__item--thuiswinkel {
  width: 80px;
}

.shops__item--visa {
  width: 40px;
}

.shops__item--bancontact {
  width: 40px;
}

.shops__item--ecommerce {
  width: 50px;
}

.shops__item--becommerce {
  width: 40px;
}

.shops__item--giropay {
  width: 50px;
}

.shops__item--sofort {
  width: 50px;
}

.shops__item--trusted {
  width: 40px;
}

.shops__item--eps {
  width: 50px;
}

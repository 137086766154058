.quote {
  padding-left: 0;
  padding-right: 0;
  margin: 0 auto;
}

.quote__text {
  position: relative;
  display: flex;
  font-family: $font-family-name__h1;
  font-weight: 400;
  font-style: italic;
  font-size: calc(32rem / 14);
  line-height: calc(60 / 50);
  grid-column: 2 / -2;
  margin-bottom: calc(45 / 1440 * 100vw);

  @media (min-width: 769px) {
    font-size: calc(50rem / 14);
    grid-column: 2 / 9;
  }

  @media (min-width: 1440px) {
    margin-bottom: 45px;
  }

  &:before {
    content: '“';
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%) translateX(-5px);
  }
}

.quote__author {
  grid-column: 2 / 9;
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: calc(25 / 14);
  font-style: normal;

  &:before {
    height: 2px;
    width: 30px;
    background-color: black;
    margin-right: 30px;
    content: '';
  }
}

/* This file contains all the implementation detail for blog blocks on the blog page */
.blog-item {
  margin-bottom: 40px;

  @media (min-width: 769px) {
    margin-bottom: calc(80 / 1440 * 100vw);
  }

  @media (min-width: 1440px) {
    margin-bottom: 80px;
  }
}

.customer-account-create {
  .columns {
    @extend .base-grid;
    padding-top: 2.1rem;
  }

  .form-create-account {
    display: flex;
    flex-direction: column;
  }

  fieldset {
    flex-basis: 100%;
    border: none;
    margin: 0;
    padding: 0;
    margin-bottom: 3.92rem;

    @media (min-width: 768px) {
      max-width: 50%;
    }
  }

  .password-strength-meter,
  .mage-error,
  .label,
  .custom-checkbox__text {
    font-family: $font-family__base;
    font-size: $root__font-size;
    font-weight: $font-weight__light;
    line-height: $line-height__base;
  }

  .input-text {
    font-family: $font-family__base;
  }

  .legend {
    font-family: $font-family-name__h1;
    font-size: 1.71rem;
    font-weight: $font-weight__semibold;
    line-height: $h1__line-height;
    color: $verno-header-primary;
  }

  .custom-checkbox__text {
    font-style: normal;
    line-height: 1.5;
  }

  .custom-checkbox:before {
    border: 2px solid grey;

    .page-footer & {
      border: none;
    }
  }

  .persistent {
    display: flex;
    align-items: flex-end;
    margin-bottom: 1.78rem;
  }

  .tooltip.wrapper {
    margin-left: 1rem;
  }

  .no-margin-bottom:not(.search) {
    margin-bottom: 0;
  }

  .field:not(.search) {
    margin-bottom: 4rem;
  }

  .no-margin-bottom:not(.search) {
    margin-bottom: 0;
  }
}

.homepage-header {
  position: relative;
  padding-top: 14.7rem;
  padding-bottom: calc(30 / 14 * 1rem);

  @media (min-width: 1025px) and (max-width: 1441px) {
    padding-top: 9.4583333vw;
    padding-bottom: 12.2361111111vw;
  }

  @media (min-width: 1441px) {
    padding-top: 11.4583333vw;
    padding-bottom: 14.2361111111vw;
  }
}

.homepage-header__buttons {
  @media (min-width: 768px) {
    width: 24rem;
  }
}

.homepage-header__button {
  margin: 0 0 0.7rem 0;
  width: 100%;

  &:last-child {
    margin: 0.7rem 0 0;
    display: inline-block;
  }
}

.homepage-header {
  #react-configure-frame-flow button {
    width: 100%;
  }
}

.homepage-header__title-cnt {
  margin-bottom: calc(30 / 14 * 1rem);
  grid-column: auto / span 12;

  @media (min-width: 1024px) {
    margin-bottom: 40px;
  }
}

.homepage-header__title {
  font-size: 3.8rem;
  line-height: 1;
  color: white;
  font-family: $font-family-name__title;
  margin: 0;

  @media (min-width: 1024px) {
    font-size: 4.2857142857rem;
  }
}

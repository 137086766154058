.catalog-category-view {
  .columns {
    @extend .base-grid;
  }

  .pages {
    padding: 2.85rem 0 2.14rem 0;

    @media (min-width: 768px) {
      padding: 4.28rem 0;
    }
  }

  .message.info.empty {
    color: white;
    font-family: $font-family-name__h2;
    font-size: $h2__font-size;
    font-weight: $font-weight__light;
    line-height: $h2__line-height;
    padding: 8rem 0;
  }

  // When the mobile filter is active place content over header
  .page-main.filter-active {
    z-index: 12;
  }

  .page-header__svg {
    fill: white;
  }

  .products-grid {
    display: block;
  }
}

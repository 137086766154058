.custom-select-container {
  display: block;
  position: relative;
  cursor: pointer;
  border-bottom: 2px solid black;

  select {
    margin-bottom: 0;
    cursor: pointer;
  }

  // Custom arrow for the select
  &:before {
    content: '';
    width: 10px;
    height: 10px;
    display: block;
    background-image: url('../images/icons/chevron-bold.svg');
    background-size: 6px 10px;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    pointer-events: none;
  }

  &.white {
    &:before {
      background-image: url('../images/icons/chevron-white.svg');
    }
  }

  &.large {
    display: flex; // make children fill out height

    .mock-select {
      padding-right: 2.8571428571rem;
      border-bottom: 2px solid white;
    }
  }
}

/* Custom select */
.mock-select {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  select {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    appearance: menulist-button;
    cursor: pointer;
  }
}

.mock-select__text {
  font-size: 1rem;
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding: 8px 0;
  font-style: italic;
  font-weight: 300;
}

#layered-filter-block-container {
  position: relative;
  background: #fff;
  cursor: pointer;
  padding: 1.42rem 1rem;
  z-index: 1;

  @media (min-width: 769px) {
    padding: 0;
    z-index: 3;
  }

  #layered-filter-block {
    @media (min-width: 769px) {
      display: flex;
      align-items: center;
      padding: 0 $filterGutter;
    }
  }

  .block-title {
    display: flex;
    justify-content: space-between;
    font-family: $font-family__rubik-semi-bold;
    font-weight: 500;
    letter-spacing: 1px;

    span:focus {
      font-weight: 600;
      outline: 0;
    }

    @media (min-width: 769px) {
      display: none;
    }

    .chevron-expand {
      transform: rotate(90deg);
    }
  }

  .filter-current-subtitle,
  .filter-subtitle,
  .filter-count-label {
    display: none;
  }

  .filter-actions {
    @media (min-width: 769px) {
      margin-left: auto;
    }
  }

  .filter-clear {
    color: #9b9b9b;
    font-family: $font-family__base;
    font-size: 0.85rem;
    text-decoration: underline;
    margin-bottom: 0;
  }

  .filter-options-item {
    padding-bottom: 1.42rem;

    &:last-child {
      padding-bottom: 0;
    }

    @media (min-width: 769px) {
      padding-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }

    @media (min-width: 1024px) {
      margin-right: calc(1440vw / 150);
    }

    @media (min-width: 1440px) {
      margin-right: 150px;
    }
  }

  .filter-options-title {
    display: flex;
    justify-content: space-between;
    position: relative;
    font-family: $font-family__rubik-semi-bold;
    font-weight: 500;
    letter-spacing: 1px;
    cursor: pointer;

    @media (min-width: 769px) {
      display: inline-block;
      padding-top: 1.42rem;
      padding-bottom: 1.42rem;
    }

    &:focus {
      outline: none;
    }

    span:after {
      display: block;
      width: 100%;
      height: 2px;
      background-color: #000;
    }

    .chevron-expand {
      transition: transform 250ms cubic-bezier(0.6, 0, 0.01, 0.9);
      display: block;

      @media (min-width: 769px) {
        top: 50%;
        right: -15px;
        position: absolute;
        transform: translateY(-50%) rotate(90deg);
      }
    }
  }

  .active > .filter-options-title .chevron-expand {
    transform: translateY(-50%) rotate(-90deg);
  }

  .active > .filter-options-content .items,
  .active > .filter-options-content .swatch-layered {
    @media (min-width: 769px) {
      animation: SubMenuIn 700ms cubic-bezier(0.6, 0, 0.01, 0.9) forwards;
    }
  }

  .filter-title strong:focus {
    outline: none;
  }

  .filter-content {
    display: none;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;

    @media (min-width: 769px) {
      display: block;
      overflow: inherit;
      position: inherit;
      flex: 1;
      padding-right: 50px;
    }
  }

  .filter-options {
    background-color: #fff;
    //animation: SubMenuIn 700ms cubic-bezier(0.6, 0, 0.01, 0.9) forwards;
    padding: 1rem;

    @media (min-width: 769px) {
      display: flex;
      padding: 0;
      align-items: center;
      justify-content: space-between;
    }

    @media (min-width: 1024px) {
      justify-content: flex-start;
    }
  }

  .filter-options-item.has-items {
    .filter-options-title > span {
      @extend .link;
      pointer-events: none; // disable hover
      line-height: 1.4;

      // Underline must be black
      &:before,
      &:after {
        background: $verno-black;
      }
    }
  }

  .filter-options-item.active {
    .filter-options-content {
      display: block;
    }
  }

  .filter-options-content {
    overflow: hidden;
    margin: 0;
    display: none;

    @media (min-width: 769px) {
      position: absolute;
      width: 100%;
      margin-top: 0;
      transform: translateX(-50%);
      left: 50%;
    }

    .items,
    .swatch-layered {
      margin: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      background-color: #f5f5ef;
      padding: 1rem;

      @media (min-width: 769px) {
        padding: $filterGutter;
      }
    }

    .item,
    .swatch-attribute-options li {
      margin: 0.175rem;
      display: inline-block;

      &.active a .price,
      &.active a {
        background-color: #000;
        color: #f5f5ef;
      }

      .count {
        color: #9b9b9b;
      }
    }

    .item a .price,
    .item a,
    .swatch-attribute-options li a {
      font-family: $font-family__base;
      display: inline-block;
      font-size: 0.9rem;
      padding: 0.71rem;
      background-color: #fff;
      border-radius: 2px;
      margin-bottom: 0;
      line-height: 1;
    }

    .item a .price {
      padding: 0;
    }

    .swatch-attribute-options a {
      margin-bottom: 0;
    }
  }

  // hide loading animation, remove later.
  .ln_overlay {
    display: none;
  }

  .dimension {
    display: flex;
    flex-wrap: wrap;
  }

  .dimension__input {
    flex: 1;
    font-family: $font-family__base;
    margin-bottom: 0;

    @media (min-width: 769px) {
      flex-basis: calc(35% - 0.71rem);
    }
  }

  .dimension__unit {
    font-family: $font-family__base;
    display: flex;
    align-self: center;
    flex-basis: auto;
    margin-left: 0.71rem;
  }

  .dimension__seperator {
    font-family: $font-family__base;
    display: flex;
    align-self: center;
    flex-basis: auto;
    margin: 0 1rem;

    @media (min-width: 769px) {
      flex-basis: 10%;
      padding-left: 0.335rem;
    }
  }

  //override css created by multiple layers plugins!
  ol.sy-multiple-layered-navigation-items li {
    &:only-child {
      width: 100%;
    }

    a {
      background-image: none !important;
      background-color: #fff !important;
    }

    &.active a {
      background-color: #000 !important;
    }
  }

  // Hide before detaching and moving filter-current
  .filter-current {
    display: none;
  }

  // Swatches
  .filter-options-content {
    .swatch-attribute-options {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    .swatch-attribute-options li a {
      display: flex;
      align-items: center;
    }

    .swatch-attribute-options li.selected a {
      background-color: black;
      color: white;
    }

    .swatch-option {
      opacity: 1;
      margin-right: 0.71rem; // Dude
    }

    .swatch-label {
      display: block;
    }

    // Color exceptions for Swatches
    .swatch-cnt.selected .swatch-option[option-tooltip-value='#000000'] {
      border: 1px solid white !important;
    }
    .swatch-cnt.selected .swatch-option[option-tooltip-value='#ffffff'] {
      border: 1px solid white !important;
    }

    /* Dimensions */
    .dimension fieldset {
      margin: 0;
      padding: 0;
      border: 0;
      width: 100%;
    }

    .dimension__cnt {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      @media (min-width: 769px) {
        flex-wrap: nowrap;
        max-width: 500px;
      }
    }

    .dimension__submit {
      @media (min-width: 769px) {
        margin-left: 1rem;
      }
    }
  }

  .swatch-option:before,
  .swatch-option:after {
    display: none;
  }
}

#layer-product-list {
  .filter-current {
    display: flex;
    flex: 1;
    align-content: flex-start;
    padding: 0;

    .items {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding-left: 0;
      list-style: none;

      @media (min-width: 769px) {
        padding-right: 1rem;
      }
    }

    .item {
      background-color: #000;
      border-radius: 2px;
      height: 30px;
      padding-left: 9px;
      padding-right: 9px;
      margin: 0 0.175rem 0 0;
      display: flex;
      align-items: center;
      position: relative;

      span {
        padding: 0;
        font-size: 11px;
        font-family: $font-family__base;
        line-height: 1;
        color: #fff;
        display: block;
        position: relative;
        z-index: 2;
      }

      a {
        line-height: 1;
        display: block;
        width: 8px;
        margin-left: 0.175rem;

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.3);
          content: '';
          display: block;
          opacity: 0;
          z-index: 1;
          transition: opacity 120ms ease-out;
          pointer-events: none;
        }

        img {
          max-width: 100%;
          position: relative;
          z-index: 2;
        }

        &:hover:before {
          opacity: 1;
        }
      }
    }
  }

  .filter-current-subtitle {
    display: none;
  }
}

@import '_mobile-filter-bar';

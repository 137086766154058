.catalog-category-view {
  // TODO: add (random? catalog?) background image
  background-color: $verno-green;
}

// TODO: add typography
// TODO: change item layout (title first) in template
// TODO: add regular + special price styling
// TODO: add sale label styling

// PRODUCT GRID

.products-grid {
  .items {
    margin: 0;
    padding: 0;
    // TODO: add old browser grid fallback
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr);
    grid-gap: 1px;
  }

  .product-item {
    grid-column: auto / span 6;

    @media (min-width: 530px) {
      grid-column: auto / span 4;
    }

    @media (min-width: 1024px) {
      grid-column: auto / span 3;
    }
  }

  li {
    list-style-type: none;
  }
}

.article-step {
  padding: calc(110 / 1440 * 100vw) 0;
  color: white;
  background: $verno-green;
}

.article-step__title {
  color: white;
  margin-bottom: calc(70 / 1440 * 100vw);

  @media (min-width: 1440px) {
    margin-bottom: 70px;
  }
}

.article-step__img {
  margin-bottom: calc(40rem / 14);

  @media (min-width: 769px) {
    margin-bottom: 0;
  }
}

.hyvareactcheckout-reactcheckout-index {
  .page-main {
    @extend .base-grid;
  }

  .country {
    svg {
      height: 14px !important;
      margin-right: -10px;
    }
  }

  h3 {
    font-family: "Noe semibold";
    font-size: 1.71rem;
    letter-spacing: normal;
    text-transform: none;
  }

  div[class$="-control"] {
    border-radius: 0;
    border: none;
    border-bottom: 2px solid black;
  }
  div[class$="-control"] div:first-child {
    padding: 2px 0;
  }
  #shipping_method .disabled-method .title:after {
    color: #fff;
  }
  #shipping_method div[class$="-control"], #shipping_address .mt-2.form-control, #payment_method .mt-2.form-control {
    border-bottom: none;
  }
  span[class$="-indicatorSeparator"] {
    display: none;
  }
  div[class$="-indicatorContainer"] svg {
    fill: black;
  }
  .btn {
    border: none;
    height: 3.9rem;
  }
  [type='checkbox'], [type='radio'] {
    color: #000;
  }
  .page-footer {
    z-index: auto;
  }
  #overview .hidden .pb-2 {
    border-color: #f5f5ef;
  }

  @media (min-width: 1024px) {
    .logo.page-header__logo {
      margin-top: -8px;
    }
  }

  #payment_method {
    .disabled-method {
      .title {
        color: #9B9B9B;
        &:after {
          color: #fff;
        }
        &:before {
            background-color: #9B9B9B;
        }
      }
      .py-4 {
        display: none;
      }
    }
    .py-4 {
      @media (min-width: 1024px) {
        padding-left: 42px;
      }
    }
  }

  #coupon_code input[name=text]  {
    border-color: #000;
  }
  #coupon_code input[name=text]  ~ .feedback:after {
    display: none;
  }

  #login {
    .my-4 {
      @media (min-width: 1024px) {
        padding-left: 42px;
      }
    }
  }

  .nav-toggle, .mobile-header-cart {
    display: none;
  }

  .page-title-wrapper {
    display: block;
  }

  .checkout-container {
    @media (min-width: 1024px) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  ol,
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  fieldset {
    border: none;
    margin: 0;
    padding: 0;
  }

  .checkout-shipping-address {
    .select,
    .input-text {
      padding: 0.5rem;
      width: 100%;
    }
  }

  .authentication-wrapper,
  .opc-estimated-wrapper {
    flex: 100%;
  }

  .field {
    margin-bottom: 1rem;

    .field-tooltip {
      background: #f5f5ef;
      padding: 1rem;
      display: inline-block;

      #tooltip-label.label {
        font-weight: bold;
      }
    }

    span.note {
      display: block;
      padding: 1rem;
    }
  }
  p {
    color: #262626;
  }
  label {
    display: block;
    margin-bottom: 0.5rem;
    letter-spacing: 0.25px;
  }
  .bg-custom-gray-6 {
    background-color: #F5F5F0;
  }
  #shipping_address label, #login label:not(.custom-radio-container) {
    font-family: "Rubik medium";
    color: #262626;
  }
  .custom-radio-container {
    margin-bottom: 0;
    color: #262626;
  }

  .field.street.required span:after,
  .field._required .label:after {
    content: '*';
    font-size: 1rem;
  }

  .street .field {
    margin-bottom: 0;

    .input-text {
      margin-bottom: 0.5rem;
    }
  }

  .street.field.required > .label > span {
    display: none;
  }

  .street .field._required .label:after {
    display: none;
  }

  .step-title {
    font-family: $font-family-name__h1;
    font-size: 1.71rem;
    font-weight: $font-weight__semibold;
    line-height: $h1__line-height;
    color: $verno-header-primary;
    margin-bottom: 1.785rem;
  }

  .authentication-dropdown {
    display: none;

    &._show {
      display: block;
    }
  }

  .opc-progress-bar,
  .estimated-block {
    margin-bottom: 3.57rem;
  }

  .estimated-label,
  .estimated-price {
    font-family: $font-family-name__h4;
    font-size: $h4__font-size;
    font-weight: $font-weight__light;
    line-height: $h4__line-height;
  }

  .minicart-wrapper {
    display: none;
  }

  .table-checkout-shipping-method {
    width: 100%;
    margin-bottom: 3.57rem;

    thead {
      display: none;
    }

    .col-price {
      width: 150px;
    }

    .custom-radio {
      margin-right: 1rem;
    }

    .col-method {
      width: 1.42rem;
    }

    .row {
      cursor: pointer;
    }
  }

  .custom-radio {
    &:before {
      border: 2px solid #000;
    }
  }

  .shipping-address-items {
    @media (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .shipping-address-item {
    border: 2px solid #f8f5ed;
    padding: 1rem;

    @media (min-width: 768px) {
      width: 50%;
      padding-right: 1.4rem;
      padding-bottom: 1.4rem;
    }

    &.selected-item {
      border-color: $verno-gold;
    }
  }

  .action-select-shipping-item {
    margin-top: 1rem;
  }

  .opc-wrapper {
    margin-bottom: 3.57rem;

    @media (min-width: 1024px) {
      flex: 60%;
      padding-right: 8.333%;
    }
  }

  .opc-summary-wrapper {
    .modal-header {
      display: none;
    }

    @media (min-width: 1024px) {
      flex: 40%;
    }
  }

  .items-in-cart {
    .title {
      display: none;
    }

    .minicart-items {
      display: flex !important;
    }
  }

  .checkout-shipping-address {
    margin-bottom: 3.57rem;

    @media (min-width: 768px) {
      padding-right: 8.333%;
    }
  }

  .messages {
    flex-basis: 100%;
  }

  [data-role='email-with-possible-login'],
  .opc-payment,
  .items-in-cart,
  .table-totals,
  .checkout-billing-address {
    margin-bottom: 3.57rem;
  }

  .product-item-name {
    font-family: $font-family-name__h1;
    font-style: normal;
    text-transform: none;
    font-size: 1.375rem;
    letter-spacing: 0px;
  }

  .title {
    margin-bottom: 1.785rem;
    font-size: 24px !important;
    padding: 7px 0 5px 55px;
  }

  .action-edit-address {
    margin-top: 1.785rem;
  }

  .checkout-billing-address {
    .custom-checkbox__text {
      line-height: 1.5;
    }

    .custom-checkbox:before {
      border: 2px solid #000;
    }
  }

  .select {
    background-color: transparent;
    width: auto;
  }

  .field-select-billing {
    .label {
      font-family: $font-family-name__base;
    }
  }

  .shipping-information {
    @media (min-width: 768px) {
      display: flex;
    }

    .action {
      @extend .link;
      margin: 0;
      padding: 0;
      background-color: transparent;
      font-family: $font-family-name__base;
      line-height: 1.5;

      &:before {
        top: auto;
      }
    }

    .shipping-information-title {
      font-family: $font-family-name__h3;
      font-size: $h3__font-size;
      font-weight: $font-weight__heavier;
      line-height: $h3__line-height;
      letter-spacing: $h3__letter-spacing;
      text-transform: uppercase;
      color: $verno-header-primary;
    }

    .ship-to,
    .ship-via {
      @media (min-width: 768px) {
        flex-basis: 50%;
      }
    }
  }

  .discount-code {
    .payment-option-title:focus {
      outline: none;
      cursor: pointer;
    }

    &._active .action-toggle:after {
      transform: rotate(-90deg);
    }

    .action-toggle {
      font-family: $font-family__rubik-semi-bold;
      font-weight: 500;
      letter-spacing: 1px;
      margin-bottom: 0.5rem;

      &:after {
        content: '';
        display: inline-block;
        background-image: url('../images/icons/chevron-bold.svg');
        height: 9px;
        width: 5px;
        transform: rotate(90deg);
        margin-left: 0.78rem;
        transition: transform 250ms cubic-bezier(0.6, 0, 0.01, 0.9);
      }
    }
  }

  .item-options {
    background: $verno-light-green;
    padding: 1rem;
    margin-top: 2.14rem;
    width: calc(100% - 2rem);
    z-index: 2;

    @media (min-width: 768px) {
      margin-top: 0.71rem;
      width: auto;
    }

    dt,
    dd {
      font-family: $font-family__base;
      margin: 0 0 5px 0;
    }

    dt {
      font-weight: bold;
    }
  }
}

.checkout-onepage-success,
.checkout-onepage-failure {
  .page-title-wrapper {
    display: block;
  }

  .page-main {
    @extend .base-grid;
  }
}

@keyframes ProgressBar {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.checkout-onepage-success {
  .page-main {
    padding-bottom: 3.92rem;
  }
}

.agreements-modal {
  display: none;

  ._has-modal & {
    display: block;
  }

  .checkout-agreements-item-content {
    height: 70vh;
    overflow-y: auto;
  }
}

.payment-method {
  .payment-method-content {
    align-items: flex-start;
    flex-direction: column;
    padding: 0 4px;
    width: 100%;
  }

  .payment-method-title {
    width: 45%;
  }

  &._active {
    .payment-method-content {
      margin-top: 1rem;

      .select {
        align-self: flex-start;
      }

      .checkout-agreements-block {
        .checkout-agreements {
          .checkout-agreement {
            margin: 1rem 0;

            button {
              cursor: pointer;
              background: white;
              border: none;
              color: #000;
              text-decoration: none;
              position: relative;
              display: inline-block;
              padding: 0.72rem 0.45rem;
              margin: 0;

              &:before {
                display: none;
              }

              &:after {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                height: 2px;
                width: 100%;
                background: #feac00;
                right: 0;
                -webkit-transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99)
                  100ms;
                transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99) 100ms;
              }

              &:hover:after {
                width: 0;
                -webkit-transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99);
                transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99);
              }
            }
          }
        }
      }
    }

    .actions-toolbar {
      .primary {
        button {
          box-sizing: border-box;
          padding: 1.21rem 1.43rem;
          border: none;
          z-index: 1;
          position: relative;
          cursor: pointer;
          background-color: #feac00;
          color: #000;
          font-family: 'Rubik medium';

          &:before {
            content: '';
            background: #f5f5ef;
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            height: 100%;
            -webkit-transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99)
              200ms;
            transition: width 600ms cubic-bezier(0.6, 0, 0.01, 0.99) 200ms;
            z-index: -1;
          }

          &:hover:before {
            width: 100%;
            left: 0;
          }

          &:after {
            display: none;
          }
        }
      }
    }
  }
}

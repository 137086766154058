.customer-account-login {
  .page-main {
    @extend .base-grid;
    padding-top: 2.1rem;
  }

  .login-container {
    display: flex;
    flex-wrap: wrap;
  }

  .block-customer-login,
  .block-new-customer {
    flex-basis: 100%;
    margin-bottom: 3.92rem;

    @media (min-width: 768px) {
      flex-basis: 50%;
      padding-right: 8.33%;
    }
  }

  fieldset {
    border: none;
    margin: 0;
    padding: 0;
  }

  .block-title {
    font-family: $font-family-name__h1;
    font-size: 1.71rem;
    font-weight: $font-weight__semibold;
    line-height: $h1__line-height;
    color: $verno-header-primary;
  }

  .label,
  .custom-checkbox__text,
  .note {
    font-family: $font-family__base;
    font-size: $root__font-size;
    font-weight: $font-weight__light;
    line-height: $line-height__base;
  }

  .custom-checkbox__text {
    font-style: normal;
    line-height: 1.5;
  }

  .note {
    margin-bottom: 2rem;
  }

  .persistent {
    display: flex;
    align-items: flex-end;
    margin-bottom: 1.78rem;
  }

  .tooltip.wrapper {
    margin-left: 1rem;
    margin-bottom: -0.15rem;
  }

  .actions-toolbar .login {
    margin-bottom: 1.78rem;
  }

  .custom-checkbox:before {
    border: 2px solid grey;
  }

  .field:not(.search) {
    margin-bottom: 4rem;
  }

  .no-margin-bottom:not(.search) {
    margin-bottom: 0;
  }
}

.tooltip.remember-me__tooltip {
  display: inline-block;
  background: $verno-gold;
  padding: 0.3rem;
  color: $verno-black;
  font-style: normal;
  line-height: 1;

  &:before,
  &:after {
    display: none;
  }
}

/* Some resets */
.filter-mobile__header {
  padding: 1rem;
  position: relative;
  background-color: white;
  display: none;

  @media (min-width: 769px) {
    display: block;
    padding: 0;
    order: 2;
  }
}

.filter-mobile__footer,
#close-mobile-filter,
#filter-mobile__results-btn {
  display: block;

  @media (min-width: 769px) {
    display: none;
  }
}

#close-mobile-filter,
#filter-mobile__results-btn,
#filter-mobile__back {
  border: 0;
  margin: 0;
  outline: none;
}

#filter-mobile__back {
  display: none;
  position: absolute;
  left: 0;
  background: none;
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  justify-content: center;
  transform: translateX(
    -25%
  ); // Because the button is bigger than the icon for usability

  img {
    max-width: 20px;
    transform: rotate(180deg);
  }

  @media (min-width: 769px) {
    display: none;
  }
}

#close-mobile-filter {
  position: absolute;
  right: 1rem;
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  transform: translateX(
    25%
  ); // Because the button is bigger than the icon for usability

  img {
    max-width: 15px;
  }

  @media (min-width: 769px) {
    display: none;
  }
}

.filter-mobile__title-cnt {
  margin-right: auto;
  position: relative;
  display: flex;
  align-items: center;
}

.filter-mobile__title {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1;
  font-family: $font-family-name__cta;

  @media (min-width: 769px) {
    display: none;
  }
}

.is-layered {
  #filter-mobile__back + .filter-mobile__title {
    margin-left: 35px;
  }

  #filter-mobile__back {
    @media (max-width: 769px) {
      display: flex;
    }
  }

  .filter-actions {
    @media (max-width: 769px) {
      display: none;
    }
  }
}

.filter-mobile__footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  z-index: 2;
}

#layer-product-list {
  .filter-current {
    @media (max-width: 1025px) {
      display: none;
    }
  }
}

/* When the filter is active.. */
.filter-active {
  //overflow: hidden; <-- Why is this?

  .filter-mobile__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    position: relative;
    z-index: 2;
  }

  #layered-filter-block {
    @media (max-width: 1025px) {
      padding-bottom: 50px; // Make space for floating button
    }
  }

  #layered-filter-block-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    overflow: auto;
    padding: 0;
    z-index: 15;

    .block-title {
      display: none;
    }

    // Clear all link
    .block-actions {
      margin-left: 1rem;
    }

    .filter-content {
      display: block;
      left: auto;
      top: auto;
      position: relative;
      overflow: visible;

      @media (max-width: 1025px) {
        position: static;
      }
    }

    .filter-options {
      @media (max-width: 1025px) {
        padding: 0;
      }
    }

    .filter-options .filter-actions {
      display: none;
    }

    .filter-options-content {
      @media (max-width: 1025px) {
        margin: 0;
      }

      .item,
      .swatch-cnt {
        margin: calc(4 / 14 * 1rem);
      }

      .item a,
      .swatch-cnt a {
        font-size: 1rem;
        padding: 1rem;

        &:active {
          background-color: #f5f5f5;
        }
      }

      .swatch-option {
        margin-right: 1rem;
      }
    }

    // Sub-filter
    .filter-options-item {
      padding-bottom: 0;
      border-bottom: 2px solid $verno-light-green;

      &:first-child {
        border-top: 2px solid $verno-light-green;
      }
    }

    .filter-options-title {
      @media (max-width: 1025px) {
        padding: 1rem;
        font-family: $font-family-name__base;
      }

      .chevron-expand {
        transform: none;
      }
    }

    .dimension__submit {
      flex-basis: 100%;
      margin-top: 1rem;
    }
  }

  .filter-options-item.active {
    .filter-options-content {
      @media (max-width: 769px) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $verno-light-green;
        z-index: 1;
        padding-top: 60px; // Spacing for header..
      }
    }
  }
}

body:not(.prevent-filter-animation) {
  .filter-options-item {
    // Hide all items by default
    .item,
    .swatch-cnt,
    .dimension {
      @media (max-width: 769px) {
        opacity: 0;
        transform: translateX(35px);
      }
    }
  }

  .filter-options-item {
    // Base animation for all items
    .item,
    .swatch-cnt,
    .dimension {
      @media (max-width: 769px) {
        animation: slideIn 400ms cubic-bezier(0.6, 0, 0.01, 0.9) forwards;
      }
    }

    @for $i from 1 through 50 {
      .item:nth-child(#{$i}),
      .swatch-cnt:nth-child(#{$i}),
      .dimension:nth-child(#{$i}) {
        @media (max-width: 769px) {
          animation-delay: calc(100ms + (#{$i} * 20ms));
        }
      }
    }
  }
}

.swatch-attribute-options {
  display: flex;
  flex-wrap: wrap;

  &:focus {
    outline: none;
  }
}

// disable all focus outlines
.product-options-wrapper:focus,
.product-options-wrapper > .fieldset:focus,
.swatch-opt:focus,
.swatch-attribute:focus {
  outline: none;
}

.swatch-attribute-label,
.swatch-attribute-selected-option,
.super-attribute-select,
.swatch-label {
  display: none; // Temporary solution till either template or javascript is fixed.
}

.swatch-opt {
  margin: 0 0 2.1rem 0.33rem;
}

.swatch-option {
  position: relative;
  width: 1.15rem;
  height: 1.4rem;
  margin: 0 1.28rem 0 0;
  opacity: 0.5;
  transition: opacity 600ms cubic-bezier(0.6, 0, 0.01, 0.99);
  cursor: pointer;

  // add border if swatch is white.
  &[option-tooltip-value='#ffffff'] {
    border: 1px solid grey;

    &.selected {
      border: none;
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1.8rem;
    height: 2rem;
    border: 2px solid black;
    transform: translate(-50%, -50%) scale(0.5);
    transition: transform 600ms cubic-bezier(0.6, 0, 0.01, 0.99);
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: inherit;
  }

  &:focus {
    outline: none;
  }

  &:hover:before {
    transform: translate(-50%, -50%) scale(1);
  }

  &.selected {
    opacity: 1;

    &:before {
      transform: translate(-50%, -50%) scale(1);
    }
  }
  &.image {
    width: 42px !important;
    height: 44px !important;
    background-size: contain !important;
    opacity: 1;
    &:before {
      width: 53px !important;
      height: 55px !important;
      transform: translate(-50%, -50%) scale(1);
    }
    &.selected:before {
      border: solid 2px #000;
    }
    &:not(.selected):before {
      border: solid 2px #ccc;
    }
  }
}
.portraitLandscapeSwitch {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
  .switch {
    border: none;
    background: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    border: solid 2px #ccc;
    display: flex;
    justify-content: end;
    padding: 10px;
  }
  .portrait, .landscape {
    &.active {
      border: solid 2px #000;
    }
  }
  .portrait:before, .landscape:before {
    content: '';
    width: 20px;
    height: 40px;
    border: dashed 2px #000;
    display: block;
    margin-bottom: 5px;
  }
  .landscape:before {
    width: 40px;
    height: 20px;
    margin-bottom: 15px;
  }
}

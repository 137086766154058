.payment-method {
  padding: 1rem 0;
  border-bottom: 2px solid $verno-light-green;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .custom-radio {
    display: inline-block;
    margin-right: 1rem;
  }

  .payment-icon {
    display: block;
  }

  .messages,
  .select {
    margin: 0;
  }

  .select {
    margin-bottom: 1.785rem;
    align-self: flex-start;

    @media (min-width: 768px) {
      margin-bottom: 0;
      width: 50%;
    }
  }

  .payment-method-content .messages {
    flex-basis: 0;
  }

  .custom-radio-container {
    margin-bottom: 0;

    > span {
      margin-right: 1rem;

      @media (min-width: 768px) {
        margin-right: 0;
        width: 50%;
      }
    }
  }

  .payment-method-title.field {
    margin: 0;
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: 768px) {
      margin-bottom: 0;
      width: 50%;
      flex-direction: row;
    }
  }

  .payment-method-content {
    display: flex;
    width: 100%;
    align-items: flex-end;

    @media (min-width: 768px) {
      width: 50%;
      align-items: center;
    }

    .select {
      margin-bottom: 0;

      @media (min-width: 768px) {
        margin-bottom: inherit;
      }
    }
  }

  .custom-radio-container {
    display: flex;
    align-items: center;
    flex-basis: 100%;
    cursor: pointer;
  }

  .actions-toolbar {
    margin-left: 0;

    button {
      background-color: transparent;
      border: none;
      padding: 0;
    }
  }

  .checkout-agreements-block,
  .actions-toolbar {
    display: none;
  }

  &._active {
    .checkout-agreements-block,
    .actions-toolbar {
      display: block;
    }
  }

  .custom-radio span {
    font-size: 1.14rem;
  }
}

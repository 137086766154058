.checkout-cart-index {
  .page-main {
    @extend .base-grid;
  }

  .loading-mask {
    position: fixed;
    top: 0;
    right: 0;
    background: $verno-white;
    z-index: 99;
    width: 100%;
    height: 100%;

    @media (min-width: 1024px) {
      width: 40%;
    }
  }

  .loader {
    display: block;
    position: absolute;
    top: 50%;
    width: $fotorama-spinner-width;
    height: $fotorama-spinner-height;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .discount {
    margin-bottom: 3.92rem;
  }

  .cart-container {
    margin-bottom: 3.92rem;

    @media (min-width: 1024px) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .cart-summary,
  .cart.items {
    @media (min-width: 768px) {
      width: 100%;
    }

    @media (min-width: 1024px) {
      max-width: inherit;
    }

    > .item {
      display: block;
      padding-top: 2rem;
      padding-bottom: 2rem;
      border-bottom: 2px solid $verno-light-green;

      @media (min-width: 768px) {
        display: flex;
        align-items: center;
      }
    }
  }

  .cart-summary {
    @media (min-width: 1024px) {
      flex-basis: 40%;
      padding-left: 4.1665%;
    }
  }

  .table-caption {
    text-align: left;
    font-family: $font-family-name__h2;
    font-size: $h2__font-size;
    font-weight: $font-weight__light;
    line-height: $h2__line-height;
    margin-bottom: 2.14rem;
  }

  #block-shipping {
    display: none;
  }

  .page-title-wrapper {
    display: block;
  }

  .discount {
    .title {
      font-family: $font-family__rubik-semi-bold;
      font-weight: 500;
      letter-spacing: 1px;
      margin-bottom: 0.5rem;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }

    .chevron-expand {
      margin-left: 0.78rem;
      transform: rotate(90deg);
      transition: transform 250ms cubic-bezier(0.6, 0, 0.01, 0.9);
    }

    &.active .chevron-expand {
      transform: rotate(-90deg);
    }
  }

  .table-wrapper {
    overflow: auto;
    margin-bottom: 3.92rem;

    &.cart {
      max-width: inherit;
    }

    @media (min-width: 768px) {
      overflow: initial;
    }
  }

  .cart.actions {
    display: block;

    @media (min-width: 768px) {
      display: block;
    }

    .action,
    .update {
      align-self: flex-start;
    }
  }

  .label,
  .cart-totals {
    margin-bottom: 3.92rem;
    font-family: $font-family__base;
    font-size: $root__font-size;
    font-weight: $font-weight__light;
    line-height: $line-height__base;
    margin-bottom: $indent__base;

    .table-caption {
      font-family: $font-family-name__h1;
    }

    .table {
      width: 100%;
    }

    .mark {
      text-align: left;
      font-weight: 300;

      .label {
        font-weight: inherit;
      }
    }
  }

  .product-item-name a {
    font-family: $font-family-name__h1;
    font-style: normal;
    text-transform: none;
    font-size: 1.375rem;
    letter-spacing: 0px;

    &:after,
    &:before {
      display: none;
    }
  }

  .form-cart {
    td span {
      font-family: $font-family__base;
      font-size: $root__font-size;
      font-weight: $font-weight__light;
      line-height: $line-height__base;
    }

    .label,
    thead span {
      font-family: $font-family-name__h3;
      font-size: $h3__font-size;
      font-weight: $font-weight__heavier;
      line-height: $h3__line-height;
      letter-spacing: $h3__letter-spacing;
      text-transform: uppercase;
      color: $verno-header-primary;
    }

    .col {
      text-align: left;
      font-size: 12px;
      width: 25%;
    }

    @media (min-width: 1024px) {
      flex-basis: 60%;
      padding-right: 4.1665%;
    }
  }

  .checkout-methods-items {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;

    @media (min-width: 768px) {
      justify-content: flex-start;

      .checkout {
        position: static;
      }
    }
  }

  .product-item-photo {
    @media (min-width: 768px) {
      margin-right: 1rem;
    }
  }

  .item-info {
    display: flex;
    flex-wrap: wrap;

    @media (min-width: 768px) {
      align-items: center;
      padding-top: 1.5em;
      padding-bottom: 1.5em;
      width: 100%;
    }

    .col {
      padding: 0 1rem 0 0;

      @media (min-width: 768px) {
        padding: 0 2rem 0 0;
      }

      &:last-child {
        padding: 0;
      }
    }

    .item {
      flex-basis: 100%;
      margin-bottom: 1.78rem;

      @media (min-width: 768px) {
        flex-basis: 40%;
        margin-bottom: 0;
        display: flex;
        align-items: center;
      }

      img {
        @media (min-width: 768px) {
          margin: 0;
        }
      }

      .product-item-details {
        display: flex;
        justify-content: space-between;

        @media (min-width: 768px) {
          display: block;
          margin: 0;
        }
      }

      .product-item-details,
      .product-item-photo {
        @media (min-width: 768px) {
          flex-basis: 50%;
          margin-bottom: 0;
        }
      }
    }

    .col.price,
    .col.qty,
    .col.subtotal {
      flex-basis: 33.333%;

      span {
        display: block;
      }

      @media (min-width: 768px) {
        flex-basis: 20%;

        .label {
          display: none;
        }
      }
    }

    .qty {
      padding-top: 0;
      padding-bottom: 0;
      margin: 0;
    }

    .product-item-details {
      height: 2.85rem;
    }

    .product-image-photo {
      @media (min-width: 1400px) {
        max-width: 100%;
      }
    }
  }

  .item-actions {
    text-align: right;

    @media (max-width: 787px) {
      display: block;
    }

    td {
      @media (max-width: 787px) {
        margin-left: auto;
        display: block;
        margin-top: -29px;
      }
    }
  }

  thead {
    display: none;
    border-bottom: 2px solid $verno-light-green;

    @media (min-width: 768px) {
      display: block;
      padding-right: 1.42rem;
      padding-bottom: 1rem;
    }

    tr {
      @media (min-width: 768px) {
        display: flex;
      }
    }

    th {
      @media (min-width: 768px) {
        flex-basis: 20%;
      }

      &.item {
        @media (min-width: 768px) {
          flex-basis: 40%;
        }
      }
    }
  }

  .action-delete {
    &:before,
    &:after {
      display: none;
    }

    img {
      height: 1rem;
    }
  }

  .continue {
    margin-bottom: 1.96rem;
  }

  .update {
    border: none;
    padding: 0;
    background-color: transparent;
    line-height: 1.5;

    @media (min-width: 768px) {
      margin-left: 1rem;
    }

    &:before {
      top: auto;
      height: 2px;
    }
  }

  .item-options {
    position: absolute;
    display: none;
    background: $verno-light-green;
    padding: 1rem;
    margin-top: 2.14rem;
    width: calc(100% - 2rem);
    z-index: 2;

    @media (min-width: 768px) {
      margin-top: 0.71rem;
      width: auto;
    }

    dt,
    dd {
      font-family: $font-family__base;
      margin: 0 0 5px 0;
    }

    dt {
      font-weight: bold;
    }
  }

  .toggle-item-options {
    display: block;
    position: relative;

    &:focus {
      outline: none;
    }

    &:after {
      content: '';
      display: inline-block;
      background-image: url('../images/icons/chevron-bold.svg');
      background-repeat: no-repeat;
      background-position: center;
      height: 0.71rem;
      width: 0.71rem;
      transform: rotate(90deg);
      transition: transform 250ms cubic-bezier(0.6, 0, 0.01, 0.9);
      margin-left: 0.78rem;
    }

    &.active:after {
      transform: rotate(-90deg);
    }
  }

  .toggle-item-options.active ~ .item-options {
    display: block;
  }
}
